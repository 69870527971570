import { keyBy, looseTypeKeyBy } from "@libs/utils/keyBy";
import { maybeLighten } from "@libs/utils/maybeLighten";

export const treatmentPlanCategoryOption = {
  label: "Cadet Blue",
  value: "#73A9AD",
  light: "#E3EEEF",
} as const;
export const unitedBlueOption = { label: "United Blue", value: "#5F8CE6", light: "#D1D9E9" } as const;
export const TREATMENT_PLAN_CATEGORY_COLOR = treatmentPlanCategoryOption.value;

export const ColorOptions = [
  { label: "Wine", value: "#6C2E2E", light: "#E2D5D5" },
  { label: "Auburn", value: "#A62626", light: "#F3E1E1" },
  { label: "Teracotta", value: "#BB6464", light: "#F1E0E0" },
  { label: "Brown", value: "#795323", light: "#F7ECDE" },
  { label: "Sandy", value: "#F3A471", light: "#FFDDC7" },
  { label: "Maize", value: "#EAC63F", light: "#FFF6D4" },
  { label: "Khaki", value: "#E6DC88", light: "#EFEEE5" },
  { label: "Vegas Gold", value: "#CCB257", light: "#ECE3C3" },
  { label: "Acid", value: "#BABD1C", light: "#F1F2D2" },
  { label: "Green Yellow", value: "#BECC62", light: "#F6FCD0" },
  { label: "June Bud", value: "#B9E25E", light: "#E8F1D4" },
  { label: "Forest", value: "#76AC76", light: "#E4EEE4" },
  { label: "Moss Green", value: "#83A04E", light: "#C2D2A5" },
  { label: "Morning", value: "#879788", light: "#E7EAE7" },
  { label: "Sap Green", value: "#497C23", light: "#D4DFCC" },
  { label: "Bottle Green", value: "#0E6655", light: "#CFE0DD" },
  treatmentPlanCategoryOption,
  { label: "Blue Steel", value: "#33808B", light: "#D1DFE1" },
  { label: "Teal", value: "#099AA8", light: "#CEEBEE" },
  { label: "Shadow", value: "#748DA6", light: "#E3E8ED" },
  { label: "Sky", value: "#77D2DC", light: "#E3F6F8" },
  unitedBlueOption,
  { label: "Baby Blue", value: "#ADD3FA", light: "#EFF6FE" },
  { label: "Blue Gray", value: "#5E698C", light: "#D0DBFF" },
  { label: "Egyptian Blue", value: "#0035A3", light: "#CCD7ED" },
  { label: "Violet", value: "#5F55B9", light: "#DFDDF1" },
  { label: "Pink", value: "#EE65AA", light: "#FCE0EE" },
  { label: "Fuchsia", value: "#9C36A8", light: "#EBD7EE" },
  { label: "Plum", value: "#E1A1F0", light: "#F9ECFC" },
  { label: "Rose", value: "#F4728A", light: "#FFD1D1" },
] as const;

export const colorsByName = keyBy(ColorOptions, "label");

const colorsByValue = looseTypeKeyBy(ColorOptions, "value");
const colorsByLightValue = looseTypeKeyBy(ColorOptions, "light");

/*
  Given a color, return the light version of that color. At one point
  we had a mapping of dark to light colors and this function will preserve those
  colors. If the color is not part of that mapping, we'll lighten the color
  if it's dark.
*/
export const getLightColor = (color: string) => {
  const upper = color.toUpperCase();
  const darkMatch = colorsByValue[upper];

  // for backwards compatibility we'll use the legacy mapping of dark to light
  if (darkMatch) {
    return darkMatch.light;
  }

  const lightMatch = colorsByLightValue[upper];

  // for backwards compatibility we'll make sure that light colors chosen
  // won't get lightened further
  if (lightMatch) {
    return lightMatch.light;
  }

  // for colors that aren't part of our legacy mapping we'll lighten them
  // if they're dark
  const adjusted = maybeLighten(color);

  return adjusted;
};

/*
  Given a color, will only return the dark version of the color if it was
  one of the orginal color mappings we offered otherwise it just returns the color
*/
export const getDarkColor = (color: string) => {
  const upper = color.toUpperCase();
  const lightMatch = colorsByLightValue[upper];

  if (lightMatch) {
    return lightMatch.value;
  }

  return color;
};
