import { FC, useState, useMemo, useCallback } from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getPagingDetails } from "@libs/utils/queries";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { useSearchKeyboardNavigation } from "@libs/hooks/useSearchModal";
import { CommandPaletteSearchInput } from "@libs/components/UI/CommandPaletteSearchInput";
import { getPatientLink } from "components/Patients/patientLinks";
import { SearchPatientsNoResults } from "components/Main/SearchPatientsNoResults";
import { SearchPatientResult } from "components/Main/SearchPatientResult";

import { getRecentSearchHistory, searchForPatients } from "api/patients/queries";
import { clearRecentSearches } from "api/patients/mutations";

import { useNullablePathParams } from "hooks/usePathParams";

import { paths, routesConfig } from "utils/routing/paths";
import { handleError } from "utils/handleError";

export const SearchPatientsModal: FC<{
  onRequestClose: Func;
  onClickPatient?: (patient: PatientSummaryVO) => void;
}> = ({ onRequestClose, onClickPatient }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const patientTab = useNullablePathParams("patientTab")?.tab;

  const { practiceId } = useAccount();
  const [searchString, setSearchString] = useState("");
  const { search: debouncedSearchString } = useDebouncedSearch(searchString);
  const { searchResultsRef, highlightedIndex, handleMouseEnterResult, handleKeyDownSearch } =
    useSearchKeyboardNavigation(debouncedSearchString);

  const [recentSearchPatientsQuery, searchPatientsQuery] = useApiQueries([
    getRecentSearchHistory({ args: { practiceId } }),
    searchForPatients({
      args: {
        practiceId,
        searchString: debouncedSearchString,
        pageSize: 10,
        pageNumber: 1,
      },
      queryOptions: { enabled: debouncedSearchString.length > 0 },
    }),
  ]);

  const [clearRecentSearchesMutation] = useApiMutations([clearRecentSearches]);

  const hasMoreResults = useMemo(() => {
    const totalPages = getPagingDetails(searchPatientsQuery)?.totalPages;

    return Boolean(totalPages && totalPages > 1);
  }, [searchPatientsQuery]);

  const handleClearRecentSearches = () => {
    clearRecentSearchesMutation.mutate({ practiceId }, { onError: handleError });
  };

  const handleAddAppointment = useCallback(
    (patientId: number) => {
      if (
        matchPath(routesConfig.addPatientAppointment.path, location.pathname) ||
        matchPath(routesConfig.scheduleAppointment.path, location.pathname) ||
        matchPath(routesConfig.editAppointment.path, location.pathname) ||
        matchPath(routesConfig.scheduleAsap.path, location.pathname)
      ) {
        navigate(
          paths.addPatientAppointment({
            patientId,
            from: new URLSearchParams(location.search).get("from") ?? undefined,
          }),
          { replace: true }
        );
      } else {
        navigate(paths.addPatientAppointment({ patientId, from: getFullUrl(location) }));
      }

      onRequestClose();
    },
    [location, navigate, onRequestClose]
  );

  return (
    <Modal centerVertically={false} onClickOutside={onRequestClose} onClose={onRequestClose} size="xs">
      <div className="pt-3 px-3 pb-2 rounded-t">
        <CommandPaletteSearchInput
          searchString={searchString}
          onChangeSearch={setSearchString}
          onKeyDownSearch={handleKeyDownSearch}
          isSearching={searchPatientsQuery.isLoading}
          placeholder="Search Patients"
        />
      </div>

      <ModalContent className="px-3 pb-3">
        <QueryResult
          queries={[recentSearchPatientsQuery, searchPatientsQuery]}
          loading={
            <div className="h-[3.875rem]">
              <LoadingContent />
            </div>
          }
        >
          {recentSearchPatientsQuery.data && !searchPatientsQuery.data ? (
            <>
              <div
                className={`
                  flex
                  justify-between
                  px-3
                  py-2
                  font-sansSemiBold
                  text-xs
                  text-slate-900
                `}
              >
                <span>Recent</span>
                {recentSearchPatientsQuery.data.length > 0 ? (
                  <Button onClick={handleClearRecentSearches} theme="link">
                    Clear
                  </Button>
                ) : null}
              </div>

              {recentSearchPatientsQuery.data.length > 0 ? (
                <div ref={searchResultsRef}>
                  {recentSearchPatientsQuery.data.map((patient, index) => (
                    <SearchPatientResult
                      key={patient.id}
                      patient={patient}
                      patientLink={getPatientLink(patient.id, patientTab)}
                      onLinkToPatient={onRequestClose}
                      onAddAppointment={handleAddAppointment}
                      onMouseEnterResult={() => handleMouseEnterResult(index)}
                      onClick={onClickPatient}
                      isHighlighted={index === highlightedIndex}
                      isRecent
                    />
                  ))}
                </div>
              ) : (
                <SearchPatientsNoResults />
              )}
            </>
          ) : searchPatientsQuery.data ? (
            searchPatientsQuery.data.length > 0 ? (
              <div ref={searchResultsRef}>
                {searchPatientsQuery.data.map((patient, index) => (
                  <SearchPatientResult
                    key={patient.id}
                    patient={patient}
                    patientLink={getPatientLink(patient.id, patientTab)}
                    onLinkToPatient={onRequestClose}
                    onAddAppointment={handleAddAppointment}
                    onMouseEnterResult={() => handleMouseEnterResult(index)}
                    isHighlighted={index === highlightedIndex}
                  />
                ))}
              </div>
            ) : (
              <SearchPatientsNoResults />
            )
          ) : null}
        </QueryResult>
      </ModalContent>

      {searchPatientsQuery.data && hasMoreResults ? (
        <div className="border-t border-t-slate-200 px-3 py-2">
          <ModalFooterButtons>
            <Link
              className="font-sansSemiBold text-xs text-archyBlue-500"
              to={paths.patients(searchString ? { search: searchString } : undefined)}
              onClick={onRequestClose}
            >
              See all results
            </Link>
          </ModalFooterButtons>
        </div>
      ) : null}
    </Modal>
  );
};
