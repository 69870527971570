import { produce } from "immer";
import { GetApiMethod } from "@libs/@types/api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import {
  AppointmentVO,
  PatientListRequest,
  PatientLookupRequest,
  PatientSummaryVO,
  PersonalDetailsVO,
} from "@libs/api/generated-api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getNextPatientAppointmentQuery = makeQuery({
  queryKey: ["practices", "getNextPatientAppointment"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    patientId,
    practiceId,
    {
      // TODO required for tags, remove when backend is updated
      fetchIcons: true,
    },
  ],
  queryOptions: fiveMinuteCache,
});

const allButArchivedPatientFilter: PatientCriteriaStatus[] = [
  "PROSPECT",
  "ACTIVE",
  "INACTIVE",
  "NONPATIENT",
  "DECEASED",
];

const withDefaultPatientQuery = (query: ApiPatientQueryParams): ApiPatientQueryParams => {
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "patientCriteria.status": allButArchivedPatientFilter,
    ...query,
  };
};

const withDefaultPatientData = (data: PatientListRequest): PatientListRequest => {
  if (!data.patientCriteria?.status || data.patientCriteria.status.length === 0) {
    return produce(data, (draft) => {
      if (draft.patientCriteria) {
        draft.patientCriteria.status = allButArchivedPatientFilter;
      } else {
        draft.patientCriteria = { status: allButArchivedPatientFilter };
      }
    });
  }

  return data;
};

export const getPatientQuery = makeQuery({
  queryKey: ["practices", "getPatient"],
  formatParams: ({
    practiceId,
    patientId,
    includeContactPatientDetails = false,
  }: {
    practiceId: number;
    patientId: number;
    includeContactPatientDetails?: boolean;
  }) => [patientId, practiceId, { includeContactPatientDetails }],
  queryOptions: oneMinuteCache,
});

export const getPatientSummary = makeQuery({
  queryKey: ["practices", "getPatientSummary"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    patientId,
    practiceId,
  ],
  queryOptions: oneMinuteCache,
});

export const searchForPatients = makeQuery({
  queryKey: ["practices", "getPatients"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & ApiPatientQueryParams) => {
    return [practiceId, withDefaultPatientQuery(query)];
  },
});

export type AppointmentCriteria = NonNullable<ApiPatientQueryParams["appointmentCriteria"]>;
export type OrderBy = "ASCENDING" | "DESCENDING";
export type PatientCriteriaGender = NonNullable<PersonalDetailsVO["gender"]>;
export type PatientCriteriaInsuranceVerified = "VERIFIED" | "NOT_VERIFIED" | "AUTO_VERIFIED" | "FAILED";
export type PatientCriteriaStatus = NonNullable<PersonalDetailsVO["status"]>;
export type SortColumn =
  | "balanceAmount"
  | "insurance"
  | "firstName"
  | "lastName"
  | "nextAppointment"
  | "phone"
  | "ssn";

export type ApiPatientQueryParams = Omit<
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  Parameters<GetApiMethod<"practices", "getPatients">>[1],
  "sortColumn" | "orderBy"
> & {
  sortColumn?: SortColumn;
  orderBy?: OrderBy;
};

export type InifinitePatientsQueryResponse = UseInfiniteApiListQueryResult<PatientSummaryVO>;

export const getInfinitePatients = makeInfiniteQuery({
  queryKey: ["practices", "getPatients"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & ApiPatientQueryParams) => [
    practiceId,
    withDefaultPatientQuery(query),
  ],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getInfinitePatientsV2 = makeInfiniteQuery({
  queryKey: ["practices", "getPatientsV2"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
    data: PatientListRequest;
  }) => [practiceId, query, withDefaultPatientData(data)],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getLookupPatientQuery = makeQuery({
  queryKey: ["practices", "lookupPatient"],
  formatParams: ({ practiceId, ...lookup }: { practiceId: number } & PatientLookupRequest) => [
    practiceId,
    lookup,
  ],
  queryOptions: oneMinuteCache,
});

export const lookupPatientSummary = makeQuery({
  queryKey: ["practices", "lookupPatientSummary"],
  formatParams: ({ practiceId, ...lookup }: { practiceId: number } & PatientLookupRequest) => [
    practiceId,
    lookup,
  ],
  queryOptions: oneMinuteCache,
});

export const getMedicalHistoryAuditQuery = makeQuery({
  queryKey: ["practices", "getMedicalHistoryAudit"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    patientId,
    practiceId,
  ],
  queryOptions: oneMinuteCache,
});

interface GetPatientAppointmentParams {
  practiceId: number;
  patientId: number;
  dateMode?: "FUTURE" | "PAST";
  includeStates?: AppointmentVO["state"][];
  excludeStates?: AppointmentVO["state"][];
  pageNumber: number;
  pageSize: number;
  searchString?: string;
}

export const getPatientAppointmentsQuery = makeQuery({
  queryKey: ["practices", "getPatientAppointments"],
  formatParams: ({ practiceId, patientId, ...query }: GetPatientAppointmentParams) => [
    patientId,
    practiceId,
    query,
  ],
  queryOptions: fiveMinuteCache,
});

export const getPatientRecallsQuery = makeQuery({
  queryKey: ["practices", "getPatientRecalls"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    practiceId,
    patientId,
  ],
  queryOptions: oneMinuteCache,
});

export const getFamilyMembersV2Query = makeQuery({
  queryKey: ["v2", "getFamilyMembersV2"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    includeUnlinked?: boolean;
  }) => [practiceId, patientId, query],
  queryOptions: oneMinuteCache,
});

export const getInfiniteAppointmentsQuery = makeInfiniteQuery({
  queryKey: ["practices", "getPatientAppointments"],
  formatParams: ({ practiceId, patientId, ...query }: GetPatientAppointmentParams) => [
    patientId,
    practiceId,
    query,
  ],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});

export const getRecentSearchHistory = makeQuery({
  queryKey: ["v2", "getRecentSearchHistoryV2"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: cacheForSession,
});

export const getPatientCallCard = makeQuery({
  queryKey: ["practices", "getPatientCallCard"],
  formatParams: ({
    practiceId,
    patientId,
    phone,
  }: {
    practiceId: number;
    patientId: number;
    phone?: string;
  }) => [practiceId, patientId, { phone }],
  queryOptions: oneMinuteCache,
});
