import { useMemo, FC } from "react";
import { Navigate } from "react-router-dom";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { UNAUTHORIZED } from "@libs/utils/statusCodes";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingState } from "components/Main/LoadingState";
import { getEmployee } from "api/employee/queries";
import { getSupportUser } from "api/user/queries";
import { paths } from "utils/routing/paths";
import { getPracticeFeatureStates, getPracticeInfoById } from "api/practice/queries";
import { isMFARequiredError } from "utils/isMfaRequiredError";
import { RecoverError } from "components/Main/RecoverError";
import { SupportUserProvider } from "components/Main/SupportUserProvider";
import { EmployeeUserProvider } from "components/Main/EmployeeUserProvider";
import { PracticeProvider } from "components/Main/PracticeProvider";
import { AccountProvider } from "components/Main/AccountProvider";
import { FeatureFlagsContextProvider } from "contexts/FeatureFlagsContext";

export const CurrentUserProvider: FC<{
  email: string;
  userId: number;
  practiceId: number;
  isSupportUser: boolean;
}> = ({ practiceId, userId, email, isSupportUser }) => {
  const [employeeQuery, supportUserQuery, practiceQuery, featureStatesQuery] = useApiQueries([
    getEmployee({
      args: { practiceId, employeeId: userId, includeRole: true },
      queryOptions: { enabled: !isSupportUser },
    }),
    getSupportUser({
      args: { practiceId, supportUserId: userId },
      queryOptions: { enabled: isSupportUser },
    }),
    getPracticeInfoById({
      args: { practiceId },
    }),
    getPracticeFeatureStates({ args: { practiceId } }),
  ]);

  // Unauthorized errors that aren't MFA errors are errors related to the user's tokens.
  // MFA errors are handled in the api client error handlers and will redirect to mfa setup.
  // All other errors should just show an error screen
  const unauthorizedError = useMemo(() => {
    const employeeError = employeeQuery.error;
    const supportUserError = supportUserQuery.error;
    const practiceError = practiceQuery.error;

    if (employeeError?.status === UNAUTHORIZED && !isMFARequiredError(employeeError)) {
      return employeeError;
    }

    if (supportUserError?.status === UNAUTHORIZED && !isMFARequiredError(supportUserError)) {
      return supportUserError;
    }

    if (practiceError?.status === UNAUTHORIZED && !isMFARequiredError(practiceError)) {
      return practiceError;
    }

    return null;
  }, [employeeQuery.error, supportUserQuery.error, practiceQuery.error]);

  const employeeStatusSignOutReason = useMemo(() => {
    const employee = employeeQuery.data;

    if (!employee) {
      return undefined;
    }

    const status = employee.employmentDetails.status;

    return status
      ? isOneOf(status, ["INACTIVE", "ARCHIVED", "FURLOUGHED"])
        ? status
        : undefined
      : "INACTIVE";
  }, [employeeQuery.data]);

  return unauthorizedError ? (
    <Navigate
      replace
      to={paths.signOut({
        signOutReason: "UNAUTHORIZED",
      })}
    />
  ) : employeeStatusSignOutReason ? (
    <Navigate replace to={paths.signOut({ signOutReason: employeeStatusSignOutReason })} />
  ) : (
    <QueryResult
      queries={[employeeQuery, supportUserQuery, practiceQuery, featureStatesQuery]}
      loadError={<RecoverError />}
      loading={<LoadingState />}
    >
      {practiceQuery.data && featureStatesQuery.data ? (
        <FeatureFlagsContextProvider features={featureStatesQuery.data}>
          <AccountProvider
            email={email}
            userId={userId}
            practiceId={practiceId}
            practiceUuid={practiceQuery.data.uuid}
            features={featureStatesQuery.data}
          >
            <PracticeProvider practice={practiceQuery.data}>
              {supportUserQuery.data ? (
                <SupportUserProvider email={email} user={supportUserQuery.data} />
              ) : employeeQuery.data ? (
                <EmployeeUserProvider employee={employeeQuery.data} practice={practiceQuery.data} />
              ) : null}
            </PracticeProvider>
          </AccountProvider>
        </FeatureFlagsContextProvider>
      ) : null}
    </QueryResult>
  );
};
