import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getMedicalImageDetails = makeQuery({
  queryKey: ["practices", "getMedicalImageDetails"],
  formatParams: ({
    practiceId,
    patientId,
    imageId,
    query,
  }: {
    practiceId: number;
    patientId: number;
    imageId: number;
    query?: { populateDicomUrl?: boolean };
  }) => [practiceId, patientId, imageId, query],
  queryOptions: {
    ...oneMinuteCache,
  },
});
export const getPearlAnalysisForImage = makeQuery({
  queryKey: ["practices", "getPearlAnalysisForImage"],
  formatParams: ({
    practiceId,
    patientId,
    imageId,
  }: {
    practiceId: number;
    patientId: number;
    imageId: number;
  }) => [practiceId, patientId, imageId],
  queryOptions: {
    ...cacheForSession,
  },
});

export const getMount = makeQuery({
  queryKey: ["practices", "getMount"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    mountId: number;
    query?: { includeArchived?: boolean };
  }) => [args.practiceId, args.patientId, args.mountId, args.query],
  queryOptions: fiveMinuteCache,
});
type ImageQuery = {
  sortColumn?: string;
  sortDirection?: "ASCENDING" | "DESCENDING";
  teeth?: string[];
  imageTeethSearchMode?: "CONTAINS_ALL" | "CONTAINS_ANY";
  imageIds?: number[];
  maxPageSize?: number;
  pageNumber: number;
  pageSize: number;
};
export const getMedicalImages = makeInfiniteQuery({
  queryKey: ["practices", "getMedicalImages"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
  } & ImageQuery) => [practiceId, patientId, query],
  queryOptions: { ...oneMinuteCache, getNextPageParam },
});

interface MountQuery {
  imageType?: string;
  sortColumn?: string;
  orderBy?: string;
  pageNumber: number;
  pageSize: number;
  populateImages?: boolean;
}

export const getInfiniteMountsQuery = makeInfiniteQuery({
  queryKey: ["practices", "getMountList"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: { practiceId: number; patientId: number } & MountQuery) => [practiceId, patientId, query],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});

export const useInfiniteMountsQuery = (
  params: {
    practiceId: number;
    patientId: number;
  } & Omit<MountQuery, "pageSize" | "pageNumber">
) => {
  return useInfiniteApiQuery(
    getInfiniteMountsQuery({ args: { ...params, pageSize: PAGE_SIZE, pageNumber: 1 } })
  );
};

export const getPracticeImagingSettings = makeQuery({
  queryKey: ["practices", "getPracticeImagingSettings"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: {
    ...cacheForSession,
  },
});
