import { FC, LazyExoticComponent, lazy } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentModule = Record<string, any>;

/**
 * A utility function that dynamically imports a module and lazily loads a React component from it.
 * It is useful when the default export of the module is not the component you want to load.
 *
 * @template T The type of the module that contains the component.
 * @template K The key of the component in the module.
 * @param {() => Promise<T>} importPromise - A function that returns a promise which resolves to the module.
 * @param {K} component - The key of the component in the module.
 * @returns {LazyExoticComponent<T[K]>} Returns a lazy-loaded version of the specified component.
 */

type ComponentKeys<T extends ComponentModule> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T]: T[K] extends FC<any> ? K : never;
}[keyof T];

export const lazyDefault = <T extends ComponentModule, K extends ComponentKeys<T>>(
  importPromise: () => Promise<T>,
  component: K
): LazyExoticComponent<T[K]> => lazy(() => importPromise().then((mod) => ({ default: mod[component] })));
