import { captureException as sentryCaptureException } from "@sentry/react";
import { toast } from "react-toastify";
import { isObject } from "@libs/utils/types";

export const CODE_USER_CANCELLED = -1032;
export const CODE_SYSTEM_BUSY = -2007;

export const DWT_IGNORABLE_ERROR_CODES = new Set([CODE_USER_CANCELLED]);

export class TwainError extends Error {
  errorCode;
  api;
  constructor({ errorCode, errorString, api }: { errorString: string; errorCode: number; api?: string }) {
    super(errorString);
    this.name = this.constructor.name;
    this.errorCode = errorCode;
    this.api = api;
  }
}

// Twain throws objects as errors, with code/message as keys.  This converts those objects into proper error classes
export const checkTwainError = (error: unknown) => {
  if (isObject(error) && error["code"] && error["message"]) {
    return new TwainError({
      errorCode: error["code"] as number,
      errorString: error["message"] as string,
      api: "unknown",
    });
  }

  return error;
};

export const isTwainError = (error: unknown): error is TwainError => {
  return error instanceof TwainError;
};

// Full error list: https://www.dynamsoft.com/web-twain/docs/info/api/appendix.html#error-list
const TWAIN_ERROR_HUMAN_MESSAGES: Record<number, string> = {
  // Source or Source Manager reported an error to the user and handled the error
  [-1005]: "Scanner operation failed. Is the scanner online?",

  // Sequence error. The operation can not be performed upon the current Source Manager or Source state
  [-1031]: "Scanner operation failed. Is the scanner online?",
};

export const handleTwainError = (error: TwainError) => {
  const humanMessage = TWAIN_ERROR_HUMAN_MESSAGES[error.errorCode] || error.message;

  toast.error(`[${error.api || "UNKNOWN_API"}] ${humanMessage} (error code: ${error.errorCode})`);
  logTwainErrorToSentry(error);
};

export const logTwainErrorToSentry = (error: TwainError) => {
  sentryCaptureException(error, {
    tags: {
      ["twain.errorCode"]: error.errorCode,
    },
    contexts: {
      ["Twain Dynamsoft Error"]: {
        errorCode: error.errorCode,
        errorString: error.message,
        api: error.api,
      },
    },
  });
};
