import { FC, createContext, useContext, useCallback, useMemo, PropsWithChildren } from "react";

import { AppointmentVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

import { deleteAppointment } from "api/scheduling/mutations";

import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";

interface RequestDeleteAppointmentOptions {
  onSuccess?: Func;
  onError?: Func;
  onCancel?: Func;
}

interface DeleteAppointmentContextValue {
  requestDeleteAppointment: (appointment: AppointmentVO, options?: RequestDeleteAppointmentOptions) => void;
  isDeleting: boolean;
}

const Context = createContext<DeleteAppointmentContextValue>({
  requestDeleteAppointment: noop,
  isDeleting: false,
});

Context.displayName = "DeleteAppointmentContext";

export const useDeleteAppointment = () => useContext(Context);

export const DeleteAppointmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [deleteAppointmentMutation] = useApiMutations([deleteAppointment]);
  const { practiceId } = useAccount();
  const deleteModal = useItemModal<{
    appointment: AppointmentVO;
    options?: RequestDeleteAppointmentOptions;
  }>(null);

  const requestDeleteAppointment = useCallback(
    (appointment: AppointmentVO, options?: RequestDeleteAppointmentOptions) => {
      if (deleteModal.isOpen) {
        return;
      }

      deleteModal.open({ appointment, options });
    },
    [deleteModal]
  );

  const handleConfirmDelete = useCallback(() => {
    if (!deleteModal.isOpen) {
      return;
    }

    const { appointment, options } = deleteModal.item;

    deleteAppointmentMutation.mutate(
      { practiceId, appointment },
      {
        onSuccess: () => {
          deleteModal.close();
          options?.onSuccess?.();
        },
        onError: (error) => {
          handleError(error);
          options?.onError?.();
        },
      }
    );
  }, [deleteModal, deleteAppointmentMutation, practiceId]);

  const handleCancelDelete = useCallback(() => {
    if (!deleteModal.isOpen) {
      return;
    }

    deleteModal.close();
    deleteModal.item.options?.onCancel?.();
  }, [deleteModal]);

  const contextValue = useMemo(
    () => ({ requestDeleteAppointment, isDeleting: deleteAppointmentMutation.isLoading }),
    [deleteAppointmentMutation.isLoading, requestDeleteAppointment]
  );

  return (
    <Context.Provider value={contextValue}>
      {children}
      {deleteModal.isOpen && (
        <ConfirmationModal
          primaryText="Are you sure you want to delete this appointment?"
          secondaryText="If you confirm, all procedures and other data linked to this appointment will be permanently deleted."
          isConfirming={deleteAppointmentMutation.isLoading}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </Context.Provider>
  );
};
