import { isHttpResponseError } from "@libs/utils/isHttpResponseError";

/**
 * Checks if an API error response indicates that a PIN is required.
 *
 * @param response - The API error response to check.
 * @returns `true` if the error response indicates that a PIN is required, `false` otherwise.
 */
export const hasFailedPin = (response: unknown) => {
  if (!isHttpResponseError(response)) {
    return false;
  }

  return (response.error.errors ?? []).some((error) => error.errorCode === "PIN_REQUIRED");
};
