import { FC, Fragment, useMemo, ReactNode } from "react";
import { AppointmentPatientProcedureVO, AppointmentVO, ProviderVO, RoomVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { cx } from "@libs/utils/cx";
import { formatISOTimeRangeAsAmPmShort, formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { ReactComponent as NoShowIcon } from "@libs/assets/icons/warning-circle-filled.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle-filled.svg";
import { isDefined } from "@libs/utils/types";
import { isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { LabCaseMultiStatusIcon } from "components/LabCases/LabComponents";
import { AppointmentStatusButton } from "components/ScheduleAppointments/AppointmentStatusButton";
import { sortAppointmentTags } from "components/ScheduleAppointments/tags";
import { PillTag } from "components/Notes/PillTag";
import { ClinicalNoteStatus } from "components/ScheduleAppointments/ClinicalNoteStatus";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";

const cxThemeStyles: Record<AppointmentVO["state"], PillProps["theme"]> = {
  REQUESTED: "magenta",
  READY: "magenta",
  CONFIRMED: "green",
  ARRIVED: "yellow",
  IN_PROGRESS: "orange",
  UNCONFIRMED: "red",
  COMPLETED: "grey",
  NO_SHOW: "grey",
  CANCELED: "grey",
  UNSCHEDULED: "blue",
  CHECKOUT: "blue",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _DELETED: undefined,
};

const contentMap: Record<AppointmentVO["state"], ReactNode> = {
  REQUESTED: "Requested",
  CONFIRMED: "Confirmed",
  ARRIVED: "Arrived",
  CHECKOUT: "Checkout",
  READY: "Ready",
  IN_PROGRESS: "In Chair",
  UNCONFIRMED: "Unconfirmed",
  COMPLETED: "Completed",
  NO_SHOW: (
    <div className="flex flex-nowrap items-center relative pl-[18px]">
      <NoShowIcon className="w-5 h-5 absolute -left-1" /> No Show
    </div>
  ),
  CANCELED: (
    <div className="flex flex-nowrap items-center relative pl-[18px]">
      <CancelIcon className="w-5 h-5 absolute -left-1" /> Cancelled
    </div>
  ),
  UNSCHEDULED: "In Holder",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _DELETED: null,
};

const getConfirmationTooltipContent = (
  state: AppointmentVO["state"],
  confirmation: AppointmentVO["confirmation"]
) => {
  if (state === "CONFIRMED" && confirmation?.confirmedDate) {
    return `Confirmed on ${formatISODate(confirmation.confirmedDate)} by ${
      confirmation.confirmedBy?.fullDisplayName ?? "Auto Text"
    }`;
  }

  if (state === "UNCONFIRMED" && confirmation?.reminderSentDate) {
    return `Reminder Sent ${formatISODate(confirmation.reminderSentDate)}`;
  }

  return undefined;
};

const AppointmentStatusPill: React.FC<{
  appointment: AppointmentVO;
  onUpdateAppointmentState?: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
}> = ({ appointment, onUpdateAppointmentState }) => {
  const { state, confirmation } = appointment;

  const confirmationTooltipContent = useMemo(
    () => getConfirmationTooltipContent(state, confirmation),
    [state, confirmation]
  );

  return onUpdateAppointmentState ? (
    <AppointmentStatusButton
      appointment={appointment}
      onRequestUpdateAppointmentState={onUpdateAppointmentState}
      placement="bottom-start"
    >
      <FloatingTooltip content={confirmationTooltipContent} theme="MEDIUM">
        <Pill theme={cxThemeStyles[state]}>{contentMap[state]}</Pill>
      </FloatingTooltip>
    </AppointmentStatusButton>
  ) : (
    <FloatingTooltip content={confirmationTooltipContent} theme="MEDIUM">
      <Pill theme={cxThemeStyles[state]}>{contentMap[state]}</Pill>
    </FloatingTooltip>
  );
};

export type AppointmentPatientProcedurePillProps = {
  displayName: string;
  description: string;
  count: number;
  theme?: PillProps["theme"];
  pillRound?: PillProps["round"];
};

const AppointmentPatientProcedurePill: FC<AppointmentPatientProcedurePillProps> = ({
  displayName,
  description,
  theme = "slate200",
  pillRound = true,
  count = 1,
}) => {
  return (
    <FloatingTooltip content={description}>
      <Pill className="flex items-center gap-x-1" theme={theme} round={pillRound}>
        {displayName}
        {count > 1 ? (
          <div
            className={`
              flex
              flex-col
              items-center
              justify-content
              size-4
              rounded-full
              bg-slate-300
              font-sansSemiBold
              text-xxs
              text-slate-700
            `}
          >
            {count}
          </div>
        ) : null}
      </Pill>
    </FloatingTooltip>
  );
};

export const GroupedAppointmentProcedurePills: FC<
  {
    procedures: AppointmentPatientProcedureVO[];
  } & { theme?: PillProps["theme"]; pillRound?: PillProps["round"] }
> = ({ procedures, theme, pillRound }) => {
  const groupedPatientProcedures = useMemo(() => {
    const grouped = new Map<string, AppointmentPatientProcedurePillProps>();

    procedures.forEach((pp) => {
      const id = `${pp.dentalProcedureId}-${pp.displayName}`;
      const count = grouped.get(id)?.count ?? 0;

      grouped.set(id, {
        displayName: pp.displayName,
        description: pp.description,
        count: count + 1,
      });
    });

    return [...grouped];
  }, [procedures]);

  return (
    <>
      {groupedPatientProcedures.map(([id, pp]) => (
        <AppointmentPatientProcedurePill
          key={id}
          displayName={pp.displayName}
          description={pp.description}
          count={pp.count}
          theme={theme}
          pillRound={pillRound}
        />
      ))}
    </>
  );
};

export const AppointmentPills: FC<{
  appointment: AppointmentVO;
  onUpdateAppointmentState?: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
}> = ({ appointment, onUpdateAppointmentState }) => {
  return (
    <div className="flex flex-wrap gap-0.5">
      <AppointmentStatusPill appointment={appointment} onUpdateAppointmentState={onUpdateAppointmentState} />
      <GroupedAppointmentProcedurePills procedures={appointment.patientProcedures} />
    </div>
  );
};

export const AppointmentDate: FC<{
  date: string;
  state: AppointmentVO["state"];
}> = ({ date, state }) => (
  <p className="font-sansSemiBold">
    {isUnscheduledAsapAppt(date)
      ? "Unscheduled"
      : state === "UNSCHEDULED"
        ? "In Holder"
        : formatISODate(date, "EEE, MMM d, yyyy")}
  </p>
);

export const AppointmentTimeSpan: FC<{
  startTime: string;
  endTime: string;
  state: AppointmentVO["state"];
  date: string;
  duration: number;
  truncate?: boolean;
}> = ({ startTime, endTime, state, date, duration, truncate }) => {
  const timeSpan = formatISOTimeRangeAsAmPmShort(startTime, endTime);

  return (
    <p className={cx(truncate && "w-full truncate")}>
      {state === "UNSCHEDULED" || isUnscheduledAsapAppt(date) ? `${duration} min` : timeSpan}
    </p>
  );
};

export const AppointmentProvidersRoom: FC<{
  dentist: ProviderVO;
  provider: ProviderVO;
  state: AppointmentVO["state"];
  date: string;
  room: RoomVO;
}> = ({ dentist, date, provider, room, state }) => {
  const lines = useMemo(() => {
    const _lines = [dentist.name.shortDisplayName];

    if (provider.id !== dentist.id) {
      _lines.push(provider.name.shortDisplayName);
    }

    if (state !== "UNSCHEDULED" && !isUnscheduledAsapAppt(date)) {
      _lines.push(room.roomName);
    }

    return _lines;
  }, [dentist, room, date, state, provider]);

  return (
    <div className="flex gap-x-1 flex-wrap">
      {lines.map((line, index) => (
        <Fragment key={index}>
          <div>{line}</div>
          {index < lines.length - 1 ? "•" : null}
        </Fragment>
      ))}
    </div>
  );
};

export const AppointmentProviders: FC<{
  dentist: ProviderVO;
  provider: ProviderVO;
}> = ({ dentist, provider }) => {
  const lines = useMemo(() => {
    const _lines = [dentist.name.shortDisplayName];

    if (provider.id !== dentist.id) {
      _lines.push(provider.name.shortDisplayName);
    }

    return _lines;
  }, [dentist, provider]);

  return (
    <div className="flex gap-x-1 flex-wrap">
      {lines.map((line, index) => (
        <Fragment key={index}>
          <div>{line}</div>
          {index < lines.length - 1 ? "•" : null}
        </Fragment>
      ))}
    </div>
  );
};

export const AppointmentComment: FC<{
  appointment: AppointmentVO;
  // eslint-disable-next-line complexity
}> = ({ appointment }) => {
  const patientNotes = usePatientNotesRouter();
  const { comments, tags, patientProcedures, clinicalNoteStatus, patient, id } = appointment;
  const labCases = useMemo(
    () => patientProcedures.map((procedure) => procedure.labCaseStatus).filter(isDefined),
    [patientProcedures]
  );

  const appointmentTags = useMemo(() => {
    if (tags) {
      return [...tags.autoAppointment, ...tags.customAppointment];
    }

    return [];
  }, [tags]);

  return comments || appointmentTags.length || clinicalNoteStatus || labCases.length ? (
    <div className="flex flex-col gap-y-1">
      <span className="font-sansSemiBold text-xs leading-6">Notes</span>
      {appointmentTags.length || clinicalNoteStatus || labCases.length ? (
        <div className="flex items-center flex-wrap gap-1">
          {clinicalNoteStatus ? (
            <ClinicalNoteStatus
              patientId={patient.id}
              appointmentId={id}
              status={clinicalNoteStatus}
              onNoteLoaded={(note) => patientNotes.open("edit", { patientId: patient.id, uuid: note.uuid })}
            />
          ) : null}
          {labCases.length > 0 && <LabCaseMultiStatusIcon shape="square" labCases={labCases} size="md" />}

          {sortAppointmentTags(appointmentTags).map((tag) => (
            <PillTag tag={tag} key={tag.id} theme={tag.color === "#F1F5F9" ? "slate200" : "custom"} />
          ))}
        </div>
      ) : null}
      {comments ? <p className="whitespace-pre-line">{comments}</p> : null}
    </div>
  ) : null;
};

export const AppointmentRates: FC<{
  appointment: AppointmentVO;
  hasInsurance: boolean;
}> = ({ appointment, hasInsurance }) => {
  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <span className="font-sansSemiBold leading-6">Rates</span>

      <div className="flex gap-x-1.5">
        {hasInsurance ? (
          <>
            <span className="w-24">Negotiated</span>
            <span>{formatCurrency(appointment.negotiatedRate)}</span>
          </>
        ) : (
          <>
            <span className="w-24">UCR</span>
            <span>{formatCurrency(appointment.ucrRate)}</span>
          </>
        )}
      </div>

      <div className="flex gap-x-1.5">
        <div className="w-24">Pt Estimate</div>
        <span>
          {formatCurrency(appointment.patientAmount)}{" "}
          <span className="text-slate-500">{`(${formatCurrency(appointment.deductibleAmount)} ded)`}</span>
        </span>
      </div>
    </div>
  );
};
