import { RefAttributes, useCallback, useMemo } from "react";
import ReactSelect, {
  components as Components,
  Options,
  MultiValue,
  ActionMeta,
  MultiValueRemoveProps,
  MultiValueGenericProps,
} from "react-select";
import SelectComponent from "react-select/dist/declarations/src/Select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { ReactComponent as ClearIcon } from "@libs/assets/icons/cancel.svg";
import {
  defaultComponents as singleSelectComponents,
  DisplayValue,
  findSelectedOption,
  getOptionLabelRenderer,
} from "@libs/components/UI/Select";
import { getBaseStyles, mergeSelectStyles } from "@libs/components/UI/selectStyles";

export type MultiSelectProps<V extends SelectOptionValue, T extends SelectOption<V>> = Omit<
  StateManagerProps<T, true, GroupedSelectOption<V, T>>,
  "options" | "value" | "menuPortalTarget"
> &
  RefAttributes<SelectComponent<T, true, GroupedSelectOption<V, T>>> & {
    options: Options<T> | GroupedSelectOption<V, T>[];
    value?: V[];
    display?: SelectDisplayType;
    allowDuplicateSelections?: boolean;
    onItemsSelected?: (value: V[]) => void;
  };

const defaultComponents = {
  DropdownIndicator: singleSelectComponents.DropdownIndicator,
  ClearIndicator: singleSelectComponents.ClearIndicator,
  MultiValueRemove: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: MultiValueRemoveProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) => {
    return (
      <Components.MultiValueRemove {...props}>
        <ClearIcon />
      </Components.MultiValueRemove>
    );
  },
  Input: singleSelectComponents.Input,
};

const getMultiValueLabel = (display: SelectDisplayType) => {
  return function MultiValueComponent<
    V extends SelectOptionValue,
    T extends SelectOption<V>,
    MultiSelect extends boolean,
  >(props: MultiValueGenericProps<T, MultiSelect, GroupedSelectOption<V, T>>) {
    const option = props.data as T | undefined;

    return option ? (
      <Components.MultiValueLabel {...props}>
        <DisplayValue option={option} display={display} />
      </Components.MultiValueLabel>
    ) : null;
  };
};

export const findMultiSelectedOptions = <V extends SelectOptionValue, T extends SelectOption<V>>(
  value: V[] | undefined,
  options: Options<T> | GroupedSelectOption<V, T>[],
  allowDuplicateSelections: boolean | undefined
) => {
  return (
    value?.map((selectedItem, index) => {
      const item = findSelectedOption(options, selectedItem);

      return {
        ...item,
        value: allowDuplicateSelections ? `${selectedItem}-${index}` : selectedItem,
      } as T;
    }) || []
  );
};

export const MultiSelect = <V extends SelectOptionValue, T extends SelectOption<V>>({
  onChange,
  onItemsSelected,
  allowDuplicateSelections,
  components,
  value,
  styles,
  display = "label",
  ...props
}: MultiSelectProps<V, T>) => {
  const { customComponents, labelRenderer } = useMemo(() => {
    const customDefaults = {
      ...defaultComponents,
      MultiValueLabel: getMultiValueLabel(display),
    };

    return {
      customComponents: components
        ? {
            ...customDefaults,
            ...components,
          }
        : customDefaults,
      labelRenderer: getOptionLabelRenderer(display),
    };
  }, [components, display]);

  const selectedOptions = useMemo(() => {
    return findMultiSelectedOptions(value, props.options, allowDuplicateSelections);
  }, [props.options, value, allowDuplicateSelections]);

  const handleOnChange = useCallback(
    (option: MultiValue<T>, actionMeta: ActionMeta<T>) => {
      if (onChange) {
        onChange(option, actionMeta);
      }

      if (onItemsSelected) {
        onItemsSelected(option.map((op) => op.value));
      }
    },
    [onItemsSelected, onChange]
  );

  const selectStyles = useMemo(() => {
    const baseStyles = getBaseStyles<V, T, true>();

    return mergeSelectStyles<V, T, true>(baseStyles, styles, true);
  }, [styles]);

  const menuPortalTarget = usePortalElement();

  return (
    <ReactSelect
      onChange={handleOnChange}
      value={selectedOptions}
      components={customComponents}
      formatOptionLabel={labelRenderer}
      isMulti={true}
      isSearchable={true}
      closeMenuOnSelect={true}
      styles={selectStyles}
      menuPortalTarget={menuPortalTarget}
      placeholder=""
      {...props}
    />
  );
};
