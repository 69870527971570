import { addMinutes, intervalToDuration, isSameDay } from "date-fns";
import {
  AppointmentSeriesVO,
  AppointmentVO,
  OpenSlotVO,
  Preference as ApiSlotPreferences,
  AppointmentRequest,
  ScheduleBlockVO,
  UpdateScheduleBlockRequest,
  NameVO,
  AppointmentCardConfigVO,
} from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import {
  ANY_DAY,
  formatAsISOTime,
  formatISODate,
  getLocalDate,
  MINUTE_IN_SECONDS,
  SECOND_IN_MS,
  setTimeOnDate,
} from "@libs/utils/date";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { DecimalPlaces, round } from "@libs/utils/math";
import { SlotPreferences } from "components/ScheduleAppointments/types";
import { TimeSlotPreference } from "components/ScheduleAppointments/timeSlotPreference";

export const UNSCHEDULED_APPT_DATE = "2099-01-01";
export const isUnscheduledAsapAppt = (date?: string) => date === "2099-01-01";
export const getAppointmentMutatedLabel = ({
  isUnscheduled,
  wasCreated,
}: {
  isUnscheduled: boolean;
  wasCreated: boolean;
}) => {
  if (wasCreated) {
    return isUnscheduled ? "Added to ASAP List" : "Appointment Created";
  }

  return "Appointment Updated";
};
export const getDurationLabel = (durationInMinutes: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: durationInMinutes * MINUTE_IN_SECONDS * SECOND_IN_MS,
  });

  // hours
  let label = "";

  if (duration.hours) {
    label = `${duration.hours} ${duration.hours > 1 ? "hrs" : "hr"}`;
  }

  if (duration.minutes) {
    label += `${label.length ? " " : ""}${duration.minutes} ${duration.minutes > 1 ? "mins" : "min"}`;
  } else if (!duration.hours) {
    label = "0 mins";
  }

  return label;
};

export const toggleMap = <K extends string | number, V>(map: Record<K, V | undefined>, key: K, value: V) => {
  const copy = { ...map };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (copy[key]) {
    delete copy[key];
  } else {
    copy[key] = value;
  }

  return copy;
};

const addToArrayMap = <K extends string | number, V>(map: Record<K, V[]>, key: K, value: V) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (map[key]) {
    map[key].push(value);
  } else {
    map[key] = [value];
  }
};

const consolidateDays = (daysWithSlots: AppointmentSeriesVO[] | undefined) => {
  if (!daysWithSlots?.length) {
    return [];
  }

  const consolidated: AppointmentSeriesVO[] = [];

  let lastDate = "";
  let consolidatedIndex = -1;

  for (const dayWithSlots of daysWithSlots) {
    if (dayWithSlots.date === lastDate) {
      consolidated[consolidatedIndex] = {
        date: dayWithSlots.date,
        openSlots: [...consolidated[consolidatedIndex].openSlots, ...dayWithSlots.openSlots],
      };
    } else {
      consolidatedIndex++;
      consolidated[consolidatedIndex] = dayWithSlots;
      lastDate = dayWithSlots.date;
    }
  }

  return consolidated;
};

export const chunkSlotsByInterval = (daysWithSlots: AppointmentSeriesVO[] | undefined) => {
  if (!daysWithSlots?.length) {
    return [];
  }

  const consolidated = consolidateDays(daysWithSlots);

  let daysWithSlotsChunkedByInterval: { date: string; slotsChunkedByInterval: OpenSlotVO[][] }[] = [];

  for (const dayWithSlots of consolidated) {
    const slotsByInterval: Record<string, OpenSlotVO[]> = {};

    for (const openSlot of dayWithSlots.openSlots) {
      addToArrayMap(slotsByInterval, `${openSlot.startTime}-${openSlot.endTime}`, openSlot);
    }

    let slotsChunkedByInterval = Object.values(slotsByInterval);
    const now = new Date();

    // Sort by interval startTime
    slotsChunkedByInterval.sort((a, b) => {
      // Since all slots in list have the same interval we can just compare
      // the first items startTime
      const aDate = setTimeOnDate(now, a[0].startTime);
      const bDate = setTimeOnDate(now, b[0].startTime);

      return aDate.getTime() - bDate.getTime();
    });

    // Sort sort within interval groups by provdider name
    slotsChunkedByInterval = slotsChunkedByInterval.map((slotList) => {
      const copy = [...slotList];

      copy.sort((a, b) => {
        const aName = a.provider.name.shortDisplayName;
        const bName = b.provider.name.shortDisplayName;

        return aName < bName ? 1 : bName < aName ? -1 : 0;
      });

      return copy;
    });

    daysWithSlotsChunkedByInterval = [
      ...daysWithSlotsChunkedByInterval,
      {
        date: dayWithSlots.date,
        slotsChunkedByInterval,
      },
    ];
  }

  return daysWithSlotsChunkedByInterval;
};

/**
 * Returns an object containing the dentist and hygienist for the given appointment.
 *
 * @param appointment - The appointment object to extract providers from.
 * @returns An object containing the dentist and hygienist for the appointment.
 */
export const getAppointmentProviders = (appointment: AppointmentVO) => {
  return {
    dentist: appointment.dentist,
    hygienist: appointment.provider.id === appointment.dentist.id ? undefined : appointment.provider,
  };
};

/**
 * Formats the names of the healthcare providers for an appointment.
 *
 * @param appointment - The appointment object.
 * @param nameStyle - The style of name to use for the providers.
 * @returns The formatted provider names.
 */
export const formatProviderNames = (
  appointment: AppointmentVO,
  nameStyle: Extract<keyof NameVO, "shortDisplayName" | "fullDisplayName">
) => {
  const providers = getAppointmentProviders(appointment);

  return `${providers.dentist.name[nameStyle]}${
    providers.hygienist ? ` - ${providers.hygienist.name[nameStyle]}` : ""
  }`;
};

export const isEmptyTime = (start: string, end: string) => {
  const noneTime = "00:00:00";

  return start === noneTime && end === noneTime;
};

const parseTimeItem = (timePref: TimeSlotPreference) => {
  const date = new Date();

  date.setHours(timePref.hours);
  date.setMinutes(timePref.minutes);
  date.setSeconds(timePref.seconds);

  return formatAsISOTime(date);
};

// Empty Preference:

export const getPreferencePutData = (
  preferences: SlotPreferences,
  asapComment: string
): ApiSlotPreferences => {
  const providerIds = [
    ...Object.values(preferences.dentists ?? {})
      .map((provider) => provider?.id)
      .filter(isDefined),
    ...Object.values(preferences.hygienists ?? {})
      .map((provider) => provider?.id)
      .filter(isDefined),
  ];
  const daysPreferred = Object.values(preferences.days ?? {})
    .map((item) => item?.value)
    .filter(isDefined);
  const rangePreference = {
    startTime: parseTimeItem(preferences.from),
    endTime: parseTimeItem(preferences.to),
  };

  const comment = asapComment.trim();

  // if (
  //   !comment &&
  //   providerIds.length === 0 &&
  //   daysPreferred.length === 0 &&
  //   isEmptyTime(rangePreference.startTime, rangePreference.endTime)
  // ) {
  //   // No preference
  //   return null;
  // }

  return {
    providerIds,
    daysPreferred,
    timeRanges: [rangePreference],
    comment: comment.length === 0 ? undefined : comment,
  };
};

export const getExpiredCDTCodeDate = (endDate: string | undefined, now: Date) =>
  endDate && getLocalDate(endDate) < now ? endDate : undefined;

export const getExpiredCDTCodeMessage = (isoDate: string) =>
  `CDT Code expired on ${formatISODate(
    isoDate
  )}. Use it only for appointments completed in the years it was valid.`;

export const getAppointmentRequest = (appointment: AppointmentVO) => {
  const args: AppointmentRequest = {
    newProcedures: [],
    ignoreFeeCalcError: true,
    editedProcedureIds: appointment.patientProcedures.map((pp) => pp.id),
    state: appointment.state,
    patientId: appointment.patient.id || 0,
    duration: appointment.duration,
    startTime: appointment.startTime,
    providerId: appointment.provider.id,
    dentistId: appointment.dentist.id,
    roomId: appointment.room.id,
    date: appointment.date,
    sendConfirmationAndReminders: appointment.sendConfirmationAndReminders,
    appointmentCategoryId: appointment.categoryName?.id,
    comments: appointment.comments,
    asap: appointment.asap,
    color: appointment.color,
    tags: {
      customIds: appointment.tags?.customAppointment.map((tag) => tag.id) ?? [],
    },
  };

  return args;
};

export const getBlockRequest = (block: ScheduleBlockVO) => {
  const args: UpdateScheduleBlockRequest = {
    title: block.title,
    date: block.date,
    startTime: block.startTime,
    endTime: block.color,
    roomIds: block.rooms.map((room) => room.id),
    providerIds: block.providers.map((prov) => prov.id),
    comments: block.comments,
    color: block.color,
    recurrence: block.recurrence,
  };

  return args;
};

export const endDateIsSameAsStart = (startTime: string, val: number) => {
  const startDate = getLocalDate(ANY_DAY, startTime);
  const endDate = addMinutes(startDate, val);

  return isSameDay(startDate, endDate);
};

export const getPatientCardName = ({
  config,
  appointment,
  hipaaView,
}: {
  config: AppointmentCardConfigVO;
  appointment: AppointmentVO;
  hipaaView: boolean;
}) => {
  const { patient, patientAge } = appointment;

  if (hipaaView) {
    return formatFullNameToInitials({ fullName: patient.shortDisplayName });
  }

  let name = patient.fullDisplayName;

  if (config.reversePatientNameOrder) {
    name = `${patient.lastName}, ${patient.firstName}`;

    if (!config.hidePreferredName && patient.preferredName) {
      name = `${name} "${patient.preferredName}"`;
    }
  } else if (config.hidePreferredName) {
    name = patient.shortDisplayName;
  }

  if (config.showPatientAge) {
    return `${name}, ${patientAge}`;
  }

  return name;
};

export const PX_PER_MINUTE = 1.6;
export const getAvailablePixels = (duration: number) =>
  round(duration * PX_PER_MINUTE, DecimalPlaces.hundredth);

export const getLineClamp = (availablePx: number, usedPixels: number, lineHeight: number) => {
  return Math.floor((availablePx - usedPixels) / lineHeight);
};
