import { cx } from "@libs/utils/cx";
import { Banner } from "@libs/components/UI/Banner";

export const ContactExistsBanner: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <Banner className={cx("text-sm rounded mb-4", className)} theme="warning">
      Contact details required
    </Banner>
  );
};
