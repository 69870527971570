import { FC, Suspense } from "react";
import { Navigate, Route } from "react-router-dom";
import { isOneOf } from "@libs/utils/isOneOf";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { NotFound } from "components/NotFoundRoute";
import { HomeRoute } from "components/HomeRoute";
import { signedInRedirect } from "utils/routing/paths";
import { SSOZendeskRoute } from "components/SSO/SSOZendeskRoute";
import { AuthLayout } from "components/Main/AuthLayout";
import { useEnvContext } from "contexts/EnvContext";
import { ImagingHubProvider } from "components/ImageCapturing/ImagingHubContext";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { SSOLearnuponRoute } from "components/SSO/SSOLearnuponRoute";

const VerifyOdontogramRoute = lazyDefault(() => {
  return import("components/Charting/internal/VerifyOdontogramRoute");
}, "VerifyOdontogramRoute");

const DashboardRoute = lazyDefault(() => {
  return import("components/Dashboard/DashboardRoute");
}, "DashboardRoute");

const ClaimsApp = lazyDefault(() => {
  return import("components/Claims/ClaimsApp");
}, "ClaimsApp");

const SettingsRoute = lazyDefault(() => {
  return import("components/Settings/SettingsRoute");
}, "SettingsRoute");

const CommunicationsApp = lazyDefault(() => {
  return import("components/Communications/CommunicationsApp");
}, "CommunicationsApp");

const ErxNotificationsRoute = lazyDefault(() => {
  return import("components/Erx/ErxNotificationsRoute");
}, "ErxNotificationsRoute");

const PatientsApp = lazyDefault(() => {
  return import("components/Patient/PatientsApp");
}, "PatientsApp");

const EmployeesApp = lazyDefault(() => {
  return import("components/Employee/EmployeesApp");
}, "EmployeesApp");

const SchedulingApp = lazyDefault(() => {
  return import("components/ScheduleAppointments/SchedulingApp");
}, "SchedulingApp");

const MessagingRoute = lazyDefault(() => {
  return import("components/Messaging/MessagingRoute");
}, "MessagingRoute");

const LabCasesRoute = lazyDefault(() => {
  return import("components/LabCases/LabCasesRoute");
}, "LabCasesRoute");

const AuthRoutes: {
  index?: boolean;
  path?: string;
  element: JSX.Element;
}[] = [
  {
    index: true,
    element: <HomeRoute />,
  },
  {
    path: "dashboard/*",
    element: (
      <RoleGuardLock domain="REPORTING" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <DashboardRoute />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "patients/*",
    element: (
      <RoleGuardLock domain="PATIENT_PROFILE" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <PatientsApp />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "employees/*",
    element: (
      <Suspense fallback={<LoadingContent />}>
        <EmployeesApp />
      </Suspense>
    ),
  },
  {
    path: "claims/*",
    element: (
      <RoleGuardLock domain="CLAIMS" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <ClaimsApp />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "settings/*",
    element: (
      <RoleGuardLock domain="PRACTICE_SETTINGS" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <SettingsRoute />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "schedule/*",
    element: (
      <RoleGuardLock domain="APPOINTMENT_MANAGEMENT" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <SchedulingApp />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "messaging",
    element: (
      <RoleGuardLock domain="COMMUNICATION" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <MessagingRoute />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "communications/*",
    element: (
      <RoleGuardLock domain="COMMUNICATION" action="BULK_MESSAGE">
        <Suspense fallback={<LoadingContent />}>
          <CommunicationsApp />
        </Suspense>
      </RoleGuardLock>
    ),
  },
  {
    path: "erx",
    element: (
      <Suspense fallback={<LoadingContent />}>
        <ErxNotificationsRoute />
      </Suspense>
    ),
  },
  {
    path: "sso/zendesk",
    element: <SSOZendeskRoute />,
  },
  {
    path: "sso/learnupon",
    element: <SSOLearnuponRoute />,
  },
  {
    path: "lab-cases",
    element: (
      <RoleGuardLock domain="LAB_CASE" action="ACCESS_ALL">
        <Suspense fallback={<LoadingContent />}>
          <LabCasesRoute />
        </Suspense>
      </RoleGuardLock>
    ),
  },
];

export const SignedInAppRoutes: FC = () => {
  const env = useEnvContext();

  return (
    <ImagingHubProvider>
      <AuthLayout>
        <SentryRoutes>
          {AuthRoutes.map((routeParams, i) => {
            return <Route {...routeParams} key={i} />;
          })}
          {isOneOf(env.REACT_APP_ENVIRONMENT, ["development", "preprod"]) ? (
            <Route
              path="verify-odontogram"
              element={
                <Suspense>
                  <VerifyOdontogramRoute />
                </Suspense>
              }
            />
          ) : null}
          <Route path="onboarding/*" element={<Navigate replace to={signedInRedirect} />} />
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </AuthLayout>
    </ImagingHubProvider>
  );
};
