import { Fragment, useCallback, useEffect, useMemo } from "react";
import { isOneOf } from "@libs/utils/isOneOf";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useMangoContext } from "components/Mango/MangoContext";
import { PatientCard } from "components/Mango/PatientCard";
import { CallHeader } from "components/Mango/CallHeader";
import { MinimizedContent } from "components/Mango/MinimizedContent";
import { useWebSocketMessage } from "hooks/useWebSocketMessage";
import { CallData, CallType, CallsByNumberMap, CallsToDisplayMapKeys } from "components/Mango/types";
import { ServerPushMessages } from "api/websocket/types";
import { Popover } from "components/UI/Popover";

const CALL_DISPLAY_ORDER: CallsToDisplayMapKeys[] = ["ongoingCalls", "inboundCalls", "outboundCalls"];

export const MangoPopOver: React.FC = () => {
  const {
    inboundCallsMap,
    outboundCallsMap,
    hideAllCalls,
    addCall,
    updateCall,
    deleteCall,
    allowInbound,
    ext,
  } = useMangoContext();
  const display = useBoolean(false);
  const isMinimized = useBoolean(false);
  const displayMinimize = useBoolean(false);

  const callsToDisplay = useMemo(() => {
    const inboundCalls: CallsByNumberMap = {};
    const ongoingCalls: CallsByNumberMap = {};

    if (allowInbound) {
      Object.keys(inboundCallsMap).forEach((phoneNumber) => {
        if (inboundCallsMap[phoneNumber].display) {
          if (inboundCallsMap[phoneNumber].state === "PHONE_CALL_RINGING") {
            inboundCalls[phoneNumber] = inboundCallsMap[phoneNumber];
          } else if (inboundCallsMap[phoneNumber].state === "PHONE_CALL_ANSWERED") {
            ongoingCalls[phoneNumber] = inboundCallsMap[phoneNumber];
          }
        }
      });
    }

    const outboundCalls: CallsByNumberMap = {};

    Object.keys(outboundCallsMap)
      .filter((phoneNumber) => outboundCallsMap[phoneNumber].display)
      .forEach((phoneNumber) => (outboundCalls[phoneNumber] = outboundCallsMap[phoneNumber]));

    return { inboundCalls, ongoingCalls, outboundCalls };
  }, [allowInbound, inboundCallsMap, outboundCallsMap]);

  const hasDisplayedCalls = useMemo(
    () =>
      Boolean(
        Object.values(callsToDisplay.inboundCalls).some((call) => call.display) ||
          Object.values(callsToDisplay.ongoingCalls).some((call) => call.display) ||
          Object.values(callsToDisplay.outboundCalls).some((call) => call.display)
      ),
    [callsToDisplay.inboundCalls, callsToDisplay.ongoingCalls, callsToDisplay.outboundCalls]
  );

  const handlePhoneMessages = useCallback(
    // eslint-disable-next-line complexity
    (data: ServerPushMessages) => {
      if (
        data.type !== "PHONE_CALL_HANGUP" &&
        data.type !== "PHONE_CALL_ANSWERED" &&
        data.type !== "PHONE_CALL_RINGING"
      ) {
        return;
      }

      // Ignore any messages that are not for this computer's configured extension
      if (data.payload.extensionNumber === ext) {
        const phoneNumber = data.payload.phoneNumber;
        const isOutbound = data.payload.outbound;
        const callTypeToUpdate = isOutbound ? CallType.OUTBOUND : CallType.INBOUND;

        if (
          phoneNumber &&
          ((!isOutbound && phoneNumber in inboundCallsMap) || (isOutbound && phoneNumber in outboundCallsMap))
        ) {
          if (isOneOf(data.type, ["PHONE_CALL_RINGING", "PHONE_CALL_ANSWERED"])) {
            const updates: Partial<CallData> = { callMainId: data.payload.callMainId, state: data.type };

            updateCall(callTypeToUpdate, phoneNumber, updates);
          } else {
            deleteCall(callTypeToUpdate, phoneNumber);
          }
        } else if (!isOutbound) {
          const callToAdd = {
            callerId: data.payload.callerId,
            callMainId: data.payload.callMainId,
            callType: CallType.INBOUND,
            display: true,
            extensionNumber: data.payload.extensionNumber,
            patientId: data.payload.patientId,
            phoneNumber,
            state: data.type,
          };

          addCall(CallType.INBOUND, phoneNumber, callToAdd);
        }
      }
    },
    [ext, inboundCallsMap, outboundCallsMap, updateCall, deleteCall, addCall]
  );

  const handleClose = useCallback(() => {
    display.off();
    isMinimized.off();

    hideAllCalls();
  }, [display, isMinimized, hideAllCalls]);

  useWebSocketMessage(handlePhoneMessages);

  useEffect(() => {
    if (hasDisplayedCalls) {
      display.on();
    } else {
      display.off();
      displayMinimize.off();
      isMinimized.off();
    }
  }, [hasDisplayedCalls, displayMinimize, isMinimized, display]);

  return display.isOn ? (
    <div className="mt-4">
      <Popover display={display} onClose={handleClose} onHover={displayMinimize.on}>
        {isMinimized.isOn ? (
          <div className="mt-4">
            <MinimizedContent onMaximize={isMinimized.off} />
          </div>
        ) : (
          <div className="w-96 p-4 overflow-y-auto">
            {CALL_DISPLAY_ORDER.map((callGroup) => {
              return Object.entries(callsToDisplay[callGroup]).length ? (
                <Fragment key={callGroup}>
                  <CallHeader
                    callGroup={callGroup}
                    callsToDisplay={callsToDisplay}
                    canMinimize={displayMinimize.isOn}
                    onMinimize={isMinimized.on}
                  />
                  {Object.values(callsToDisplay[callGroup]).map((callData) => (
                    <div className="flex flex-col" key={callData.patientId ?? callData.callMainId}>
                      <PatientCard
                        callerId={callData.callerId}
                        callState={callData.state}
                        callType={callData.callType}
                        isOngoing={callGroup === "ongoingCalls"}
                        patientId={callData.patientId}
                        phone={callData.phoneNumber}
                      />
                    </div>
                  ))}
                </Fragment>
              ) : null;
            })}
          </div>
        )}
      </Popover>
    </div>
  ) : null;
};
