import {
  ComponentProps,
  ComponentPropsWithoutRef,
  FC,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { offset, shift } from "@floating-ui/react-dom-interactions";
import {
  AppointmentPatientProcedureVO,
  LabCaseAppointmentVO,
  LabCasePatientProcedureVO,
  LabCaseReturnReasonVO,
  LabCaseStatusVO,
  LabCaseVO,
  LabVO,
  PatientSummaryVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";
import { passRefs } from "@libs/utils/forms";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatISODate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { IconThemes, cxIconSizes } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { ReactComponent as FormIcon } from "@libs/assets/icons/forms-filled.svg";
import { ReactComponent as LabIcon } from "@libs/assets/icons/lab-case.svg";
import { ReactComponent as LabFilledIcon } from "@libs/assets/icons/lab-case-filled.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Menu, useMenu } from "@libs/components/UI/Menu";
import { Pill } from "@libs/components/UI/Pill";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOption, MenuOptionPaint, MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { NotificationCountIndicator } from "@libs/components/UI/NotificationIndicator";
import { FormFieldTextarea } from "@libs/components/UI/FormFieldTextarea";
import { printLabOrderForm } from "api/lab/queries";
import { QueryFiltersFlyover } from "components/UI/QueryFiltersFlyover";
import { OverflowItems } from "components/UI/OverflowItems";
import { LabCasesQuery } from "utils/routing/labCases";
import { getLeastAdvancedLabCaseStatus } from "components/LabCases/utils";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { useNow } from "hooks/useNow";

export const labCaseStatusOptions: { label: string; value: LabCaseVO["status"] }[] = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent", value: "SENT" },
  { label: "Received", value: "RECEIVED" },
  { label: "Returned", value: "RETURNED" },
  { label: "Ready", value: "READY" },
  { label: "Complete", value: "COMPLETE" },
];

export const PatientSearchFormFieldSelect = forwardRef<
  HTMLInputElement,
  Pick<
    ComponentPropsWithoutRef<typeof PatientSearchInput>,
    "onSearchChange" | "onClear" | "searchString" | "isSearching" | "required" | "error" | "disabled"
  > &
    Pick<
      ComponentPropsWithoutRef<typeof PatientSearchResultsMenu>,
      "patientSearchResults" | "onPatientSelect"
    >
>(({ patientSearchResults, onPatientSelect, onSearchChange, onClear, ...inputProps }, ref) => {
  const menu = useMenu<HTMLInputElement>();
  const [highlightIndex, setHighlightIndex] = useState<number>();

  const handleSearchKey: NonNullable<ComponentPropsWithoutRef<typeof PatientSearchInput>["onSearchKey"]> = (
    key
  ) => {
    switch (key) {
      case "ArrowDown": {
        setHighlightIndex((i) => Math.min((i ?? -1) + 1, patientSearchResults.length - 1));

        break;
      }
      case "ArrowUp": {
        setHighlightIndex((i) => Math.max((i ?? 1) - 1, 0));

        break;
      }
      case "Enter": {
        if (highlightIndex == null) {
          return;
        }

        const patient = patientSearchResults[highlightIndex];

        onPatientSelect(patient);
        menu.close();

        break;
      }
      // No default
    }
  };

  return (
    <>
      <PatientSearchInput
        ref={passRefs([ref, menu.triggerRef])}
        label="Patient"
        minWidth="h-full"
        maxWidth="h-full"
        {...inputProps}
        onSearchChange={(search) => {
          onSearchChange(search);
          setHighlightIndex(0);

          if (search) {
            menu.open();
          }
        }}
        onSearchKey={handleSearchKey}
        onClear={() => {
          onSearchChange("");
          onClear?.();
        }}
      />
      {menu.isOpen && (
        <PatientSearchResultsMenu
          triggerRef={menu.triggerRef}
          patientSearchResults={patientSearchResults}
          onPatientSelect={(patient) => {
            onPatientSelect(patient);
            setHighlightIndex(undefined);
            menu.close();
          }}
          onRequestClose={menu.close}
          highlightIndex={highlightIndex}
          onOptionEnter={setHighlightIndex}
        />
      )}
    </>
  );
});

export const PatientSearchInput = forwardRef<
  HTMLInputElement,
  {
    minWidth?: string;
    maxWidth?: string;
    searchString: string;
    isSearching: boolean;
    onSearchChange: (search: string) => void;
    onClear?: Func;
    onSearchKey?: (key: "ArrowUp" | "ArrowDown" | "Enter") => void;
  } & Omit<
    ComponentPropsWithoutRef<typeof FormFieldInput>,
    | "Icon"
    | "onIconClick"
    | "onBlur"
    | "onChange"
    | "onFocus"
    | "placeholder"
    | "value"
    | "iconClassName"
    | "autoComplete"
    | "onKeyDown"
  >
>(
  (
    { label, minWidth, maxWidth, searchString, isSearching, onSearchChange, onClear, onSearchKey, ...rest },
    ref
  ) => {
    const expandSearch = useBoolean(false);

    return (
      <FormFieldInput
        {...rest}
        ref={ref}
        label={label}
        className={cx(
          "transition-all duration-50 ease-out",
          expandSearch.isOn || searchString ? maxWidth ?? "w-52" : minWidth ?? "w-48"
        )}
        Icon={isSearching ? RefreshIcon : searchString ? RemoveIcon : SearchIcon}
        iconOnClick={onClear}
        onBlur={expandSearch.off}
        onChange={(e) => onSearchChange(e.target.value)}
        onFocus={expandSearch.on}
        placeholder="Search Patients"
        value={searchString}
        iconClassName={isSearching ? "animate-spin" : undefined}
        autoComplete="off"
        onKeyDown={(e) => {
          if (isOneOf(e.key, ["ArrowUp", "ArrowDown", "Enter"])) {
            e.preventDefault();
            onSearchKey?.(e.key);
          }
        }}
      />
    );
  }
);

export const PatientSearchResultsMenu: FC<
  {
    patientSearchResults: PatientSummaryVO[];
    onPatientSelect: (patient: PatientSummaryVO) => void;
    highlightIndex?: number;
    onOptionEnter?: (index: number) => void;
  } & ComponentProps<typeof Menu>
> = ({ patientSearchResults, className, highlightIndex, onPatientSelect, onOptionEnter, ...rest }) => {
  const now = useNow();

  return patientSearchResults.length > 0 ? (
    <Menu
      className={cx(
        `flex
         flex-col
         max-h-72
         overflow-y-auto
         border-greyLighter
         bg-white
         border
         shadow-main
         rounded`,
        className
      )}
      {...rest}
      matchTriggerWidth={rest.matchTriggerWidth ?? true}
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      middleware={[shift({ crossAxis: true }), offset(10)]}
      placement={rest.placement ?? "bottom"}
    >
      <MenuOptions
        options={createMenuOptions(
          ...patientSearchResults.map((patient, i) => ({
            value: i,
            label: getPatientDisplayNameFromPatient(now, patient),
            highlighted: i === highlightIndex,
            onMouseEnter: () => onOptionEnter?.(i),
          }))
        )}
        onOptionClick={(option) => onPatientSelect(patientSearchResults[option.value])}
      />
    </Menu>
  ) : null;
};

export const Note: FC<{
  note: string;
  max: number;
  error?: string;
  className?: string;
  onChange: (newNote: string) => void;
}> = ({ note, max, error, className, onChange }) => {
  return (
    <FormFieldTextarea
      label="Notes"
      rows={5}
      value={note}
      onChange={(e) => onChange(e.target.value)}
      className={className}
      error={error}
      disableResize
    >
      <CharacterCounter className="absolute bottom-1 right-3 text-xs" currentCount={note.length} max={max} />
    </FormFieldTextarea>
  );
};

export const LabFormIcon: React.FC<{ labUuid: LabVO["uuid"] }> = ({ labUuid }) => {
  const { practiceId } = useAccount();
  const [printFormClicked, setPrintFormClicked] = useState(false);
  const [printUrlQuery] = useApiQueries([
    printLabOrderForm({ args: { practiceId, labUuid }, queryOptions: { enabled: printFormClicked } }),
  ]);

  // Open print url in new tab when printFormClicked is true and printUrlQuery is ready.
  useEffect(() => {
    if (printFormClicked && printUrlQuery.data) {
      setPrintFormClicked(false);
      window.open(printUrlQuery.data, "_target");
    }
  }, [printFormClicked, printUrlQuery.data]);

  return (
    <FloatingTooltip content="Lab Order Form" theme="SMALL">
      {printFormClicked && printUrlQuery.isInitialLoading ? (
        <Spinner animation="border" size="md" variant="greyLighter" />
      ) : (
        <ButtonIcon SvgIcon={FormIcon} size="md" onClick={() => setPrintFormClicked(true)} />
      )}
    </FloatingTooltip>
  );
};

const cxLabCaseStatusIcons: Record<
  LabCaseVO["status"],
  { Icon: FunctionComponent<SVGProps<SVGSVGElement>>; textColor: string; bgColor: string }
> = {
  DRAFT: { Icon: LabIcon, textColor: "text-slate-500", bgColor: "bg-slate-50" },
  SENT: { Icon: LabFilledIcon, textColor: "text-blue", bgColor: "bg-blueLight" },
  RECEIVED: { Icon: LabFilledIcon, textColor: "text-yellow", bgColor: "bg-yellowLight" },
  RETURNED: { Icon: LabFilledIcon, textColor: "text-orange", bgColor: "bg-orangeLight" },
  READY: { Icon: LabFilledIcon, textColor: "text-green", bgColor: "bg-greenLight" },
  COMPLETE: { Icon: LabFilledIcon, textColor: "text-slate-500", bgColor: "bg-slate-50" },
};

const labCaseStatusIconsToMenuIconThemes: Record<LabCaseVO["status"], IconThemes> = {
  DRAFT: "slate500", // text-slate-500
  SENT: "primary", // text-blue
  RECEIVED: "yellow", // text-yellow
  RETURNED: "warning", // text-orange
  READY: "success", // text-green
  COMPLETE: "slate500", // text-slate-500
};

const cxCircleSizes = {
  xs: cxIconSizes.sm,
  sm: cxIconSizes.md,
  md: cxIconSizes.lg,
  lg: cxIconSizes.xl,
  xl: cxIconSizes.xxl,
  xxl: "h-9 w-9",
};

export const LabCaseStatusIcon: FC<{
  status: LabCaseVO["status"];
  size: keyof typeof cxIconSizes;
  shape?: "default" | "square";
}> = ({ status, size, shape = "default" }) => {
  const { Icon, textColor, bgColor } = cxLabCaseStatusIcons[status];

  return (
    <div
      className={cx(
        shape === "square"
          ? cx("rounded flex items-center justify-center", bgColor, cxCircleSizes[size])
          : cxIconSizes[size]
      )}
    >
      <Icon className={cx(cxIconSizes[size], textColor)} />
    </div>
  );
};

export const LabCaseMultiStatusIcon: FC<
  { labCases: LabCaseStatusVO[] } & Omit<ComponentProps<typeof LabCaseStatusIcon>, "status">
> = ({ labCases, ...labCaseStatusIconProps }) => {
  const leastAdvancedStatus = useMemo(
    () => getLeastAdvancedLabCaseStatus(labCases)?.status ?? "DRAFT",
    [labCases]
  );

  return (
    <NotificationCountIndicator theme="slate700" count={labCases.length > 1 ? labCases.length : undefined}>
      <FloatingTooltip
        content={
          labCases.length > 1 ? `${labCases.length} Lab Cases` : sentenceCaseConstant(leastAdvancedStatus)
        }
        theme="SMALL"
      >
        <div>
          <LabCaseStatusIcon {...labCaseStatusIconProps} status={leastAdvancedStatus} />
        </div>
      </FloatingTooltip>
    </NotificationCountIndicator>
  );
};

export const LabCaseStatusMenuButton: FC<
  PropsWithChildren<
    {
      labCaseReturnReasons: LabCaseReturnReasonVO[];
      buttonClassName?: string;
      onStatusChange: (status: LabCaseVO["status"], reason?: LabCaseReturnReasonVO["uuid"]) => void;
    } & Pick<ComponentProps<typeof ButtonMenu>, "placement" | "className">
  >
> = ({ labCaseReturnReasons, buttonClassName, children, onStatusChange, ...rest }) => {
  const menu = useMenu<HTMLButtonElement>();
  const reasonsSubMenu = useMenu<HTMLButtonElement>();

  return (
    <ButtonMenu
      {...rest}
      isOpen={menu.isOpen}
      onRequestOpen={menu.open}
      onRequestClose={() => {
        menu.close();
        reasonsSubMenu.close();
      }}
      menuContent={
        <div className="flex flex-col">
          {Object.entries(cxLabCaseStatusIcons).map(([caseStatus, { Icon }]) => {
            const status = caseStatus as LabCaseVO["status"];

            return status === "RETURNED" ? (
              <ButtonMenu
                key={status}
                isOpen={reasonsSubMenu.isOpen}
                onRequestOpen={reasonsSubMenu.open}
                onRequestClose={reasonsSubMenu.close}
                placement="right-start"
                menuContent={
                  <MenuOptions
                    options={createMenuOptions(
                      ...labCaseReturnReasons.map(({ uuid, reason }) => ({
                        value: uuid,
                        label: reason,
                      }))
                    )}
                    onOptionClick={(option) => {
                      onStatusChange(status, option.value);
                      menu.close();
                      reasonsSubMenu.close();
                    }}
                  />
                }
              >
                {(buttonProps) => {
                  return (
                    <MenuOption
                      option={{
                        ...buttonProps,
                        value: status,
                        label: sentenceCaseConstant(status),
                        SvgIcon: Icon,
                        iconTheme: labCaseStatusIconsToMenuIconThemes[status],
                      }}
                      onOptionClick={reasonsSubMenu.open}
                    />
                  );
                }}
              </ButtonMenu>
            ) : (
              <MenuOption
                key={status}
                option={{
                  value: status,
                  label: sentenceCaseConstant(status),
                  SvgIcon: Icon,
                  iconTheme: labCaseStatusIconsToMenuIconThemes[status],
                }}
                onOptionClick={(option) => {
                  onStatusChange(option.value);
                  menu.close();
                  reasonsSubMenu.close();
                }}
              />
            );
          })}
        </div>
      }
    >
      {(buttonProps) => {
        return (
          // eslint-disable-next-line react/button-has-type
          <button {...buttonProps} className={cx("flex", buttonClassName)}>
            {children}
          </button>
        );
      }}
    </ButtonMenu>
  );
};

export const LabCaseStatus: FC<{
  labCaseStatus: LabCaseVO["status"];
}> = ({ labCaseStatus }) => {
  return (
    <div className="text-xs flex items-center gap-x-2">
      <LabCaseStatusIcon size="md" status={labCaseStatus} />
      {sentenceCaseConstant(labCaseStatus)}
    </div>
  );
};

type LabCaseQueryFilters = Pick<LabCasesQuery, "labCaseStatuses">;

export const LabCaseQueryFiltersFlyover: FC<{
  statuses: LabCaseStatusVO["status"][];
  selectedStatuses: LabCaseStatusVO["status"][];
  onSubmit: (query: LabCaseQueryFilters) => void;
  onClose: () => void;
}> = ({ statuses, selectedStatuses, onClose, onSubmit }) => {
  const [draftQueryParams, setDraftQueryParams] = useState<LabCaseQueryFilters>({
    labCaseStatuses: [...selectedStatuses],
  });

  return (
    <QueryFiltersFlyover
      content={
        <div className="flex flex-col gap-y-6">
          <LabCaseStatusFilter
            statuses={statuses}
            selectedStatus={new Set(draftQueryParams.labCaseStatuses)}
            onSelectedStatusesChange={(updated) =>
              setDraftQueryParams({ ...draftQueryParams, labCaseStatuses: [...updated] })
            }
          />
        </div>
      }
      isSubmitting={false}
      onClose={onClose}
      onSubmit={() => onSubmit(draftQueryParams)}
    />
  );
};

const LabCaseStatusFilter: FC<{
  statuses: LabCaseStatusVO["status"][];
  selectedStatus: Set<LabCaseStatusVO["status"]>;
  onSelectedStatusesChange: (status: Set<LabCaseStatusVO["status"]>) => void;
}> = ({ statuses, selectedStatus, onSelectedStatusesChange }) => (
  <FilterSection title="Status">
    <CheckboxList
      layout="vert"
      verticalLayout="normal"
      options={statuses.map((status) => ({
        value: status,
        label: <LabCaseStatus labCaseStatus={status} />,
        checked: selectedStatus.has(status),
      }))}
      selectedValues={selectedStatus}
      onChange={onSelectedStatusesChange}
    />
  </FilterSection>
);

const FilterSection: FC<
  PropsWithChildren<{
    title: string;
  }>
> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-sm font-sansSemiBold flex items-center justify-between">
        <div>{title}</div>
      </div>
      {children}
    </div>
  );
};

export const LabCaseMultiStatusMenuButton: React.FC<
  PropsWithChildren<{
    labCaseStatuses: LabCaseStatusVO[];
    returnReasons: LabCaseReturnReasonVO[];
    onStatusChange: (
      labCaseStatusUuid: LabCaseStatusVO["uuid"],
      status: LabCaseVO["status"],
      reason?: LabCaseReturnReasonVO["uuid"]
    ) => void;
  }>
> = ({ labCaseStatuses, returnReasons, children, onStatusChange }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <div className="flex flex-col max-w-[13rem]">
          {labCaseStatuses.map((labCaseStatus) => (
            <MenuOptionPaint key={labCaseStatus.uuid}>
              <LabCaseStatusMenuButton
                buttonClassName="w-full"
                labCaseReturnReasons={returnReasons}
                onStatusChange={(status, reason) => {
                  onStatusChange(labCaseStatus.uuid, status, reason);
                  menu.off();
                }}
                placement="right-start"
              >
                <div className="flex items-center text-xs justify-between gap-x-8 w-full">
                  <div className="flex-1 overflow-hidden">
                    <ProceduresWithOverflow procedures={labCaseStatus.patientProcedures ?? []} />
                  </div>
                  <LabCaseStatusIcon size="md" status={labCaseStatus.status} />
                </div>
              </LabCaseStatusMenuButton>
            </MenuOptionPaint>
          ))}
        </div>
      }
      onRequestClose={menu.off}
    >
      {(buttonProps) => (
        <button {...buttonProps} className="block" type="button">
          {children}
        </button>
      )}
    </ButtonMenu>
  );
};

export const ProceduresWithOverflow: FC<{ procedures: LabCasePatientProcedureVO[] }> = ({ procedures }) => {
  return (
    <OverflowItems
      className="items-center"
      items={procedures}
      renderItem={(proc) => <span key={proc.id}>{proc.displayName}</span>}
      renderOverflow={(overflowItems) => (
        <FloatingTooltip
          theme="MEDIUM"
          content={<ProceduresOverflowToolipContent procedures={overflowItems} />}
        >
          <div>
            <PlusCount count={overflowItems.length} />
          </div>
        </FloatingTooltip>
      )}
    />
  );
};

type SimpleProcedure = Pick<AppointmentPatientProcedureVO, "id" | "displayName" | "description">;

export const ProcedurePillsWithOverflow: FC<{ procedures: SimpleProcedure[] }> = ({ procedures }) => {
  return (
    <OverflowItems
      className="items-center"
      items={procedures}
      renderItem={(proc) => (
        <FloatingTooltip key={proc.id} content={proc.description}>
          <Pill>{proc.displayName}</Pill>
        </FloatingTooltip>
      )}
      renderOverflow={(overflowItems) => (
        <FloatingTooltip
          theme="MEDIUM"
          content={<ProceduresOverflowToolipContent procedures={overflowItems} />}
        >
          <div>
            <PlusCount count={overflowItems.length} />
          </div>
        </FloatingTooltip>
      )}
    />
  );
};

export const ProceduresOverflowToolipContent: FC<{ procedures: SimpleProcedure[] }> = ({ procedures }) => {
  return (
    <ul className="space-y-2">
      {procedures.map((proc) => (
        <li key={proc.id} className="list-inside list-disc">
          <span className="-ml-1 font-sansSemiBold">{proc.displayName}:</span> {proc.description}
        </li>
      ))}
    </ul>
  );
};

export const AppointmentOverflowTooltipContent: FC<{ appointments: LabCaseAppointmentVO[] }> = ({
  appointments,
}) => {
  return (
    <ul className="space-y-2">
      {appointments.map((appt) => (
        <li key={appt.id} className="list-inside list-disc">
          <span className="-ml-1">{formatISODate(appt.date)}</span>
        </li>
      ))}
    </ul>
  );
};

const PlusCount: FC<{ count: number }> = ({ count }) => (
  <span className="text-archyBlue-500 cursor-default">+{count}</span>
);
