/* eslint-disable complexity */
import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { pluralize } from "@libs/utils/pluralize";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";

interface Props {
  selectedCount?: number;
  total?: number;
  singular?: string;
  plural?: string;
  className?: string;
}

/**
 * A component for rendering a selection count and/or total count of items.
 */
export const ItemsCount: FC<Props> = ({ className, selectedCount, total, singular, plural }) => {
  const label = singular && plural ? pluralize(total ?? selectedCount ?? 0, singular, plural) : "";

  return !selectedCount && total == null ? null : (
    <div
      className={cx(
        `text-xs
         border
         bg-white
         border-greyLighter
         py-1
         px-3
         h-6
         rounded`,
        className
      )}
    >
      {selectedCount
        ? total == null || selectedCount === total
          ? `${formatNumberWithCommas(selectedCount)} ${label} selected`
          : `${formatNumberWithCommas(selectedCount)} of ${formatNumberWithCommas(total)} ${label} selected`
        : total == null
          ? null
          : `${formatNumberWithCommas(total)} ${label}`}
    </div>
  );
};
