import { FC, useMemo } from "react";
import { FamilyRelationVO, InsuranceDetailsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  formType?: "ADD_FAMILY" | "CREATE_PATIENT" | "INSURANCE";
  includedOptions?: Set<InsuranceDetailsVO["type"]>;
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  onRelationshipChanged?: (value: FamilyRelationVO["relation"]) => void;
  onUpdate: (type: InsuranceDetailsVO["type"]) => void;
  positionRelations?: "right" | "bottom";
  relationshipError?: string;
  relationshipValue?: FamilyRelationVO["relation"];
  patientInsuranceFlag?: boolean;
}

export const DEFAULT_INCLUDED_OPTIONS: Set<InsuranceDetailsVO["type"]> = new Set([
  "PRIMARY_SUBSCRIBER",
  "DEPENDENT",
  "NONE",
]);

export const SelectInsuranceSubscriberType: FC<Props> = ({
  children,
  disabled = false,
  formType,
  includedOptions = DEFAULT_INCLUDED_OPTIONS,
  insuranceSubscriberType,
  onUpdate,
  patientInsuranceFlag = false,
}) => {
  const options = useMemo(() => {
    const baseOptions: { label: string; value: InsuranceDetailsVO["type"] }[] = [
      { label: "Primary Subscriber", value: "PRIMARY_SUBSCRIBER" },
      { label: "Dependent", value: "DEPENDENT" },
      { label: "None", value: "NONE" },
    ];

    return baseOptions.filter((option) => includedOptions.has(option.value));
  }, [includedOptions]);

  return disabled && formType === "INSURANCE" ? (
    <>
      <div className={cx("flex flex-col text-xs text-greyDark", !patientInsuranceFlag && "mt-2")}>
        <FormFieldLabel className="text-xs font-sansSemiBold" content="Subscriber Type" required />
        <span className={cx(patientInsuranceFlag ? "flex items-center pt-1" : "mt-3 mb-4")}>
          {sentenceCaseConstant(insuranceSubscriberType)}
        </span>
      </div>
      {!patientInsuranceFlag && (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 max-w-5xl">{children}</div>
      )}
    </>
  ) : (
    <RadioList
      label="Subscriber type"
      onChange={(e) => {
        onUpdate(e.target.value as InsuranceDetailsVO["type"]);
      }}
      options={options}
      optionListClassName={formType === "INSURANCE" && patientInsuranceFlag ? "h-8" : ""}
      required
      selectedValue={insuranceSubscriberType}
    >
      {children}
    </RadioList>
  );
};
