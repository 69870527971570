import { ChangeEvent, FC, KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { cx } from "@libs/utils/cx";
import { formatISOTimeAsAmPm } from "@libs/utils/date";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { ToggleGroup } from "components/UI/ToggleGroup";
import {
  AmPm,
  amPmOptions,
  constrainHourValue,
  constrainMinuteValue,
  getNextValueForHourKeydown,
  getNextValueForMinuteKeydown,
  inputValuesToTimeString,
  padTime,
  TimeInputValues,
  timeStringToInputValues,
} from "./timeInput.utils";

type Layout = "default" | "labelOut";

const useAmPmValue = (amPm: AmPm | undefined) => {
  const lastAmPmValue = useRef(amPm);

  useEffect(() => {
    if (amPm) {
      lastAmPmValue.current = amPm;
    }
  }, [amPm]);

  return amPm ?? lastAmPmValue.current;
};

const cxStyles = {
  input: ({ layout }: { layout: Layout }) =>
    cx(
      `focus-visible:outline-none
       px-4
       text-xs
       disabled:text-greyLight
       block
       w-full
       rounded
       min-w-12`,
      layout === "labelOut" ? "py-2" : "py-1.5"
    ),
};

export const TimeInput: FC<{
  className?: string;
  value?: string;
  error?: string;
  displayErrorMessage?: boolean;
  edit?: boolean;
  layout?: Layout;
  disabled?: boolean;
  required?: boolean;
  label?: ReactNode;
  onChange?: (val: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}> = ({
  className,
  value,
  error,
  displayErrorMessage = true,
  label,
  required,
  disabled,
  layout = "default",
  edit = true,
  onChange,
  onKeyDown,
}) => {
  const timeInput = useMemo(() => {
    return timeStringToInputValues(value ?? "");
  }, [value]);

  // ensures that once an ampm value is selected it sticks
  // even if the hours input is cleared out
  const ampmValue = useAmPmValue(timeInput.amPm);

  const updateTime = useCallback(
    (updates: Partial<TimeInputValues>) => {
      const merged = { ...timeInput, amPm: ampmValue, ...updates };

      onChange?.(inputValuesToTimeString({ ...merged, seconds: merged.hours || merged.minutes ? "00" : "" }));
    },
    [timeInput, onChange, ampmValue]
  );

  const handleHourKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    const nextValues = getNextValueForHourKeydown(e.currentTarget.value, e.key, ampmValue);

    if (nextValues) {
      updateTime(nextValues);
    }

    onKeyDown?.(e);
  };

  const handleMinuteKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    const nextValue = getNextValueForMinuteKeydown(e.currentTarget.value, e.key, timeInput.hours, ampmValue);

    if (nextValue) {
      updateTime(nextValue);
    }

    onKeyDown?.(e);
  };

  const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateTime({ hours: constrainHourValue(e.target.value) });
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateTime({ minutes: constrainMinuteValue(e.target.value) });
  };

  const handleMinuteBlur = () => {
    timeInput.minutes && updateTime({ minutes: padTime(timeInput.minutes) });
  };

  return (
    <div className={className}>
      {label ? (
        <FormFieldLabel
          className="font-sansSemiBold text-xs pb-1"
          required={required}
          disabled={disabled}
          error={error}
          content={label}
        />
      ) : null}
      {edit ? (
        <>
          <div className="flex items-center">
            <FormFieldContainer disabled={disabled} error={error} className="w-14">
              <input
                type="text"
                placeholder="HH"
                disabled={disabled}
                className={cxStyles.input({ layout })}
                inputMode="numeric"
                value={timeInput.hours}
                onChange={handleHourChange}
                onKeyDown={handleHourKeydown}
              />
            </FormFieldContainer>
            <div className="mx-1">:</div>
            <FormFieldContainer disabled={disabled} error={error} className="w-14">
              <input
                type="text"
                placeholder="MM"
                inputMode="numeric"
                disabled={disabled}
                className={cxStyles.input({ layout })}
                value={timeInput.minutes}
                onChange={handleMinuteChange}
                onBlur={handleMinuteBlur}
                onKeyDown={handleMinuteKeydown}
              />
            </FormFieldContainer>
            <ToggleGroup
              size="md"
              disabled={disabled}
              className="ml-2"
              options={amPmOptions}
              selectedValue={ampmValue}
              onOptionKeyDown={onKeyDown}
              onChange={(_e, option) => updateTime({ amPm: option.value })}
            />
          </div>

          {error && displayErrorMessage ? (
            <FormFieldError className="pt-1 w-48">{error}</FormFieldError>
          ) : null}
        </>
      ) : (
        <div className="text-xs font-sansSemiBold">{value ? formatISOTimeAsAmPm(value) : ""}</div>
      )}
    </div>
  );
};
