import { useCallback, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  ActionMeta,
  components as Components,
  DropdownIndicatorProps,
  InputActionMeta,
  SingleValue,
} from "react-select";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { Spinner } from "@libs/components/UI/Spinner";
import { isDefined } from "@libs/utils/types";
import { FormFieldSelect, FormFieldSelectProps } from "components/UI/FormFieldSelect";

interface BaseProps {
  minLength?: number;
  onSearch: (value: string) => void;
}

const SEARCH_DEBOUNCE_DELAY_MS = 200;

export type FormFieldAutoCompleteProps<V extends SelectOptionValue, T extends SelectOption<V>> = BaseProps &
  FormFieldProps &
  FormFieldSelectProps<V, T>;

const MIN_LENGTH_TO_SEARCH = 1;

const defaultComponents = {
  LoadingIndicator: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: DropdownIndicatorProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) =>
    props.selectProps.isClearable && props.hasValue ? null : (
      <Spinner variant="secondary" size="sm" animation="border" />
    ),
  DropdownIndicator: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: DropdownIndicatorProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) => {
    return (props.selectProps.isClearable && props.hasValue) || props.selectProps.isLoading ? null : (
      <Components.DropdownIndicator {...props}>
        <Icon SvgIcon={SearchIcon} className="fill-slate-700" />
      </Components.DropdownIndicator>
    );
  },
};

export const FormFieldAutoComplete = <V extends SelectOptionValue, T extends SelectOption<V>>({
  onSearch,
  components,
  minLength = MIN_LENGTH_TO_SEARCH,
  onChange,
  onInputChange,
  ...props
}: FormFieldAutoCompleteProps<V, T>) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedCallback(onSearch, SEARCH_DEBOUNCE_DELAY_MS, {
    trailing: true,
  });

  const handleInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      setSearch(newValue);

      if (newValue.length >= minLength) {
        debouncedSearch(newValue);
      }

      if (actionMeta.action === "input-change") {
        onInputChange?.(newValue, actionMeta);
      }
    },
    [onInputChange, debouncedSearch, minLength]
  );

  const handleChange = useCallback(
    (option: SingleValue<T>, actionMeta: ActionMeta<T>) => {
      if (!isDefined(option)) {
        onInputChange?.("", { action: "input-change", prevInputValue: search });
        setSearch("");
      }

      onChange?.(option, actionMeta);
    },
    [onChange, onInputChange, search]
  );

  const customComponents = useMemo(() => {
    return components
      ? {
          ...defaultComponents,
          ...components,
        }
      : defaultComponents;
  }, [components]);

  return (
    <FormFieldSelect
      // This prevents default filtering behavior of react-select when options
      // are generated asynchronously and/or due to results of a fuzzy search
      // https://github.com/JedWatson/react-select/issues/2865#issuecomment-508407878
      filterOption={() => true}
      {...(search.length < minLength && !props.openMenuOnFocus ? { menuIsOpen: false } : undefined)}
      loadingMessage={() => null}
      noOptionsMessage={() => null}
      {...props}
      components={customComponents}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  );
};
