import { ApiClientProvider } from "@libs/contexts/ApiClientContext";
import { getTokenErrorReason } from "@libs/utils/cognito";
import { isIPBlockedError } from "@libs/utils/isIPBlocked";
import { captureException } from "@sentry/react";
import { FC, PropsWithChildren, useCallback, useRef } from "react";
import { ErrorResponse, HttpResponse } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useLocation } from "react-router-dom";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useEnvContext } from "contexts/EnvContext";
import { useSignoutWithReason } from "hooks/useSignoutWithReason";
import { headers } from "utils/headers";
import { isMFARequiredError } from "utils/isMfaRequiredError";
import { paths } from "utils/routing/paths";

export const PracticeApiClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const env = useEnvContext();
  const { localStorage } = useStorageContext();

  const location = useLocation();
  const locationRef = useRef(location);

  // referencing locationRef vs location
  // because we need to keep this function a stable reference
  locationRef.current = location;

  const signOutWithReason = useSignoutWithReason();

  const handleResponseError = useCallback(
    async (response: HttpResponse<unknown, ErrorResponse | undefined>) => {
      if (response.error && isIPBlockedError(response.status, response.error)) {
        signOutWithReason("IP_BLOCKED");
      } else if (isMFARequiredError(response)) {
        const tokens = await getTokensForAuthCheck(localStorage);

        window.location.assign(
          paths.mfaSetup({ returnUrl: getFullUrl(locationRef.current), lastUserId: tokens.account?.userId })
        );
      }
    },
    [signOutWithReason, localStorage]
  );

  const handleRequestTokens = useCallback(async () => {
    try {
      return await getTokensForApi(localStorage);
    } catch (e) {
      const reason = getTokenErrorReason(e);

      if (!reason) {
        captureException(e);
      }

      signOutWithReason(reason);
      throw e;
    }
  }, [signOutWithReason, localStorage]);

  return (
    <ApiClientProvider
      baseUrl={env.REACT_APP_API_HOST}
      headers={headers}
      onRequestTokens={handleRequestTokens}
      onErrorResponse={handleResponseError}
    >
      {children}
    </ApiClientProvider>
  );
};
