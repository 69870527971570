import { FC, useMemo } from "react";
import { subDays } from "date-fns";
import { ContactVO, PatientSummaryVO } from "@libs/api/generated-api";
import { getRelations } from "@libs/hooks/useRelations";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Divider } from "@libs/components/UI/Divider";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { getPreferredContactMode } from "components/Patient/contactModes";
import { PatientValidation } from "components/Patient/formSchemas";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { PatientMatch } from "components/Patient/PatientMatch";
import { STATUS_OPTIONS } from "components/Patients/FiltersFlyoverContent";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldContactModes } from "components/Patient/FormFieldContactModes";
import { FormFieldPreferredContactMode } from "components/Patient/FormFieldPreferredContactMode";
import { ContactOption, PatientDraft } from "components/Patient/types";
import { ContactSearch } from "components/Patient/ContactSearch";
import { AddressFields } from "components/Patient/AddressFields";

export interface PatientFormProps {
  displayRelationshipSelect?: boolean;
  onSelectPatientMatch?: (matchedPatient: PatientSummaryVO) => void;
  onUpdatePatient: (updates: Partial<PatientDraft>) => void;
  onClearContact: Func;
  onSelectContact: (contact: ContactVO) => void;
  onAddContact: (patientFirstName: string) => void;
  onEditContact: (contactPatientId: number, patientFirstName: string) => void;
  defaultContactOptions?: () => ContactOption[];
  patientDraft: PatientDraft;
  patientMatch: PatientSummaryVO | undefined;
  validations: PatientValidation;
  contactSelection: ContactVO | undefined;
}

export const PatientForm: FC<PatientFormProps> = ({
  displayRelationshipSelect = false,
  onSelectPatientMatch,
  onClearContact,
  onSelectContact,
  onAddContact,
  onEditContact,
  onUpdatePatient,
  defaultContactOptions,
  patientDraft,
  patientMatch,
  validations,
  contactSelection,
}) => {
  const yesterday = useMemo(() => subDays(new Date(), 1), []);

  return (
    <div>
      <div className="pb-6 text-sm font-sansSemiBold">Personal Details</div>
      <div className="grid gap-4 grid-cols-4">
        {patientMatch && (
          <div className="col-span-4">
            <PatientMatch onSelect={onSelectPatientMatch} patient={patientMatch} />
          </div>
        )}

        {displayRelationshipSelect && (
          <FormFieldSelect
            className="col-span-4"
            error={validations.relationship.$error}
            isClearable={false}
            isSearchable={false}
            label="Relationship"
            onChange={(option) => onUpdatePatient({ relationship: option?.value })}
            options={getRelations().filter((option) => option.value !== "SELF")}
            required
            value={patientDraft.relationship}
          />
        )}
        <FormFieldInput
          className="col-span-2"
          error={validations.firstName.$error}
          label="First Name"
          onChange={(e) => onUpdatePatient({ firstName: e.target.value })}
          required
          value={patientDraft.firstName}
        />
        <FormFieldInput
          className="col-span-2"
          error={validations.lastName.$error}
          label="Last Name"
          onChange={(e) => onUpdatePatient({ lastName: e.target.value })}
          required
          value={patientDraft.lastName}
        />
        <FormFieldSelectMenusDatepicker
          className="col-span-2"
          error={validations.dob.$error}
          label="Date of Birth"
          maxDate={yesterday}
          onChange={(newDate) => onUpdatePatient({ dob: newDate })}
          required
          selected={patientDraft.dob}
        />
        <FormFieldSelect
          className="col-span-2"
          error={validations.status.$error}
          isClearable={false}
          isSearchable={false}
          label="Status"
          onChange={(option) => onUpdatePatient({ status: option?.value })}
          options={STATUS_OPTIONS}
          required
          value={patientDraft.status ?? "ACTIVE"}
        />
      </div>

      <Divider className="my-7 border-dashed" />
      <div className="pb-6 text-sm font-sansSemiBold">Contact Details</div>

      <div className="grid gap-4 grid-cols-4">
        <FormFieldContactModes
          hasContact={Boolean(contactSelection)}
          error={validations.contactModes.$error}
          contactModes={patientDraft.contactModes}
          className="col-span-2"
          onChange={(val) => {
            onUpdatePatient({
              contactModes: val,
              preferredContactMode: getPreferredContactMode(val, patientDraft.preferredContactMode),
            });
          }}
        />

        <FormFieldPreferredContactMode
          hasContact={Boolean(contactSelection)}
          error={validations.preferredContactMode.$error}
          contactModes={patientDraft.contactModes}
          preferredContactMode={patientDraft.preferredContactMode}
          className="col-span-2"
          onChange={(value) => {
            onUpdatePatient({
              preferredContactMode: value,
            });
          }}
        />
        <FormFieldPhoneInput
          className="col-span-2"
          Icon={InfoIcon}
          iconTooltip={{
            content: "Reminder: Confirm patient consent before using this phone number for messaging.",
          }}
          error={validations.phoneNumber.$error}
          label="Phone"
          onValueChange={(value) => onUpdatePatient({ phoneNumber: value })}
          required={!contactSelection && patientDraft.preferredContactMode !== "EMAIL"}
          value={patientDraft.phoneNumber}
        />
        <FormFieldInput
          className="col-span-2"
          error={validations.email.$error}
          label="Email"
          onChange={(e) => onUpdatePatient({ email: e.target.value })}
          required={!contactSelection && patientDraft.preferredContactMode === "EMAIL"}
          type="email"
          value={patientDraft.email}
        />
        <AddressFields
          address={patientDraft.addressDetails}
          onUpdate={(addressDetails) => onUpdatePatient({ addressDetails })}
        />

        <div className="col-span-4">
          <ContactSearch
            contact={contactSelection}
            onClearContact={onClearContact}
            onAddNewContact={() => onAddContact(patientDraft.firstName)}
            onEditContact={(contactPatientId) => onEditContact(contactPatientId, patientDraft.firstName)}
            onSelectContact={onSelectContact}
            defaultContactOptions={defaultContactOptions}
          />
        </div>
      </div>
    </div>
  );
};
