import React, { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { CurrentUser } from "contexts/CurrentUserContext";
import { PendoValue, usePendo } from "components/Main/usePendo";
import { getHasForceDynamsoft } from "components/ImageCapturing/useForceDynamsoft";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";

const Context = createContext<PendoValue>({
  trackEvent: noop,
});

Context.displayName = "PracticePendoContext";
export const PracticePendoContext = Context;
export const PracticePendoProvider: React.FC<
  PropsWithChildren & {
    user: CurrentUser;
    practice: PracticeInfoVO;
  }
> = ({ user, practice, children }) => {
  const { status: hubStatus } = useImagingHub();

  const logger = usePendo({ user, practice, isSignedIn: true });

  useEffect(() => {
    const isForceDynamsoft = getHasForceDynamsoft();

    if (isForceDynamsoft) {
      logger.trackEvent("Force Dynamsoft is enabled", {
        isAisAllowed: hubStatus.isAISAllowed,
        isAisDisabled: hubStatus.isAISDisabled,
        version: hubStatus.version,
        isRunning: hubStatus.isRunning,
      });
    }
  }, [hubStatus.isAISAllowed, hubStatus.isAISDisabled, hubStatus.isRunning, hubStatus.version, logger]);

  return <Context.Provider value={logger}>{children}</Context.Provider>;
};

export const usePracticePendoLogger = () => useContext(PracticePendoContext);
