import { PatientCallCardVO } from "@libs/api/generated-api";
import { UseBooleanResult } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as AppointmentIcon } from "@libs/assets/icons/appointments.svg";
import { ReactComponent as FamilyMembersIcon } from "@libs/assets/icons/people.svg";
import { AppointmentMenuContent } from "components/Mango/AppointmentMenuContent";
import { FamilyMembersMenuContent } from "components/Mango/FamilyMembersMenuContent";

interface Props {
  appointmentMenu: UseBooleanResult;
  familyMembersMenu: UseBooleanResult;
  isHovering: boolean;
  patientCallCard: PatientCallCardVO;
}

export const PatientCardActions: React.FC<Props> = ({
  appointmentMenu,
  familyMembersMenu,
  isHovering,
  patientCallCard,
}) => {
  return (
    <div className="relative flex items-center gap-x-2">
      {patientCallCard.familyMembers.length ? (
        <ButtonMenu
          isOpen={familyMembersMenu.isOn}
          menuContent={
            <FamilyMembersMenuContent
              familyMembers={patientCallCard.familyMembers}
              onClose={familyMembersMenu.off}
            />
          }
          onRequestClose={familyMembersMenu.off}
          onRequestOpen={familyMembersMenu.on}
          placement="top-end"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={FamilyMembersIcon}
              size="lg"
              theme={isHovering ? "primary" : "slate700"}
            />
          )}
        </ButtonMenu>
      ) : null}
      {patientCallCard.nextAppointment && (
        <ButtonMenu
          isOpen={appointmentMenu.isOn}
          menuContent={
            <AppointmentMenuContent
              appointment={patientCallCard.nextAppointment}
              onClose={appointmentMenu.off}
            />
          }
          onRequestClose={appointmentMenu.off}
          onRequestOpen={appointmentMenu.on}
          placement="top-end"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={AppointmentIcon}
              size="lg"
              theme={isHovering ? "primary" : "slate700"}
            />
          )}
        </ButtonMenu>
      )}
    </div>
  );
};
