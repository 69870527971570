import React, { useCallback, useMemo } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { PatientAppointmentDetails } from "components/PatientBlurbInfo/PatientAppointmentDetails";
import { getPatientSummary } from "api/patients/queries";
import { paths } from "utils/routing/paths";
import { updateAppointment } from "api/scheduling/mutations";
import { getAppointmentRequest } from "components/ScheduleAppointments/utils";
import { handleError } from "utils/handleError";

interface Props {
  appointment: AppointmentVO;
  onClose: Func;
}

export const AppointmentMenuContent: React.FC<Props> = ({ appointment, onClose }) => {
  const { practiceId } = useAccount();

  const [patientQuery] = useApiQueries([
    getPatientSummary({
      args: { patientId: appointment.patient.id, practiceId },
    }),
  ]);

  const [updateAppointmentMutation] = useApiMutations([updateAppointment]);

  const handleUpdateAppointmentState = useCallback(
    (appt: AppointmentVO, newState: AppointmentVO["state"]) => {
      updateAppointmentMutation.mutate(
        {
          practiceId,
          appointmentId: appt.id,
          original: {
            state: appt.state,
            date: appt.date,
            asap: appt.asap,
          },
          data: {
            ...getAppointmentRequest(appt),
            state: newState,
          },
        },
        {
          onError: handleError,
        }
      );
    },
    [practiceId, updateAppointmentMutation]
  );

  const handleEditAppointment = useCallback(() => {
    window.open(
      paths.editAppointment({ appointmentId: appointment.id, patientId: appointment.patient.id }),
      "_blank"
    );
    onClose();
  }, [appointment.id, appointment.patient.id, onClose]);

  const appointmentMenuActions = useMemo(
    () => ({
      edit: handleEditAppointment,
    }),
    [handleEditAppointment]
  );

  return (
    <div className="flex flex-col w-96 p-5">
      <QueryResult
        loading={
          <div className="h-40">
            <LoadingContent />
          </div>
        }
        queries={[patientQuery]}
      >
        {patientQuery.data && (
          <PatientAppointmentDetails
            appointment={appointment}
            appointmentActions={appointmentMenuActions}
            isNextAppointment={false}
            hasInsurance={Boolean(patientQuery.data.primaryInsuranceId)}
            onUpdateAppointmentState={handleUpdateAppointmentState}
          />
        )}
      </QueryResult>
    </div>
  );
};
