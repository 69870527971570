import { ReactNode, useId, useLayoutEffect, useMemo, useRef, useState } from "react";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { useWindowSize } from "utils/useWindowSize";

/* eslint-disable @typescript-eslint/naming-convention */
const spacingValues = {
  1: "gap-x-1",
};
/* eslint-enable @typescript-eslint/naming-convention */

interface Props<T> {
  items?: T[];
  spacing?: keyof typeof spacingValues;
  className?: string;
  renderItem: (item: T, index: number) => ReactNode;
  renderOverflow: (overflowItems: T[]) => ReactNode;
}

const getOverflowCount = <T,>({
  availableWidth,
  columnGap,
  children,
  items,
  overflowId,
}: {
  items: T[];
  children: Element[];
  columnGap: number;
  availableWidth: number;
  overflowId: string;
}) => {
  let currentWidth = 0;
  let proposedIndex = 0;

  while (currentWidth <= availableWidth && proposedIndex < children.length) {
    const proposedElement = children[proposedIndex];
    const proposedWidth =
      currentWidth + (proposedIndex === 0 ? 0 : columnGap) + proposedElement.getBoundingClientRect().width;

    // if we are within the available width
    // take the new proposed width and continue
    // adding more items
    if (proposedWidth < availableWidth) {
      proposedIndex++;
      currentWidth = proposedWidth;
      continue;
    }

    // we are not within the available width

    // if it's not the overflow element that is causing
    // the overflow then consider every item
    // from the proposedIndex and after as overflow
    if (proposedElement.id !== overflowId) {
      return items.slice(proposedIndex).length;
    }

    // if it is the overflow element that is causing
    // the overflow then take one more step back
    // to try and fit items plus the overflow element
    if (proposedIndex >= 1) {
      return items.slice(proposedIndex - 1).length;
    }

    // if the oveflow item is the only element and it
    // can't fit than just assume everything is overflow.
    return items.length;
  }

  return undefined;
};

/**
 *  An unstyled component for handling the logic of rendering items on one line and
 *  if there isn't sufficient space the consumer component is notified and given
 *  a callback to render an overflow indicator.
 *  @param items - a list of items you want to render
 *  @param spacing - the spacing between items
 *  @param renderItem - a callback that takes an item and its index and returns how you want the item to be rendered
 *  @param renderOverflow - a callback that gets called when there is not sufficient space
 *  and is provided the overflow items that don't fit
 *
 */
export const OverflowItems = <T,>({
  items,
  spacing = 1,
  renderItem,
  renderOverflow,
  className,
}: Props<T>) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childrenRef = useRef<HTMLDivElement | null>(null);
  const overflowId = useId();
  const [overflowCount, setOverflowCount] = useState(0);

  const { filteredItems, overflowItems } = useMemo(() => {
    return {
      filteredItems: overflowCount ? items?.slice(0, -1 * overflowCount) : items,
      overflowItems: overflowCount ? items?.slice(-1 * overflowCount) : [],
    };
  }, [overflowCount, items]);

  const screen = useWindowSize();

  // This allows items to re-appear.
  // If this was not hear we would never attempt
  // to re-render more elements to know whether they
  // fit or not.
  useLayoutEffect(() => {
    if (isDefined(screen?.width)) {
      setOverflowCount(0);
    }
  }, [screen?.width]);

  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if (parentRef.current && childrenRef.current && items) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const childrenRect = childrenRef.current.getBoundingClientRect();

      // if children are wider than parent we need to do some measuring to
      // figure out how many children plus an overflow indicator can fit
      if (childrenRect.width > parentRect.width) {
        // selects all children including the overflow indicator if present

        const children = childrenRef.current.children;

        if (children.length) {
          const availableWidth = parentRect.width;

          const columnGap = Number.parseInt(window.getComputedStyle(childrenRef.current).columnGap);

          const childrenElements = [...children];

          // given the available width, gap, rendered children, items list, and overflow
          // elementId we can check to see what the item overflow count should be
          const newOverflowCount = getOverflowCount({
            availableWidth,
            columnGap,
            children: childrenElements,
            items,
            overflowId,
          });

          if (isDefined(newOverflowCount)) {
            setOverflowCount(newOverflowCount);
          }
        }
      }
    }
  }, [screen?.width, items, overflowCount, overflowId]);

  return (
    <div ref={parentRef} className="w-full">
      <div ref={childrenRef} className={cx("flex w-max", spacingValues[spacing], className)}>
        {filteredItems?.map(renderItem)}
        {overflowItems?.length ? <div id={overflowId}>{renderOverflow(overflowItems)}</div> : null}
      </div>
    </div>
  );
};
