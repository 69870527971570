import { FC, useMemo, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { ReactComponent as EmployeesIcon } from "@libs/assets/icons/medical-doctor.svg";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as TrainingIcon } from "@libs/assets/icons/graduate.svg";

import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as HelpIcon } from "@libs/assets/icons/help.svg";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FormFieldButton } from "@libs/components/UI/FormFieldButton";
import { MenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useMainLinks } from "components/Main/MainLinksContext";

import { routesConfig } from "utils/routing/paths";
import { checkPermission } from "components/Roles/roleUtils";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { getUserAccounts } from "api/user/queries";
import { useEnvContext } from "contexts/EnvContext";
import { EnvValues } from "env";

interface Props {
  practiceName: string;
  isOnboarding?: boolean;
  onOptionClick: Func;
  onSwitchAccount: Func;
  onViewSoftareInfo: Func;
}

const getArchyTrainingOption = (env: EnvValues["REACT_APP_ENVIRONMENT"]) => {
  const invalidEnvironments = new Set<EnvValues["REACT_APP_ENVIRONMENT"]>(["demo", "production"]);

  return invalidEnvironments.has(env)
    ? undefined
    : createMenuOption({
        label: "Archy Training",
        value: "archy-training",
        SvgIcon: TrainingIcon,
      });
};

export const PracticeMenuContent: FC<Props> = ({
  isOnboarding,
  practiceName,
  onOptionClick,
  onSwitchAccount,
  onViewSoftareInfo,
}) => {
  const navigate = useNavigate();
  const { links } = useMainLinks();
  const currentUser = useCurrentUser();
  const { REACT_APP_ENVIRONMENT } = useEnvContext();

  const [userAccountsQuery] = useApiQueries([getUserAccounts({ args: { userTypes: ["EMPLOYEE"] } })]);

  const menuOptions = useMemo(
    () =>
      [
        ...(isOnboarding
          ? []
          : [
              checkPermission(currentUser.roleV2, "PRACTICE_SETTINGS", "ACCESS_ALL").isPermitted
                ? createMenuOption({
                    label: "Settings",
                    value: "settings",
                    SvgIcon: SettingsIcon,
                  })
                : null,

              checkPermission(currentUser.roleV2, "EMPLOYEE_SETTINGS", "ACCESS_ALL").isPermitted
                ? createMenuOption({
                    label: "Employees",
                    value: "employees",
                    SvgIcon: EmployeesIcon,
                  })
                : null,
            ]),
        getArchyTrainingOption(REACT_APP_ENVIRONMENT),
        createMenuOption({
          label: "Help",
          value: "help",
          SvgIcon: HelpIcon,
        }),
        createMenuOption({
          label: "Software Info",
          value: "softareInfo",
          SvgIcon: InfoIcon,
        }),
      ].filter(isDefined),
    [REACT_APP_ENVIRONMENT, currentUser.roleV2, isOnboarding]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "settings": {
          navigate(links.settings);
          break;
        }
        case "employees": {
          navigate(links.employees);
          break;
        }
        case "help": {
          window.open(routesConfig.ssoZendesk.path, "_blank");
          break;
        }
        case "archy-training": {
          window.open(routesConfig.ssoLearnupon.path, "_blank");
          break;
        }
        case "softareInfo": {
          onViewSoftareInfo();
          break;
        }
        default: {
          break;
        }
      }

      onOptionClick();
    },
    [links.employees, navigate, onOptionClick, links.settings, onViewSoftareInfo]
  );

  return (
    <>
      <div className="px-3 py-2">
        <div className="min-h-[34px] w-44">
          <QueryResult queries={[userAccountsQuery]}>
            {userAccountsQuery.data ? (
              userAccountsQuery.data.length > 1 || currentUser.type === "SUPPORT_USER" ? (
                <FormFieldButton
                  inputClassName="truncate"
                  buttonContent={practiceName}
                  onClick={onSwitchAccount}
                  Icon={DownCaretIcon}
                />
              ) : (
                <div
                  className={`
                    rounded
                    border
                    border-grey-200
                    px-4
                    py-2
                    text-xs
                    text-slate-900
                  `}
                >
                  {practiceName}
                </div>
              )
            ) : null}
          </QueryResult>
        </div>
      </div>

      <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
    </>
  );
};
