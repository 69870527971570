import { FC } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PatientVO } from "@libs/api/generated-api";
import { pluralize } from "@libs/utils/pluralize";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import SuccessPatient from "assets/images/patient-success.svg";
import { paths } from "utils/routing/paths";
import { Chip } from "components/UI/Chip";

const navigateProfile = (navigate: NavigateFunction, patientId: number, onRequestClose: Func) => {
  const url = paths.patient({ patientId });

  navigate(url);
  onRequestClose();
};

export const SuccessPopup: FC<{
  onRequestClose: Func;
  patients: PatientVO[];
}> = ({ onRequestClose, patients }) => {
  const navigate = useNavigate();
  const [patient1, patient2] = patients;

  return (
    <Modal size="xs">
      <ButtonIcon
        className="absolute top-5 right-5"
        SvgIcon={CancelIcon}
        onClick={onRequestClose}
        theme="primary"
      />
      <ModalContent padding="sm">
        <img alt="Success Patient" className="block m-auto" src={SuccessPatient} />
        <div className="text-center">
          <div className="font-sans text-4xl">{patients.length}</div>
        </div>
        <div className="text-center">
          <div className="font-sansSemiBold text-base">
            {pluralize(patients.length, "New Patient Created", "New Patients Created")}
          </div>
        </div>
      </ModalContent>
      <div className="flex-none p-5 bg-actionLight text-center h-[88px] rounded-b">
        <div className="font-sansSemiBold text-xs">View Patient Profile</div>
        <div className="flex justify-center mt-2">
          <Chip
            className="text-primaryTheme"
            onClick={() => navigateProfile(navigate, patient1.id, onRequestClose)}
          >
            {patient1.personalDetails.displayName}
          </Chip>
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {patient2 && (
            <Chip
              className="ml-3 text-primaryTheme"
              onClick={() => navigateProfile(navigate, patient2.id, onRequestClose)}
            >
              {patient2.personalDetails.displayName}
            </Chip>
          )}
        </div>
      </div>
    </Modal>
  );
};
