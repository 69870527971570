import { FC, useState } from "react";
import { ContactVO, PatientSummaryVO, PatientVO } from "@libs/api/generated-api";
import { FlyoverReel } from "components/UI/FlyoverReel";
import { CreatePatientFormPanel } from "components/Patient/CreatePatientFormPanel";
import { AddContactPanel } from "components/Patient/AddContactPanel";
import { EditContactPanel } from "components/Patient/EditContactPanel";

interface Props {
  onRequestClose: Func;
  onCreated: (patients: PatientVO[]) => void;
}

type CreatePatientPanel =
  | { id: "create"; isDirty?: boolean }
  | { id: "contact"; patientFirstName: string; patientId?: number; isDirty?: boolean };

const initialPanel = { id: "create" } as CreatePatientPanel;

export const CreatePatient: FC<Props> = ({ onRequestClose, onCreated }) => {
  const [contactSelection, setContactSelection] = useState<ContactVO>();

  return (
    <FlyoverReel initialPanel={initialPanel} onClose={onRequestClose} size="md">
      {({ panel, pushPanel, goBack, handleClose, markDirty, handleBack, setPanels }) => {
        const selectEditMatch = (patient: PatientSummaryVO) => {
          setPanels((last) => ({
            ...last,
            list: last.list.map((currentPanel) =>
              currentPanel.id === "contact"
                ? {
                    ...currentPanel,
                    patientFirstName: patient.name.firstName,
                    patientId: patient.id,
                  }
                : currentPanel
            ),
          }));
        };

        return panel.id === "create" ? (
          <CreatePatientFormPanel
            onCancel={handleClose}
            contactSelection={contactSelection}
            onCreated={onCreated}
            onClearContact={() => {
              markDirty();
              setContactSelection(undefined);
            }}
            onDirty={markDirty}
            onAddContact={(patientFirstName) => pushPanel({ id: "contact", patientFirstName })}
            onEditContact={(patientId, patientFirstName) =>
              pushPanel({ id: "contact", patientId, patientFirstName })
            }
            onSelectContact={(selection) => {
              markDirty();
              setContactSelection(selection);
            }}
          />
        ) : panel.patientId ? (
          <EditContactPanel
            onCancel={handleBack}
            onDirty={markDirty}
            contactPatientId={panel.patientId}
            onContactSelected={(selection) => {
              setContactSelection(selection);
              goBack();
            }}
            onMatchSelected={selectEditMatch}
            patientFirstName={panel.patientFirstName}
          />
        ) : (
          <AddContactPanel
            onCancel={handleBack}
            onDirty={markDirty}
            onContactSelected={(selection) => {
              setContactSelection(selection);
              goBack();
            }}
            onMatchSelected={selectEditMatch}
            patientFirstName={panel.patientFirstName}
          />
        );
      }}
    </FlyoverReel>
  );
};
