import { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from "react";

import { META_KEY, isTypeableElement } from "@libs/utils/keyboard";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";

export const useSearchModal = () => {
  const searchModal = useBoolean(false);
  const searchShortcut = `${META_KEY} + K`;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const slashShortcut = e.key === "/" && !isTypeableElement(e.target);
      const defaultShortcut = (META_KEY === "Ctrl" ? e.ctrlKey : e.metaKey) && e.key === "k";

      if ((slashShortcut || defaultShortcut) && searchModal.isOff) {
        e.preventDefault();

        searchModal.on();
      }
    },
    [searchModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return { searchModal, searchShortcut };
};

export const useSearchKeyboardNavigation = (debouncedSearchString: string) => {
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const handleKeyDownSearch: KeyboardEventHandler = useCallback(
    (e) => {
      if (!searchResultsRef.current) {
        return;
      }

      if (isOneOf(e.key, ["ArrowUp", "ArrowDown", "Enter"])) {
        e.preventDefault();

        const results = searchResultsRef.current.querySelectorAll("a");

        if (e.key === "Enter") {
          results[highlightedIndex].click();

          return;
        }

        const nextIndex =
          e.key === "ArrowUp"
            ? // Arrow up on first result moves to last result, else next up
              highlightedIndex === 0
              ? results.length - 1
              : highlightedIndex - 1
            : // Arrow down on last result moves to first result, else next down
              highlightedIndex === results.length - 1
              ? 0
              : highlightedIndex + 1;

        setHighlightedIndex(nextIndex);
      }
    },
    [highlightedIndex]
  );

  // Set highlighted index to 0 when search string changes
  useEffect(() => setHighlightedIndex(0), [debouncedSearchString]);

  return {
    searchResultsRef,
    highlightedIndex,
    handleMouseEnterResult: setHighlightedIndex,
    handleKeyDownSearch,
  };
};
