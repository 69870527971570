import { ContactDetailsVO, ContactModeVO, ContactVO, FamilyMemberVO } from "@libs/api/generated-api";
import { lowercase } from "@libs/utils/casing";
import { ContactOption } from "components/Patient/types";
import { sortByName } from "components/PatientProfile/PatientOverview/utils";
import { getPatientDisplayName } from "utils/names";

export const CONTACT_MODE_OPTIONS: { label: string; value: ContactDetailsVO["preferredContactMode"] }[] = [
  {
    label: "Text", // Default
    value: "TEXT",
  },
  {
    label: "Call",
    value: "CALL",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
];

export const DEFAULT_PREFERRED_CONTACT_MODE = "TEXT";

export const DEFAULT_CONTACT_MODE = {
  email: true,
  call: true,
  text: true,
};

export const hasAllowedContactMode = (contactMode: ContactDetailsVO["contactModes"]) => {
  return contactMode.email || contactMode.text || contactMode.call;
};

export const isTextingDisabled = (contact: ContactVO) => {
  return !contact.contactModes.text || !contact.textPhone;
};

export const isCallingDisabled = (contact: ContactVO) => {
  return !contact.contactModes.call || !contact.callPhone;
};

export const isEmailingDisabled = (contact: ContactVO) => {
  return !contact.contactModes.email || !contact.email;
};

export const getPreferredContactMode = (
  newAllowedContactModes: ContactModeVO,
  currentPreferredMode: ContactDetailsVO["preferredContactMode"] | undefined
) => {
  if (!currentPreferredMode) {
    return currentPreferredMode;
  }

  if (!newAllowedContactModes[lowercase(currentPreferredMode)]) {
    return undefined;
  }

  return currentPreferredMode;
};

export type ContactMode = keyof ContactModeVO;

export const EMPTY_CONTACT: ContactVO = {
  age: 0,
  name: {
    id: 0,
    fullDisplayName: "",
    shortDisplayName: "",
    firstName: "",
    lastName: "",
  },
  phone: "",
  email: "",
  textPhone: "",
  callPhone: "",
  contactModes: DEFAULT_CONTACT_MODE,
  preferredContactMode: DEFAULT_PREFERRED_CONTACT_MODE,
  displayName: "",
  relation: undefined,
};

export const familyMembersToContactOptions = (
  familyMembers: FamilyMemberVO[],
  currentPatientId?: number
): ContactOption[] => {
  return familyMembers
    .filter((familyMember) => familyMember.memberPatientId !== currentPatientId)
    .sort(sortByName)
    .map((familyMember) => {
      const displayName = getPatientDisplayName(familyMember);

      return {
        isDisabled: familyMember.contact.relation !== "SELF",
        label: displayName,
        value: familyMember.memberPatientId,
        data: {
          ...familyMember.contact,
          relation: familyMember.relation,
        },
      };
    });
};
