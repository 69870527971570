import { Link } from "react-router-dom";
import { PatientCallCardVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { genderInitial } from "components/PatientProfile/PatientOverview/utils";

import { paths } from "utils/routing/paths";
import { CallType } from "components/Mango/types";

interface Props {
  callerId: string | undefined;
  callType: CallType;
  contactName: string;
  isOngoing: boolean;
  patientCallCard: PatientCallCardVO | undefined;
  patientId: number | undefined;
  patientName: string;
  phone: string | undefined;
}

const cxStyles = {
  container: "flex flex-col items-start text-greyDark text-xs",
  name: "font-sansSemiBold text-sm",
  phone: "font-sansSemiBold text-greenDark",
};

// eslint-disable-next-line complexity
export const PatientCardData: React.FC<Props> = ({
  callerId,
  callType,
  contactName,
  isOngoing,
  patientCallCard,
  patientId,
  patientName,
  phone,
}) => {
  return (
    <div className="flex flex-1 items-center h-full">
      <div className="h-full">
        {patientCallCard ? (
          <div className={cxStyles.container}>
            {patientCallCard.multiplePatientsWithSamePhone && callType !== CallType.OUTBOUND && (
              <span>Could be</span>
            )}
            {patientCallCard.contact && callType === CallType.OUTBOUND ? (
              <>
                <span className={cxStyles.name}>{contactName}</span>
                <span>{`Contact person for ${patientName}`}</span>
              </>
            ) : patientId ? (
              <Link
                className={cxStyles.name}
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                to={paths.patient({ patientId })}
              >
                {patientName}
              </Link>
            ) : (
              <span className={cxStyles.name}>{patientName}</span>
            )}
            {isOngoing ? (
              <div className="flex items-center gap-x-2">
                {formatISODate(patientCallCard.summary.dob)}
                <VerticalDivider size="sm" />
                {`${patientCallCard.summary.age}${
                  patientCallCard.summary.gender ? `, ${genderInitial[patientCallCard.summary.gender]}` : ""
                }`}
              </div>
            ) : (
              <span className={cxStyles.phone}>{phone ? formatPhoneNumber(phone) : ""}</span>
            )}
          </div>
        ) : (
          <div className={cxStyles.container}>
            <span className={cx("capitalize", cxStyles.name)}>
              {callerId?.toLowerCase() ?? "Unknown Caller"}
            </span>
            {!isOngoing && <span className={cxStyles.phone}>{phone ? formatPhoneNumber(phone) : ""}</span>}
          </div>
        )}
      </div>
    </div>
  );
};
