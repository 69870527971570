export type SetValues = number | string;

export type HasAllValues<T extends SetValues, Values extends T[]> = Record<
  Values[number],
  null
> extends Record<T, null>
  ? Values
  : never;

/**
 * Returns an object with utility methods for ensuring that all values in a union type are present and unique.
 * @template T The type of the enumeration values, which must extend string or number.
 * @returns {Object} An object
 * `createSet` returns a set of all the values in the union type.
 * `createUniqueList` returns a list of all the values in the union type with duplicates removed.
 */
export const union = <T extends SetValues>() => ({
  createSet: <Values extends T[]>(enumValues: HasAllValues<T, Values>) => new Set(enumValues),
  createUniqueList: <Values extends T[]>(enumValues: HasAllValues<T, Values>) => [...new Set(enumValues)],
});
