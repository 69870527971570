import { FC, useCallback } from "react";

import { LastWorktimeVOV2, WorktimeActionRequest } from "@libs/api/generated-api";
import { formatAsISOTime, nowInTimezone } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { EditClockInOutTime } from "components/Main/EditClockInOutTime";
import { ClockInOutNow } from "components/Main/ClockInOutNow";
import { addWorktimeEntry } from "api/employee/mutations";
import { handleError } from "utils/handleError";

interface Props {
  lastStatus: LastWorktimeVOV2 | undefined;
  onCloseClick: Func;
}

export const ClockInOut: FC<Props> = ({ lastStatus, onCloseClick }) => {
  const editing = useBoolean(false);
  const { id } = useAccount();
  const practice = useCurrentPractice();

  const [addWorktimeEntryMutation] = useApiMutations([addWorktimeEntry]);

  const handleAddEntry = useCallback(
    (action: WorktimeActionRequest) => {
      addWorktimeEntryMutation.mutate(
        {
          practiceId: practice.id,
          employeeId: id,
          data: action,
        },
        {
          onError: handleError,
          onSuccess: onCloseClick,
        }
      );
    },
    [practice.id, id, addWorktimeEntryMutation, onCloseClick]
  );

  const updateClock = () => {
    const lastAction = lastStatus?.lastAction;

    const currentTime = formatAsISOTime(nowInTimezone(practice.timezoneId));
    const isClockingIn = lastAction === "CLOCK_OUT";

    handleAddEntry({
      time: currentTime,
      action: isClockingIn ? "CLOCK_IN" : "CLOCK_OUT",
    });
  };

  const updateWorkTime = ({ comment, time }: { comment: string; time: string }) => {
    const lastAction = lastStatus?.lastAction ?? "CLOCK_OUT";

    const currentTime = formatAsISOTime(nowInTimezone(practice.timezoneId));
    const isClockingIn = lastAction === "CLOCK_OUT";

    handleAddEntry({
      time: currentTime,
      submittedTime: time,
      action: isClockingIn ? "CLOCK_IN" : "CLOCK_OUT",
      notes: comment,
    });
  };

  return (
    <Modal
      onClose={onCloseClick}
      size="2xs"
      title={lastStatus?.lastAction === "CLOCK_IN" ? "Clock Out" : "Clock In"}
    >
      {editing.isOn ? (
        <EditClockInOutTime
          lastStatus={lastStatus}
          onSubmit={updateWorkTime}
          onCancel={editing.off}
          isSaving={addWorktimeEntryMutation.isLoading}
        />
      ) : (
        <ClockInOutNow
          lastStatus={lastStatus}
          onConfirm={updateClock}
          onCancel={onCloseClick}
          onEdit={editing.on}
          isSaving={addWorktimeEntryMutation.isLoading}
        />
      )}
    </Modal>
  );
};
