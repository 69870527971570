import React from "react";
import { Link } from "react-router-dom";
import { LinkedFamilyMemberVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ArrowIcon } from "@libs/assets/icons/right-arrow.svg";
import { paths } from "utils/routing/paths";

const FamilyMemberRow: React.FC<{ member: LinkedFamilyMemberVO; onClose: Func }> = ({ member, onClose }) => {
  const hover = useBoolean(false);

  return (
    <Link
      className={`
        flex
        items-center
        justify-between
        h-8
        px-2
        min-w-60
        text-xs
        rounded
        group
      `}
      onClick={onClose}
      onMouseEnter={hover.on}
      onMouseLeave={hover.off}
      target="_blank"
      to={paths.patient({ patientId: member.name.id })}
    >
      <div className="group-hover:bg-grey-100">{member.name.fullDisplayName.split(",")[0]}</div>
      <Icon className="hidden group-hover:block" SvgIcon={ArrowIcon} size="lg" />
    </Link>
  );
};

interface Props {
  familyMembers: LinkedFamilyMemberVO[];
  onClose: Func;
}

export const FamilyMembersMenuContent: React.FC<Props> = ({ familyMembers, onClose }) => {
  return (
    <div className="flex flex-col p-1">
      {familyMembers.map((member) => (
        <FamilyMemberRow key={member.name.id} member={member} onClose={onClose} />
      ))}
    </div>
  );
};
