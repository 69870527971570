import React from "react";
import Skeleton from "react-loading-skeleton";
import { PatientInsuranceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";

const cxSizes = { sm: "h-4 w-4", md: "h-5 w-5", lg: "h-6 w-6" };

export type InsuranceBadgeSizes = keyof typeof cxSizes;

const cxBorderColors = {
  white: "border-white",
  slate100: "border-slate-100",
};

export type InsuranceBadgeProps = {
  children: React.ReactNode;
  className: string;
  loading?: boolean;
  tooltip: string;
  size: InsuranceBadgeSizes;
  borderColor?: keyof typeof cxBorderColors;
  badgeType?: "small" | "large";
};

const InsuranceBadge: React.FC<InsuranceBadgeProps> = ({
  children,
  className,
  loading = false,
  size,
  tooltip,
  borderColor,
  badgeType = "small",
}) => {
  return loading ? (
    <Skeleton className={cx("rounded-full", cxSizes[size])} />
  ) : (
    <FloatingTooltip content={tooltip} theme="SMALL">
      <span
        className={cx(
          `rounded-full
           flex
           items-center
           justify-center
           text-white
           font-normal
           text-xs
           font-sans`,
          badgeType === "small" ? cx(cxSizes[size]) : "h-6 px-2",
          borderColor ? cx("border", cxBorderColors[borderColor]) : "",
          className
        )}
      >
        {children}
      </span>
    </FloatingTooltip>
  );
};

type InsuranceSubscriberBadgeProps = {
  networkBadgeType?: "small" | "large";
  isPrimary?: boolean;
  className?: string;
  loading?: boolean;
  networkType?: PatientInsuranceVO["insuranceNetworkType"];
  size?: InsuranceBadgeSizes;
  borderColor?: InsuranceBadgeProps["borderColor"];
};

// eslint-disable-next-line complexity
export const InsuranceSubscriberBadge: React.FC<InsuranceSubscriberBadgeProps> = ({
  networkBadgeType = "small",
  isPrimary,
  className,
  borderColor,
  loading = false,
  networkType,
  size = "md",
}) => {
  return (
    <InsuranceBadge
      borderColor={borderColor}
      loading={loading}
      badgeType={networkBadgeType}
      className={cx(
        isPrimary || networkType === "IN_NETWORK"
          ? "text-blueDark bg-blueLight"
          : "text-magentaDark bg-magentaLight",
        className
      )}
      size={size}
      tooltip={
        isDefined(isPrimary)
          ? isPrimary
            ? "Primary Subscriber"
            : "Dependent"
          : networkType === "IN_NETWORK"
            ? "In Network"
            : "Out of Network"
      }
    >
      <span className={cx(isPrimary || networkType === "IN_NETWORK" ? "text-blueDark" : "text-magentaDark")}>
        {isDefined(isPrimary)
          ? isPrimary
            ? "P"
            : "D"
          : networkType === "IN_NETWORK"
            ? networkBadgeType === "small"
              ? "I"
              : "In Network"
            : networkBadgeType === "small"
              ? "O"
              : "Out of Network"}
      </span>
    </InsuranceBadge>
  );
};

type VerificationBadgeProps = {
  type: "benefits" | "eligibility";
  verified: boolean;
  loading: boolean;
  size?: InsuranceBadgeSizes;
  tooltip: string;
  borderColor?: InsuranceBadgeProps["borderColor"];
};

export const VerificationBadge: React.FC<VerificationBadgeProps> = ({
  type,
  verified,
  loading = false,
  tooltip,
  size = "md",
  borderColor,
}) => {
  return (
    <InsuranceBadge
      borderColor={borderColor}
      loading={loading}
      className={cx(verified ? "bg-greenLight" : "bg-magentaLight")}
      size={size}
      tooltip={tooltip}
    >
      <span className={cx(verified ? "text-greenDark" : "text-magentaDark")}>
        {type === "benefits" && "B"} {type === "eligibility" && "E"}
      </span>
    </InsuranceBadge>
  );
};
