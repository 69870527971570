import {
  ContactDetailsVO,
  ContactVO,
  CreatePatientRequest,
  FamilyRelationVO,
  InsuranceDetailsVO,
  PatientSummaryVO,
  UpdatePatientInsuranceRequest,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatAsISODate } from "@libs/utils/date";
import { NO_SELECTED_NUMERIC_ID, UseInsuranceState } from "components/Patient/hooks";
import {
  ExistingPatient,
  PrimarySubscriber,
  DependentPrimarySubscriber,
  PatientDraft,
  SelectedPrimarySubscriber,
  Permission,
  ContactDraft,
} from "components/Patient/types";
import { DEFAULT_CONTACT_MODE, DEFAULT_PREFERRED_CONTACT_MODE } from "components/Patient/contactModes";

const getPrimarySubscriberPostData = (primarySubscriber: PrimarySubscriber) => ({
  employer: primarySubscriber.employer,
  carrierId: primarySubscriber.carrierId,
  ...(primarySubscriber.subscriberIdType === "ssn"
    ? {
        ssn: primarySubscriber.ssn ?? undefined,
        ssnLastFour: primarySubscriber.ssn ? undefined : primarySubscriber.ssnLastFour,
      }
    : {
        subscriberId: primarySubscriber.subscriberId,
      }),
});

const getDependentPrimarySubscriberPostData = (newPrimarySubscriber: DependentPrimarySubscriber) => ({
  firstName: newPrimarySubscriber.firstName,
  lastName: newPrimarySubscriber.lastName,
  dob: newPrimarySubscriber.dob ? formatAsISODate(newPrimarySubscriber.dob) : "",
  employer: newPrimarySubscriber.employer,
  carrierId: newPrimarySubscriber.carrierId,
  relation: newPrimarySubscriber.relationship,
  ...(newPrimarySubscriber.subscriberIdType === "ssn"
    ? { ssn: newPrimarySubscriber.ssn }
    : {
        subscriberId: newPrimarySubscriber.subscriberId,
      }),
});

export const getContactPostData = (contact: ContactDraft) => {
  const patient: UpdatePatientRequest = {
    additionalInformation: {},
    contactDetails: {
      email: contact.email,
      phone: contact.phoneNumber,
      contactModes: contact.contactModes,
      // validators enforce this isn't undefined
      preferredContactMode: contact.preferredContactMode as ContactDetailsVO["preferredContactMode"],
      addressDetails: contact.addressDetails ?? undefined,
    },
    personalDetails: {
      dob: contact.dob ? formatAsISODate(contact.dob) : "",
      firstName: contact.firstName,
      lastName: contact.lastName,
      status: contact.status,
    },
  };

  return patient;
};

const getNewPatientPostData = (
  newPatient: PatientDraft,
  options?: {
    contactSelection?: ContactVO;
  }
) => {
  const patient: CreatePatientRequest = {
    contactDetails: {
      email: newPatient.email,
      phone: newPatient.phoneNumber,

      // If a contact is selected ignore contact mode selections
      // and just use the defaults
      preferredContactMode: options?.contactSelection
        ? DEFAULT_PREFERRED_CONTACT_MODE
        : // validators enforce this isn't undefined
          (newPatient.preferredContactMode as ContactDetailsVO["preferredContactMode"]),
      contactModes: options?.contactSelection ? DEFAULT_CONTACT_MODE : newPatient.contactModes,

      contactPatientId: options?.contactSelection?.name.id,
      contactRelation: options?.contactSelection?.relation,
      addressDetails: newPatient.addressDetails ?? undefined,
    },

    insuranceDetails: {
      type: newPatient.insuranceSubscriberType,
    },
    personalDetails: {
      dob: newPatient.dob ? formatAsISODate(newPatient.dob) : "",
      firstName: newPatient.firstName,
      lastName: newPatient.lastName,
      status: newPatient.status,
    },
  };

  return patient;
};

export interface CreatePatientFormState {
  dependentPrimarySubscriber: DependentPrimarySubscriber;
  primarySubscriber: PrimarySubscriber;
  selectedPrimarySubscriber: SelectedPrimarySubscriber;
  patientMatch: PatientSummaryVO | undefined;
  patientDraft: PatientDraft;
}
export interface UpdateInsuranceFormState extends CreatePatientFormState {
  insuranceState: UseInsuranceState["insuranceState"];
}

const getPrimarySubscriber = (params: {
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  primarySubscriber: PrimarySubscriber;
  dependentPrimarySubscriber: DependentPrimarySubscriber;
  selectedPrimarySubscriber: SelectedPrimarySubscriber;
}) => {
  const {
    insuranceSubscriberType,
    primarySubscriber,
    selectedPrimarySubscriber,
    dependentPrimarySubscriber,
  } = params;

  return insuranceSubscriberType === "NONE"
    ? undefined
    : insuranceSubscriberType === "PRIMARY_SUBSCRIBER"
      ? getPrimarySubscriberPostData(primarySubscriber)
      : selectedPrimarySubscriber.patientId === NO_SELECTED_NUMERIC_ID
        ? getDependentPrimarySubscriberPostData(dependentPrimarySubscriber)
        : {
            patientId: selectedPrimarySubscriber.patientId,
            relation: dependentPrimarySubscriber.relationship,
          };
};

export const getCreatePatientPostData = ({
  dependentPrimarySubscriber,
  primarySubscriber,
  patientDraft: newPatient,
  contactSelection,

  selectedPrimarySubscriber,
}: CreatePatientFormState & { contactSelection?: ContactVO }) => {
  const postData = getNewPatientPostData(newPatient, { contactSelection });

  return {
    ...postData,
    insuranceDetails: {
      ...postData.insuranceDetails,
      primarySubscriber: getPrimarySubscriber({
        insuranceSubscriberType: newPatient.insuranceSubscriberType,
        primarySubscriber,
        dependentPrimarySubscriber,
        selectedPrimarySubscriber,
      }),
    },
  };
};

export interface AddFamilyMemberFormState {
  isExistingPatient: boolean;
  primarySubscriber: PrimarySubscriber;
  patientDraft: PatientDraft;
  selectedPrimarySubscriber: SelectedPrimarySubscriber;
  permissions: Set<Permission>;
  memberPermissions: Set<Permission>;
  currentPatientId: number;
  existingPatient: ExistingPatient;
}

const assertRelationship = (relation?: FamilyRelationVO["relation"]): FamilyRelationVO["relation"] => {
  if (relation === undefined) {
    throw new Error("relationship required");
  }

  return relation;
};

export const getAddFamilyMemberPostData = ({
  primarySubscriber,
  patientDraft,
  contactSelection,
  selectedPrimarySubscriber,
  permissions,
  memberPermissions,
  currentPatientId,
  existingPatient,
}: AddFamilyMemberFormState & { contactSelection: ContactVO | undefined }) => {
  const postPermissions = {
    member: {
      hasPhiAccess: memberPermissions.has("phi"),
      isGuardian: memberPermissions.has("guardian"),
    },
    patient: {
      hasPhiAccess: permissions.has("phi"),
      isGuardian: permissions.has("guardian"),
    },
  };

  if (existingPatient.patientId !== NO_SELECTED_NUMERIC_ID) {
    return {
      type: "Existing",
      relation: assertRelationship(existingPatient.relationship),
      existingPatient: {
        id: existingPatient.patientId,
      },
      permissions: postPermissions,
    };
  }

  const newPatientPostData = getNewPatientPostData(patientDraft, { contactSelection });

  return {
    type: "New",
    relation: assertRelationship(patientDraft.relationship),
    permissions: postPermissions,
    newPatient: {
      ...newPatientPostData,
      insuranceDetails: {
        ...newPatientPostData.insuranceDetails,
        primarySubscriber:
          patientDraft.insuranceSubscriberType === "NONE"
            ? undefined
            : patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER"
              ? getPrimarySubscriberPostData(primarySubscriber)
              : {
                  patientId: selectedPrimarySubscriber.patientId,
                  relation:
                    selectedPrimarySubscriber.patientId === currentPatientId && patientDraft.relationship
                      ? patientDraft.relationship
                      : selectedPrimarySubscriber.relationship,
                },
      },
    },
  };
};

export const getUpdatePatientInsurancePatchData = (
  insuranceState: UseInsuranceState["insuranceState"],
  subscriber: PrimarySubscriber,
  relation?: FamilyRelationVO["relation"]
): UpdatePatientInsuranceRequest => {
  const changedSsn = isDefined(subscriber.ssn);

  return {
    carrierId: subscriber.carrierId,
    employer: subscriber.employer,

    // When a patient changes subscriberIdType to 'ssn' but doesn't edit it. We only know the ssnLastFour of primarySubscriber
    ssn: subscriber.subscriberIdType === "ssn" ? subscriber.ssn : undefined,

    // When a patient changes subscriberIdType to 'ssn' but doesn't edit it. We only know the ssnLastFour of primarySubscriber
    ssnLastFour: !changedSsn && subscriber.subscriberIdType === "ssn" ? subscriber.ssnLastFour : undefined,
    relation,
    subscriberId: subscriber.subscriberIdType === "subscriberId" ? subscriber.subscriberId : undefined,
    releaseOfPatientInfo: insuranceState.releaseOfPatientInfo,
    assignmentOfBenefits: insuranceState.assignmentOfBenefitsToPractice,
    startDate: insuranceState.startDate ?? "",
    expiryDate: insuranceState.expiryDate ?? "",
    notes: insuranceState.notes,
  };
};

export const getAddInsurancePostData = (subscriberState: UpdateInsuranceFormState): InsuranceDetailsVO => {
  const {
    patientDraft: newPatient,
    primarySubscriber,
    dependentPrimarySubscriber,
    selectedPrimarySubscriber,
    insuranceState,
  } = subscriberState;

  return {
    type: newPatient.insuranceSubscriberType,
    primarySubscriber: {
      ...getPrimarySubscriber({
        insuranceSubscriberType: newPatient.insuranceSubscriberType,
        primarySubscriber,
        dependentPrimarySubscriber,
        selectedPrimarySubscriber,
      }),
      releaseOfPatientInfo: insuranceState.releaseOfPatientInfo,
      assignmentOfBenefits: insuranceState.assignmentOfBenefitsToPractice,
      notes: insuranceState.notes,
      startDate: insuranceState.startDate,
      expiryDate: insuranceState.expiryDate,
    },
  };
};
