import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";
import { noop } from "@libs/utils/noop";
import { UseBooleanResult, makeInitialState, useBoolean } from "@libs/hooks/useBoolean";

type IntraOfficeChatContext = {
  chatIsOn: boolean;
  closeChat: Func;
  currentChannelUrl: string | null;
  openChat: Func;
  popover: UseBooleanResult;
  setCurrentChannelUrl: (updatedUrl: string | null) => void;
};

const Context = createContext<IntraOfficeChatContext>({
  chatIsOn: false,
  closeChat: noop,
  currentChannelUrl: null,
  openChat: noop,
  popover: makeInitialState(false),
  setCurrentChannelUrl: noop,
});

Context.displayName = "IntraOfficeChatContext";

export const useIntraOfficeChatContext = () => useContext(Context);

export const IntraOfficeChatProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const chat = useBoolean(false);
  const popover = useBoolean(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState<string | null>(null);

  const handleChatOpen = useCallback(() => {
    chat.on();
    popover.off();
  }, [chat, popover]);

  const value = useMemo(() => {
    return {
      chatIsOn: chat.isOn,
      closeChat: chat.off,
      currentChannelUrl,
      openChat: handleChatOpen,
      popover,
      setCurrentChannelUrl,
    };
  }, [chat.isOn, chat.off, currentChannelUrl, handleChatOpen, popover]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
