import { FC, PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { ErrorBoundary as SentryErrorBoundary, captureException } from "@sentry/react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getTokenErrorReason } from "@libs/utils/cognito";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { shallowEqual } from "@libs/utils/shallowEqual";
import { MangoPopOver } from "components/Mango/MangoPopOver";
import { Navigation } from "components/Main/Navigation";
import { getLastWorktimeAction } from "api/employee/queries";
import { getProfileImageQuery } from "api/user/queries";
import { getPracticeCounters } from "api/practice/queries";
import { MainLinksProvider, getDefaultMainLinks } from "components/Main/MainLinksContext";
import { GlobalLinksProvider } from "contexts/AppLinksContext";
import { DeleteAppointmentProvider } from "contexts/DeleteAppointmentContext";
import { getClinicianPrescriptionStatus, getDosespotNotificationCount } from "api/erx/queries";
import { WebSocketProvider } from "contexts/WebSocketContext";
import { MangoProvider } from "components/Mango/MangoContext";
import { TwainProvider } from "components/ImageCapturing/TwainProvider";
import { NotFoundErrorBoundary } from "components/Main/NotFoundErrorBoundary";
import { RecoverError } from "components/Main/RecoverError";
import { NotFound } from "components/NotFoundRoute";
import { AppLayout } from "components/Main/AppLayout";
import { IntraOfficeChatProvider } from "components/OfficeChat/IntraOfficeChatContext";
import { IntraOfficeChatPopover } from "components/OfficeChat/IntraOfficeChatPopover";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { SegmentProvider } from "components/Main/PracticeSegmentContext";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { useSignoutWithReason } from "hooks/useSignoutWithReason";
import { PatientAppointmentProvider } from "contexts/PatientAppointmentContext";
import { PatientLinksStateProvider } from "contexts/PatientLinksContext";

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const account = useAccount();
  const currentUser = useCurrentUser();
  const practice = useCurrentPractice();
  const signOutWithReason = useSignoutWithReason();
  const handleTokenError = useCallback(
    (error: unknown) => {
      const reason = getTokenErrorReason(error);

      if (!reason) {
        captureException(error);
      }

      signOutWithReason(reason);
    },
    [signOutWithReason]
  );

  const [{ data: counters }, { data: profileImage }, { data: clockData }, { data: userPrescriptionStatus }] =
    useApiQueries([
      getPracticeCounters({ args: { practiceId: account.practiceId } }),
      getProfileImageQuery({ args: { userId: account.id, practiceId: account.practiceId } }),
      getLastWorktimeAction({ args: { practiceId: account.practiceId, employeeId: account.id } }),
      getClinicianPrescriptionStatus({
        args: { practiceId: account.practiceId, employeeId: account.id },
      }),
    ]);

  const [{ data: prescriptionNotificationCount }] = useApiQueries([
    getDosespotNotificationCount({
      args: { practiceId: account.practiceId, employeeId: account.id },
      queryOptions: { enabled: Boolean(userPrescriptionStatus?.isEnabled) },
    }),
  ]);

  // If the default links reference changes it will reset the nav links
  // to the default for the current user. This is great when the user's
  // role actually changes because nav links state may contain links that
  // a user no longer has access to. However, roleV2 references can change
  // when the employee is refetched even though the role values didn't changed.
  // So we only want to return a new reference for defaultLinks if the users role
  // actually changed
  const defaultLinksRef = useRef<ReturnType<typeof getDefaultMainLinks>>();
  const defaultLinks = useMemo(() => {
    const newDefaultLinks = getDefaultMainLinks(currentUser.roleV2);

    if (shallowEqual(newDefaultLinks, defaultLinksRef.current)) {
      return defaultLinksRef.current;
    }

    defaultLinksRef.current = newDefaultLinks;

    return newDefaultLinks;
  }, [currentUser.roleV2]);

  return (
    <SegmentProvider user={currentUser} practice={practice}>
      <WebSocketProvider onTokenError={handleTokenError}>
        <MangoProvider>
          <IntraOfficeChatProvider>
            <GlobalLinksProvider>
              <PatientAppointmentProvider>
                <PatientLinksStateProvider>
                  <MainLinksProvider defaultLinks={defaultLinks}>
                    <DeleteAppointmentProvider>
                      <AppLayout
                        nav={
                          <Navigation
                            user={currentUser}
                            clockData={clockData}
                            profileImage={profileImage}
                            practice={practice}
                            practiceCounters={counters}
                            canPrescribe={userPrescriptionStatus?.isEnabled ?? false}
                            erxNotificationCount={prescriptionNotificationCount?.totalNotificationCount}
                          />
                        }
                      >
                        <SentryErrorBoundary fallback={<RecoverError />}>
                          <NotFoundErrorBoundary notFoundContent={<NotFound />}>
                            <TwainProvider>
                              {children}
                              {/* Max height should be 100vh less the height of the header + 12px padding on top + bottom */}
                              <div
                                className={`
                                  absolute
                                  bottom-3
                                  right-3
                                  flex
                                  flex-col
                                  max-h-[calc(100vh-82px)]
                                `}
                              >
                                <RoleGuardHide domain="CHAT" action="ACCESS_ALL">
                                  <IntraOfficeChatPopover />
                                </RoleGuardHide>
                                <div className="flex-1">
                                  <MangoPopOver />
                                </div>
                              </div>
                            </TwainProvider>
                          </NotFoundErrorBoundary>
                        </SentryErrorBoundary>
                      </AppLayout>
                    </DeleteAppointmentProvider>
                  </MainLinksProvider>
                </PatientLinksStateProvider>
              </PatientAppointmentProvider>
            </GlobalLinksProvider>
          </IntraOfficeChatProvider>
        </MangoProvider>
      </WebSocketProvider>
    </SegmentProvider>
  );
};
