import { QueryClient } from "@tanstack/react-query";
import {
  BatchApproveWorktimeRequest,
  CreateEmployeeRequest,
  EmployeeVO,
  OnboardEmployeeRequest,
  UpdateEmployeeRequest,
  UpdateWorktimeRequest,
  WorktimeActionRequest,
} from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { makeMutation } from "@libs/utils/mutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { ApiName } from "@libs/@types/api";
import { updateSsnCache } from "api/user/cache";
import { trackIniatedWebSocketEvents } from "storage/initiatedWebSocketEventTimestamps";
import {
  handlePracticeAppointmentsUpdated,
  handlePatientAppointmentsUpdated,
  handleProvidersUpdated,
  handleScheduleBlocksUpdated,
  handleProviderHoursUpdated,
  handleRoomsUpdated,
} from "api/websocket/handleScheduleUpdated";

const invalidateEmployeeUpdate = (queryClient: QueryClient, args: { practiceId: number }) => {
  trackIniatedWebSocketEvents("SCHEDULE_UPDATED_V2");
  handleRoomsUpdated({ queryClient, practiceId: args.practiceId });
  handleProvidersUpdated({ queryClient, practiceId: args.practiceId });
  handleProviderHoursUpdated({ queryClient, practiceId: args.practiceId });
  handleScheduleBlocksUpdated({ queryClient, practiceId: args.practiceId });
  handlePatientAppointmentsUpdated({ queryClient, practiceId: args.practiceId });
  handlePracticeAppointmentsUpdated({ queryClient, practiceId: args.practiceId });
  queryClient.invalidateQueries([getQueryKey("practices", "getInactiveProviders"), args]);
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployeeOverview"), args]);
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployees"), args]);
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployee"), args]);

  // employee is a prop on PayrollVO
  queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), args]);
};

export const updateEmployee = makeMutation({
  mutationKey: ["practices", "updateEmployee"],
  formatParams: (args: { practiceId: number; employeeId: number; data: UpdateEmployeeRequest }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, employeeId }) => {
      invalidateEmployeeUpdate(queryClient, { practiceId });
      updateCachedData<EmployeeVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getEmployee"),
            {
              practiceId,
              employeeId,
            },
          ],
          exact: true,
        },
        () => {
          return data.data.data;
        }
      );
    },
  }),
});

const invalidateTimesheet = (
  queryClient: QueryClient,
  { employeeId, practiceId }: { employeeId: number; practiceId: number }
) => {
  const invalidated: ApiName<"practices">[] = [
    "getLastWorktimeAction",
    "getTimesheetData",
    "getWorktimeOverview",
  ];

  for (const key of invalidated) {
    queryClient.invalidateQueries([getQueryKey("practices", key), { employeeId, practiceId }]);
  }
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployees"), { practiceId }]);

  queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), { practiceId }]);

  // Reporting calls related to time sheets:
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployeeWorkingHourStats"), { practiceId }]);
  queryClient.invalidateQueries({
    queryKey: [
      getQueryKey("practices", "getTimeSeries"),
      { practiceId, data: { metric: "employeeWorkingHour" } },
    ],
    exact: false,
  });
};

export const addWorktimeEntry = makeMutation({
  mutationKey: ["practices", "addWorktimeEntry"],
  formatParams: (args: {
    payrollId?: string;
    practiceId: number;
    employeeId: number;
    data: WorktimeActionRequest;
  }) => [args.practiceId, args.employeeId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { employeeId, practiceId }) => {
      invalidateTimesheet(queryClient, { employeeId, practiceId });
    },
  }),
});

export const updateWorktimeEntry = makeMutation({
  mutationKey: ["practices", "updateWorktimeEntry"],
  formatParams: (args: {
    payrollId?: string;
    practiceId: number;
    employeeId: number;
    worktimeId: number;
    data: UpdateWorktimeRequest;
  }) => [args.practiceId, args.employeeId, args.worktimeId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { employeeId, practiceId }) => {
      invalidateTimesheet(queryClient, { employeeId, practiceId });
    },
  }),
});

export const approveWorktimeEntries = makeMutation({
  mutationKey: ["practices", "approveWorktimeEntries"],
  formatParams: (args: {
    payrollId?: string;
    practiceId: number;
    employeeId: number;
    data: BatchApproveWorktimeRequest;
  }) => [args.practiceId, args.employeeId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { employeeId, practiceId }) => {
      invalidateTimesheet(queryClient, { employeeId, practiceId });
    },
  }),
});

export const deleteWorktimeEntry = makeMutation({
  mutationKey: ["practices", "deleteWorktimeEntry"],
  formatParams: (args: { practiceId: number; worktimeId: number; employeeId: number }) => [
    args.practiceId,
    args.employeeId,
    args.worktimeId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { employeeId, practiceId }) => {
      invalidateTimesheet(queryClient, { employeeId, practiceId });
    },
  }),
});

export const onboardEmployee = makeMutation({
  mutationKey: ["practices", "onboardEmployee"],
  formatParams: (args: { practiceId: number; employeeId: number; data: OnboardEmployeeRequest }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, employeeId, data }) => {
      invalidateEmployeeUpdate(queryClient, { practiceId });
      queryClient.invalidateQueries([getQueryKey("practices", "getEmployee"), { practiceId, employeeId }]);

      if (data.personalDetails.ssn) {
        updateSsnCache(data.personalDetails.ssn, practiceId, employeeId);
      }
    },
  }),
});

export const createEmployee = makeMutation({
  mutationKey: ["practices", "createEmployee"],
  formatParams: (args: { practiceId: number; data: CreateEmployeeRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      invalidateEmployeeUpdate(queryClient, { practiceId });
    },
  }),
});

export const deleteEmployee = makeMutation({
  mutationKey: ["practices", "multiUpdate1"],
  formatParams: (args: { practiceId: number; action: "DELETE" | "ARCHIVE"; ids: number[] }) => [
    args.practiceId,
    { action: args.action },
    { ids: args.ids },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      invalidateEmployeeUpdate(queryClient, { practiceId });
    },
  }),
});

export const resendEmployeeInvite = makeMutation({
  mutationKey: ["practices", "resendEmployeeInvite"],
  formatParams: (args: { employeeId: number; practiceId: number }) => [args.employeeId, args.practiceId],
});

export const verifyEmployeePin = makeMutation({
  mutationKey: ["practices", "verifyEmployeePin"],
  formatParams: (args: { practiceId: number; employeeId: number; pin: string }) => [
    args.practiceId,
    args.employeeId,
    { headers: { ["X-GF-EMPLOYEE-PIN"]: args.pin } },
  ],
});

export const resetEmployeeMfa = makeMutation({
  mutationKey: ["practices", "resetEmployeeMfa"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
});
