import { AppointmentVO } from "@libs/api/generated-api";

interface StatusOption {
  apiValue: AppointmentVO["state"];
  prettyName: string;
}

export const statusOptions: StatusOption[] = [
  {
    apiValue: "UNSCHEDULED",
    prettyName: "Saved As Hold",
  },
  {
    apiValue: "UNCONFIRMED",
    prettyName: "Unconfirmed",
  },
  {
    apiValue: "CONFIRMED",
    prettyName: "Confirmed",
  },
  {
    apiValue: "ARRIVED",
    prettyName: "Arrived",
  },
  {
    apiValue: "READY",
    prettyName: "Ready",
  },
  {
    apiValue: "IN_PROGRESS",
    prettyName: "In Chair",
  },
  {
    apiValue: "CHECKOUT",
    prettyName: "Checkout",
  },
  {
    apiValue: "COMPLETED",
    prettyName: "Complete",
  },
  {
    apiValue: "CANCELED",
    prettyName: "Cancelled",
  },
  {
    apiValue: "NO_SHOW",
    prettyName: "No-Show",
  },
];

export const statusIndicatorOptions = statusOptions.filter((item) => item.apiValue !== "UNSCHEDULED") as {
  apiValue: Exclude<AppointmentVO["state"], "_DELETED" | "UNSCHEDULED">;
  prettyName: string;
}[];

const stateStyles: Record<Exclude<AppointmentVO["state"], "_DELETED" | "UNSCHEDULED">, string> = {
  REQUESTED: "bg-magenta",
  READY: "bg-magenta",
  CHECKOUT: "bg-blue",
  CANCELED: "bg-greyLightest",
  COMPLETED: "bg-greyLighter",
  CONFIRMED: "bg-green",
  IN_PROGRESS: "bg-orange",
  NO_SHOW: "bg-greyLightest",
  ARRIVED: "bg-yellow",
  UNCONFIRMED: "bg-red",
};

export const getStateStyles = (state: AppointmentVO["state"]) => {
  if (state === "_DELETED" || state === "UNSCHEDULED") {
    return null;
  }

  return stateStyles[state];
};
