/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { noop } from "@libs/utils/noop";
import { isMac } from "@libs/utils/userAgent";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { secondsToMilliseconds } from "date-fns";
import { useAccount } from "@libs/contexts/AccountContext";
import { DeviceInfo, ImagingHttpApi, ImagingHub } from "api/imaging/imaging-hub";
import { usePromise } from "utils/usePromise";
import { getPracticeImagingSettings } from "api/imaging/queries";
import { UpdateType } from "api/imaging/imaging-api";
import { useForceDynamsoft } from "components/ImageCapturing/useForceDynamsoft";

type StatusState = {
  isRunning: boolean;
  updateType: UpdateType;
  isAISDisabled: boolean;
  isAISAllowed: boolean;

  version: string;
};

const unknownState: StatusState = {
  isRunning: false,
  updateType: "Unknown",
  isAISDisabled: true,
  isAISAllowed: false,
  version: "Unknown",
};

export type ImagingHubContext = {
  hub: ImagingHub;
  status: StatusState;
  hasFetchedImagingSettings: boolean;
  availableDevicesQuery: {
    data?: DeviceInfo[];
    error: Error | null;
    fetch: () => Promise<void>;
    isLoading: boolean;
    reset: Func;
  };
  hasForcedDynamsoft: boolean;
  toggleForceDynamsoft: Func;
};

const hub = new ImagingHub();

export const ImagingHubContext = React.createContext<ImagingHubContext>({
  hub,
  status: unknownState,
  hasFetchedImagingSettings: false,
  availableDevicesQuery: {
    data: undefined,
    error: null,
    fetch: () => Promise.resolve(),
    isLoading: true,
    reset: noop,
  },
  hasForcedDynamsoft: false,
  toggleForceDynamsoft: noop,
});

export const useImagingHub = () => React.useContext<ImagingHubContext>(ImagingHubContext);

// Use this hook when you're about to display devices, and you want to make sure that the Imaging Hub is running
export const useLatestAISDevices = (shouldFetch?: boolean) => {
  const { availableDevicesQuery, status } = useImagingHub();
  const fetchDevices = availableDevicesQuery.fetch;

  const hasWarned = React.useRef(false);

  useEffect(() => {
    if (shouldFetch === false || status.isAISDisabled) {
      return;
    }

    if (status.isRunning) {
      fetchDevices();
    } else if (!hasWarned.current) {
      hasWarned.current = true;
      toast.error(
        "Archy Imaging Service is not running. Launch the application called Archy Imaging Service located on your desktop."
      );
    }
  }, [fetchDevices, shouldFetch, status]);
};

export const ImagingHubProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [availableDevices, setAvailableDevices] = useState<DeviceInfo[] | undefined>(undefined);
  const [status, setStatus] = useState<StatusState>(unknownState);
  const { practiceId } = useAccount();
  const [imagingSettingsQuery] = useApiQueries([getPracticeImagingSettings({ args: { practiceId } })]);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const reconnectAttempt = useRef<number>(0);
  const { hasForcedDynamsoft, toggleForceDynamsoft } = useForceDynamsoft();

  const fetchAISStatus = useCallback(() => {
    ImagingHttpApi.support
      .getHealth()
      .then((response) => {
        const data = response.data;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const fallbackVersion = data.updateType ? "<= 1.0" : "<= 2.1";

        setStatus({
          isRunning: response.ok,
          // Disabled because old AIS versions don't return updateType
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          updateType: data.updateType ?? "Mandatory",
          isAISDisabled: hasForcedDynamsoft,
          isAISAllowed: true,
          // AIS didn't have updateType before 2.0. AIS didn't have version until after 2.1
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          version: data.version ?? fallbackVersion,
        });
      })
      .catch(() => {
        setStatus((prev) => {
          if (!prev.isRunning && prev.isAISDisabled === hasForcedDynamsoft) {
            // Don't trigger a re-render if values haven't changed.
            return prev;
          }

          return {
            ...prev,
            isRunning: false,
            isAISDisabled: hasForcedDynamsoft,
            isAISAllowed: true,
          };
        });

        if (hasForcedDynamsoft) {
          return;
        }

        reconnectAttempt.current += 1;

        let delayInSeconds = 5;

        if (reconnectAttempt.current > 5) {
          delayInSeconds = 15;
        } else if (reconnectAttempt.current > 20) {
          delayInSeconds = 30;
        }
        // Try again in 5 seconds. This way, the user doesn't have to refresh the page
        // in case AIS it not fully started yet.

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        timer.current = setTimeout(fetchAISStatus, secondsToMilliseconds(delayInSeconds));
      });
  }, [hasForcedDynamsoft]);

  useEffect(() => {
    const unsubOnClose = hub.onClose(() => {
      fetchAISStatus();
    });

    if (imagingSettingsQuery.data) {
      const aisAllowed = imagingSettingsQuery.data.features.includes("ARCHY_IMAGING_SERVICE");

      setStatus((prev) => {
        return prev.isAISAllowed === aisAllowed ? prev : { ...prev, isAISAllowed: aisAllowed };
      });

      if (aisAllowed && !isMac() && !hasForcedDynamsoft) {
        fetchAISStatus();
      }
    }

    if (hasForcedDynamsoft) {
      setStatus((prev) => {
        return prev.isAISDisabled
          ? prev
          : {
              ...prev,
              isAISDisabled: true,
            };
      });
    }

    return () => {
      unsubOnClose();

      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, [fetchAISStatus, hasForcedDynamsoft, timer, imagingSettingsQuery.data]);

  const promise = useCallback(() => hub.getDevices(), []);

  const {
    call: loadDevices,
    error: requestDevicesError,
    isPending: isRequestingDevices,
    clearError: clearDevicesError,
  } = usePromise(promise);

  const fetchDevices = useCallback(() => {
    if (status.isRunning && !status.isAISDisabled) {
      return loadDevices().then((result) => {
        if (result?.devices) {
          setAvailableDevices(result.devices);
        } else {
          setAvailableDevices([]);
        }
      });
    }

    return Promise.resolve();
  }, [loadDevices, status]);

  useEffect(() => {
    fetchDevices();
  }, [status, fetchDevices]);

  const value = useMemo((): ImagingHubContext => {
    return {
      hub,
      status,
      hasFetchedImagingSettings: imagingSettingsQuery.isFetched,
      availableDevicesQuery: {
        data: availableDevices,
        error: requestDevicesError,
        fetch: fetchDevices,
        isLoading: isRequestingDevices,
        reset: clearDevicesError,
      },
      hasForcedDynamsoft,
      toggleForceDynamsoft,
    };
  }, [
    status,
    imagingSettingsQuery.isFetched,
    availableDevices,
    requestDevicesError,
    fetchDevices,
    isRequestingDevices,
    clearDevicesError,
    hasForcedDynamsoft,
    toggleForceDynamsoft,
  ]);

  return <ImagingHubContext.Provider value={value}>{children}</ImagingHubContext.Provider>;
};
