import { useMemo } from "react";
import { useDebounce } from "use-debounce";
import { formatAsISODate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useAccount } from "@libs/contexts/AccountContext";
import { lookupPatientSummary } from "api/patients/queries";

export const useLookupPatient = (
  args: { firstName: string; lastName: string; dob: Date | null },
  carrierId?: number
) => {
  const { practiceId } = useAccount();
  const lookupPatientArgs = useMemo(
    () => ({
      firstName: args.firstName,
      lastName: args.lastName,
      practiceId,
      carrierId,
      dob: args.dob ? formatAsISODate(args.dob) : "",
    }),
    [args.dob, args.firstName, args.lastName, practiceId, carrierId]
  );

  const [debouncedArgs] = useDebounce(lookupPatientArgs, SEARCH_DEBOUNCE_DELAY_MS);

  const [{ data: patientMatch, isLoading }] = useApiQueries([
    lookupPatientSummary({
      args: debouncedArgs,
      queryOptions: {
        enabled: Boolean(debouncedArgs.firstName && debouncedArgs.lastName && debouncedArgs.dob),
      },
    }),
  ]);

  return useMemo(
    () => ({
      patientMatch,
      isLoading,
    }),
    [patientMatch, isLoading]
  );
};
