import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { ToggleButtonList, ToggleButtonRadioListProps } from "components/UI/ToggleButtonList";
import { ToggleButtonShape } from "components/UI/ToggleButton";

type Size = "sm" | "md" | "lg";

const sizeToShapes: Record<Size, ToggleButtonShape> = {
  sm: "toggleGroupSmall",
  md: "toggleGroupMedium",
  lg: "toggleGroupLarge",
};

const cxSizeToHeight: Record<Size, string> = {
  sm: "h-5",
  md: "h-8",
  lg: "h-9",
};

export const ToggleGroup = <V extends ValueTypes, T extends OptionInputOption<V>>({
  size,
  listClassName,
  ...rest
}: Omit<ToggleButtonRadioListProps<V, T>, "type" | "shape" | "layout" | "containerLayout"> & {
  size: keyof typeof sizeToShapes;
}) => {
  return (
    <ToggleButtonList
      layout="custom"
      listClassName={cx(
        "border rounded flex w-fit gap-x-0.5",
        rest.disabled ? "border-slate-200" : "border-slate-300",
        cxSizeToHeight[size],
        listClassName
      )}
      shape={sizeToShapes[size]}
      type="radio"
      {...rest}
    />
  );
};
