import { useCallback, useEffect, useRef, useState } from "react";
import { ChatUnreadMessageContentEvent } from "@libs/api/generated-api";
import { SECOND_IN_MS } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { Avatar } from "@libs/components/UI/Avatar";
import { useWebSocketMessage } from "hooks/useWebSocketMessage";
import { ServerPushMessages } from "api/websocket/types";
import { Popover } from "components/UI/Popover";
import { getLastMessageCreatedAt } from "components/OfficeChat/utils";
import { getEmployeeChatPreference } from "api/intraOfficeChat/queries";
import { OfficeChatVisibilityState } from "components/OfficeChat/OfficeChatButton";
import { useIntraOfficeChatContext } from "components/OfficeChat/IntraOfficeChatContext";

// eslint-disable-next-line complexity
export const IntraOfficeChatPopover: React.FC = () => {
  const { practiceId } = useAccount();
  const { chatIsOn, currentChannelUrl, openChat, popover, setCurrentChannelUrl } =
    useIntraOfficeChatContext();
  const visibilityStateRef = useRef<OfficeChatVisibilityState>({ isOpen: false });

  useEffect(() => {
    const flyoutState = visibilityStateRef.current;

    // Remove any existing timeout since it's open again.
    if (chatIsOn && flyoutState.disconnectTimer) {
      clearTimeout(flyoutState.disconnectTimer);
      flyoutState.disconnectTimer = undefined;
    }

    flyoutState.isOpen = chatIsOn;
  }, [chatIsOn]);

  const [notificationData, setNotificationData] = useState<ChatUnreadMessageContentEvent | undefined>(
    undefined
  );

  const [{ data: chatSettings }] = useApiQueries([getEmployeeChatPreference({ args: { practiceId } })]);

  const playSound = chatSettings?.useSound;
  const popoverAllowed = chatSettings?.showPopover;
  const previewAllowed = chatSettings?.showPopover && chatSettings.showPreview;

  const handleIntraOfficeChatMessage = useCallback(
    async (data: ServerPushMessages) => {
      if (data.type !== "INTRA_OFFICE_CHAT_UNREAD_MESSAGE_CONTENT") {
        return;
      }

      // If chat is already open to the channel of the incoming message, don't
      // do anything
      if (data.payload.channelUrl === currentChannelUrl && chatIsOn) {
        return;
      }

      if (playSound) {
        const audio = new Audio("/resources/chat-sound.wav");

        try {
          await audio.play();
        } catch {
          // Nothing - with Chrome's autoplay policy, this will fail until the
          // user has taken some kind of user interaction with the page and will
          // throw an error. We assume that our users are interacting with the
          // app so we don't care about the error and do nothing.
        }
      }

      if (!chatIsOn && popoverAllowed) {
        popover.on();
        setNotificationData((last) => {
          return {
            ...last,
            ...data.payload,
          };
        });
      }
    },
    [chatIsOn, currentChannelUrl, playSound, popover, popoverAllowed]
  );

  useWebSocketMessage(handleIntraOfficeChatMessage);

  const handleOpenChat = useCallback(() => {
    if (notificationData?.channelUrl) {
      openChat();
      setCurrentChannelUrl(notificationData.channelUrl);
    }
  }, [notificationData?.channelUrl, openChat, setCurrentChannelUrl]);

  const handleClose = useCallback(() => {
    setNotificationData(undefined);
  }, []);

  return popoverAllowed ? (
    <Popover display={popover} onClose={handleClose}>
      {notificationData && (
        <button
          className="flex items-center w-96 p-6 gap-x-3 overflow-hidden"
          onClick={handleOpenChat}
          type="button"
        >
          <Avatar
            imageUrl={notificationData.senderProfileUrl}
            name={notificationData.senderNickname}
            size="lg"
          />
          <div className="flex-1 flex flex-col items-start overflow-hidden">
            <div className="text-sm">
              {notificationData.channelCustomType === "DIRECT"
                ? notificationData.senderNickname
                : notificationData.channelName}
            </div>
            {previewAllowed && (
              <div className="flex gap-x-1 w-full text-xs truncate">
                <span className="truncate">{notificationData.message}</span>
                <span>•</span>
                <span>{getLastMessageCreatedAt(notificationData.sentAt * SECOND_IN_MS)}</span>
              </div>
            )}
          </div>
        </button>
      )}
    </Popover>
  ) : null;
};
