import { ReactNode } from "react";
import { PatientSummaryVO, PatientVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ReactComponent as ViewProfile } from "@libs/assets/icons/open-profile.svg";
import { paths } from "utils/routing/paths";

type Patient = PatientVO | PatientSummaryVO;
interface Props<T extends Patient> {
  patient: T;
  error?: string;
  onSelect?: (patient: T) => void;
  children?: ReactNode;
  ctaText?: string;
}

export const PatientMatch = <T extends Patient>({
  patient,
  onSelect,
  error,
  ctaText = "Add this patient",
  children,
}: Props<T>) => {
  const hasError = Boolean(!onSelect || error);

  return (
    <div className={cx("p-2 text-xs relative rounded", hasError ? "bg-redLight text-red" : "bg-actionLight")}>
      <div className="flex justify-between">
        {children ?? <p>A patient with the same name and birthday is already in the system.</p>}
        <a
          className="font-sansSemiBold flex ml-2 items-center flex-none"
          href={paths.patient({ patientId: patient.id })}
          target="_blank"
        >
          <span className={cx("block", !hasError && "text-primaryTheme")}>View Profile</span>
          <ViewProfile className={cx("h-4 w-4 ml-1", !hasError && "fill-primaryTheme")} />
        </a>
      </div>
      {onSelect ? (
        <button
          type="button"
          onClick={() => onSelect(patient)}
          className={cx("font-sansSemiBold mt-2", !hasError && "text-primaryTheme")}
        >
          {ctaText}
        </button>
      ) : null}
    </div>
  );
};
