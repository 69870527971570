import { useCallback, useEffect, useMemo } from "react";
import { isOneOf } from "@libs/utils/isOneOf";
import { MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { getPatientCallCard } from "api/patients/queries";
import { useMangoContext } from "components/Mango/MangoContext";
import { PatientCardActions } from "components/Mango/PatientCardActions";
import { PatientCardData } from "components/Mango/PatientCardData";
import { PatientCardProfileImage } from "components/Mango/PatientCardProfileImage";
import { CallDataState, CallType } from "components/Mango/types";

interface Props {
  callerId: string | undefined;
  callType: CallType;
  callState: CallDataState;
  isOngoing: boolean;
  patientId: number | undefined;
  phone?: string;
}

const CLEAR_POPUP_TIME_IN_MS = 2000;

export const PatientCard: React.FC<Props> = ({
  callerId,
  callType,
  callState,
  isOngoing,
  patientId,
  phone,
}) => {
  const { practiceId } = useAccount();
  const hover = useBoolean(false);
  const { deleteCall, inboundCallsMap, outboundCallsMap, updateCall } = useMangoContext();
  const isOutboundCall = Boolean(callType === CallType.OUTBOUND && phone && phone in outboundCallsMap);

  useEffect(() => {
    // Once outbound call is connected, we auto remove popover after 2 secs.
    // If practice never places the outbound call or we do not receive events
    // indicating the call has been connected, we remove after 1 min of
    // inactivity so user does not need to refresh or manually remove card
    if (isOutboundCall) {
      const isConnected = isOneOf(callState, ["PHONE_CALL_RINGING", "PHONE_CALL_ANSWERED"]);

      const id = isConnected
        ? window.setTimeout(() => {
            phone && updateCall(callType, phone, { display: false });
          }, CLEAR_POPUP_TIME_IN_MS)
        : window.setTimeout(() => {
            phone && deleteCall(CallType.OUTBOUND, phone);
          }, MINUTE_IN_SECONDS * SECOND_IN_MS);

      return () => {
        window.clearTimeout(id);
      };
    }

    return undefined;
  }, [callState, callType, deleteCall, isOutboundCall, phone, updateCall]);

  const [patientCallCardQuery] = useApiQueries([
    getPatientCallCard({
      args: { patientId: patientId ?? 0, practiceId, phone },
      queryOptions: { enabled: Boolean(patientId) },
    }),
  ]);
  const { data: patientCallCard } = patientCallCardQuery;
  const { patientName, contactName } = useMemo(() => {
    return {
      patientName: patientCallCard
        ? `${patientCallCard.summary.firstName} ${patientCallCard.summary.lastName}`
        : "",
      contactName: patientCallCard?.contact
        ? `${patientCallCard.contact.firstName} ${patientCallCard.contact.lastName}`
        : "",
    };
  }, [patientCallCard]);

  const handleDismissPtCard = useCallback(() => {
    if (!phone) {
      return;
    }

    if (phone in inboundCallsMap || phone in outboundCallsMap) {
      updateCall(callType, phone, { display: false });
    }
  }, [callType, updateCall, phone, inboundCallsMap, outboundCallsMap]);

  const familyMembersMenu = useBoolean(false);
  const appointmentMenu = useBoolean(false);

  return (
    <QueryResult
      loading={
        <div className="h-16">
          <LoadingContent />
        </div>
      }
      queries={[patientCallCardQuery]}
    >
      <div
        className="flex items-center justify-between h-full w-full p-2 rounded"
        onMouseEnter={hover.on}
        onMouseLeave={hover.off}
      >
        <div className="flex items-center gap-x-2">
          <PatientCardProfileImage
            isHovering={hover.isOn}
            onDismissPtCard={handleDismissPtCard}
            patientCallCard={patientCallCard}
            patientName={patientName}
          />
          <PatientCardData
            callerId={callerId}
            callType={callType}
            contactName={contactName}
            isOngoing={isOngoing}
            patientCallCard={patientCallCard}
            patientId={patientId}
            patientName={patientName}
            phone={phone}
          />
        </div>
        {isOngoing && patientCallCard && (
          <PatientCardActions
            appointmentMenu={appointmentMenu}
            familyMembersMenu={familyMembersMenu}
            isHovering={hover.isOn}
            patientCallCard={patientCallCard}
          />
        )}
      </div>
    </QueryResult>
  );
};
