import React from "react";
import { WebTwain } from "dwt/dist/types/WebTwain";
import { TwainEvent } from "components/ImageCapturing/types";

const emptyRef = {
  current: false,
};

export const NO_DEVICE_INDEX = -1;

export type TwainSource = { label: string; index: number; driverType: string | undefined };
export type OnImageCaptured = (
  webTwain: WebTwain,
  eventType: TwainEvent,
  outputInfo?: { imageId: number }
) => void;

type TwainContextType = {
  twain?: WebTwain;
  setTwain: React.Dispatch<React.SetStateAction<WebTwain | undefined>>;
  setSources: React.Dispatch<React.SetStateAction<TwainSource[] | null>>;
  sources: TwainSource[] | null;
  setIsUsingTestCam: React.Dispatch<React.SetStateAction<boolean>>;
  isUsingTestCam: boolean;
  hasTriggeredInit: React.MutableRefObject<boolean>;
  hasLoadedSources: React.MutableRefObject<boolean>;
  setNeededDownloadUrl: React.Dispatch<React.SetStateAction<string | null>>;
  neededDownloadUrl: string | null;
  twainLoadError: Error | null;
  setTwainLoadError: (e: Error | null) => void;
  onImageReadyRef: React.MutableRefObject<OnImageCaptured | null>;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const TwainContext = React.createContext<TwainContextType>({
  setTwain: () => undefined,
  setSources: () => undefined,
  hasLoadedSources: emptyRef,
  hasTriggeredInit: emptyRef,
  onImageReadyRef: {
    current: null,
  },
  sources: null,
  setIsUsingTestCam: () => undefined,
  setNeededDownloadUrl: () => undefined,
  neededDownloadUrl: null as null | string,
  isUsingTestCam: false,
  twainLoadError: null,
  setTwainLoadError: () => undefined,
  setSelectedIndex: () => undefined,
  selectedIndex: NO_DEVICE_INDEX,
});
export const twainElementId = "mountPageTwainElemId";
