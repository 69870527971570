import { FC, PropsWithChildren } from "react";
import { flip } from "@floating-ui/react-dom";
import { offset } from "@floating-ui/react-dom-interactions";
import { AppointmentVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonMenu, ButtonMenuProps } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as AcceptIcon } from "@libs/assets/icons/check.svg";
import { cx } from "@libs/utils/cx";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { apptCardCxStyles } from "components/ScheduleAppointments/AppointmentCard";
import { AppointmentCardStatusMenu } from "components/ScheduleAppointments/AppointmentCardStatusMenu";

interface Props extends Pick<ButtonMenuProps, "placement"> {
  appointment: AppointmentVO;
  onRequestUpdateAppointmentState: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  hasBottomLeftIndicator?: boolean;
}

export const AppointmentStatusButton: FC<PropsWithChildren<Props>> = ({
  appointment,
  onRequestUpdateAppointmentState,
  hasBottomLeftIndicator = false,
  placement = "right-start",
  children,
}) => {
  const statusMenu = useBoolean(false);
  const confirmCompleteModal = useBoolean(false);

  return (
    <>
      <ButtonMenu
        isOpen={statusMenu.isOn}
        onRequestClose={statusMenu.off}
        onRequestOpen={statusMenu.on}
        placement={placement}
        middleware={[flip({ crossAxis: true }), offset({ crossAxis: 7 })]}
        menuContent={
          appointment.state === "REQUESTED" ? (
            <MenuOptionButton
              name="Update State"
              onClick={() => {
                onRequestUpdateAppointmentState(appointment, "UNCONFIRMED");
                statusMenu.off();
              }}
            >
              <div className="flex items-center w-48 gap-x-2">
                <Icon SvgIcon={AcceptIcon} size="md" />
                Accept
              </div>
            </MenuOptionButton>
          ) : (
            <AppointmentCardStatusMenu
              appointment={appointment}
              onRequestUpdateAppointmentState={(apiValue) => {
                if (apiValue === "COMPLETED") {
                  confirmCompleteModal.on();
                } else {
                  onRequestUpdateAppointmentState(appointment, apiValue);
                }

                statusMenu.off();
              }}
            />
          )
        }
        className={cx(
          !children && apptCardCxStyles.statusMenuTrigger(appointment.state, hasBottomLeftIndicator)
        )}
      >
        {(props) => (
          <button
            {...props}
            className="h-full w-full"
            data-testid="appt-status-button-menu"
            type="button"
            disabled={appointment.state === "COMPLETED"}
          >
            {children}
          </button>
        )}
      </ButtonMenu>
      {confirmCompleteModal.isOn && (
        <ConfirmationModal
          primaryText="Are you sure you want to mark this appointment complete?"
          secondaryText="After marking an appointment as completed, the charges associated with this appointment will appear in a patient’s billing record & a draft claim will be created."
          onCancel={confirmCompleteModal.off}
          onConfirm={() => {
            onRequestUpdateAppointmentState(appointment, "COMPLETED");
            confirmCompleteModal.off();
          }}
        />
      )}
    </>
  );
};
