import { QueryClient } from "@tanstack/react-query";
import { PayrollEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handlePayrollReview = ({
  data,
  queryClient,
  practiceId,
}: {
  data: PayrollEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getPayrolls"), { practiceId }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPayroll"),
    { practiceId, payrollId: data.payrollUuid },
  ]);
};
