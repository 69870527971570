import { PracticeFeatureStateVO, PracticeFeatureVO } from "@libs/api/generated-api";
import { createContext, useContext, FC, PropsWithChildren, useMemo } from "react";

export type FeatureFlagsContextValue = {
  // Features pulled from the server
  featuresEnabled: Set<PracticeFeatureVO["type"]>;

  // For use for flagging features based on environment for testing purposes
  clientFeatures: Set<never>;
};

const Context = createContext<FeatureFlagsContextValue>({
  featuresEnabled: new Set(),
  clientFeatures: new Set(),
});

Context.displayName = "FeatureFlagsContext";

export const useFeatureFlags = () => useContext(Context);
export const FeatureFlagsContext = Context;

export const FeatureFlagsContextProvider: FC<PropsWithChildren<{ features?: PracticeFeatureStateVO[] }>> = ({
  children,
  features,
}) => {
  // Feature flags can be enabled for different environments with appEnv from
  // const { REACT_APP_ENVIRONMENT } = useEnvContext(), e.g. isOneOf(REACT_APP_ENVIRONMENT, ["development", "preprod"])

  const value = useMemo(
    () => ({
      featuresEnabled: new Set(
        features?.filter((item) => item.isEnabled).map((item) => item.practiceFeature.type)
      ),
      clientFeatures: new Set<never>(),
    }),
    [features]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
