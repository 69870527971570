import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { IconSizes } from "@libs/components/UI/Icon";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";

interface BaseProps {
  Icon?: IconComponent;
  buttonContent: ReactNode;
  iconClassName?: string;
  iconSize?: IconSizes;
}

export type FormFieldButtonProps = BaseProps &
  FormFieldProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof FormFieldProps>;

export const FormFieldButton = forwardRef<HTMLButtonElement, FormFieldButtonProps>(
  (
    {
      disabled,
      required,
      label,
      error,
      Icon,
      className,
      iconClassName,
      iconSize,
      buttonContent,
      edit = true,
      children,
      type = "button",
      layout,
      id,
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const formContext = useFormContext();
    const mergedFormContext = useMergeFormContext(formContext, { layout });

    return (
      <FormField
        disabled={disabled}
        required={required}
        edit={edit}
        label={label}
        error={error}
        layout={mergedFormContext.layout}
        className={className}
        id={fieldId}
      >
        {edit ? (
          <div className={cxFormFieldStyle.wrapper}>
            <button
              ref={ref}
              id={fieldId}
              // eslint-disable-next-line react/button-has-type
              type={type}
              disabled={disabled}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: Boolean(Icon),
                  hasLabel: Boolean(label),
                  layout: mergedFormContext.layout,
                }),
                inputClassName
              )}
              {...rest}
            >
              {buttonContent}
            </button>
            {Icon ? (
              <div className={cxFormFieldStyle.iconContainer({ layout: mergedFormContext.layout })}>
                <Icon
                  role="img"
                  className={cx(
                    cxFormFieldStyle.icon({ actionable: true, disabled, size: iconSize }),
                    iconClassName
                  )}
                />
              </div>
            ) : null}
            {children}
          </div>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
            {buttonContent || "-"}
          </span>
        )}
      </FormField>
    );
  }
);
