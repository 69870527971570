import { useEffect, useMemo, useRef } from "react";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import Skeleton from "react-loading-skeleton";
import { passRefs } from "@libs/utils/forms";
import { UseInfiniteScrollQueryProps, useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { DarkRoomSkeleton } from "components/PatientProfile/Imaging/MountRoute/DarkRoomSkeleton";

export const useInfiniteScrollingSelectComponents = <
  T extends SelectOption<V>,
  IsMulti extends boolean,
  V extends SelectOptionValue,
>({
  infiniteQuery,
  rootMargin,
  isDark,
}: UseInfiniteScrollQueryProps & {
  isDark?: boolean;
}) => {
  const { rootRef, scrollRef: scrollSentry } = useInfiniteScrollQuery({ infiniteQuery, rootMargin });
  const isQueryInitialized = Boolean(infiniteQuery.data);
  const queryPages = infiniteQuery.data?.pages;
  const pageDetails = queryPages?.[queryPages.length - 1].apiResponse.data.pageDetails;
  const totalPages = pageDetails?.totalPages ?? Number.POSITIVE_INFINITY;
  const currentPage = pageDetails?.pageNumber ?? 1;
  const hasLoadedAll = totalPages <= currentPage;
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // When scrolling finishes loading, the select list scrolls to the top.  This keeps the scroll position at the bottom
    if (hasLoadedAll) {
      scrollRef.current?.scrollTo(0, scrollRef.current.scrollHeight);
    }
  }, [hasLoadedAll]);

  return useMemo(() => {
    const components: Partial<SelectComponents<T, IsMulti, GroupedSelectOption<V, T>>> = {
      MenuList: ({ children, innerProps, innerRef }) => {
        return (
          <div ref={rootRef}>
            <div
              {...innerProps}
              ref={passRefs([innerRef, scrollRef])}
              className="max-h-[550px] relative overflow-y-auto box-border"
            >
              {children}
              {isQueryInitialized && !hasLoadedAll && (
                <div ref={scrollSentry} className="pb-1">
                  {isDark ? (
                    <DarkRoomSkeleton className="h-8 rounded-none" count={5} />
                  ) : (
                    <Skeleton className="h-8 rounded-none" count={5} />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
    };

    return components;
  }, [isQueryInitialized, hasLoadedAll, isDark, rootRef, scrollSentry]);
};
