import { GetApiMethod } from "@libs/@types/api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getAllInsuranceCarriersQuery = makeQuery({
  queryKey: ["practices", "getAllPracticeInsuranceCarriers"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getAutoEligibilityCarriers = makeQuery({
  queryKey: ["practices", "getAutoEligibilityCarriers"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

type InsuranceCarriersQuery = Parameters<GetApiMethod<"practices", "getPracticeInsuranceCarriers">>["1"] & {
  practiceId: number;
};

export const getPracticeInsuranceCarriers = makeInfiniteQuery({
  queryKey: ["practices", "getPracticeInsuranceCarriers"],
  formatParams: ({ practiceId, ...rest }: InsuranceCarriersQuery) => [practiceId, rest],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getCarrier = makeQuery({
  queryKey: ["practices", "getCarrier"],
  formatParams: (args: { practiceId: number; carrierId: number }) => [args.practiceId, args.carrierId],
  queryOptions: cacheForSession,
});

export type InsurancePlanQuery = {
  sortColumn?: string;
  orderBy?: string;
  carrierIds?: number[];
  searchString?: string;
  feeScheduleId?: number;
  hidePlansWithNoPatients?: boolean;
  maxPageSize?: number;
  pageNumber: number;
  pageSize: number;
  practiceId: number;
};

export const getInfiniteInsurancePlans = makeInfiniteQuery({
  queryKey: ["practices", "getInsurancePlans"],
  formatParams: ({ practiceId, ...rest }: InsurancePlanQuery) => [practiceId, rest],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getFrequencyLimitationCategories = makeQuery({
  queryKey: ["v2", "getFrequencyLimitationCategories"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});
