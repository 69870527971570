import { TagVO } from "@libs/api/generated-api";
import { Glyph } from "@libs/components/UI/Glyph";
import { FC } from "react";

interface Props {
  tag: TagVO;
}

export const Tag: FC<Props> = ({ tag }) => {
  return (
    <div className="flex items-center gap-x-1">
      <div className="flex-none">
        <Glyph family={tag.glyph.family}>{tag.glyph.value}</Glyph>
      </div>
      <div className="leading-5 text-xs">{tag.name}</div>
    </div>
  );
};
