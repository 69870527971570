import { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";

export const LoadingOverlay: FC<{
  isLoading: boolean;
  opaque?: boolean;
  overlayClassName?: string;
  loadingText?: ReactNode;
  loadingSubText?: ReactNode;
  children?: ReactNode;
  centerVertically?: boolean;
}> = ({ children, isLoading, loadingText, loadingSubText, overlayClassName, opaque, centerVertically }) => {
  if (!isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div className="relative h-full w-full">
      {children}
      <LoadingOverlaySpinner
        loadingText={loadingText}
        loadingSubText={loadingSubText}
        overlayClassName={overlayClassName}
        opaque={opaque}
        centerVertically={centerVertically}
      />
    </div>
  );
};

/**
 * A component that displays a loading spinner over its children when `isLoading` is true. This
 * version doesn't unmout the children when `isLoading` is true.
 *
 * @param isLoading - A boolean indicating whether the loading spinner should be displayed.
 * @param children - The child elements to display under the loading spinner.
 * @param loadingOverlaySpinnerProps - Additional props to pass to the `LoadingOverlaySpinner`
 * component.
 * @returns A React component that displays a loading spinner over its children when `isLoading` is
 * true.
 */
export const LoadingOverlayV2: FC<
  PropsWithChildren<{ isLoading: boolean } & ComponentProps<typeof LoadingOverlaySpinner>>
> = ({ children, isLoading, ...loadingOverlaySpinnerProps }) => {
  return (
    <div className={cx(isLoading ? "relative h-full w-full" : "contents")}>
      {children}
      {isLoading && <LoadingOverlaySpinner {...loadingOverlaySpinnerProps} />}
    </div>
  );
};
