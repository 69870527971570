import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { getStatementUpdatedBatchProcessor } from "api/websocket/getStatementUpdatedBatchProcessor";
import { WebSocketMessageEmitter, WebSocketMessageEvent } from "api/websocket/webSocketMessage";

export const useStatementUpdatedMessage = (messages: WebSocketMessageEmitter | undefined) => {
  const { timezoneId, id: practiceId } = useCurrentPractice();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (messages) {
      const statementUpdatedBatchProcessor = getStatementUpdatedBatchProcessor({ timezoneId });
      const handler = (event: WebSocketMessageEvent) => {
        if (event.detail.type === "STATEMENT_UPDATED") {
          statementUpdatedBatchProcessor.queue({ queryClient, practiceId, ...event.detail.payload });
        }
      };

      messages.addEventListener("message", handler);

      return () => {
        messages.removeEventListener("message", handler);
        statementUpdatedBatchProcessor.flush();
      };
    }

    return undefined;
  }, [messages, practiceId, queryClient, timezoneId]);
};
