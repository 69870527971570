import { forwardRef, useState } from "react";
import { cx } from "@libs/utils/cx";
import { cxIconStyles } from "@libs/components/UI/formFieldStyle";
import { ReactComponent as Hide } from "@libs/assets/icons/hide.svg";
import { ReactComponent as Show } from "@libs/assets/icons/eye-filled.svg";
import { FormFieldInput, FormFieldInputProps } from "@libs/components/UI/FormFieldInput";

export const FormFieldPassword = forwardRef<HTMLInputElement, FormFieldInputProps>((props, ref) => {
  const [type, setType] = useState<"password" | "text">("password");
  const iconClassName = cxIconStyles.element({
    disabled: props.disabled,
  });

  return (
    <FormFieldInput {...props} ref={ref} type={type}>
      <button
        type="button"
        tabIndex={-1}
        className={cx(
          "absolute bottom-full z-0 w-4 h-4",
          props.layout === "labelOut" && "-translate-y-1",
          props.layout === "labelLeft" ? "right-0" : "right-1"
        )}
        onClick={() => setType((last) => (last === "password" ? "text" : "password"))}
      >
        <div className={cxIconStyles.container({ layout: props.layout || "labelOut", clickable: true })}>
          {type === "password" ? <Hide className={iconClassName} /> : <Show className={iconClassName} />}
        </div>
      </button>
    </FormFieldInput>
  );
});
