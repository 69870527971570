import React, { useContext } from "react";
import { toast } from "react-toastify";

const NotificationContext = React.createContext({
  handleSuccess: toast.success,
  handleError: toast.error,
  handleInfo: toast.info,
  handleWarn: toast.warn,
});

export const useNotificationContext = () => useContext(NotificationContext);
