import { FC, useEffect, PropsWithChildren } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { PracticeContext } from "@libs/contexts/PracticeContext";
import { usePick } from "@libs/hooks/usePick";
import { setPracticeProperties } from "utils/sentry";

export const PracticeProvider: FC<
  PropsWithChildren<{
    practice: PracticeInfoVO;
  }>
> = ({ practice, children }) => {
  const practiceProperties = usePick(practice, ["name", "timezoneId", "ownerName", "uuid"]);

  useEffect(() => {
    setPracticeProperties(practiceProperties);
  }, [practiceProperties]);

  return <PracticeContext.Provider value={practice}>{children}</PracticeContext.Provider>;
};
