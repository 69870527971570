import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { redirectLoader } from "@libs/router/redirectLoader";
import { NoInternetConnection } from "@libs/components/UI/NoInternetConnection";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { Suspense } from "react";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { SentryRouteErrorFallback } from "components/Main/SentryRouteErrorFallback";
import { loader as signOutLoader } from "components/Main/SignOutRoute.loader";
import { loader as mfaSetupLoader } from "components/MfaSetup/MfaSetupRoute.loader";
import { loader as selectAccountLoader } from "components/Main/SelectAccountRoute.loader";
import { SignedInApp, loader as signedInAppLoader } from "components/Main/SignedInApp";
import { authLoader } from "components/SignIn/authLoader";
import { paths } from "utils/routing/paths";
import { PracticeRouterContext } from "router/types";

const SignOutRoute = lazyDefault(() => import("components/Main/SignOutRoute"), "SignOutRoute");

const PostSignOutRoute = lazyDefault(() => import("components/Main/PostSignOutRoute"), "PostSignOutRoute");

const SelectAccountRoute = lazyDefault(
  () => import("components/Main/SelectAccountRoute"),
  "SelectAccountRoute"
);

const MfaSetupRoute = lazyDefault(() => import("components/MfaSetup/MfaSetupRoute"), "MfaSetupRoute");

const MainSignIn = lazyDefault(() => import("components/SignIn/MainSignIn"), "MainSignIn");

const ForgotPassword = lazyDefault(() => import("components/SignIn/ForgotPassword"), "ForgotPassword");

const SupportSignIn = lazyDefault(() => import("components/SignIn/SupportSignIn"), "SupportSignIn");

const OAuthSignIn = lazyDefault(() => import("components/SignIn/OAuthSignIn"), "OAuthSignIn");

export const getRoutes = (context: PracticeRouterContext) => {
  return [
    {
      path: "*",
      element: (
        <>
          <Outlet />
          <ToastContainer
            className="z-20"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NoInternetConnection />
        </>
      ),
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          element: (
            <Suspense fallback={<LoadingContent />}>
              <Outlet />
            </Suspense>
          ),
          children: [
            {
              path: "sign-out",
              element: <SignOutRoute />,
              loader: signOutLoader(context),
            },
            {
              path: "post-sign-out",
              element: <PostSignOutRoute />,
            },
            {
              path: "select-account",
              element: <SelectAccountRoute />,
              loader: selectAccountLoader(context),
            },
            {
              path: "mfa-setup",
              element: <MfaSetupRoute />,
              loader: mfaSetupLoader(context),
            },
            {
              path: "auth/sign-in",
              element: <MainSignIn />,
              loader: authLoader(context, "signIn"),
            },
            {
              // Deprecated. User auth/sign-in?resetPassword=1 instead
              path: "auth/first-sign-in",
              loader: redirectLoader(paths.signIn({ resetPassword: true })),
            },
            {
              path: "auth/forgot-password",
              element: <ForgotPassword />,
              loader: authLoader(context, "forgotPassword"),
            },
            {
              path: "auth/support",
              element: <SupportSignIn />,
              loader: authLoader(context, "supportSignIn"),
            },
            {
              path: "oauth/sign-in",
              element: <OAuthSignIn />,
            },
            {
              path: "sign-in",
              loader: redirectLoader(paths.signIn()),
            },
            {
              path: "first-sign-in",
              loader: redirectLoader(paths.firstSignIn()),
            },
            {
              path: "forgot-password",
              loader: redirectLoader(paths.forgotPassword()),
            },
          ],
        },
        {
          path: "*",
          element: <SignedInApp />,
          loader: signedInAppLoader(context),
        },
      ],
    },
  ];
};
