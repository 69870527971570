import { FC } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { AppointmentStatus } from "components/ScheduleAppointments/AppointmentStatus";
import { statusIndicatorOptions } from "./statusOptions";

interface Props {
  appointment: AppointmentVO;
  onRequestUpdateAppointmentState: (newState: AppointmentVO["state"]) => void;
}

export const AppointmentCardStatusMenu: FC<Props> = ({ appointment, onRequestUpdateAppointmentState }) => {
  return (
    <div className="w-[194px]">
      {statusIndicatorOptions.map((statusConstant) => {
        return (
          <MenuOptionButton
            key={statusConstant.apiValue}
            onClick={() => {
              onRequestUpdateAppointmentState(statusConstant.apiValue);
            }}
          >
            <AppointmentStatus
              appointmentState={statusConstant.apiValue}
              isSelected={statusConstant.apiValue === appointment.state}
            >
              {statusConstant.prettyName}
            </AppointmentStatus>
          </MenuOptionButton>
        );
      })}
    </div>
  );
};
