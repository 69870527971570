import { produce } from "immer";
import { QueryClient } from "@tanstack/react-query";
import {
  BatchCreatePatientProcedureRequest,
  BatchDeletePatientProcedureRequest,
  BatchUpdatePatientProcedureRequest,
  CreateTreatmentPlanRequest,
  UpdateTreatmentPlanRequest,
  UpdateTreatmentPlanStateRequest,
  UpdatePatientProcedureRequest,
  TreatmentPlanAddProceduresRequest,
  UpsertPatientToothRequest,
  UpsertPerioChartExamEntriesRequest,
  UpdatePerioChartExamRequest,
  PerioChartExamSettingsVO,
  CreatePerioChartExamRequest,
  PerioChartExamVO,
  SuccessResponseListPerioChartExamVO,
  SuccessResponsePerioChartExamVO,
  CreatePatientProcedureRequest,
  TreatmentPlanVO,
  PatientProcedureVO,
  UpdatePatientProcedureFeeRequest,
  AppointmentVO,
  CreateTreatmentPlanFormTaskRequest,
} from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import { isOneOf } from "@libs/utils/isOneOf";
import { updateCachedData } from "@libs/utils/queryCache";
import { ApiResponseData } from "@libs/@types/api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { invalidateAlerts, invalidateNoteLists } from "api/notes/cache";

const getPatientChartV2QueryKey = (practiceId: number, patientId: number) => [
  getQueryKey("v2", "getPatientChartV2"),
  { practiceId, patientId },
];

export const invalidateProceduresAndTreatmentPlan = (
  queryClient: QueryClient,
  {
    practiceId,
    patientId,
    patientProcedureId,
  }: { practiceId: number; patientId?: number; patientProcedureId?: number }
) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getTreatmentPlan"), { practiceId }]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientProcedures"),
    isNullish(patientId) ? { practiceId } : { patientId, practiceId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientProcedure"),
    isNullish(patientProcedureId) ? { practiceId } : { patientProcedureId, practiceId },
  ]);
};

const invalidatePatientProcedure = (
  queryClient: QueryClient,
  {
    appointment,
    practiceId,
    patientId,
    date,
    status,
  }: {
    date: PatientProcedureVO["date"];
    status: PatientProcedureVO["status"];
    appointment?: AppointmentVO;
    practiceId: number;
    patientId: number;
  }
) => {
  if (appointment) {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAppointment"),
      { practiceId, appointmentId: appointment.id },
    ]);
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAppointmentCards"),
      { practiceId, date: appointment.date },
    ]);

    if (appointment.state === "UNSCHEDULED") {
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentHolds"), { practiceId }]);
    } else if (appointment.state === "REQUESTED") {
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentRequests"), { practiceId }]);
    }

    if (appointment.asap) {
      queryClient.invalidateQueries([getQueryKey("practices", "getAsapAppointments"), { practiceId }]);
    }
  } else if (isOneOf(status, ["SCHEDULED", "DONE"])) {
    // When switching the dental procedure of a patient procedure from the charting
    // page you have no access to the appointment iassociated with the procedure.
    queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { patientId, practiceId }]);
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAppointmentCards"),
      date && status === "SCHEDULED" ? { practiceId, date } : { practiceId },
    ]);
    queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentHolds"), { practiceId }]);
    queryClient.invalidateQueries([getQueryKey("practices", "getAsapAppointments"), { practiceId }]);
    queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentRequests"), { practiceId }]);
  }

  queryClient.invalidateQueries([
    getQueryKey("practices", "getNextPatientAppointment"),
    { practiceId, patientId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientAppointments"),
    { practiceId, patientId },
  ]);

  invalidateNoteLists({ queryClient, practiceId, patientId });
  invalidateAlerts({ queryClient, practiceId, patientId });

  queryClient.invalidateQueries(getPatientChartV2QueryKey(practiceId, patientId));
  invalidateProceduresAndTreatmentPlan(queryClient, { patientId, practiceId });
};

export const createPatientProcedures = makeMutation({
  mutationKey: ["practices", "createPatientProcedures"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    data: BatchCreatePatientProcedureRequest;
  }) => [args.patientId, args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      queryClient.invalidateQueries(getPatientChartV2QueryKey(practiceId, patientId));
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedures"), { patientId }]);
    },
  }),
});

export const deletePatientProcedures = makeMutation({
  mutationKey: ["practices", "deletePatientProcedures"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: BatchDeletePatientProcedureRequest;
  }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      // Patient procedures that are scheduled or completed can't be deleted
      // so no need to invalidate appointment related queries

      queryClient.invalidateQueries(getPatientChartV2QueryKey(practiceId, patientId));
      invalidateProceduresAndTreatmentPlan(queryClient, { patientId, practiceId });
    },
  }),
});

export const createTreatmentPlanFormTask = makeMutation({
  mutationKey: ["practices", "createTreatmentPlanFormTask"],
  formatParams: (args: { practiceId: number; data: CreateTreatmentPlanFormTaskRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const updatePatientProcedures = makeMutation({
  mutationKey: ["practices", "updatePatientProcedures"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: BatchUpdatePatientProcedureRequest;
  }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { patientId, practiceId });
    },
  }),
});

export const switchDentalProcedureOnPatientProcedure = makeMutation({
  mutationKey: ["practices", "switchDentalProcedureOnPatientProcedure"],
  formatParams: (args: {
    practiceId: number;
    patientProcedureId: number;
    patientId: number;
    appointment?: AppointmentVO;
    data: CreatePatientProcedureRequest;
  }) => [args.practiceId, args.patientProcedureId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (responseData, { patientId, practiceId, appointment }) => {
      invalidatePatientProcedure(queryClient, {
        appointment,
        practiceId,
        patientId,
        date: responseData.data.data.date,
        status: responseData.data.data.status,
      });
    },
  }),
});

export const updatePatientProcedure = makeMutation({
  mutationKey: ["practices", "updatePatientProcedure"],
  formatParams: (args: {
    practiceId: number;
    patientProcedureId: number;
    patientId: number;
    appointment?: AppointmentVO;
    data: UpdatePatientProcedureRequest;
  }) => [args.practiceId, args.patientProcedureId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (responseData, { patientId, practiceId, appointment }) => {
      invalidatePatientProcedure(queryClient, {
        appointment,
        practiceId,
        patientId,
        date: responseData.data.data.date,
        status: responseData.data.data.status,
      });
    },
  }),
});

export const createTreatmentPlan = makeMutation({
  mutationKey: ["practices", "createTreatmentPlan"],
  formatParams: (args: { practiceId: number; data: CreateTreatmentPlanRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { data: { patientId } }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientTreatmentPlansV2"), { patientId }]);
    },
  }),
});

export const updateTreatmentPlan = makeMutation({
  mutationKey: ["practices", "updateTreatmentPlan"],
  formatParams: (args: {
    practiceId: number;
    treatmentPlanUuid: string;
    patientId: number;
    data: UpdateTreatmentPlanRequest;
  }) => [args.practiceId, args.treatmentPlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, treatmentPlanUuid }) => {
      updateCachedData(
        queryClient,
        { queryKey: [getQueryKey("practices", "getTreatmentPlan"), { treatmentPlanUuid }] },
        () => response.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientTreatmentPlansV2"), { patientId }]);
    },
  }),
});

export const updateTreatmentPlanState = makeMutation({
  mutationKey: ["practices", "updateTreatmentPlanState"],
  formatParams: (args: {
    practiceId: number;
    treatmentPlanUuid: string;
    patientId: number;
    data: UpdateTreatmentPlanStateRequest;
  }) => [args.practiceId, args.treatmentPlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onMutate: ({ treatmentPlanUuid, data }) => {
      // optimistic update of treatment plan state
      updateCachedData<TreatmentPlanVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getTreatmentPlan"), { treatmentPlanUuid }] },
        (txPlan) => {
          return produce(txPlan, (draft) => {
            draft.state = data.state;
          });
        }
      );
    },
    onSuccess: (_data, { patientId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientTreatmentPlansV2"), { patientId }]);
    },
    onError: (_error, { treatmentPlanUuid }) => {
      // rollback optimistic update
      queryClient.invalidateQueries([getQueryKey("practices", "getTreatmentPlan"), { treatmentPlanUuid }]);
    },
  }),
});

export const deleteTreatmentPlan = makeMutation({
  mutationKey: ["practices", "deleteTreatmentPlan"],
  formatParams: (args: { practiceId: number; treatmentPlanUuid: string; patientId: number }) => [
    args.practiceId,
    args.treatmentPlanUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { treatmentPlanUuid, patientId, practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getTreatmentPlan"),
        { treatmentPlanUuid, practiceId },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientTreatmentPlansV2"),
        { patientId, practiceId },
      ]);
    },
  }),
});

export const addProceduresToTreatmentPlan = makeMutation({
  mutationKey: ["practices", "addProceduresToTreatmentPlan"],
  formatParams: (args: {
    practiceId: number;
    treatmentPlanUuid: string;
    data: TreatmentPlanAddProceduresRequest;
  }) => [args.practiceId, args.treatmentPlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { treatmentPlanUuid, practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getTreatmentPlan"),
        { treatmentPlanUuid, practiceId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientTreatmentPlansV2"), { practiceId }]);
    },
  }),
});

export const upsertPatientTeeth = makeMutation({
  mutationKey: ["practices", "upsertPatientTeeth"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: { teeth: UpsertPatientToothRequest[] };
  }) => [args.practiceId, args.patientId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, patientId }) => {
      queryClient.invalidateQueries(getPatientChartV2QueryKey(practiceId, patientId));
    },
  }),
});

export const upsertPerioChartExamSettings = makeMutation({
  mutationKey: ["practices", "upsertPerioChartExamSettings"],
  formatParams: (args: { practiceId: number; providerId: number; data: PerioChartExamSettingsVO }) => [
    args.practiceId,
    args.providerId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, providerId }) => {
      updateCachedData<PerioChartExamSettingsVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getPerioChartExamSettings"), { practiceId, providerId }],
          exact: true,
        },
        () => data.data.data
      );
    },
  }),
});

export const updatePerioChartExamEntries = makeMutation({
  mutationKey: ["practices", "updatePerioChartExamEntries"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    perioChartExamUuid: string;
    data: UpsertPerioChartExamEntriesRequest;
  }) => [args.practiceId, args.patientId, args.perioChartExamUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId, perioChartExamUuid }) => {
      // Update the cache for the main exam with the saved changes.
      const examsQueryKey = [
        getQueryKey("practices", "getPerioChartExam"),
        { practiceId, patientId, perioChartExamUuid },
      ];

      // This purposely does not call updateCachedData because we don't want to trigger a full
      // re-render since the saving happens async behind the scenes. Instead, it just updates
      // the existing cache entries directly.
      const examsData =
        queryClient.getQueryData<ApiResponseData<SuccessResponsePerioChartExamVO>>(examsQueryKey);

      if (examsData?.data.data) {
        const { entries } = examsData.data.data;
        const savedEntries = data.data.data;
        const unsavedEntries = entries.filter(
          (existingEntry) =>
            savedEntries.findIndex(
              (newEntry) =>
                existingEntry.toothNum === newEntry.toothNum &&
                existingEntry.perioSequenceType === newEntry.perioSequenceType
            ) === -1
        );

        examsData.data.data.entries = [...unsavedEntries, ...savedEntries];
      }
    },
  }),
});

export const updatePerioChartExam = makeMutation({
  mutationKey: ["practices", "updatePerioChartExam"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    perioChartExamUuid: string;
    data: UpdatePerioChartExamRequest;
  }) => [args.practiceId, args.patientId, args.perioChartExamUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId, perioChartExamUuid }) => {
      const newExamData = data.data.data;

      updateCachedData<PerioChartExamVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getPerioChartExam"),
            { practiceId, patientId, perioChartExamUuid },
          ],
          exact: true,
        },
        () => newExamData
      );

      const examsQueryKey = [getQueryKey("practices", "getPerioChartExams"), { practiceId, patientId }];
      const examsData =
        queryClient.getQueryData<ApiResponseData<SuccessResponseListPerioChartExamVO>>(examsQueryKey);
      const exams = examsData?.data.data || [];

      const newExams = produce(exams, (draft) => {
        const updatedExam = draft.find((exam) => exam.uuid === perioChartExamUuid);

        if (updatedExam) {
          updatedExam.date = newExamData.date;
          updatedExam.note = newExamData.note;
          updatedExam.provider = newExamData.provider;
        }
      });

      updateCachedData<PerioChartExamVO[]>(
        queryClient,
        {
          queryKey: examsQueryKey,
          exact: true,
        },
        () => newExams
      );
    },
  }),
});

export const createPerioChartExam = makeMutation({
  mutationKey: ["practices", "createPerioChartExam"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreatePerioChartExamRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId }) => {
      // Update the get cache so it doesn't immediately fetch.
      const exam = data.data.data;

      queryClient.setQueryData<ApiResponseData<SuccessResponsePerioChartExamVO>>(
        [
          getQueryKey("practices", "getPerioChartExam"),
          { practiceId, patientId, perioChartExamUuid: exam.uuid },
        ],
        {
          data: {
            pageDetails: {},
            data: exam,
          },
        }
      );

      // Populate the exams list with the new entry.
      const examsQueryKey = [getQueryKey("practices", "getPerioChartExams"), { practiceId, patientId }];
      const examsData =
        queryClient.getQueryData<ApiResponseData<SuccessResponseListPerioChartExamVO>>(examsQueryKey);
      const exams = examsData?.data.data;

      updateCachedData<PerioChartExamVO[]>(
        queryClient,
        {
          queryKey: examsQueryKey,
          exact: true,
        },
        () => (exams ? [exam, ...exams] : [exam])
      );
    },
  }),
});

export const deletePerioChartExam = makeMutation({
  mutationKey: ["practices", "deletePerioChartExam"],
  formatParams: (args: { practiceId: number; patientId: number; perioChartExamUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.perioChartExamUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId, perioChartExamUuid }) => {
      const examsQueryKey = [getQueryKey("practices", "getPerioChartExams"), { practiceId, patientId }];
      const examsData =
        queryClient.getQueryData<ApiResponseData<SuccessResponseListPerioChartExamVO>>(examsQueryKey);
      const exams = examsData?.data.data;

      if (exams) {
        updateCachedData<PerioChartExamVO[]>(
          queryClient,
          {
            queryKey: examsQueryKey,
            exact: true,
          },
          () => exams.filter((exam) => exam.uuid !== perioChartExamUuid)
        );
      }
    },
  }),
});

export const clonePerioChartExam = makeMutation({
  mutationKey: ["practices", "clonePerioChartExam"],
  formatParams: (args: { practiceId: number; patientId: number; perioChartExamUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.perioChartExamUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { patientId, practiceId, perioChartExamUuid }) => {
      const clonedExam = data.data.data;

      //Update cache with new clone
      updateCachedData<PerioChartExamVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getPerioChartExam"),
            { practiceId, patientId, perioChartExamUuid },
          ],
          exact: true,
        },
        () => clonedExam
      );

      const examsQueryKey = [getQueryKey("practices", "getPerioChartExams"), { practiceId, patientId }];
      const examsData =
        queryClient.getQueryData<ApiResponseData<SuccessResponseListPerioChartExamVO>>(examsQueryKey);
      const exams = examsData?.data.data;

      updateCachedData<PerioChartExamVO[]>(queryClient, { queryKey: examsQueryKey, exact: true }, () =>
        exams ? [clonedExam, ...exams] : [clonedExam]
      );
    },
  }),
});

export const updatePatientProcedureFee = makeMutation({
  mutationKey: ["practices", "updatePatientProcedureFee"],
  formatParams: (args: {
    practiceId: number;
    patientProcedureId: number;
    data: UpdatePatientProcedureFeeRequest;
  }) => [args.practiceId, args.patientProcedureId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, patientProcedureId }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientProcedureId });
    },
  }),
});

export const resetPrimaryEstimates = makeMutation({
  mutationKey: ["practices", "resetPrimaryEstimates"],
  formatParams: (args: { practiceId: number; patientProcedureId: number }) => [
    args.practiceId,
    args.patientProcedureId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, patientProcedureId }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientProcedureId });
    },
  }),
});
