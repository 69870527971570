import { SsnResponse } from "@libs/api/generated-api";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { queryClient } from "utils/queryClient";

export const updateSsnCache = (ssnUpdate: string, practiceId: number, userId: number) => {
  updateCachedData<SsnResponse>(
    queryClient,
    {
      queryKey: [getQueryKey("practices", "getSsn"), { practiceId, userId }],
      exact: true,
    },

    () => {
      return {
        ssn: ssnUpdate,
      };
    }
  );
};
