import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { noop } from "@libs/utils/noop";
import { useQueryParamsConfig } from "@libs/hooks/useQueryParamsConfig";
import { isDefined } from "@libs/utils/types";
import { AppointmentVO } from "@libs/api/generated-api";
import { formUrl } from "@libs/router/url";
import { matchPath } from "react-router-dom";
import { keys } from "@libs/utils/object";
import { APPOINTMENT_ID, PATIENT_ID, patientPanelQueryConfig } from "utils/routing/patientPanel";

type PatientAppointment = {
  patientId?: number;
  appointmentId?: number;
};

type PatientAppointmentContextValue = PatientAppointment & {
  setPatient: (params: { patientId: number }) => void;
  deleteAppointment: (params: { patientId: number; appointmentId: number }) => void;
  setPatientAppointment: (params: { appointmentId: number; patientId: number }) => void;
};

const Context = createContext<PatientAppointmentContextValue>({
  deleteAppointment: noop,
  setPatient: noop,
  setPatientAppointment: noop,
});

Context.displayName = "PatientAppointment";

/**
 * PatientAppointmentProvider serves three purposes:
 * - It holds the last viewed patient and appointment ids in memory for the lifetime of the signed
 *   in session. When a user navigates to a new page and neither query parameters nor path parameters are
 *   present to represent which patient and appointment should be displayed in the patient snapshot, the UI
 *   can fallback to these values. This provides a consistent stable patient snapshot UI that doesn't jump around
 *   as the user moves around the app.
 * - For routes where the appointmentId param is not particularly useful as a path param or query param,
 *   PatientAppointmentProvider acts as the appointmentId state for that route.
 * - Whenever a page changes the patientId or appointmentId, usePatientAppointmentListener can subscibe
 *   to those changes and clean any stored link references that contain stale patient or appointmentIds.
 */
export const PatientAppointmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [patientAppointmentIds, setPatientAppointmentIds] = useState<PatientAppointment>({});

  const deleteAppointment = useCallback((params: { patientId: number; appointmentId: number }) => {
    setPatientAppointmentIds((last) => {
      if (params.patientId === last.patientId && params.appointmentId === last.appointmentId) {
        return {
          patientId: last.patientId,
        };
      }

      return last;
    });
  }, []);

  const setPatientAppointment = useCallback((params: { patientId: number; appointmentId: number }) => {
    setPatientAppointmentIds(params);
  }, []);

  const setPatient = useCallback((params: { patientId: number }) => {
    setPatientAppointmentIds((last) => ({
      patientId: params.patientId,
      appointmentId: last.patientId === params.patientId ? last.appointmentId : undefined,
    }));
  }, []);

  const value = useMemo(() => {
    return {
      ...patientAppointmentIds,
      setPatientAppointment,
      deleteAppointment,
      setPatient,
    };
  }, [patientAppointmentIds, setPatient, deleteAppointment, setPatientAppointment]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * This hook provides access to the global PatientAppointmentContextValue
 *
 * @returns {PatientAppointmentContextValue}
 */
export const usePatientAppointmentContext = () => useContext(Context);

export type OnPatientAppointmentChange = (params: {
  patientId: number;
  lastPatientId: number | undefined;
  appointmentId?: number;
}) => void;

/**
 * This hook is useful when you want to take some action whenever the patieny appoinment context has changed.
 * Currently it's used to clean stale patient appointment ids from stored links.
 *
 * @param {OnPatientAppointmentChange} onPatientAppointmentChange - a callback that will be called on mount and
 * whenever the patient appointment context changes. If there is no patientId in use yet it will not be
 * called.
 * @returns {undefined}
 */
export const usePatientAppointmentListener = (onPatientAppointmentChange: OnPatientAppointmentChange) => {
  const patientAppointmentLinkContext = usePatientAppointmentContext();

  const ref = useRef(patientAppointmentLinkContext);

  useEffect(() => {
    // bail if the current patientId is not defined
    if (!isDefined(patientAppointmentLinkContext.patientId)) {
      return;
    }

    const lastPatientId = ref.current.patientId;

    ref.current = patientAppointmentLinkContext;

    onPatientAppointmentChange({
      patientId: patientAppointmentLinkContext.patientId,
      appointmentId: patientAppointmentLinkContext.appointmentId,
      lastPatientId,
    });
  }, [patientAppointmentLinkContext, onPatientAppointmentChange]);
};

/**
 * Use this hook on routes that have the patient snapshot where the patientId and appointmentId
 * are managed by path parameters.
 *
 * @param {{ patientId: number; appoimtmentId?: number }} pathParams - Pass the patientId and optionally
 * the appointmentId value that are parsed out of the current path using this hook. If no appointmentId
 * is available, the appointmentId will default to the last used appointmentId in memory for the patientId.
 * @returns {Object} Returns values and functions to keep the user's current path values in sync with the
 * last used patientId and appointmentId in memory.
 */
export const usePatientAppointmentPathState = (pathParams: { patientId: number; appointmentId?: number }) => {
  const { deleteAppointment, setPatient, setPatientAppointment, patientId, appointmentId } =
    usePatientAppointmentContext();

  const handleAppointmentDeleted = useCallback(
    (deletedParams: { patientId: number; appointmentId: number }) => {
      deleteAppointment(deletedParams);
    },
    [deleteAppointment]
  );

  const handleAppointmentSelected = useCallback(
    (appointment: AppointmentVO) => {
      setPatientAppointment({ appointmentId: appointment.id, patientId: appointment.patient.id });
    },
    [setPatientAppointment]
  );

  useEffect(() => {
    if (isDefined(pathParams.appointmentId)) {
      setPatientAppointment({ patientId: pathParams.patientId, appointmentId: pathParams.appointmentId });
    } else {
      setPatient({ patientId: pathParams.patientId });
    }
  }, [pathParams.patientId, pathParams.appointmentId, setPatient, setPatientAppointment]);

  return {
    patientId: pathParams.patientId,
    appointmentId: patientId === pathParams.patientId ? appointmentId : undefined,
    handleAppointmentDeleted,
    handleAppointmentSelected,
  };
};

/**
 * Use this hook on routes that have the patient snapshot where the patientId and appointmentId
 * are managed by the query string.
 *
 * @param {{ defaultPatientToMemory?: boolean }} options - When a patientId query param doesn't exist
 * the last used patientId in memory is used. This can be turned off by passing { defaultPatientToMemory: false }.
 * This can be useful when you want the UI to be able to clear the patient snapshot area.
 * @returns {Object} Returns values and functions to manage the patientId and appointmentId query
 * string state, keep the last used patientId and appointmentId in memory update to date, and use
 * the last used values in memory if the query params don't exist.
 */
export const usePatientAppointmentQueryState = (options?: { defaultPatientToMemory?: boolean }) => {
  const { deleteAppointment, setPatient, setPatientAppointment, patientId, appointmentId } =
    usePatientAppointmentContext();

  const { query, updateQuery } = useQueryParamsConfig(patientPanelQueryConfig);
  const defaultPatientToMemory = options?.defaultPatientToMemory ?? true;

  const safePatientAppointment = useMemo(() => {
    const resolved: PatientAppointment = {};

    if (query.patientId) {
      resolved.patientId = query.patientId;
      resolved.appointmentId =
        query.appointmentId ?? patientId === query.patientId ? appointmentId : undefined;
    } else if (defaultPatientToMemory) {
      const lastId = patientId;

      resolved.patientId = lastId;
      resolved.appointmentId = lastId ? appointmentId : undefined;
    }

    return resolved;
  }, [query.appointmentId, query.patientId, appointmentId, patientId, defaultPatientToMemory]);

  const handleAppointmentDeleted = useCallback(
    (deletedParams: { patientId: number; appointmentId: number }) => {
      deleteAppointment(deletedParams);

      if (deletedParams.appointmentId === query.appointmentId) {
        updateQuery("replaceIn", { appointmentId: undefined });
      }
    },
    [updateQuery, query.appointmentId, deleteAppointment]
  );

  const handleAppointmentSelected = useCallback(
    (appointment: AppointmentVO) => {
      updateQuery("replaceIn", { appointmentId: appointment.id, patientId: appointment.patient.id });
    },
    [updateQuery]
  );

  const handlePatientSelected = useCallback(
    (newPatientId: number | undefined) => {
      updateQuery("replaceIn", {
        appointmentId: undefined,
        patientId: newPatientId,
      });
    },
    [updateQuery]
  );

  useEffect(() => {
    if (isDefined(query.appointmentId) && isDefined(query.patientId)) {
      setPatientAppointment({ patientId: query.patientId, appointmentId: query.appointmentId });
    } else if (isDefined(query.patientId)) {
      setPatient({ patientId: query.patientId });
    }
  }, [query.patientId, query.appointmentId, setPatient, setPatientAppointment]);

  return {
    ...safePatientAppointment,
    handlePatientSelected,
    handleAppointmentDeleted,
    handleAppointmentSelected,
  };
};

export type GetNewUrl = (params: {
  appointmentId?: number;
  lastPatientId: number | undefined;
  patientId: number;
  path: string;
  searchParams: URLSearchParams;
  currentPath?: string;
  isFrom: boolean;
}) => string;

export type PathsWithIdReferencesConfig<T extends string> = Partial<
  Record<
    T,
    {
      pattern: string;
      end?: boolean;
      getNewUrl: GetNewUrl;
    }[]
  >
>;

const pathRequiresUpdate = <T extends string>(
  config: PathsWithIdReferencesConfig<T>,
  appName: T,
  path: string
) => {
  const pathConfigs = appName in config ? config[appName] : undefined;

  if (!pathConfigs) {
    return null;
  }

  for (const pathConfig of pathConfigs) {
    const match = matchPath({ path: pathConfig.pattern, end: pathConfig.end ?? true }, path);

    if (match) {
      return pathConfig.getNewUrl;
    }
  }

  return null;
};

// This is the default mapping function for updating a stored patient appointment link
// the the patient appointment context changes.
export const getNewUrl: GetNewUrl = ({ path, searchParams, patientId, appointmentId }) => {
  const newSearchParams = new URLSearchParams(searchParams);

  newSearchParams.set(PATIENT_ID, patientId.toString());

  if (appointmentId) {
    newSearchParams.set(APPOINTMENT_ID, appointmentId.toString());
  } else {
    newSearchParams.delete(APPOINTMENT_ID);
  }

  return formUrl(path, newSearchParams);
};

// Given the current app link state and configuration for
// path patterns and url mapping funtions, when the patient appointment
// changes cleanAppLinks will return a set up updates ensuring the urls
// and any "from" query params they have are not stale.
export const cleanAppLinks = <T extends string>({
  patientId,
  appointmentId,
  lastPatientId,
  linksRef,
  config,
  currentPath,
}: {
  patientId: number;
  lastPatientId: number | undefined;
  appointmentId: number | undefined;
  linksRef: MutableRefObject<Record<T, string>>;
  config: PathsWithIdReferencesConfig<T>;
  currentPath?: string;
}) => {
  const updates: Partial<typeof linksRef.current> = {};
  let hasLinksWithStaleIds = false;

  const appNames = keys(config);

  for (const appName of appNames) {
    const [path, query] = linksRef.current[appName].split("?");
    const searchParams = new URLSearchParams(query);

    const updateUrl = pathRequiresUpdate(config, appName, path);

    if (!updateUrl) {
      continue;
    }

    hasLinksWithStaleIds = true;

    updates[appName] = updateUrl({
      patientId,
      appointmentId,
      lastPatientId,
      path,
      searchParams: cleanFromParam({ searchParams, patientId, appointmentId, config, lastPatientId }),
      isFrom: false,
      currentPath,
    });
  }

  if (hasLinksWithStaleIds) {
    return updates;
  }

  return null;
};

// Recursively updates the "from" search param value when patient appointment changes.
// Given URLSearchParams, the patientId and appointmentId values, and configuration for
// path patterns and url mapping funtions will ensure the "from" param is not stale.
export const cleanFromParam = <T extends string>({
  searchParams,
  patientId,
  appointmentId,
  config,
  lastPatientId,
}: {
  searchParams: URLSearchParams;
  patientId: number;
  appointmentId: number | undefined;
  config: PathsWithIdReferencesConfig<T>;
  lastPatientId: number | undefined;
}) => {
  const from = searchParams.get("from");

  if (from) {
    const newFrom = cleanFromLink({ patientId, appointmentId, url: from, config, lastPatientId });

    if (newFrom !== from) {
      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.set("from", newFrom);

      return newSearchParams;
    }
  }

  return searchParams;
};

// Recursively updates the "from" search param value when patient appointment changes.
// Given a url, the patientId and appointmentId values, and configuration for
// path patterns and url mapping funtions will ensure the "from" param is not stale.
export const cleanFromLink = <T extends string>({
  patientId,
  appointmentId,
  url,
  config,
  lastPatientId,
}: {
  url: string;
  patientId: number;
  appointmentId: number | undefined;
  config: PathsWithIdReferencesConfig<T>;
  lastPatientId: number | undefined;
}) => {
  const appNames = keys(config);

  const [path, query] = url.split("?");
  const searchParams = new URLSearchParams(query);

  for (const appName of appNames) {
    const updateUrl = pathRequiresUpdate(config, appName, path);

    if (!updateUrl) {
      continue;
    }

    return updateUrl({
      patientId,
      appointmentId,
      lastPatientId,
      path,
      searchParams: cleanFromParam({ searchParams, patientId, appointmentId, config, lastPatientId }),
      isFrom: true,
    });
  }

  return url;
};
