import { QueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";

export const handleRoomsUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getRoomsByPracticeId"), { practiceId }]);
};

export const handleRoomsHoursUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getAvailableRooms"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getRoomOpenHours"), { practiceId }]);
};

export const handleProvidersUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getAllProviders"), { practiceId }]);
};

export const handleProviderHoursUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getAvailableProviders"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getProviderOpenHours"), { practiceId }]);
};

export const handleScheduleBlocksUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getScheduleBlocks"), { practiceId }]);
};

export const handlePatientAppointmentsUpdated = ({
  queryClient,
  practiceId,
  patientId,
}: {
  queryClient: QueryClient;
  practiceId: number;
  patientId?: number;
}) => {
  const args = patientId ? { practiceId, patientId } : { practiceId };

  queryClient.invalidateQueries([getQueryKey("practices", "getNextPatientAppointment"), args]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPatientAppointments"), args]);
  queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), args]);
};

export const handlePracticeAppointmentsUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentHolds"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAsapAppointments"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentRequests"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
};

export const handleScheduleUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  handlePracticeAppointmentsUpdated({ queryClient, practiceId });
  handlePatientAppointmentsUpdated({ queryClient, practiceId });
  handleScheduleBlocksUpdated({ queryClient, practiceId });
  handleRoomsUpdated({ queryClient, practiceId });
  handleRoomsHoursUpdated({ queryClient, practiceId });
  handleProvidersUpdated({ queryClient, practiceId });
  handleProviderHoursUpdated({ queryClient, practiceId });
};
