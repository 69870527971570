import { RoleVO } from "@libs/api/generated-api";
import { formUrl } from "@libs/router/url";
import { FC, PropsWithChildren, useCallback, useLayoutEffect, useRef } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { findFirstMatchingRole } from "components/Roles/roleUtils";
import { createLinksContext } from "contexts/AppLinksContext";
import { paths, routesConfig } from "utils/routing/paths";
import {
  OnPatientAppointmentChange,
  usePatientAppointmentListener,
  GetNewUrl,
  PathsWithIdReferencesConfig,
  cleanAppLinks,
  getNewUrl,
  cleanFromParam,
} from "contexts/PatientAppointmentContext";

/*
 *  When the patient appointment changes and a reference to an add appointment url is found it will be updated
 *  to root schedule link pointing to the new patient and appointment if the patientId changes. If just the
 *  appointmentId changes the appointmentId reference will be updated in place.
 */
const getAddAppointmentUrl: GetNewUrl = (params) => {
  const { lastPatientId, patientId, appointmentId } = params;

  if (lastPatientId !== patientId) {
    return paths.schedule({ patientId, appointmentId });
  }

  return getNewUrl(params);
};

/*
 *  When the patient appointment changes and a reference to an edit appointment url is found it will be updated
 *  to root schedule link pointing to the new patient and appointment if the patientId changes. If the appointment
 *  id changes not need to update the query param as the edit appointment route uses PatientAppointmentProvider
 *  appointentId in memory to manage it's appointmentId state.
 */
const getEditAppointmentUrl: GetNewUrl = ({
  path,
  searchParams,
  lastPatientId,
  appointmentId,
  patientId,
}) => {
  if (lastPatientId !== patientId) {
    return paths.schedule({ patientId, appointmentId });
  }

  return formUrl(path, searchParams);
};

/*
 * We have a special case with /patients/:patientId app links. In most cases, whenever
 * the patient changes we want to point the patients app to point to the patient overview.
 * However, in other cases we want to leave it as is.
 *
 * - If the new url is being generated because it was found within a from query param.
 * We want to keep the same path in order make sure users always goes back to the path
 * they came "from" ;).
 *
 * - Whenever the user is in the patients app, the patients app link should always point
 * to the patients list page otherwise user will not have a way to reach the patients list
 * page.
 *
 * */
const shouldUsePatientProfile = ({
  currentPath,
  isFrom,
  lastPatientId,
  patientId,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
}: Parameters<GetNewUrl>[0]) => {
  const isOnPatientsApp =
    currentPath && matchPath({ path: routesConfig.patients.path, end: false }, currentPath);

  return !isFrom && lastPatientId !== patientId && !isOnPatientsApp;
};

/*
 *  This is an exhaustive configuration of all links in the app that store references to the
 *  current patient and appointment id. It's a map of MainLinks appNames to a list of the possible
 *  path patterns that match an app's current link value. When the patient appointment changes and
 *  we find a value for a given app link that matches a path pattern, we can use the getNewUrl function
 *  that will return a value that has updated patient and appointment references.
 *
 *  Storing things this way allows for efficient updates to the MainLinks value when the patient appointment
 *  values change.  For example, we'll never try to update the "communications" app link. Also when
 *  we need to update the "scheduling" app link for example, we'll only try to match a small set of possible
 *  getNewUrl mappings vs all urls that will never be present as "scheduling" values
 */
export const pathsWithIdReferences: PathsWithIdReferencesConfig<keyof MainLinks> = {
  schedule: [
    {
      pattern: routesConfig.addPatientAppointment.path,
      getNewUrl: getAddAppointmentUrl,
    },
    {
      pattern: routesConfig.editAppointment.path,
      getNewUrl: getEditAppointmentUrl,
    },
    {
      pattern: routesConfig.scheduleAppointment.path,
      getNewUrl: getAddAppointmentUrl,
    },
    {
      pattern: routesConfig.scheduleAsap.path,
      getNewUrl: getAddAppointmentUrl,
    },
    { pattern: routesConfig.huddle.path, getNewUrl },
    { pattern: routesConfig.schedule.path, getNewUrl },
    { pattern: routesConfig.scheduleAsapList.path, getNewUrl },
    { pattern: routesConfig.scheduleRequestsList.path, getNewUrl },
  ],
  patients: [
    {
      pattern: routesConfig.patient.path,
      end: false,
      getNewUrl: (params) => {
        const { patientId, searchParams, path, appointmentId } = params;

        if (shouldUsePatientProfile(params)) {
          return paths.patients({ patientId, appointmentId });
        }

        // leave as is
        return formUrl(path, searchParams);
      },
    },
    {
      pattern: routesConfig.patients.path,
      getNewUrl,
    },
  ],
  messaging: [
    {
      pattern: routesConfig.messaging.path,
      getNewUrl,
    },
  ],
  labCases: [
    {
      pattern: routesConfig.labCases.path,
      getNewUrl,
    },
  ],
};

/**
 * Determines the landing page path based on the user's role and permissions.
 * If no matching role and permissions are found, the default landing page path is returned.
 *
 * @param role - The user's role.
 * @param roleV2 - An optional role value object.
 * @returns The landing page path.
 */
export const settingsLandingPage = (roleV2: RoleVO) => {
  const DEFAULT_LANDING_PAGE = paths.settingsSection({ section: "business-information" });

  return (
    findFirstMatchingRole(roleV2, [
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_PRACTICE",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "business-information" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_PAYROLL",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "payroll-information" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_SCHEDULING",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "appointment-categories" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_INSURANCE",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "fee-schedules" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_CHARTING",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "procedure-shortcuts" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_LAB_CASES",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "labs" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_CLAIMS",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "claim-settings" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_PAYMENTS",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "billing-merchant" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_COMMS",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "email-authentication" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_FORMS",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "referral-options" }),
      },
      {
        domain: "PRACTICE_SETTINGS",
        action: "ACCESS_SECURITY",
        routePermissions: "settings",
        path: paths.settingsSection({ section: "ip-auth" }),
      },
    ])?.path ?? DEFAULT_LANDING_PAGE
  );
};

/**
 * Returns the landing page path based on the user's role and permissions.
 * If no matching role is found, the default landing page path is returned.
 *
 * @param role - The user's role.
 * @param roleV2 - Optional additional role information.
 * @returns The landing page path.
 */

export const dashboardLandingPage = (roleV2: RoleVO) => {
  const DEFAULT_DASHBOARD_LANDING_PAGE = paths.dashboardPracticeProduction({ tableTab: "providers" });

  return (
    findFirstMatchingRole(roleV2, [
      {
        domain: "REPORTING",
        action: "ACCESS_FINANCIAL",
        routePermissions: "dashboard",
        path: paths.dashboardPracticeProduction({ tableTab: "providers" }),
      },
      {
        domain: "REPORTING",
        action: "ACCESS_OPERATIONAL",
        routePermissions: "dashboard",
        path: paths.dashboardPatientsTreated(),
      },
      {
        domain: "REPORTING",
        action: "ACCESS_HYGIENE",
        routePermissions: "dashboard",
        path: paths.dashboardAppointmentDrilldown({ apptType: "hygiene" }),
      },
      {
        domain: "REPORTING",
        action: "ACCESS_ADMINISTRATIVE",
        routePermissions: "dashboard",
        path: paths.dashboardPayrollHours(),
      },
    ])?.path ?? DEFAULT_DASHBOARD_LANDING_PAGE
  );
};

export const getDefaultMainLinks = (role: RoleVO) => ({
  messaging: paths.messaging(),
  communications: paths.communications(),
  patients: paths.patients(),
  employees: paths.employees(),
  settings: settingsLandingPage(role),
  claims: paths.claims(),
  schedule: paths.schedule(),
  dashboard: dashboardLandingPage(role),
  erx: paths.erx(),
  labCases: paths.labCases(),
});

export type MainLinks = ReturnType<typeof getDefaultMainLinks>;

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext("Main", {} as MainLinks);

export const useMainLinks = useLinks;

const MainLinksPatientAppointmentListener: FC<PropsWithChildren> = ({ children }) => {
  const { linksRef, updateLinks } = useLinks();
  const location = useLocation();
  const navigate = useNavigate();
  const locationRef = useRef(getFullUrl(location));

  useLayoutEffect(() => {
    locationRef.current = getFullUrl(location);
  }, [location]);

  const handlePatientAppointmentChange: OnPatientAppointmentChange = useCallback(
    ({ patientId, appointmentId, lastPatientId }) => {
      const [path, query] = locationRef.current.split("?");

      // clean up main app link references when the patient appointment changes
      const updates = cleanAppLinks({
        patientId,
        appointmentId,
        lastPatientId,
        linksRef,
        currentPath: path,
        config: pathsWithIdReferences,
      });

      if (updates) {
        updateLinks(updates);
      }

      // clean up the current urls from query param if any
      const searchParams = new URLSearchParams(query);

      const newSearchParams = cleanFromParam({
        searchParams,
        patientId,
        appointmentId,
        lastPatientId,
        config: pathsWithIdReferences,
      });

      if (newSearchParams !== searchParams) {
        navigate(formUrl(path, newSearchParams), { replace: true });
      }
    },
    [linksRef, updateLinks, navigate]
  );

  usePatientAppointmentListener(handlePatientAppointmentChange);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MainLinksProvider = ({ children, ...props }: Parameters<typeof LinksProvider>[0]) => {
  return (
    <LinksProvider {...props}>
      <MainLinksPatientAppointmentListener>{children}</MainLinksPatientAppointmentListener>
    </LinksProvider>
  );
};
export const MainAppHistoryProvider = AppHistoryProvider;
