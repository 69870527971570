import React, { useEffect, useRef, useState } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ReactComponent as OfficeChatIcon } from "@libs/assets/icons/office-chat.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { Flyover } from "components/UI/Flyover";
import { getUnreadMessageCount, issueChatToken } from "api/intraOfficeChat/queries";
import { addUnreadMessageStateHandler } from "components/OfficeChat/officeChatEvents";
import { PracticeNavIcon } from "components/Main/PracticeNavIcon";
import { useIntraOfficeChatContext } from "components/OfficeChat/IntraOfficeChatContext";

export type OfficeChatVisibilityState = {
  isOpen: boolean;
  disconnectTimer?: NodeJS.Timeout;
};

export const TWELVE_HOURS_IN_SECONDS = 43_200;

const OfficeChat = lazyDefault(() => import("components/OfficeChat/OfficeChat"), "OfficeChatContainer");

export const OfficeChatButton: React.FC = () => {
  const { practiceId } = useAccount();
  const { chatIsOn, closeChat, openChat } = useIntraOfficeChatContext();
  const visibilityStateRef = useRef<OfficeChatVisibilityState>({ isOpen: false });

  useEffect(() => {
    const flyoutState = visibilityStateRef.current;

    // Remove any existing timeout since it's open again.
    if (chatIsOn && flyoutState.disconnectTimer) {
      clearTimeout(flyoutState.disconnectTimer);
      flyoutState.disconnectTimer = undefined;
    }

    flyoutState.isOpen = chatIsOn;
  }, [chatIsOn]);

  const [chatTokenQuery] = useApiQueries([
    issueChatToken({ args: { practiceId, data: { validDurationSeconds: TWELVE_HOURS_IN_SECONDS } } }),
  ]);

  const [unreadMessageCountQuery] = useApiQueries([
    getUnreadMessageCount({ args: { practiceId }, queryOptions: { enabled: Boolean(chatTokenQuery.data) } }),
  ]);

  const [unreadMessages, setUnreadMessages] = useState(0);

  // addUnreadMesageStateHandler returns an unsub function.
  useEffect(() => addUnreadMessageStateHandler(setUnreadMessages), []);

  useEffect(() => {
    if (unreadMessageCountQuery.data) {
      setUnreadMessages(unreadMessageCountQuery.data.unreadMessageCount);
    }
  }, [unreadMessageCountQuery.data]);

  return (
    <>
      <button type="button" onClick={openChat}>
        <PracticeNavIcon
          tooltip={{ content: "Chat", theme: "SMALL" }}
          Icon={OfficeChatIcon}
          selected={chatIsOn}
          notificationCount={unreadMessages}
        />
      </button>

      {chatTokenQuery.data && chatIsOn && (
        <Flyover
          onClose={closeChat}
          size="sm"
          clickAwayToClose={true}
          overlay={<div role="presentation" className="absolute inset-0" />}
        >
          <React.Suspense fallback={<LoadingContent />}>
            <OfficeChat
              appId={chatTokenQuery.data.appId}
              userId={chatTokenQuery.data.userId}
              accessToken={chatTokenQuery.data.token}
              visibilityState={visibilityStateRef.current}
              onClose={closeChat}
            />
          </React.Suspense>
        </Flyover>
      )}
    </>
  );
};
