import { useMemo, FC, useEffect, Suspense } from "react";
import { EmployeeVO, PracticeInfoVO, RoleVO } from "@libs/api/generated-api";
import { getLocalDate, nowInTimezone } from "@libs/utils/date";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { PracticePendoProvider } from "components/Main/PracticePendoContext";
import { SignedInAppRoutes } from "components/Main/SignedInAppRoutes";
import { CurrentUserContext } from "contexts/CurrentUserContext";
import { setSentryEmployeeProperties } from "utils/sentry";

export const hasAccess = (accessDate: string, timezoneId: string) => {
  return Boolean(getLocalDate(accessDate) <= nowInTimezone(timezoneId));
};

const OnboardingApp = lazyDefault(() => import("components/Main/OnboardingApp"), "OnboardingApp");

export const EmployeeUserProvider: FC<{
  practice: PracticeInfoVO;
  employee: EmployeeVO;
}> = ({ practice, employee }) => {
  const currentUser = useMemo(() => {
    const { id, personalDetails, contactDetails, employmentDetails, employeeSettings, roleV2 } = employee;

    return {
      id,
      type: "EMPLOYEE" as const,
      roleV2: roleV2 as RoleVO,
      name: {
        id,
        shortDisplayName: personalDetails.displayName ?? "",
        fullDisplayName: personalDetails.fullName ?? "",
        firstName: personalDetails.firstName,
        lastName: personalDetails.lastName,
      },
      phone: contactDetails.phone,
      email: contactDetails.workEmail,
      employmentDetails,
      employeeSettings,
    };
  }, [employee]);

  const employeeStatus = employee.employmentDetails.status;
  const employeeAccessDate = employee.employmentDetails.accessDate;

  const isOnboarding = useMemo(() => {
    return (
      Boolean(employeeStatus && employeeStatus === "PENDING") ||
      !hasAccess(employeeAccessDate, practice.timezoneId)
    );
  }, [employeeAccessDate, employeeStatus, practice.timezoneId]);

  useEffect(() => {
    setSentryEmployeeProperties(employeeStatus);
  }, [employeeStatus]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <PracticePendoProvider user={currentUser} practice={practice}>
        {isOnboarding ? (
          <Suspense fallback={<LoadingContent />}>
            <OnboardingApp />
          </Suspense>
        ) : (
          <SignedInAppRoutes />
        )}
      </PracticePendoProvider>
    </CurrentUserContext.Provider>
  );
};
