import { AppointmentVO } from "@libs/api/generated-api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, noCache } from "@libs/utils/queryCache";

export interface OpenSlotsRequest {
  providerIds?: number[];
  appointmentDurationInMinutes: number;
  daysPreferred?: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  startDate: string;
  timeRanges?: string[];
}

export const getSchedulingConfigQuery = makeQuery({
  queryKey: ["practices", "getSchedulingConfig"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getPracticeRoomsQuery = makeQuery({
  queryKey: ["practices", "getRoomsByPracticeId"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getRoomOpenHoursQuery = makeQuery({
  queryKey: ["practices", "getRoomOpenHours"],
  formatParams: (args: { practiceId: number; date: string }) => [args.practiceId, { date: args.date }],
  queryOptions: cacheForSession,
});

export const getAppointmentCategoriesQuery = makeQuery({
  queryKey: ["practices", "getAppointmentCategories"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getPracticeAppoinmentCardsQuery = makeQuery({
  queryKey: ["practices", "getAppointmentCards"],
  formatParams: (args: { date: string; practiceId: number; excludeStates?: AppointmentVO["state"][] }) => [
    args.practiceId,
    {
      filterDate: args.date,
      excludeStates: args.excludeStates,
      // TODO required for tags, remove when backend is updated
      includeHuddleIcons: true,
    },
  ],
  queryOptions: fiveMinuteCache,
});

export const getAppointmentQuery = makeQuery({
  queryKey: ["practices", "getAppointment"],
  // patientId is for decaching purposes
  formatParams: (args: { practiceId: number; appointmentId: number; patientId: number }) => [
    args.practiceId,
    args.appointmentId,
    // TODO required for tags, remove when backend is updated
    { fetchIcons: true },
  ],
  queryOptions: fiveMinuteCache,
});
export const getPracticeAppoinmentHoldsQuery = makeQuery({
  queryKey: ["practices", "getAppointmentHolds"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getWorkingHoursQuery = makeQuery({
  queryKey: ["practices", "getWorkingHours"],
  formatParams: (args: { practiceId: number; providerId?: number }) => {
    const { practiceId, ...query } = args;

    return [practiceId, { ...query, isSelfBookable: false }];
  },
  queryOptions: cacheForSession,
});

export const getPracticeCustomHolidaysQuery = makeQuery({
  queryKey: ["practices", "getCustomHolidays"],
  formatParams: (args: { practiceId: number; providerId?: number }) => {
    const { practiceId, ...query } = args;

    return [practiceId, query];
  },
  queryOptions: cacheForSession,
});

export type AvailabilityFilterParams = {
  appointmentDurationInMinutes: number;
  startDate: string;
  startTime: string;
};
export const getPracticeAvailableRoomsQuery = makeQuery({
  queryKey: ["practices", "getAvailableRooms"],
  formatParams: (args: { practiceId: number; filters: AvailabilityFilterParams }) => [
    args.practiceId,
    args.filters,
  ],
  queryOptions: cacheForSession,
});

export const getAvailableProvidersQuery = makeQuery({
  queryKey: ["practices", "getAvailableProviders"],
  formatParams: (args: { practiceId: number; filters: AvailabilityFilterParams }) => [
    args.practiceId,
    args.filters,
  ],
  queryOptions: cacheForSession,
});

export const getPracticePublicHolidaysQuery = makeQuery({
  queryKey: ["practices", "getPublicHolidaysByYear"],
  formatParams: (args: { practiceId: number; year: number }) => [
    args.practiceId,
    { year: String(args.year) },
  ],
  queryOptions: cacheForSession,
});

export const getOpenSlotsQuery = makeQuery({
  queryKey: ["practices", "getOpenSlots"],
  formatParams: (args: { practiceId: number; filters: OpenSlotsRequest }) => [args.practiceId, args.filters],
  queryOptions: noCache,
});

export const getProviderOpenHours = makeQuery({
  queryKey: ["practices", "getProviderOpenHours"],
  formatParams: (args: { practiceId: number; date: string }) => [args.practiceId, { date: args.date }],
  queryOptions: cacheForSession,
});

export const getScheduleBlocks = makeQuery({
  queryKey: ["practices", "getScheduleBlocks"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; filterDate: string }) => [practiceId, query],
  queryOptions: cacheForSession,
});

export const getScheduleBlockInstance = makeQuery({
  queryKey: ["practices", "getScheduleBlockInstance"],
  formatParams: ({
    practiceId,
    blockId,
    instanceDate,
  }: {
    practiceId: number;
    blockId: number;
    instanceDate: string;
  }) => [practiceId, blockId, instanceDate],
});

export const getAsapAppointmentsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getAsapAppointments"],
  formatParams: ({
    practiceId,
    sortColumn,
    sortDirection,
    pageNumber,
    pageSize,
  }: {
    practiceId: number;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, { sortColumn, sortDirection, pageNumber, pageSize }],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});

export const getAppointmentRequestsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getAppointmentRequests"],
  formatParams: ({
    practiceId,
    sortColumn,
    sortDirection,
    pageNumber,
    pageSize,
  }: {
    practiceId: number;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, { sortColumn, sortDirection, pageNumber, pageSize }],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});
