import { FC, useContext } from "react";
import { ReactComponent as ClinicalNoteIcon } from "@libs/assets/icons/clinical-notes-filled.svg";
import { PatientNoteVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { handleError } from "utils/handleError";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";

interface Props {
  status: NonNullable<PatientNoteVO["status"]>;
  patientId: number;
  appointmentId: number;
  onNoteLoaded: (note: PatientNoteVO) => void;
}

export const ClinicalNoteStatus: FC<Props> = ({ status, patientId, appointmentId, onNoteLoaded }) => {
  const loadingNote = useBoolean(false);
  const { practiceId } = useAccount();
  const { httpClient } = useContext(ApiClientContext);
  const patientNotes = usePatientNotesRouter();

  const openNote = async () => {
    loadingNote.on();

    try {
      const response = await httpClient.practices.getAppointmentClinicalNote(
        practiceId,
        patientId,
        appointmentId
      );

      onNoteLoaded(response.data.data);
    } catch (err) {
      handleError(err);
    } finally {
      loadingNote.off();
    }
  };

  return (
    <FloatingTooltip
      theme="SMALL"
      content={
        patientNotes.isOpen
          ? null
          : `Clinical Note: ${
              status === "COMPLETED" ? "Completed" : status === "DRAFT" ? "Saved" : "Incomplete"
            }`
      }
    >
      <div
        className={cx(
          "size-6 rounded flex items-center justify-center",
          status === "COMPLETED" ? "bg-greenLight" : status === "DRAFT" ? "bg-orangeLight" : "bg-redLight"
        )}
      >
        <ButtonIcon
          SvgIcon={ClinicalNoteIcon}
          theme={status === "COMPLETED" ? "success" : status === "DRAFT" ? "warning" : "error"}
          onClick={openNote}
          loading={loadingNote.isOn}
        />
      </div>
    </FloatingTooltip>
  );
};
