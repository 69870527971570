/* eslint-disable @typescript-eslint/naming-convention */
import React, { ComponentProps, useCallback, useMemo, useState } from "react";
import { components, SingleValue } from "react-select";
import { GooglePlacesAutocomplete } from "@libs/components/UI/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { geocodeByAddress } from "@libs/components/UI/GooglePlacesAutocomplete/utils/geocodeByAddress";
import { OptionType } from "@libs/components/UI/GooglePlacesAutocomplete/GooglePlacesAutocomplete.types";
import designConfig from "@libs/design.config";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { ReactComponent as ClearIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as MarkerIcon } from "@libs/assets/icons/marker.svg";
import { getSelectLayoutStyles } from "@libs/components/UI/FormFieldSelect";

export type AddressSelection = {
  address: string;
  locality: string;
  state: string;
  country: string;
  zip: string;
  raw: string;
};

type Props = FormFieldProps & {
  defaultValue?: string;
  placeholder?: string;
  apiKey: string;
  id?: string;
  onSelect: (val: AddressSelection) => void;
};

const updateTextBox = (result: google.maps.GeocoderResult[] | null) => {
  const addressResult = result?.[0].address_components || [];
  const locality = addressResult.find((item) => item.types.includes("locality"))?.long_name || "";
  const state =
    addressResult.find((item) => item.types.includes("administrative_area_level_1"))?.short_name || "";
  const country = addressResult.find((item) => item.types.includes("country"))?.short_name || "";
  const zip = addressResult.find((item) => item.types.includes("postal_code"))?.long_name || "";
  const zipSuffix = addressResult.find((item) => item.types.includes("postal_code_suffix"))?.long_name;
  const fullZip = zipSuffix ? `${zip}-${zipSuffix}` : zip;
  const street = addressResult.find((item) => item.types.includes("route"))?.long_name || "";
  const number = addressResult.find((item) => item.types.includes("street_number"))?.long_name || "";
  const addressLine1 = `${number} ${street}`.trim();

  return {
    address: addressLine1,
    locality,
    state,
    country,
    zip: fullZip,
  };
};

type GooglePlacesAutocompleteComponentsProp = Required<
  ComponentProps<typeof GooglePlacesAutocomplete>
>["selectProps"]["components"];

const CustomComponents: GooglePlacesAutocompleteComponentsProp = {
  ClearIndicator: (props) => {
    return (
      <components.ClearIndicator {...props}>
        <ClearIcon />
      </components.ClearIndicator>
    );
  },
  Input: (props) => (
    // Prevents password managers from using this control
    <components.Input {...props} data-lpignore="true" />
  ),
};

const emptyPrediction: google.maps.places.AutocompletePrediction = {
  description: "",
  place_id: "",
  matched_substrings: [],
  structured_formatting: {
    main_text: "",
    main_text_matched_substrings: [],
    secondary_text: "",
  },
  terms: [],
  types: [],
};

export const FormFieldAddressAutocomplete: React.FC<Props> = ({
  label,
  layout,
  required,
  className,
  disabled,
  error,
  edit,
  defaultValue,
  id,
  placeholder = "Search",
  onSelect,
  apiKey,
}) => {
  const formContext = useFormContext();
  const fieldId = useEnsureId({ customId: id });
  const mergedFormContext = useMergeFormContext(formContext, { layout });
  const [value, setValue] = useState<
    { label: string; value: google.maps.places.AutocompletePrediction } | undefined
  >(() => (defaultValue ? { label: defaultValue, value: emptyPrediction } : undefined));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChange = useCallback(
    async (option?: SingleValue<OptionType>) => {
      const lastValue = value;

      if (option) {
        try {
          setValue({ label: option.label.split(",")[0], value: emptyPrediction });

          const results = await geocodeByAddress(option.label);
          const formattedValues = updateTextBox(results);

          onSelect({
            ...formattedValues,
            raw: option.label,
          });
        } catch (e) {
          console.error(e);
          setValue(lastValue);
        }
      } else {
        onSelect({
          address: "",
          locality: "",
          state: "",
          country: "",
          zip: "",
          raw: "",
        });
        setValue(undefined);
      }
    },
    [value, onSelect]
  );
  const layoutStyles = useMemo(() => {
    return getSelectLayoutStyles({ layout: mergedFormContext.layout, hasLabel: Boolean(label) });
  }, [mergedFormContext.layout, label]);

  return (
    <FormField
      layout={mergedFormContext.layout}
      label={label}
      disabled={disabled}
      required={required}
      edit={edit}
      error={error}
      id={fieldId}
      className={className}
    >
      <div className={cxFormFieldStyle.wrapper}>
        <GooglePlacesAutocomplete
          apiKey={apiKey}
          selectProps={{
            tabSelectsValue: false,
            className: "pr-5 text-xs",
            inputId: fieldId,
            value,
            isClearable: true,
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => setIsMenuOpen(false),
            menuIsOpen: isMenuOpen,
            components: {
              // The value of custom `Input` component must need to be defined
              // outside or events on the input field don't be fired properly
              // and feel buggy. E.g. Focus doesn't feels janky, opening/closing
              // of menu is inconsistent, etc. See
              // https://github.com/JedWatson/react-select/issues/3237
              ...CustomComponents,
              Menu: (props) => {
                return (
                  <components.Menu {...props}>
                    <>
                      {props.selectProps.inputValue && (
                        <button
                          type="button"
                          onClick={() => {
                            onSelect({
                              raw: props.selectProps.inputValue,
                              address: props.selectProps.inputValue,
                              country: "",
                              state: "",
                              zip: "",
                              locality: "",
                            });
                            setValue({
                              label: props.selectProps.inputValue,
                              value: emptyPrediction,
                            });
                            setIsMenuOpen(false);
                          }}
                          className={`
                            rounded-t
                            bg-actionLight
                            font-sansSemiBold
                            py-2
                            px-3
                            w-full
                            text-left
                            text-primaryTheme
                          `}
                        >
                          Use: {props.selectProps.inputValue}
                        </button>
                      )}
                      {props.children}
                    </>
                  </components.Menu>
                );
              },
            },
            onChange: handleChange,
            placeholder,
            isDisabled: disabled,
            styles: {
              control: (provided) => ({
                ...provided,
                background: "transparent",
                boxShadow: "none",
                border: "none",
                cursor: "text",
                ...layoutStyles.control(),
              }),
              placeholder: (provided) => ({
                ...provided,
                margin: 0,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                padding: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                margin: 0,
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              clearIndicator: (provided) => ({
                ...provided,
                padding: 0,
                color: disabled ? designConfig.colors.greyLight : designConfig.colors.primaryTheme,
                cursor: "pointer",
                ...layoutStyles.clearIndicator(),
                svg: {
                  height: "100%",
                  width: "100%",
                },
              }),
              loadingIndicator: (provided) => ({
                ...provided,
                color: designConfig.colors.primaryTheme,
                margin: 0,
                padding: 0,
              }),
              dropdownIndicator: () => ({
                display: "none",
              }),
              menu: (provided) => ({
                ...provided,
                width: "max-content",
                minWidth: "100%",
              }),
              menuList: () => ({
                padding: 0,
              }),
              option: (provided, props) => {
                return {
                  ...provided,
                  backgroundColor: props.isFocused
                    ? designConfig.colors.greyLightest
                    : provided.backgroundColor,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  ":hover": {
                    backgroundColor: designConfig.colors.slate["100"],
                  },
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  ":last-child": {
                    borderBottomRightRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  },
                };
              },
            },
          }}
        />
        <div className={cxFormFieldStyle.iconContainer({ layout: mergedFormContext.layout })}>
          <MarkerIcon className={cxFormFieldStyle.icon({ disabled, error })} />
        </div>
      </div>
    </FormField>
  );
};
