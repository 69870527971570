import { useMemo } from "react";
import { DailyHuddleInsuranceInfoVO, PatientInsuranceResponse } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import {
  InsuranceBadgeProps,
  InsuranceBadgeSizes,
  InsuranceSubscriberBadge,
  VerificationBadge,
} from "components/Insurance/InsuranceBadges";

import { isVerifiedInsuranceStatus } from "components/Insurance/utils";

type Props = {
  badgeSize?: InsuranceBadgeSizes;
  borderColor?: InsuranceBadgeProps["borderColor"];
  dailyHuddleInsuranceInfo?: DailyHuddleInsuranceInfoVO;
  displayDivider?: boolean;
  isLoading?: boolean;
  networkBadgeType?: "small" | "large";
  primaryInsurance?: PatientInsuranceResponse;
};

// eslint-disable-next-line complexity
export const PatientInsuranceBadges: React.FC<Props> = (props) => {
  const {
    badgeSize,
    dailyHuddleInsuranceInfo,
    displayDivider = false,
    isLoading = false,
    networkBadgeType = "small",
    primaryInsurance,
    borderColor,
  } = props;

  const displayNetworkBadge = !("dailyHuddleInsuranceInfo" in props);

  const patientInsurance = primaryInsurance?.patientInsurance;

  // eslint-disable-next-line complexity
  const { eligibilityTooltip, benefitsTooltip, networkType } = useMemo(() => {
    if (dailyHuddleInsuranceInfo) {
      const {
        eligibilityVerifiedStatus,
        eligibilityLastVerifiedBy,
        eligibilityLastVerifiedAt,
        benefitVerifiedStatus,
        benefitLastVerifiedBy,
        benefitLastVerifiedAt,
      } = dailyHuddleInsuranceInfo;

      const eligibility = eligibilityVerifiedStatus
        ? isVerifiedInsuranceStatus(eligibilityVerifiedStatus)
          ? `  Eligibility verified${
              eligibilityLastVerifiedBy?.fullDisplayName
                ? `\nBy ${eligibilityLastVerifiedBy.fullDisplayName}`
                : ""
            }${eligibilityLastVerifiedAt ? `\nOn ${formatISODate(eligibilityLastVerifiedAt)}` : ""}`
          : "Eligibility unverified"
        : "Eligibility unverified";

      const benefits = benefitVerifiedStatus
        ? isVerifiedInsuranceStatus(benefitVerifiedStatus)
          ? `Benefits verified${
              benefitLastVerifiedBy?.fullDisplayName ? `\nBy ${benefitLastVerifiedBy.fullDisplayName}` : ""
            }${benefitLastVerifiedAt ? `\nOn ${formatISODate(benefitLastVerifiedAt)}` : ""}`
          : "Benefits unverified"
        : "Benefits unverified";

      return { eligibilityTooltip: eligibility, benefitsTooltip: benefits };
    }

    const eligibility = patientInsurance?.eligibilityVerifiedStatus
      ? isVerifiedInsuranceStatus(patientInsurance.eligibilityVerifiedStatus)
        ? `Eligibility verified${
            patientInsurance.eligibilityLastVerifiedBy?.fullDisplayName
              ? `\nBy ${patientInsurance.eligibilityLastVerifiedBy.fullDisplayName}`
              : ""
          }${
            patientInsurance.eligibilityLastVerifiedAt
              ? `\nOn ${formatISODate(patientInsurance.eligibilityLastVerifiedAt)}`
              : ""
          }`
        : "Eligibility unverified"
      : "Eligibility unverified";

    const benefits = primaryInsurance?.insurancePlan?.benefitVerifiedStatus
      ? isVerifiedInsuranceStatus(primaryInsurance.insurancePlan.benefitVerifiedStatus)
        ? `Benefits verified${
            primaryInsurance.insurancePlan.benefitLastVerifiedBy?.fullDisplayName
              ? `\nBy ${primaryInsurance.insurancePlan.benefitLastVerifiedBy.fullDisplayName}`
              : ""
          }${
            primaryInsurance.insurancePlan.benefitLastVerifiedAt
              ? `\nOn ${formatISODate(primaryInsurance.insurancePlan.benefitLastVerifiedAt)}`
              : ""
          }`
        : "Benefits unverified"
      : "Benefits unverified";

    return {
      eligibilityTooltip: eligibility,
      benefitsTooltip: benefits,
      networkType: primaryInsurance?.patientInsurance.insuranceNetworkType,
    };
  }, [
    dailyHuddleInsuranceInfo,
    primaryInsurance?.patientInsurance.insuranceNetworkType,
    primaryInsurance?.insurancePlan?.benefitVerifiedStatus,
    primaryInsurance?.insurancePlan?.benefitLastVerifiedBy?.fullDisplayName,
    primaryInsurance?.insurancePlan?.benefitLastVerifiedAt,
    patientInsurance?.eligibilityVerifiedStatus,
    patientInsurance?.eligibilityLastVerifiedBy?.fullDisplayName,
    patientInsurance?.eligibilityLastVerifiedAt,
  ]);

  return (
    <>
      {networkBadgeType === "small" && displayNetworkBadge && (
        <InsuranceSubscriberBadge
          borderColor={borderColor}
          loading={isLoading}
          networkType={networkType}
          size={badgeSize}
        />
      )}
      <InsuranceSubscriberBadge
        loading={isLoading}
        isPrimary={
          dailyHuddleInsuranceInfo
            ? dailyHuddleInsuranceInfo.insuranceType === "PRIMARY_SUBSCRIBER"
            : patientInsurance?.type === "PRIMARY_SUBSCRIBER"
        }
        size={badgeSize}
        borderColor={borderColor}
      />
      {displayDivider && (
        <div className="h-5">
          <VerticalDivider />
        </div>
      )}
      <VerificationBadge
        borderColor={borderColor}
        loading={isLoading}
        tooltip={eligibilityTooltip}
        size={badgeSize}
        type="eligibility"
        verified={
          dailyHuddleInsuranceInfo
            ? dailyHuddleInsuranceInfo.eligibilityVerifiedStatus
              ? isVerifiedInsuranceStatus(dailyHuddleInsuranceInfo.eligibilityVerifiedStatus)
              : false
            : patientInsurance?.eligibilityVerifiedStatus
              ? isVerifiedInsuranceStatus(patientInsurance.eligibilityVerifiedStatus)
              : false
        }
      />
      <VerificationBadge
        borderColor={borderColor}
        loading={isLoading}
        tooltip={benefitsTooltip}
        size={badgeSize}
        type="benefits"
        verified={
          dailyHuddleInsuranceInfo
            ? dailyHuddleInsuranceInfo.benefitVerifiedStatus
              ? isVerifiedInsuranceStatus(dailyHuddleInsuranceInfo.benefitVerifiedStatus)
              : false
            : primaryInsurance?.insurancePlan?.benefitVerifiedStatus
              ? isVerifiedInsuranceStatus(primaryInsurance.insurancePlan.benefitVerifiedStatus)
              : false
        }
      />
      {networkBadgeType === "large" && displayNetworkBadge && (
        <InsuranceSubscriberBadge
          borderColor={borderColor}
          loading={isLoading}
          networkBadgeType="large"
          networkType={networkType}
          size={badgeSize}
        />
      )}
    </>
  );
};
