import { useCallback, useState, ReactNode, useMemo } from "react";
import { toPercent, DecimalPlaces } from "@libs/utils/math";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FlyoverProps } from "components/UI/Flyover";
import { DirtyFlyover } from "components/UI/DirtyFlyover";

type Panel = {
  id: string;
  isDirty?: boolean;
};

const FlyoverReelPanels = <P extends Panel>({
  panels,
  onPanelSlideEnd,
  children,
}: {
  panels: { index: number; list: P[] };
  onPanelSlideEnd: Func;
  children: (panel: P) => ReactNode;
}) => {
  const styles = useMemo(
    () => ({
      container: {
        width: `${toPercent(panels.list.length, DecimalPlaces.integer)}%`,
        transform: `translateX(${
          panels.index === 0 ? 0 : toPercent((-1 * panels.index) / panels.list.length, DecimalPlaces.max)
        }%)`,
      },
      panel: {
        width: `${toPercent(1 / panels.list.length, DecimalPlaces.max)}%`,
      },
    }),
    [panels.index, panels.list.length]
  );
  const isTransitioning = useBoolean(true);

  return (
    <div
      style={styles.container}
      className={cx(
        "h-full flex items-start flex-nowrap",
        isTransitioning.isOn && "transition-transform will-change-transform"
      )}
      onTransitionEnd={(e) => {
        if (e.currentTarget === e.target && e.propertyName === "transform") {
          isTransitioning.off();
          onPanelSlideEnd();
          setTimeout(() => {
            // Hacky patch -- disables transition being triggered multiple times and glitching, once for goBack (editing index) and one for popPanel (editing length)
            isTransitioning.on();
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          }, 50);
        }
      }}
    >
      {panels.list.map((panel, index) => (
        <div
          key={`${panel.id}-${index}`}
          style={styles.panel}
          className="flex-none h-full flex flex-col relative"
        >
          {children(panel)}
        </div>
      ))}
    </div>
  );
};

type FlyoverReelFlyoverProps = Pick<FlyoverProps, "onClose" | "overlay" | "size" | "dataTestId">;

export const FlyoverReel = <P extends Panel>({
  initialPanel,
  children,
  ...flyoverProps
}: {
  initialPanel: P;
  children: (props: {
    panel: P;
    markDirty: Func;
    handleBack: Func;
    goBack: Func;
    handleClose: Func;
    pushPanel: (panel: P) => void;
    index: number;
    setPanels: React.Dispatch<
      React.SetStateAction<{
        list: P[];
        index: number;
      }>
    >;
  }) => ReactNode;
} & FlyoverReelFlyoverProps) => {
  const [panels, setPanels] = useState({
    list: [initialPanel],
    index: 0,
  });

  const isDirty = panels.list.some((panel) => panel.isDirty);

  const pushPanel = useCallback((panel: P) => {
    setPanels((last) => ({ list: [...last.list, panel], index: last.index + 1 }));
  }, []);

  const goBack = useCallback(() => {
    setPanels((last) => ({ ...last, index: last.index - 1 }));
  }, []);

  const markDirty = useCallback(() => {
    setPanels((last) => ({
      ...last,
      list: last.list.map((panel, panelIndex) =>
        panelIndex === last.index ? { ...panel, isDirty: true } : panel
      ),
    }));
  }, [setPanels]);

  const popPanel = useCallback(() => {
    setPanels((last) => {
      const movedBackwards = last.index < last.list.length - 1;

      if (movedBackwards) {
        return {
          list: last.list.slice(0, last.index + 1),
          index: last.index,
        };
      }

      return last;
    });
  }, []);

  return (
    <DirtyFlyover isDirty={isDirty} {...flyoverProps}>
      {({ confirm, onRequestClose }) => {
        const handleBack = () => {
          if (isDirty) {
            confirm({ onConfirm: goBack });
          } else {
            goBack();
          }
        };

        return (
          <FlyoverReelPanels panels={panels} onPanelSlideEnd={popPanel}>
            {(panel) =>
              children({
                panel,
                pushPanel,
                markDirty,
                handleBack,
                goBack,
                handleClose: onRequestClose,
                setPanels,
                index: panels.index,
              })
            }
          </FlyoverReelPanels>
        );
      }}
    </DirtyFlyover>
  );
};
