import { PropsWithChildren, useCallback } from "react";
import { cx } from "@libs/utils/cx";
import { UseBooleanResult, useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";

interface Props {
  display: UseBooleanResult;
  onClose: Func;
  onHover?: Func;
}

export const Popover: React.FC<PropsWithChildren & Props> = ({ children, display, onClose, onHover }) => {
  const hover = useBoolean(false);

  const handleHover = useCallback(() => {
    hover.on();
    onHover?.();
  }, [hover, onHover]);

  const handleClose = useCallback(() => {
    display.off();
    hover.off();
    onClose();
  }, [display, hover, onClose]);

  return (
    <div className={cx("justify-end transition-opacity duration-1000", display.isOn ? "flex" : "hidden")}>
      <div className="w-fit">
        <div
          className={`
            relative
            rounded
            bg-white
            border
            border-greyLighter
            shadow-[0px_2px_2px_0px_rgba(0,0,0,0.1)]
          `}
          onMouseEnter={handleHover}
          onMouseLeave={hover.off}
        >
          <button
            className={cx(
              hover.isOn
                ? `
            absolute
            -top-2.5
            -left-2.5
            flex
            items-center
            justify-center
            w-8
            h-8
            bg-white
            rounded-full
            border
            border-greyLighter
            shadow-main
            z-10
          `
                : "hidden"
            )}
            onClick={handleClose}
            type="button"
          >
            <Icon
              SvgIcon={CloseIcon}
              size="lg"
              theme="info"
              tooltip={{
                theme: "SMALL",
                content: "Dismiss",
              }}
            />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};
