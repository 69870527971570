import { FC, useMemo } from "react";

import { PracticeInfoVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { cx } from "@libs/utils/cx";

import { FormFieldAutoComplete } from "components/UI/FormFieldAutoComplete";

import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";

export interface SupportPracticeSelectProps {
  label?: string;
  listPracticesQuery: UseInfiniteApiQueryResult<PracticeInfoVO[]>;
  selectedPracticeId?: number;
  onDebouncedSearchChange: (searchValue: string) => void;
  onSearchChange: (searchValue: string) => void;
  onSelectChange: (practiceId?: number) => void;
  error?: string;
}

export const SupportPracticeSelect: FC<SupportPracticeSelectProps> = ({
  label,
  listPracticesQuery,
  selectedPracticeId,
  onDebouncedSearchChange,
  onSearchChange,
  onSelectChange,
  error,
}) => {
  const { practiceId: currentPracticeId } = useAccount();
  const practices = useFlattenPages(listPracticesQuery.data);

  const practiceComponents = useInfiniteScrollingSelectComponents<
    SelectOption<number> & Pick<PracticeInfoVO, "ownerName">,
    false,
    number
  >({
    infiniteQuery: listPracticesQuery,
  });

  const practiceOptions = useMemo(
    () =>
      practices?.map((practice) => ({
        value: practice.id,
        label: `${practice.id} - ${practice.name}`,
        ownerName: practice.ownerName,
        isDisabled: practice.id === currentPracticeId,
      })) ?? [],
    [practices, currentPracticeId]
  );

  return (
    <FormFieldAutoComplete
      aria-label="Practice"
      className="w-full"
      label={label}
      placeholder="Search by practice name..."
      components={practiceComponents}
      options={practiceOptions}
      formatOptionLabel={({ label: optionLabel, ownerName, isDisabled }, { context }) =>
        context === "menu" ? (
          <div className={cx("flex flex-col", isDisabled && "opacity-60 cursor-default")}>
            <span className="text-xs">{optionLabel}</span>
            {ownerName ? <span className="text-xxs text-gray-500">{ownerName}</span> : null}
          </div>
        ) : (
          optionLabel
        )
      }
      value={selectedPracticeId}
      onSearch={onDebouncedSearchChange}
      onInputChange={onSearchChange}
      onChange={(selectedOption) => onSelectChange(selectedOption?.value)}
      error={error}
      isLoading={listPracticesQuery.isLoading}
      isClearable={false}
    />
  );
};
