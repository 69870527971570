import { toast } from "react-toastify";
import { captureException as sentryCaptureException } from "@sentry/react";
import { handleFileDropZoneRejection, isFileUploadRejection } from "@libs/utils/isFileUploadRejection";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { handleDocumentUploadError, isDocumentUploadError } from "@libs/utils/isDocumentUploadError";
import { handleErrorResponse, isErrorResponse } from "@libs/utils/isErrorResponse";
import { handleResponseParseError, isResponseParseError } from "@libs/utils/isResponseParseError";
import { handleCodeError, isCodeError } from "@libs/utils/isCodeError";
import { handleTwainError, isTwainError } from "components/ImageCapturing/twainErrors";
import { isHubErrorResponse } from "api/imaging/imaging-hub";

export const CANNED_ERROR_MESSAGE = "Something went wrong, please try again.";

export const handleError = (error: unknown) => {
  if (isHttpResponseError(error)) {
    const errors = handleErrorResponse(error.error, error.url);

    errors.forEach((msg) => toast.error(msg));
  } else if (isFileUploadRejection(error)) {
    const errors = handleFileDropZoneRejection(error);

    errors.forEach((msg) => toast.error(msg));
  } else if (isTwainError(error)) {
    handleTwainError(error);
  } else if (isResponseParseError(error)) {
    toast.error(CANNED_ERROR_MESSAGE);
    handleResponseParseError(error);
  } else if (isDocumentUploadError(error)) {
    toast.error(error.message);
    handleDocumentUploadError(error);
  } else if (isErrorResponse(error)) {
    const errors = handleErrorResponse(error);

    errors.forEach((msg) => toast.error(msg));
  } else if (isCodeError(error)) {
    toast.error(CANNED_ERROR_MESSAGE);
    handleCodeError(error);
  } else if (isHubErrorResponse(error)) {
    error.errors.forEach((e) => toast.error(e.message));
  } else {
    toast.error(CANNED_ERROR_MESSAGE);
    sentryCaptureException(error);
  }
};
