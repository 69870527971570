import {
  ContactVO,
  FamilyMemberResponse,
  FamilyMemberVO,
  LinkedFamilyMemberVO,
  PatientVO,
} from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";

export const genderInitial: Record<NonNullable<PatientVO["personalDetails"]["gender"]>, string> = {
  MALE: "M",
  FEMALE: "F",
  UNKNOWN: "U",
  OTHER: "O",
};

export const sortFamilyMembersByAge = (a: FamilyMemberVO, b: FamilyMemberVO) => {
  return b.age - a.age;
};

export const sortByName = (
  a: { name: { fullDisplayName: string } },
  b: { name: { fullDisplayName: string } }
) => {
  return a.name.fullDisplayName === b.name.fullDisplayName
    ? 1
    : a.name.fullDisplayName > b.name.fullDisplayName
      ? 1
      : -1;
};

export const abbreviateGender = (gender: FamilyMemberVO["gender"] | undefined) => {
  return gender?.charAt(0) ?? "";
};

export const getRelationLabel = (relation: ContactVO["relation"], patientFirstName: string) => {
  return relation
    ? relation === "SELF"
      ? "This Patient"
      : `${patientFirstName}'s ${relation === "OTHER" ? "Family Member" : sentenceCaseConstant(relation)}`
    : undefined;
};

const getUnlinkedRelation = (linkedFamilyMember: LinkedFamilyMemberVO) => {
  const relation = linkedFamilyMember.relation;

  return `${linkedFamilyMember.name.firstName}'s ${
    relation === "PARENT"
      ? "Child"
      : relation === "GRANDPARENT"
        ? "Grandchild"
        : relation === "GRANDCHILD"
          ? "Grandparent"
          : relation === "CHILD"
            ? "Parent"
            : relation === "SPOUSE"
              ? "Spouse"
              : relation === "SIBLING"
                ? "Sibling"
                : "Other"
  }`;
};

export const getFamilyMemberRelationLabel = (familyMember: FamilyMemberVO, patientFirstName: string) => {
  return familyMember.relation
    ? getRelationLabel(familyMember.relation, patientFirstName)
    : // This means that they are an unlinked family member and we want to use
      // one of their linked member's information
      familyMember.linkedFamilyMembers.length
      ? getUnlinkedRelation(familyMember.linkedFamilyMembers[0])
      : "";
};

export const getAllPrimaryInsurances = (familyMembers: FamilyMemberResponse) => {
  return familyMembers.linkedFamilyMembers
    .flatMap((member) => member.insurances)
    .filter((insurance) => insurance.type === "PRIMARY_SUBSCRIBER");
};
