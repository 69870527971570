/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, forwardRef, useCallback, useEffect, useRef } from "react";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { Button } from "@libs/components/UI/Button";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { Banner } from "@libs/components/UI/Banner";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { Form } from "@libs/components/UI/Form";
import { LoadingOverlayV2 } from "components/UI/LoadingOverlay";
import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";

export type Pin = [string, string, string, string];

const FORM_ID = "pin-form";

export const INITIAL_PIN: Pin = ["", "", "", ""];

/**
 * A modal component that prompts the user to enter a PIN. The PIN is used to
 * authenticate the user and grant access to protected resources. The component
 * displays a PIN form and a "Cancel" button. When the user enters a PIN and
 * submits the form, the component sends a request to the server to authenticate
 * the PIN. If the PIN is valid, the `onPinSuccess` callback is called. If the
 * PIN is invalid, the component displays an error message and allows the user
 * to enter another PIN. If the user cancels the PIN prompt, the `onClose`
 * callback is called.
 *
 * Once the user's PIN is validated by the server, subsequent calls to
 * pin-protected endpoints will be granted access to the protected resources for
 * a period of time determined by the server. After the period of time expires,
 * the user may be prompted to enter their PIN again.
 *
 * @param onPinSuccess A callback function to call when the user enters a valid
 * PIN.
 * @param onClose A callback function to call when the user cancels the PIN
 * prompt.
 * @returns A modal component that prompts the user to enter a PIN.
 */
export const PinModal: FC<{
  onClose: Func;
  onSubmit: (pin: string) => void;
  error: unknown;
  isVerifyingPin: boolean;
  pin: Pin;
  onChangePin: (pin: Pin) => void;
}> = ({ onSubmit, onClose, error, isVerifyingPin, pin, onChangePin }) => {
  const submitPin = useCallback(
    (pinToSubmit: string) => {
      if (pinToSubmit.length !== PIN_LENGTH) {
        return;
      }

      onSubmit(pinToSubmit);
    },
    [onSubmit]
  );

  const hasPinError = hasFailedPin(error);

  return (
    <Modal size="2xs" onClose={onClose}>
      <LoadingOverlayV2 isLoading={isVerifyingPin} centerVertically>
        <ModalContent className="flex flex-col items-center px-10 py-8 gap-y-6">
          {hasPinError && <FailedPinBanner />}
          <PinHeader />
          <PinForm
            pin={pin}
            resetFocus={Boolean(error)}
            onPinChange={(newPin, updatedIndex) => {
              onChangePin(newPin);

              // If the user has entered the last digit of the PIN, submit the PIN
              if (updatedIndex === PIN_LENGTH - 1) {
                submitPin(newPin.join(""));
              }
            }}
            onSubmit={() => submitPin(pin.join(""))}
          />
        </ModalContent>
        <ModalFooter actions>
          <CancelButton onClick={onClose} />
          <ConfirmButton />
        </ModalFooter>
      </LoadingOverlayV2>
    </Modal>
  );
};

const PinHeader: FC = () => {
  return (
    <div className="flex flex-col items-center gap-y-2">
      <LockIcon className="w-5 h-5" />
      <p className="text-xs">Enter last 4 digits of your SSN</p>
    </div>
  );
};

const PIN_LENGTH = 4;

type PinFields = [
  HTMLInputElement | null,
  HTMLInputElement | null,
  HTMLInputElement | null,
  HTMLInputElement | null,
];

const PinForm: FC<{
  pin: Pin;
  resetFocus: boolean;
  onPinChange: (pin: Pin, updatedIndex: number) => void;
  onSubmit: Func;
}> = ({ pin, onPinChange, onSubmit, resetFocus }) => {
  const pinFields = useRef<PinFields>([null, null, null, null]);
  const handlePinChange = (index: number, value: string) => {
    const newPin: Pin = [...pin];
    const previousValue = newPin[index];

    newPin[index] = value;

    // If the user deleted the value and there were no previous values on that
    // field (blank), clear the previous field.
    if (!value && !previousValue && index > 0) {
      newPin[index - 1] = "";
    }

    onPinChange(newPin, index);

    // Manage focus
    if (value) {
      // Focus next field if user entered a value
      focusNextField(index);
    } else if (!value && !previousValue) {
      // Focus previous field if user deleted a value and there were no
      // previous values.
      focusPreviousField(index);
    }
  };

  const focusPreviousField = (index: number) => {
    if (index > 0) {
      pinFields.current[index - 1]?.focus();
    }
  };

  const focusNextField = (index: number) => {
    if (index < pinFields.current.length - 1) {
      pinFields.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    if (resetFocus) {
      pinFields.current[0]?.focus();
    }
  }, [resetFocus]);

  return (
    <Form
      id={FORM_ID}
      className="flex flex-col gap-y-4"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="flex items-center gap-x-2">
        <PinField
          ref={(el) => (pinFields.current[0] = el)}
          value={pin[0]}
          onChange={(value) => handlePinChange(0, value)}
          focus
        />
        <PinField
          ref={(el) => (pinFields.current[1] = el)}
          value={pin[1]}
          onChange={(value) => handlePinChange(1, value)}
        />
        <PinField
          ref={(el) => (pinFields.current[2] = el)}
          value={pin[2]}
          onChange={(value) => handlePinChange(2, value)}
        />
        <PinField
          ref={(el) => (pinFields.current[3] = el)}
          value={pin[3]}
          onChange={(value) => handlePinChange(3, value)}
        />
      </div>
    </Form>
  );
};

interface PinFieldProps {
  focus?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const PinField = forwardRef<HTMLInputElement, PinFieldProps>(({ focus, value, onChange }, ref) => {
  return (
    <FormFieldNumericInput
      autoFocus={focus}
      ref={ref}
      autoComplete="off"
      layout="tableValue"
      className="w-8 h-8"
      inputClassName="text-center"
      maxLength={1}
      value={value}
      onFocus={(e) => {
        e.target.select();
      }}
      onKeyDown={(event) => {
        if (/\d/.test(event.key) && !event.metaKey && !event.ctrlKey) {
          onChange(event.key);
          event.preventDefault();
        } else if (event.code === "Backspace") {
          onChange("");
          event.preventDefault();
        }
      }}
    />
  );
});

const FailedPinBanner: FC = () => (
  <Banner className="text-xs w-full rounded" theme="error">
    The pin you entered is invalid. Please try again.
  </Banner>
);

const CancelButton: FC<{ onClick: Func }> = ({ onClick }) => (
  <Button theme="secondary" onClick={onClick} className="min-w-button">
    Cancel
  </Button>
);

const ConfirmButton: FC = () => (
  <Button type="submit" className="min-w-button" form={FORM_ID}>
    Confirm
  </Button>
);
