import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { ImageCaptureResult, Api as ImagingApi, MountLayoutType } from "./imaging-api";

export const IMAGING_SERVICE_DOWNLOAD_URL =
  "https://imaging-service-assets.archy.com/ArchyImagingService-win-Setup.exe";

type CaptureApp = "Portal" | "Offline";
export interface CaptureInfo {
  /** @format int32 */
  patientId?: number | null;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @format date-time */
  dateOfBirth: string;
  mountLayout: MountLayoutType;
  /** @format int32 */
  mountId?: number | null;
  captureApp?: CaptureApp;
}

export type DateFilterType = "Before" | "After" | "On";
export type DriverType = "Twain" | "Schick" | "Wia";
export type CaptureStatus = {
  driverType: DriverType;
  deviceName: string;
  message?: string;

  // Use 'Finished' event to know when UI is closed
  // call StopCapturing when component unmounts
  state: "Ready" | "Capturing" | "Captured" | "Error" | "Finished";
};

export interface DeviceInfo {
  name: string;
  deviceId?: string | null;
  driverType: DriverType;
}

export interface Error {
  message?: string;
}

export interface ErrorHubResponse {
  errors: Error[];
}

export const isHubErrorResponse = (response: unknown): response is ErrorHubResponse => {
  return Boolean(
    response &&
      typeof response === "object" &&
      "errors" in response &&
      "message" in (response as ErrorHubResponse).errors[0]
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SuccessHubResponse {}

export interface AcquireImageResult {
  data: {
    imageCount: number;
  };
}

export interface AcquireImageConfig {
  showUI?: boolean;
  useAutoDocumentFeeder?: boolean;
  useDuplex?: boolean;
  autoDiscardBlankPages?: boolean;
  pixelType?: "RGB" | "Gray" | "BlackWhite";
  resolution?: number;
}

const THIRTY_MINUTES = 1_800_000;

export const BASE_AIS_URL = "http://127.0.0.1:33774";

export class ImagingHub {
  private _connection: HubConnection;
  private _connectPromise?: Promise<void>;
  private _reconnectPromiseResolver: Func | undefined;
  private _reconnectPromiseRejecter: Func | undefined;
  private _onCloseCallbacks: Array<Func> = [];

  constructor() {
    this._connection = new HubConnectionBuilder()
      .withUrl(`${BASE_AIS_URL}/imaging`)
      .configureLogging(LogLevel.Information)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect()
      .build();

    this._connection.serverTimeoutInMilliseconds = THIRTY_MINUTES;

    this._connection.onclose(() => {
      this._onCloseCallbacks.forEach((callback) => callback());

      this._connectPromise = undefined;

      this._reconnectPromiseRejecter?.();
      this._reconnectPromiseRejecter = undefined;

      console.log("Imaging hub disconnected");
    });

    this._connection.onreconnecting(() => {
      console.log("Imaging hub reconnecting");
      this._onCloseCallbacks.forEach((callback) => callback());
      this._connectPromise = new Promise<void>((resolve, reject) => {
        this._reconnectPromiseResolver = resolve;
        this._reconnectPromiseRejecter = reject;
      }).catch(() => {}); // Ensure the promise is caught. We don't care about the rejection.
    });

    this._connection.onreconnected(() => {
      // TODO - if in capture session try to re-establish it
      // When i call start function, store the args passed, on reconnect, redo it with "StartCapturing".

      // Call closeConnection when done uploading to mount
      this._reconnectPromiseResolver?.();
      this._reconnectPromiseResolver = undefined;
      this._reconnectPromiseRejecter = undefined;
      console.log("Imaging hub reconnected");
    });
  }

  public async openConnection(): Promise<void> {
    await this._connect();
  }

  public async closeConnection(): Promise<void> {
    try {
      await this._connection.stop();
    } catch (err) {
      // We don't wan't connection closing to kill the app.
      console.error("Failed to close ImagingHub connection", err);
    }
  }

  public isConnecting(): boolean {
    return this._connection.state === HubConnectionState.Connecting;
  }

  public isConnected(): boolean {
    return this._connection.state === HubConnectionState.Connected;
  }

  public async getDevices(): Promise<{ devices: DeviceInfo[] }> {
    await this._ensureConnection();

    return this._connection.invoke("GetDevices");
  }

  public async stopCapturing(): Promise<ErrorHubResponse | SuccessHubResponse> {
    await this._ensureConnection();

    return this._connection.invoke("StopCapturing");
  }

  public async startCapturing({
    driverType,
    deviceName,
    captureInfo,
  }: {
    driverType: DriverType;
    deviceName: string;
    captureInfo?: CaptureInfo;
  }): Promise<ErrorHubResponse | SuccessHubResponse> {
    await this._ensureConnection();

    return this._connection.invoke(
      "StartCapturing",
      driverType,
      deviceName,
      captureInfo
        ? {
            ...captureInfo,
            captureApp: "Portal",
          }
        : undefined
    );
  }

  public async acquireImage({
    driverType,
    deviceName,
    config,
    abortSignal,
  }: {
    driverType: DriverType;
    deviceName: string;
    config?: AcquireImageConfig;
    abortSignal: AbortSignal;
  }): Promise<ErrorHubResponse | AcquireImageResult> {
    await this._ensureConnection();

    return this._connection.invoke("AcquireImage", driverType, deviceName, config, abortSignal);
  }

  public async clearImageBuffer(): Promise<SuccessHubResponse> {
    await this._ensureConnection();

    return this._connection.invoke("ClearImageBuffer");
  }

  private _connect(): Promise<void> {
    if (this.isConnected()) {
      return Promise.resolve();
    }

    if (!this._connectPromise) {
      this._connectPromise = this._connection.start().finally(() => {
        this._connectPromise = undefined;
      });
    }

    return this._connectPromise;
  }

  private async _ensureConnection() {
    if (this._connection.state !== HubConnectionState.Connected) {
      try {
        await this._connect();
      } catch (err) {
        console.error(err);
      }
    }
  }

  public onImageResult(callback: (image: ImageCaptureResult) => void) {
    this._connection.on("OnImageResult", callback);

    return () => {
      return this._connection.off("OnImageResult");
    };
  }

  public onCaptureStatus(callback: (captureStatus: CaptureStatus) => void) {
    this._connection.on("OnCaptureStatus", callback);

    return () => {
      return this._connection.off("OnCaptureStatus");
    };
  }

  public onClose(callback: () => void) {
    this._onCloseCallbacks.push(callback);

    return () => {
      this._onCloseCallbacks = this._onCloseCallbacks.filter((existingCb) => existingCb !== callback);
    };
  }
}

export const ImagingHttpApi = new ImagingApi({
  baseUrl: BASE_AIS_URL,
});
