import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache } from "@libs/utils/queryCache";

export const getClinicianPrescriptionStatus = makeQuery({
  queryKey: ["practices", "getClinicianPrescriptionStatus"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
  queryOptions: cacheForSession,
});

export const getDosespotNotificationCount = makeQuery({
  queryKey: ["practices", "getDosespotNotificationCount"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
  queryOptions: fiveMinuteCache,
});

export const getPracticeDosespotEntities = makeQuery({
  queryKey: ["practices", "getPracticeDosespotEntities"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});
