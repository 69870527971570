import { FC, FormEvent, useMemo } from "react";
import { Auth } from "aws-amplify";
import { captureException } from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { eq, password, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { noop } from "@libs/utils/noop";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { FormFieldPassword } from "components/UI/FormFieldPassword";

import { FormSection } from "components/UI/FormSection";
import { resetEmployeeMfa } from "api/employee/mutations";
import { handleError } from "utils/handleError";
import { getUserMfa } from "api/user/queries";
import { paths } from "utils/routing/paths";
import { getCognitoErrorMessage } from "utils/auth";

const getSchema = (newPasswordValue: string) => ({
  currentPassword: [{ $v: required, $error: "Please enter your current password." }],
  newPassword: [
    { $v: required, $error: "A new password is required." },
    {
      $v: password,
      $error:
        "A valid password has 8 or more characters and least one uppercase letter, lowercase letter, number and special character: !#$%&*?@.",
    },
  ],
  confirmPassword: [
    { $v: required, $error: "Please confirm your new password." },
    { $v: eq(newPasswordValue), $error: "This does not match your new password." },
  ],
});

interface Props {
  onRequestClose: Func;
  onSuccess: Func;
}

const InputRelatedErrors = new Set(["NotAuthorizedException", "InvalidPasswordException"]);

// eslint-disable-next-line complexity
export const SecuritySettingsModal: FC<Props> = ({ onRequestClose, onSuccess }) => {
  const { id, practiceId } = useAccount();
  const resettingMfaLoading = useBoolean(false);
  const resetMfaModal = useBoolean(false);
  const navigate = useNavigate();

  const [{ currentPassword, newPassword, confirmPassword, serverErrorCode, isLoading }, setState] =
    useObjectState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      serverErrorCode: "" as CognitoErrorCode | "",
      isLoading: false,
    });

  const schema = useMemo(() => getSchema(newPassword), [newPassword]);

  const { result: validation, validate } = useValidation(
    { currentPassword, newPassword, confirmPassword },
    schema
  );
  const [resetEmployeeMfaMutation] = useApiMutations([resetEmployeeMfa]);
  const [userMfaQuery] = useApiQueries([getUserMfa({ args: {} })]);
  const handleResetMFA = async () => {
    resetMfaModal.off();
    resettingMfaLoading.on();

    try {
      await resetEmployeeMfaMutation.mutateAsync({
        practiceId,
        employeeId: id,
      });
      navigate(paths.signOut());
    } catch (e) {
      handleError(e);
      resettingMfaLoading.off();
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validate().$isValid) {
      return;
    }

    setState({ serverErrorCode: "", isLoading: true });

    const user: unknown = await Auth.currentAuthenticatedUser();

    if (user) {
      try {
        await Auth.changePassword(user, currentPassword, newPassword);
        onSuccess();
      } catch (err) {
        captureException(err);

        const code = err ? (err as { code: CognitoErrorCode }).code : ("Unknown" as CognitoErrorCode);

        setState({
          serverErrorCode: code,
          isLoading: false,
        });
      }
    }
  };

  return resettingMfaLoading.isOn ? (
    <Modal size="xs" title="Security Settings" onClose={noop}>
      <div className="flex flex-col justify-center items-center w-full h-80">
        <Spinner variant="primary" animation="border" />
      </div>
    </Modal>
  ) : resetMfaModal.isOn ? (
    <AlertModal
      onConfirm={handleResetMFA}
      primaryText="Multi Factor Authentication"
      secondaryText="Are you sure you want to reset MFA? You’ll have to set MFA upon re-login."
      confirmText="Yes"
      onCancel={resetMfaModal.off}
    />
  ) : (
    <Modal size="xs" title="Security Settings" onClose={onRequestClose}>
      <ModalForm fieldLayout="labelOut" onSubmit={handleSubmit}>
        <ModalContent className="max-w-sm">
          <FormSection title="Change Password" className="flex flex-col text-xs gap-4">
            <div>Please enter your current password to set a new one.</div>
            <FormFieldPassword
              required={true}
              error={
                serverErrorCode && serverErrorCode === "NotAuthorizedException"
                  ? getCognitoErrorMessage("NotAuthorizedException")
                  : validation.currentPassword.$error
              }
              label="Current Password"
              placeholder="Enter Password"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setState({ serverErrorCode: "", currentPassword: e.target.value })}
            />

            <div>
              Passwords must be at least 8 characters long and must mix at least 1 uppercase letter, 1
              lowercase letter, 1 number and 1 special character.
            </div>
            <FormFieldPassword
              required={true}
              error={
                serverErrorCode && serverErrorCode === "InvalidPasswordException"
                  ? getCognitoErrorMessage("InvalidPasswordException")
                  : validation.newPassword.$error
              }
              label="New Password"
              placeholder="Enter Password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setState({ serverErrorCode: "", newPassword: e.target.value })}
            />
            <FormFieldPassword
              required={true}
              error={validation.confirmPassword.$error}
              label="Confirm New Password"
              placeholder="Enter Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setState({ serverErrorCode: "", confirmPassword: e.target.value })}
            />
            {serverErrorCode && !InputRelatedErrors.has(serverErrorCode) && (
              <div className="flex items-center text-xs">
                <Icon className="mr-1" SvgIcon={ErrorIcon} theme="error" size="sm" />
                <div className="text-red font-sansSemiBold">{getCognitoErrorMessage(serverErrorCode)}</div>
              </div>
            )}
          </FormSection>
          {userMfaQuery.data?.mfaEnabled && (
            <FormSection title="Reset Multi Factor Authentication" className="flex flex-col gap-4 text-xs">
              <div>
                Upon resetting MFA you&apos;ll be logged out and have to login again using an authenticator
                app.
              </div>

              <AsyncButton
                type="button"
                theme="tertiary"
                className="text-primaryTheme min-w-button w-fit"
                isLoading={resettingMfaLoading.isOn}
                onClick={resetMfaModal.on}
              >
                Reset MFA
              </AsyncButton>
            </FormSection>
          )}
        </ModalContent>
        <ModalFooter>
          <Button type="button" theme="secondary" onClick={onRequestClose}>
            Cancel
          </Button>

          <AsyncButton displayLoadingText isLoading={isLoading} type="submit" theme="primary">
            {isLoading ? "Changing" : "Save"}
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
