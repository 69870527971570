import { AppointmentPushEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { QueryClient } from "@tanstack/react-query";
import { isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";

const isUnscheduledUpdate = ({ state, origState, asap, origAsap, date, origDate }: AppointmentPushEvent) => {
  if (state === "UNSCHEDULED" && origState === "UNSCHEDULED") {
    return true;
  }

  if (asap && isUnscheduledAsapAppt(date) && origAsap && isUnscheduledAsapAppt(origDate)) {
    return true;
  }

  return false;
};

// eslint-disable-next-line complexity
export const handleAppoinmentUpdated = ({
  queryClient,
  practiceId,
  appointmentUpdatedEvent,
}: {
  queryClient: QueryClient;
  practiceId: number;
  appointmentUpdatedEvent: AppointmentPushEvent;
}) => {
  // if the appointment was deleted or created we don't want to invalidate getAppointment
  if (appointmentUpdatedEvent.origState !== undefined && appointmentUpdatedEvent.state !== "_DELETED") {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAppointment"),
      { practiceId, appointmentId: appointmentUpdatedEvent.id },
    ]);
  }

  // updates to hold appointments don't effect appointment cards
  if (!isUnscheduledUpdate(appointmentUpdatedEvent)) {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAppointmentCards"),
      { practiceId, date: appointmentUpdatedEvent.date },
    ]);

    if (appointmentUpdatedEvent.date !== appointmentUpdatedEvent.origDate) {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAppointmentCards"),
        { practiceId, date: appointmentUpdatedEvent.origDate },
      ]);
    }

    // this is a complicated one to determine if it needs decaching. Consider optimizing
    queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
    queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddleOverview"), { practiceId }]);
  }

  // updates to hold appointments and patients requesting appointments don't effect
  // room or provider availability
  if (appointmentUpdatedEvent.state !== "REQUESTED" && !isUnscheduledUpdate(appointmentUpdatedEvent)) {
    // Only called when editing/creating an appointment in certain states so not a big deal to invalidate
    queryClient.invalidateQueries([getQueryKey("practices", "getAvailableRooms"), { practiceId }]);
    queryClient.invalidateQueries([getQueryKey("practices", "getAvailableProviders"), { practiceId }]);
  }

  // only invalidate holds when the update was to a hold
  if (
    appointmentUpdatedEvent.state === "UNSCHEDULED" ||
    appointmentUpdatedEvent.origState === "UNSCHEDULED"
  ) {
    queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentHolds"), { practiceId }]);
  }

  // only update asap appointments if the update was to an asap appointment
  if (appointmentUpdatedEvent.asap || appointmentUpdatedEvent.origAsap) {
    queryClient.invalidateQueries([getQueryKey("practices", "getAsapAppointments"), { practiceId }]);
  }

  // only update appointment requests if the update was to a requested appointment
  if (appointmentUpdatedEvent.state === "REQUESTED" || appointmentUpdatedEvent.origState === "REQUESTED") {
    queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentRequests"), { practiceId }]);
  }

  queryClient.invalidateQueries([
    getQueryKey("practices", "getNextPatientAppointment"),
    { practiceId, patientId: appointmentUpdatedEvent.patientId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("v2", "getPatientNotesV2"),
    { practiceId, patientId: appointmentUpdatedEvent.patientId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientNoteAlerts"),
    { practiceId, patientId: appointmentUpdatedEvent.patientId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientNote"),
    { practiceId, patientId: appointmentUpdatedEvent.patientId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientAppointments"),
    { practiceId, patientId: appointmentUpdatedEvent.patientId },
  ]);
};
