import { FC } from "react";
import { AddressVO } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { createAddressValue } from "api/user/data";
import { useEnvContext } from "contexts/EnvContext";

interface Props {
  address: AddressVO | null;
  edit?: boolean;
  onUpdate: (partial: AddressVO | null) => void;
}

const FormatAddress: FC<{ address: AddressVO }> = ({ address }) => {
  const humanizedAddress = formatAddress(address);

  return (
    <div>
      {humanizedAddress.lineOne}
      <br />
      {humanizedAddress.lineTwo}
    </div>
  );
};

export const AddressFields: FC<Props> = ({ address, onUpdate, edit = true }) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();

  return edit ? (
    <>
      <FormFieldAddressAutocomplete
        apiKey={REACT_APP_GOOGLE_API_KEY}
        className="col-span-2"
        defaultValue={address?.address1}
        label="Address"
        onSelect={(e) =>
          onUpdate(
            e.raw
              ? createAddressValue({ address1: e.address, state: e.state, city: e.locality, zip: e.zip })
              : null
          )
        }
      />
      <FormFieldInput
        className="col-span-2"
        label="Apt, Suite, Bldg, Street"
        onChange={(e) =>
          onUpdate(
            createAddressValue({
              ...address,
              address2: e.target.value,
            })
          )
        }
        type="text"
        value={address?.address2}
      />
      <FormFieldInput
        className="col-span-2"
        label="City"
        onChange={(e) =>
          onUpdate(
            createAddressValue({
              ...address,
              city: e.target.value,
            })
          )
        }
        type="text"
        value={address?.city}
      />
      <FormFieldInput
        label="State"
        onChange={(e) =>
          onUpdate(
            createAddressValue({
              ...address,
              state: e.target.value,
            })
          )
        }
        type="text"
        value={address?.state}
      />
      <FormFieldInput
        label="Zip"
        onChange={(e) =>
          onUpdate(
            createAddressValue({
              ...address,
              zip: e.target.value,
            })
          )
        }
        type="text"
        value={address?.zip}
      />
    </>
  ) : (
    <div className="text-xs col-span-2">
      <div className="pb-1">Address</div>
      <div className="font-sansSemiBold">{address ? <FormatAddress address={address} /> : "-"}</div>
    </div>
  );
};
