import React from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import { cx } from "@libs/utils/cx";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

const DEFAULT_MIN = 1;
const DEFAULT_MAX = 100;

const LEFT_TRACK = 0;
const MIDDLE_TRACK = 1;
const RIGHT_TRACK = 2;

const RIGHT_THUMB = 1;
const LEFT_THUMB = 0;

export type RangeSliderProps = {
  label?: string;
  labelClassName?: string;
  onChange: (range: number[]) => void;
  min?: number;
  max?: number;
  minDistance?: number;
  value?: number[];
};

type RenderTrackRenderer = NonNullable<ReactSliderProps<number[]>["renderTrack"]>;

const renderTrack: RenderTrackRenderer = ({ className, ...props }, state) => (
  <div
    {...props}
    className={cx(
      "top-0 bottom-0",
      className,
      state.index === MIDDLE_TRACK ? "bg-primaryTheme" : "bg-greyLighter",
      state.index === LEFT_TRACK && "rounded-l-xl ml-1",
      state.index === RIGHT_TRACK && "rounded-r-xl"
    )}
  />
);

type RenderThumbRenderer = NonNullable<ReactSliderProps<number[]>["renderThumb"]>;

const renderThumb: RenderThumbRenderer = (props, state) => (
  <div {...props}>
    <div
      className={cx(
        `w-2
         h-5
         absolute
         -top-1.5
         border
         rounded
         border-primaryTheme
         bg-white
         cursor-grab`,
        state.index === RIGHT_THUMB && "-left-2"
      )}
    />
    <div
      className={cx(
        `w-6
         h-6
         bg-greyLightest
         rounded
         absolute
         -bottom-11
         text-xs
         leading-6
         text-greyDark
         text-center`,
        state.index === LEFT_THUMB ? "-left-2" : "-left-4"
      )}
    >
      {state.valueNow}
    </div>
  </div>
);

export const RangeSlider: React.FC<RangeSliderProps> = ({
  label,
  labelClassName,
  onChange,
  min = DEFAULT_MIN,
  max = DEFAULT_MAX,
  minDistance = DEFAULT_MIN,
  value,
}) => {
  return (
    <div className="w-full h-16">
      <FormFieldLabel content={label} className={cx("mb-2", labelClassName)} />
      <div className="text-xs text-greyDark flex justify-between mb-1 pl-1.5">
        <div>{min}</div>
        <div>{max}</div>
      </div>
      <div className="pl-1.5 pr-1.5">
        <ReactSlider
          defaultValue={value}
          min={min}
          max={max}
          minDistance={minDistance}
          onAfterChange={onChange}
          className="w-full h-1.5"
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    </div>
  );
};
