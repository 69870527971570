import { forwardRef } from "react";
import { stripAllButNumbers } from "@libs/utils/inputFormatting";
import {
  FormFieldFormattedInput,
  FormFieldFormattedInputProps,
} from "@libs/components/UI/FormFieldFormattedInput";

export type FormFieldNumericInputProps = Omit<FormFieldFormattedInputProps, "inputMode" | "cleanValue">;

export const FormFieldNumericInput = forwardRef<HTMLInputElement, FormFieldNumericInputProps>(
  (props, ref) => {
    return (
      <FormFieldFormattedInput ref={ref} cleanValue={stripAllButNumbers} inputMode="numeric" {...props} />
    );
  }
);
