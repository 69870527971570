import { FC, useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { SECOND_IN_MS } from "@libs/utils/date";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { IDLE_TIMEOUT } from "config/react";
import { usePracticeActivityStorage } from "storage/activity";
import { useSignoutWithReason } from "hooks/useSignoutWithReason";

const PROMPT_TIMEOUT = 300_000;
const THROTTLE_ACTIVITY = 60_000;

export const SessionIdleTimer: FC = () => {
  const activityStorage = usePracticeActivityStorage();
  const [isIdle, setIsIdle] = useState(false);

  const handleIdlePrompt = useCallback(() => {
    setIsIdle(true);
  }, []);

  const handleAction = useCallback(() => {
    activityStorage.setRecentlyActive();
  }, [activityStorage]);

  const handleActivity = useCallback(() => {
    activityStorage.setRecentlyActive();
    setIsIdle(false);
  }, [activityStorage]);

  const signOutWithReason = useSignoutWithReason();
  const handleIdle = useCallback(() => {
    signOutWithReason();
  }, [signOutWithReason]);

  const idleTimer = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    eventsThrottle: THROTTLE_ACTIVITY,
    crossTab: true,
    syncTimers: SECOND_IN_MS,
    name: "session",
    onActive: handleActivity,
    onAction: handleAction,
    onPrompt: handleIdlePrompt,
    onIdle: handleIdle,
  });

  return isIdle ? (
    <AlertModal
      size="fit"
      onConfirm={() => {
        handleActivity();
        idleTimer.reset();
      }}
      primaryText={<div className="mb-4 w-56">Are you still here?</div>}
      confirmText="Yes"
    />
  ) : null;
};
