import { useState, FC, ChangeEvent, FormEvent } from "react";
import { LastWorktimeVOV2 } from "@libs/api/generated-api";
import { formatAsISOTime, nowInTimezone } from "@libs/utils/date";
import { useValidation } from "@libs/hooks/useValidation";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { FormFieldTextarea } from "@libs/components/UI/FormFieldTextarea";
import { TimeInput } from "components/UI/TimeInput";
import { commentValidation, isoTimeValidation } from "components/EmployeeProfile/Timesheet/utils";

interface Props {
  lastStatus?: LastWorktimeVOV2;
  isSaving: boolean;
  onCancel: Func;
  onSubmit: (formDate: { time: string; comment: string }) => void;
}

export const EditClockInOutTime: FC<Props> = ({ lastStatus, isSaving, onCancel, onSubmit }) => {
  const practice = useCurrentPractice();
  const [time, setTime] = useState(() => formatAsISOTime(nowInTimezone(practice.timezoneId)));
  const [comment, setComment] = useState("");
  const lastAction = lastStatus?.lastAction;

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const formValidation = useValidation(
    { comment, time },
    {
      comment: [commentValidation],
      time: [isoTimeValidation],
    }
  );
  const handleEditClock = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formValidation.validate().$isValid) {
      onSubmit({
        time,
        comment,
      });
    }
  };

  return (
    <ModalForm onSubmit={handleEditClock}>
      <ModalContent padding="lg">
        <TimeInput
          className="mb-5"
          label={lastAction === "CLOCK_IN" ? "Clock out time" : "Clock in time"}
          error={formValidation.result.time.$error}
          value={time}
          onChange={setTime}
        />

        <FormFieldTextarea
          placeholder="Enter comment"
          value={comment}
          onChange={handleCommentChange}
          error={formValidation.result.comment.$error}
        />
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" className="min-w-button" onClick={onCancel}>
          Cancel
        </Button>
        <AsyncButton isLoading={isSaving} theme="primary" type="submit" className="min-w-button">
          Send edit request
        </AsyncButton>
      </ModalFooter>
    </ModalForm>
  );
};
