import { useEffect, useMemo } from "react";
import { noop } from "@libs/utils/noop";
import { WebSocketMessageEmitter } from "api/websocket/webSocketMessage";
import { PushMessageEvent, ServerPushMessages } from "api/websocket/types";

export const useWebSocketMessages = (webSocket: WebSocket | undefined) => {
  const messages = useMemo(() => (webSocket ? new WebSocketMessageEmitter() : undefined), [webSocket]);

  useEffect(() => {
    if (webSocket && messages) {
      const messageHandler = (event: PushMessageEvent) => {
        const pushMessageData = JSON.parse(event.data) as ServerPushMessages;

        const serverMessageEvent = new CustomEvent<ServerPushMessages>("message", {
          detail: pushMessageData,
        });

        messages.dispatchEvent(serverMessageEvent);
      };

      webSocket.addEventListener("message", messageHandler);

      return () => {
        webSocket.removeEventListener("message", messageHandler);
      };
    }

    return noop;
  }, [webSocket, messages]);

  return messages;
};
