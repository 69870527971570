import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PhoneIcon } from "@libs/assets/icons/call.svg";

interface Props {
  onMaximize: Func;
}

export const MinimizedContent: React.FC<Props> = ({ onMaximize }) => {
  return (
    <div className="flex items-center justify-center w-20 h-20">
      <div
        className={`
          flex
          items-center
          justify-center
          w-12
          h-12
          rounded-full
          border
          border-greenDark
        `}
      >
        <div
          className={`
            flex
            items-center
            justify-center
            w-10
            h-10
            bg-greenDark
            rounded-full
          `}
        >
          <ButtonIcon SvgIcon={PhoneIcon} onClick={onMaximize} size="lg" theme="darkBackground" />
        </div>
      </div>
    </div>
  );
};
