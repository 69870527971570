import React from "react";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { NO_SELECTED_NUMERIC_ID } from "components/Patient/hooks";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { CarrierOption } from "components/Insurance/CarrierSelector/CarrierOption";

export type CarrierOption = {
  label: string;
  value: number;
  carrier: InsuranceCarrierVO;
};
export const handleFilterOption = (option: FilterOptionOption<CarrierOption>, searchText: string) => {
  const searchTextLowered = searchText.toLowerCase().trim();

  return (
    option.data.carrier.payerId.toLowerCase().includes(searchTextLowered) ||
    option.label.toLowerCase().includes(searchTextLowered)
  );
};

type Props = Pick<FormFieldProps, "required" | "edit" | "error" | "layout"> & {
  isClearable?: boolean;
  disabled?: boolean;
  onUpdate: (params: { carrierId: number }) => void;
  value: number;
  insuranceCarriers: InsuranceCarrierVO[] | undefined;
  display?: SelectDisplayType;
  placeholder?: string;
  formatOptionLabel?: (data: CarrierOption) => React.ReactNode;
  autoEligibilityCarrierIds?: Set<number> | undefined;
  layout?: FormFieldProps["layout"];
};
export const CarrierSelector: React.FC<Props> = ({
  insuranceCarriers,
  onUpdate,
  value,
  placeholder = "Select",
  formatOptionLabel,
  autoEligibilityCarrierIds,
  layout,
  display,
  ...rest
}) => {
  const carrierOptions: CarrierOption[] = React.useMemo(() => {
    return (
      insuranceCarriers?.map((carrier) => ({
        value: carrier.id,
        label: carrier.name,
        carrier,
      })) || []
    );
  }, [insuranceCarriers]);

  return (
    <FormFieldSelect
      label="Insurance Carrier"
      layout={layout}
      display={display}
      placeholder={placeholder}
      options={carrierOptions}
      {...rest}
      onItemChanged={(selectedCarrierId) =>
        onUpdate({
          carrierId: selectedCarrierId ?? NO_SELECTED_NUMERIC_ID,
        })
      }
      filterOption={handleFilterOption}
      formatOptionLabel={
        formatOptionLabel ??
        (({ carrier }, ctx) =>
          ctx.context === "menu" ? (
            <CarrierOption autoEligibilityCarrierIds={autoEligibilityCarrierIds} carrier={carrier} />
          ) : (
            carrier.name
          ))
      }
      value={value}
    />
  );
};
