import { QueryClient } from "@tanstack/react-query";
import { PaymentUpdatedEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handlePaymentUpdated = ({
  data,
  queryClient,
  practiceId,
}: {
  data: PaymentUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPayment"),
    { practiceId, paymentUuid: data.paymentUuid },
  ]);
  queryClient.invalidateQueries([getQueryKey("v2", "listPatientPaymentSummaries"), { practiceId }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getMultiInvoicePayment"),
    { practiceId, multiInvoicePaymentUuid: data.paymentUuid },
  ]);
};
