import { isString } from "@libs/utils/types";
import { isoTime, required } from "@libs/utils/validators";
import { HOUR_IN_MINUTES, isISOTime, isLeftISOTimeAfterRightISOTime, TIME_PLACES } from "@libs/utils/date";
import { DecimalPlaces, round } from "@libs/utils/math";

export const formatHours = (hours: number, asDecimal?: boolean) =>
  asDecimal ? formatHoursAsDecimal(hours) : formatHoursAsClockTime(hours);

const formatHoursAsClockTime = (hours: number) => {
  const hoursCount = Math.floor(hours);
  const minutesCount = Math.round((hours % 1) * HOUR_IN_MINUTES);
  const formattedHours = `${hoursCount}`.padStart(TIME_PLACES, "0");
  const formattedMinutes = `${minutesCount}`.padStart(TIME_PLACES, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

const formatHoursAsDecimal = (hours: number) => {
  const hoursCount = round(hours, DecimalPlaces.hundredth);

  return hoursCount.toFixed(DecimalPlaces.hundredth);
};

export const isoTimeValidation = { $v: isoTime, $error: "Please enter a time." };
export const commentValidation = { $v: required, $error: "Please add a comment." };
export const getClockInValidation = (clockOutTime?: string) => ({
  $v: (val: unknown) =>
    clockOutTime === undefined
      ? true
      : isISOTime(clockOutTime)
        ? isString(val) && isLeftISOTimeAfterRightISOTime(clockOutTime, val)
        : true,
  $error: "Clock in time cannot be after the clock out time.",
});
export const getClockOutValidation = (clockInTime: string) => ({
  $v: (val: unknown) =>
    isISOTime(clockInTime) ? isString(val) && isLeftISOTimeAfterRightISOTime(val, clockInTime) : true,
  $error: "Clock out time cannot be before the clock in time.",
});
