import { TagVO } from "@libs/api/generated-api";
import { filterMap, uniqueBy } from "@libs/utils/array";
import { union } from "@libs/utils/union";

const ICONS_ORDERED_BY_SLUG = union<NonNullable<TagVO["slug"]>>().createUniqueList([
  "CLINICAL",
  "NEW_PATIENT",
  "MEDICAL_CONDITION",
  "ALLERGY",
  "PREGNANT",
  "FAMILY_MEMBER_SAME_DAY",
  "SELF_SCHEDULED_APPOINTMENT",
  "PRE_MEDICATION_REQUIRED",
  "XRAY",
  "BIRTHDAY",
  "REPEAT_NO_SHOW",
  "OVERDUE_INVOICE",
  "RECALL_OVERDUE",
]);

const separateTags = (tags: TagVO[]) => {
  const allTags = uniqueBy(tags, (tag) => tag.id);

  const custom: TagVO[] = [];
  const locked: TagVO[] = [];

  for (const tag of allTags) {
    if (tag.slug) {
      locked.push(tag);
    } else {
      custom.push(tag);
    }
  }

  custom.sort((tagA, tagB) => tagA.name.localeCompare(tagB.name));

  return {
    custom,
    locked,
  };
};

/**
 * Sorts an array of tags where the custom tags are sorted alphabetically and the locked tags
 * are sorted according to the custom order array.
 *
 * @param {TagsVO[]} tags - The appointment tags.
 * @param {string[]} order - The order in which to sort the locked tags.
 * @returns {(TagVO | undefined)[]} Returns a sorted array of tags or undefined placeholders.
 * We want the undefined placeholders for the layout.
 */
export const sortAppointmentTagsWithOrder = (tags: TagVO[], order: string[]) => {
  const { custom, locked } = separateTags(tags);

  const ordered = [...order.map((val) => locked.find((tag) => tag.slug === val)), ...custom];

  return ordered;
};

/**
 * Sorts an array of tags where the custom tags are sorted alphabetically and the locked tags
 * are sorted according to the default order array.

 * @param {TagsVO[]} tags - The appointment tags.
 * @returns {TagVO[]} Returns a sorted array of tags
 */
export const sortAppointmentTags = (tags: TagVO[]) => {
  const { custom, locked } = separateTags(tags);

  const filteredLocked = filterMap(ICONS_ORDERED_BY_SLUG, (val) => locked.find((tag) => tag.slug === val));

  return [...filteredLocked, ...custom];
};
