/*
  The purpose of this file is to track the actions that initiate an imminent websocket event.
  If a websocket event is received and the current tab believes they were responsible for the event,
  it can avoid re-fetching data it already knows it has to refetch.
*/

const intiatedWebSocketEventTimestamps = {
  APPOINTMENT_UPDATED: 0,
  SCHEDULE_BLOCKS_UPDATED: 0,
  SCHEDULE_UPDATED_V2: 0,
  PATIENT_NOTE_UPDATED: 0,
  APPOINTMENT_ALERT_SETTINGS_UPDATED: 0,
};

export type InitiatedWebSocketEvents = keyof typeof intiatedWebSocketEventTimestamps;

const RECENTLY_INITIATED_DURATION = 5000;

export const trackIniatedWebSocketEvents = <E extends InitiatedWebSocketEvents>(event: E) => {
  intiatedWebSocketEventTimestamps[event] = Date.now();
};

export const didThisTabInitiateTheEvent = <E extends InitiatedWebSocketEvents>(event: E) => {
  const timestamp = intiatedWebSocketEventTimestamps[event];
  const actionAge = Date.now() - timestamp;

  return actionAge > 0 && actionAge < RECENTLY_INITIATED_DURATION;
};
