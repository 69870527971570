import { ServerPushMessages } from "api/websocket/types";

export type WebSocketMessageEvent = CustomEvent<ServerPushMessages>;
type WebSocketMessageCallback = (e: WebSocketMessageEvent) => void;
type WebSocketMessageListenerObject = {
  handleEvent: WebSocketMessageCallback;
};

/**
 * `WebSocketMessageEmitter` is a class that extends `EventTarget` to handle server push messages.
 * It provides methods to add and remove event listeners for the "message" event type,
 * and to dispatch events.
 *
 * After we parse the web socket JSON messae, we pipe the parsed/typed
 * event to the WebSocketMessageEmitter instance. The main advantages to listening to
 * WebSocket messages on a WebSocketMessageEmitter versus the WebSocket instance
 * are:
 * - We only ever parse websocket message JSON once per message and then pipe it through
 *  the event emitter, which is more performant than parsing the JSON everwhere we listen
 *  to websocket messages.
 * - The message is already typed which adds some nice ergonomics.
 *
 * @extends {EventTarget}
 */
export class WebSocketMessageEmitter extends EventTarget {
  addEventListener(
    type: "message",
    callback: WebSocketMessageCallback | WebSocketMessageListenerObject | null
  ): void {
    super.addEventListener(type, callback as EventListenerOrEventListenerObject);
  }
  removeEventListener(
    type: "message",
    callback: WebSocketMessageCallback | WebSocketMessageListenerObject | null
  ): void {
    super.removeEventListener(type, callback as EventListenerOrEventListenerObject);
  }
  dispatchEvent(event: WebSocketMessageEvent): boolean {
    return super.dispatchEvent(event);
  }

  constructor() {
    super();
  }
}
