import { FC, useMemo } from "react";
import { LastWorktimeVOV2 } from "@libs/api/generated-api";
import { formatAsISOTime, formatISOTimeAsAmPm, nowInTimezone } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

interface Props {
  lastStatus?: LastWorktimeVOV2;
  isSaving: boolean;
  onConfirm: Func;
  onCancel: Func;
  onEdit: Func;
}

export const ClockInOutNow: FC<Props> = ({ lastStatus, isSaving, onConfirm, onCancel, onEdit }) => {
  const practice = useCurrentPractice();
  const time = useMemo(() => formatAsISOTime(nowInTimezone(practice.timezoneId)), [practice.timezoneId]);

  const lastAction = lastStatus?.lastAction;

  return (
    <>
      <ModalContent padding="lg" className="font-sansSemiBold text-sm">
        <div>{lastAction === "CLOCK_IN" ? "Clock out time" : "Clock in time"}</div>
        <div className="flex items-center gap-x-4">
          <div className="font-sans text-2xl">{formatISOTimeAsAmPm(time)}</div>
          <ButtonIcon theme="primary" SvgIcon={EditIcon} onClick={onEdit} />
        </div>
      </ModalContent>

      <ModalFooter>
        <Button theme="secondary" className="min-w-button" onClick={onCancel}>
          Cancel
        </Button>
        <AsyncButton isLoading={isSaving} theme="primary" className="min-w-button" onClick={onConfirm}>
          Confirm
        </AsyncButton>
      </ModalFooter>
    </>
  );
};
