import { forwardRef, Fragment, MouseEvent, useMemo } from "react";
import { AppointmentCardConfigVO, AppointmentVO, TagVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Glyph } from "@libs/components/UI/Glyph";
import { AppointmentTimeSpan } from "components/ScheduleAppointments/AppointmentDetailComponents";
import { getPatientCardName, getAvailablePixels, getLineClamp } from "components/ScheduleAppointments/utils";

interface Props {
  appointment: AppointmentVO;
  className?: string;
  groupingContextDetail?: string;
  hipaaView: boolean;
  isNoShow: boolean;
  tags: TagVO[];
  onClick: (e: MouseEvent) => void;
  config: AppointmentCardConfigVO;
}

const cxStyles = {
  truncate: "truncate w-full",
  patientName: ({
    isNoShow,
    fontSize,
    hasTags,
  }: {
    hasTags: boolean;
    isNoShow: boolean;
    fontSize: "STANDARD" | "SMALL";
  }) =>
    cx(
      "text-xs font-sansSemiBold truncate w-full",
      isNoShow && "pr-5",
      hasTags && "pt-0.5",
      fontSize === "STANDARD" ? "text-xs" : "lg:text-xxxs xl:text-xxs"
    ),
};

const LINE_HEIGHT = 14;
const STANDARD_TITLE_LINE_HEIGHT = 17;
const ICON_HEIGHT = 22;

export const AppointmentCardDetails = forwardRef<HTMLButtonElement, Props>(
  ({ appointment, className, groupingContextDetail, tags, hipaaView, onClick, config }, ref) => {
    const duration = appointment.duration || 0;
    const availablePx = getAvailablePixels(duration);

    // eslint-disable-next-line complexity
    const conditionalLines = useMemo(() => {
      const lines = [];

      let usedPixels = tags.length
        ? ICON_HEIGHT
        : config.fontSize === "SMALL"
          ? LINE_HEIGHT
          : STANDARD_TITLE_LINE_HEIGHT;

      lines.push({
        key: "patient",
        el: (
          <div className="flex justify-between">
            <div
              className={cxStyles.patientName({
                isNoShow: appointment.state === "NO_SHOW",
                fontSize: config.fontSize,
                hasTags: tags.length > 0,
              })}
            >
              <span className="pr-1 align-middle">
                {getPatientCardName({ config, appointment, hipaaView })}
              </span>
              {tags.map((tag) => (
                <span key={tag.id} className="px-0.5 align-middle">
                  <Glyph family={tag.glyph.family} inline>
                    {tag.glyph.value}
                  </Glyph>
                </span>
              ))}
            </div>
          </div>
        ),
      });

      if (
        usedPixels + LINE_HEIGHT < availablePx &&
        config.showPatientPronunciation &&
        appointment.patient.namePronunciation &&
        !hipaaView
      ) {
        lines.push({
          key: "pronunciation",
          el: <div className={cx("italic", cxStyles.truncate)}>{appointment.patient.namePronunciation}</div>,
        });
        usedPixels += LINE_HEIGHT;
      }

      if (usedPixels + LINE_HEIGHT < availablePx && appointment.patientProcedures.length > 0) {
        lines.push({
          key: "procedures",
          el: (
            <div className={cxStyles.truncate}>
              {appointment.patientProcedures.map((item) => item.displayName).join(", ")}
            </div>
          ),
        });
        usedPixels += LINE_HEIGHT;
      }

      if (usedPixels + LINE_HEIGHT < availablePx && groupingContextDetail) {
        lines.push({
          key: "detail",
          el: <div className={cxStyles.truncate}>{groupingContextDetail}</div>,
        });
        usedPixels += LINE_HEIGHT;
      }

      if (usedPixels + LINE_HEIGHT < availablePx) {
        lines.push({
          key: "time",
          el: (
            <AppointmentTimeSpan
              truncate
              duration={appointment.duration}
              date={appointment.date}
              state={appointment.state}
              startTime={appointment.startTime}
              endTime={appointment.endTime}
            />
          ),
        });
        usedPixels += LINE_HEIGHT;
      }

      if (usedPixels + LINE_HEIGHT < availablePx && config.showComments && appointment.comments) {
        lines.push({
          key: "comments",
          el: (
            <div
              className="overflow-hidden"
              style={{
                WebkitLineClamp: getLineClamp(availablePx, usedPixels, LINE_HEIGHT),
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              <p>{appointment.comments}</p>
            </div>
          ),
        });
      }

      return lines;
    }, [config, appointment, groupingContextDetail, hipaaView, tags, availablePx]);

    return (
      <button type="button" ref={ref} onClick={onClick} className={className}>
        {conditionalLines.map((line) => {
          return <Fragment key={line.key}>{line.el}</Fragment>;
        })}
      </button>
    );
  }
);
