import React from "react";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as VerifiedIcon } from "@libs/assets/icons/verified-badge.svg";

interface Props {
  autoEligibilityCarrierIds: Set<number> | undefined;
  carrier: InsuranceCarrierVO;
}

export const CarrierOption: React.FC<Props> = ({ autoEligibilityCarrierIds, carrier }) => {
  return autoEligibilityCarrierIds ? (
    <div className="flex items-center justify-between w-96 gap-x-2">
      <div className="flex gap-x-1 truncate">
        <span className="font-sansSemiBold">{carrier.payerId}</span>
        <FloatingTooltip content={carrier.name} theme="SMALL">
          <div className="truncate">{carrier.name}</div>
        </FloatingTooltip>
      </div>
      {autoEligibilityCarrierIds.has(carrier.id) && <Icon SvgIcon={VerifiedIcon} theme="primary" />}
    </div>
  ) : (
    <div className="w-full flex gap-x-4 justify-between items-center max-w-md">
      <div className="flex-none w-14">{carrier.payerId}</div>
      <FloatingTooltip content={carrier.name} theme="SMALL">
        <div className="flex-1 min-w-0 truncate">{carrier.name}</div>
      </FloatingTooltip>

      <div className="flex-none w-24">{carrier.phone ? formatPhoneNumber(carrier.phone) : null}</div>
    </div>
  );
};
