import { useState, useCallback, useMemo, ReactNode } from "react";
import { components } from "react-select";
import { ContactDetailsVO, ContactVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { searchForPatients } from "api/patients/queries";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { ContactOption } from "components/Patient/types";
import { useNow } from "hooks/useNow";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { EMPTY_CONTACT } from "components/Patient/contactModes";
import { FormFieldAutoComplete } from "components/UI/FormFieldAutoComplete";

interface Props {
  onAddNewContact: Func;
  onClearContact: Func;
  onSelectContact: (selection: ContactVO) => void;
  onEditContact: (contactPatientId: number) => void;
  defaultContactOptions?: () => ContactOption[];
  contact: ContactVO | undefined;
  patientId?: number;
}

export const hasCompleteContactInfo = (option: {
  preferredContactMode: ContactDetailsVO["preferredContactMode"];
  textPhone?: string;
  callPhone?: string;
  email?: string;
  relation?: ContactDetailsVO["contactRelation"];
}) => {
  const hasContactMethodValue =
    (option.preferredContactMode === "TEXT" && option.textPhone) ||
    (option.preferredContactMode === "CALL" && option.callPhone) ||
    (option.preferredContactMode === "EMAIL" && option.email);

  return Boolean(hasContactMethodValue) && Boolean(option.relation);
};

const createOption = (
  label: ReactNode,
  value: number,
  contact: ContactVO,
  isDisabled?: boolean
): ContactOption => ({
  label,
  value,
  isDisabled,
  data: contact,
});

const FakeValues = {
  addContact: -1,
  loading: -2,
  error: -3,
};

const errorFakeOption = createOption(
  <div className="flex items-center justify-center gap-x-2">
    <ButtonIcon className="cursor-default" SvgIcon={WarningIcon} theme="error" size="sm" />
    <span className="text-greyDark">Sorry, an error occurred.</span>
  </div>,
  FakeValues.error,
  EMPTY_CONTACT,
  true
);

const addContactOption = createOption(
  <div className="flex gap-x-2">
    <ButtonIcon className="cursor-default" theme="primary" SvgIcon={PlusIcon} size="sm" />
    <span className="text-primaryTheme">New Contact</span>
  </div>,
  FakeValues.addContact,
  EMPTY_CONTACT
);

const DisabledOptionLabel = ({ label }: { label: ReactNode }) => (
  <div className="flex items-center justify-between gap-x-2">
    <span className="text-slate-500">{label}</span>
    <ButtonIcon
      tooltip={{ content: "Cannot assign someone who already has a contact person" }}
      className="cursor-default"
      SvgIcon={InfoIcon}
      size="sm"
    />
  </div>
);

// prevents selected option from being scrolled to
const customComponents = {
  Option: components.Option,
};

export const ContactSearch: React.FC<Props> = ({
  onAddNewContact,
  onSelectContact,
  onEditContact,
  onClearContact,
  patientId,
  contact,
  defaultContactOptions,
}) => {
  const now = useNow();
  const { practiceId } = useAccount();

  const [searchString, setSearchString] = useState("");

  const [{ data: patientResults, error, isLoading: isSearching, refetch }] = useApiQueries([
    searchForPatients({
      args: {
        orderBy: "ASCENDING",
        practiceId,
        searchString,
        pageSize: 10,
        pageNumber: 1,
        /* eslint-disable @typescript-eslint/naming-convention */
        "patientCriteria.excludePatientIds": [patientId ?? 0],
        "patientCriteria.hasNoContact": true,
        /* eslint-enable @typescript-eslint/naming-convention */
        sortColumn: "firstName",
      },
      queryOptions: { enabled: false },
    }),
  ]);

  const searchOptions = useMemo(() => {
    let options: ContactOption[] = [];

    if (searchString) {
      options = error
        ? [errorFakeOption]
        : patientResults?.length
          ? patientResults
              .filter((pt) => pt.id !== patientId)
              .map((pt) => {
                const displayName = getPatientDisplayNameFromPatient(now, pt);

                return {
                  label: displayName,
                  value: pt.id,
                  data: {
                    ...pt.contact,
                    relation: undefined,
                  },
                };
              })
          : [];

      return [addContactOption, ...options];
    }

    const defaultOptions = defaultContactOptions?.();

    if (defaultOptions) {
      options = defaultOptions.map((option) => ({
        ...option,
        label: option.isDisabled ? <DisabledOptionLabel label={option.label} /> : option.label,
      }));
    }

    // need to insert an option for the current selected contact if it isn't in the list
    // so that react-select can display the selection. This can happen when a search result
    // is selected but the user is not yet a family member.
    if (contact && !defaultOptions?.find((o) => o.value === contact.name.id)) {
      options = [createOption(contact.displayName, contact.name.id, contact), ...options];
    }

    return [addContactOption, ...options];
  }, [defaultContactOptions, error, patientId, patientResults, searchString, contact, now]);

  const handleSelectContact = useCallback(
    (option: ContactOption | null) => {
      if (option) {
        if (option.value === contact?.name.id) {
          onSelectContact(contact);
        } else if (option.value === FakeValues.addContact) {
          onAddNewContact();
        } else if (hasCompleteContactInfo(option.data)) {
          onSelectContact(option.data);
        } else {
          const betterMatch = defaultContactOptions?.().find(
            (defaultOption) =>
              defaultOption.value === option.value && hasCompleteContactInfo(defaultOption.data)
          );

          if (betterMatch) {
            onSelectContact(betterMatch.data);
          } else {
            onEditContact(option.value);
          }
        }
      } else {
        onClearContact();
      }
    },
    [onAddNewContact, onClearContact, onSelectContact, onEditContact, contact, defaultContactOptions]
  );

  return (
    <FormFieldAutoComplete
      data-testid="contact-search-input"
      label={
        <TooltipLabel
          tooltip={{
            content: "Assign a person who will receive all communications on behalf of this patient.",
          }}
        >
          Contact Person
        </TooltipLabel>
      }
      onSearch={() => refetch()}
      onChange={handleSelectContact}
      onInputChange={setSearchString}
      isLoading={isSearching}
      options={searchOptions}
      placeholder="Search Patients"
      value={contact?.name.id}
      openMenuOnFocus
      components={customComponents}
    />
  );
};
