import { useMemo } from "react";
import { useDebounce } from "use-debounce";
import { PatientInsuranceVO, PatientVO } from "@libs/api/generated-api";
import { ANY_DAY, formatAsISODate, getLocalDate } from "@libs/utils/date";
import { isSSN } from "@libs/utils/ssn";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { useAccount } from "@libs/contexts/AccountContext";
import { getLookupPatientQuery } from "api/patients/queries";

import {
  PatientDraft,
  PrimarySubscriber,
  SelectedPrimarySubscriber,
  SubscriberIdType,
  DependentPrimarySubscriber,
  ContactDraft,
} from "components/Patient/types";
import { DEFAULT_CONTACT_MODE, DEFAULT_PREFERRED_CONTACT_MODE } from "components/Patient/contactModes";
import { useLookupPatient } from "hooks/useLookupPatient";

export const NO_SELECTED_NUMERIC_ID = -1;

export const useLookupPrimarySubscriber = (
  args: {
    ssn?: string;
    subscriberId?: string;
    subscriberIdType: SubscriberIdType;
    dob: Date | null;
    carrierId: number;
  },
  currentSubscriber?: PatientInsuranceVO["subscriber"]
) => {
  const { practiceId } = useAccount();

  const lookupPatientArgs = useMemo(() => {
    // for matching by subscriber ID, dob match is not necessary but a date
    // needs to be passed in so use ANY_DAY
    const dob = args.dob ? formatAsISODate(args.dob) : ANY_DAY;

    if (args.subscriberIdType === "ssn") {
      return {
        practiceId,
        ssn: args.ssn && isSSN(args.ssn) ? args.ssn : undefined,
        dob,
        carrierId: args.carrierId,
      };
    }

    return {
      practiceId,
      subscriberId: args.subscriberId,
      dob,
      carrierId: args.carrierId,
    };
  }, [args.carrierId, args.dob, args.ssn, args.subscriberId, args.subscriberIdType, practiceId]);

  const [debouncedArgs] = useDebounce(lookupPatientArgs, SEARCH_DEBOUNCE_DELAY_MS);

  const [{ data: patientMatch }] = useApiQueries([
    getLookupPatientQuery({
      args: debouncedArgs,
      queryOptions: {
        enabled: Boolean(
          (debouncedArgs.ssn || debouncedArgs.subscriberId) && debouncedArgs.dob && debouncedArgs.carrierId
        ),
      },
    }),
  ]);

  // patientMatch?.insuranceDetails.primaryInsurance should always be defined if there's a patient match, but sometimes it isn't
  // this is why we check insuranceDetails.primaryInsurance
  return patientMatch?.id !== currentSubscriber?.patientId && patientMatch?.insuranceDetails.primaryInsurance
    ? patientMatch
    : undefined;
};

export const useLinkedPrimarySubscriber = (args?: { subscriberIdType?: SubscriberIdType }) => {
  return useObjectState<DependentPrimarySubscriber>({
    firstName: "",
    lastName: "",
    dob: null,
    subscriberIdType: args?.subscriberIdType ?? "ssn",
    relationship: undefined,
    carrierId: NO_SELECTED_NUMERIC_ID,
    employer: "",
    ssnLastFour: "",
    ssn: "",
    subscriberId: "",
    patientId: -1,
  });
};

export const usePatientDraft = (
  options: {
    initialPrimarySubscriber?: Pick<PrimarySubscriber, "patientId" | "subscriberIdType">;
  } = {}
) => {
  const [selectedPrimarySubscriber, updateSelectedPrimarySubscriber] =
    useObjectState<SelectedPrimarySubscriber>({
      patientId: NO_SELECTED_NUMERIC_ID,
      relationship: undefined,
    });
  const [patientDraft, updatePatientDraft] = useObjectState<PatientDraft>({
    addressDetails: null,
    dob: null,
    email: "",
    insuranceSubscriberType: "PRIMARY_SUBSCRIBER",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    preferredContactMode: DEFAULT_PREFERRED_CONTACT_MODE,
    contactModes: DEFAULT_CONTACT_MODE,
    relationship: undefined,
    status: "ACTIVE",
  });

  const [primarySubscriber, updatePrimarySubscriber] = useObjectState<PrimarySubscriber>({
    carrierId: NO_SELECTED_NUMERIC_ID,
    subscriberIdType: options.initialPrimarySubscriber?.subscriberIdType ?? "ssn",
    employer: "",
    ssn: undefined,
    subscriberId: undefined,
    patientId: options.initialPrimarySubscriber?.patientId ?? NO_SELECTED_NUMERIC_ID,
  });
  const { patientMatch } = useLookupPatient(patientDraft);

  return {
    patientDraft,
    primarySubscriber,
    selectedPrimarySubscriber,
    patientMatch,
    handleUpdatePatientDraft: updatePatientDraft,
    handleUpdatePrimarySubscriber: updatePrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber: updateSelectedPrimarySubscriber,
  };
};

export const useContactDraft = (patient?: PatientVO) => {
  const [contactDraft, updateContactDraft] = useObjectState<ContactDraft>(() =>
    patient
      ? {
          addressDetails: patient.contact.address ?? null,
          dob: patient.personalDetails.dob ? getLocalDate(patient.personalDetails.dob) : null,
          email: patient.contact.email ?? "",
          firstName: patient.personalDetails.firstName,
          lastName: patient.personalDetails.lastName,
          phoneNumber: patient.contact.textPhone ?? "",
          preferredContactMode: patient.contact.preferredContactMode,
          contactModes: patient.contact.contactModes,
          relationship: undefined,
          status: patient.personalDetails.status,
        }
      : {
          addressDetails: null,
          dob: null,
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          preferredContactMode: DEFAULT_PREFERRED_CONTACT_MODE,
          contactModes: DEFAULT_CONTACT_MODE,
          relationship: undefined,
          status: "NONPATIENT",
        }
  );

  const draftMatchesServer =
    patient?.personalDetails.firstName === contactDraft.firstName &&
    patient.personalDetails.lastName === contactDraft.lastName &&
    contactDraft.dob &&
    patient.personalDetails.dob === formatAsISODate(contactDraft.dob);

  const { patientMatch } = useLookupPatient(
    // if the draft matches the server, we don't want to search for a patient match
    draftMatchesServer ? { firstName: "", lastName: "", dob: null } : contactDraft
  );

  return {
    contactDraft,
    patientMatch,
    handleUpdateContactDraft: updateContactDraft,
  };
};

export type InsuranceState = {
  assignmentOfBenefitsToPractice: boolean;
  expiryDate: string | undefined;
  notes: string;
  releaseOfPatientInfo: boolean;
  startDate: string | undefined;
};

export const usePatientInsuranceState = (insurance: PatientInsuranceVO | undefined) => {
  const [insuranceState, setInsuranceState] = useObjectState<InsuranceState>({
    assignmentOfBenefitsToPractice: true,
    expiryDate: undefined as string | undefined,
    notes: "",
    releaseOfPatientInfo: true,
    startDate: undefined as string | undefined,
  });

  useSyncOnce((loadedInsurance) => {
    setInsuranceState({
      assignmentOfBenefitsToPractice: loadedInsurance.assignmentOfBenefits,
      expiryDate: loadedInsurance.expiryDate,
      notes: loadedInsurance.subscriber.notes,
      releaseOfPatientInfo: loadedInsurance.releaseOfPatientInfo,
      startDate: loadedInsurance.startDate,
    });
  }, insurance);

  return {
    insuranceState,
    handleInsuranceChange: setInsuranceState,
  };
};

export type UseInsuranceState = ReturnType<typeof usePatientInsuranceState>;
