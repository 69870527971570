import { QueryClient } from "@tanstack/react-query";
import { DosespotNotificationEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handleUpdatedErxNotificationCount = ({
  data,
  queryClient,
  practiceId,
}: {
  data: DosespotNotificationEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getDosespotNotificationCount"),
    { practiceId, employeeId: data.employeeId },
  ]);
};
