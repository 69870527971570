const HEX_RADIX = 16;
const HEX_COLOR_PART_LEN = 2;
const WHITE_RGB = 255;

const hexStringToRGB = (hex: string) => {
  const rgb: number[] = [];

  for (let i = 0; i < hex.length; i += HEX_COLOR_PART_LEN) {
    rgb.push(Number.parseInt(hex.slice(i, i + HEX_COLOR_PART_LEN), HEX_RADIX));
  }

  return rgb;
};

const rgbToHex = (rgbValue: number) => {
  return rgbValue.toString(HEX_RADIX).padStart(HEX_COLOR_PART_LEN, "0").toUpperCase();
};

const blendWhite = (rgbValue: number, opacity: number) => {
  return Math.round((1 - opacity) * WHITE_RGB + opacity * rgbValue);
};

const RED_LUMINANCE = 0.2126;
const GREEN_LUMINANCE = 0.7152;
const BLUE_LUMINANCE = 0.0722;
const getLuminance = (rgb: number[]): number => {
  const [r, g, b] = rgb.map((c) => c / WHITE_RGB);

  return RED_LUMINANCE * r + GREEN_LUMINANCE * g + BLUE_LUMINANCE * b;
};

// Arbitrary values that are used to say colors with
// a luminance less than this value should be blended
// with a white to make it lighter.
const VERY_LIGHT_COLOR_LUMINANCE = 0.87;
const LIGHT_COLOR_LUMINANCE = 0.75;
const LIGHTEN = 0.2;
const LIGHTEN_LESS = 0.4;

const SHORT_HEX = 3;

/**
 * Lightens a hexadecimal color if its luminance is below a certain threshold.
 *
 * @param {string} hex - The hexadecimal color to potentially lighten.
 * @returns {string} Returns the original color if its luminance is above the threshold,
 * otherwise returns a lighter color.
 */
export const maybeLighten = (hex: string) => {
  let normalizedHex = hex.replace(/#/, "");

  // valid hex #000 gets normalized to #000000
  normalizedHex =
    normalizedHex.length === SHORT_HEX ? [...normalizedHex].map((c) => c + c).join("") : normalizedHex;

  const rgbValues = hexStringToRGB(normalizedHex);
  const luminance = getLuminance(rgbValues);

  const shouldLighten = luminance < VERY_LIGHT_COLOR_LUMINANCE;

  if (shouldLighten) {
    const opacity = luminance < LIGHT_COLOR_LUMINANCE ? LIGHTEN : LIGHTEN_LESS;
    const blendedHex = rgbValues
      .map((rgb) => blendWhite(rgb, opacity))
      .map((blendedRgb) => rgbToHex(blendedRgb))
      .join("");

    return `#${blendedHex}`;
  }

  return hex;
};
