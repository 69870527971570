import { IssueChatTokenRequest } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession } from "@libs/utils/queryCache";

export const issueChatToken = makeQuery({
  queryKey: ["practices", "issueChatToken"],
  formatParams: (args: { practiceId: number; data: IssueChatTokenRequest }) => [args.practiceId, args.data],
  queryOptions: cacheForSession,
});

export const getUnreadMessageCount = makeQuery({
  queryKey: ["practices", "getUnreadMessageCount"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getEmployeeChatPreference = makeQuery({
  queryKey: ["practices", "getEmployeeChatPreference"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});
