import {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  MutableRefObject,
  FC,
  PropsWithChildren,
  memo,
} from "react";
import { useLocation, NavigationType, useNavigationType } from "react-router-dom";
import { noop } from "@libs/utils/noop";
import { getFullUrl } from "@libs/utils/location";

interface LinkContextValue<T extends Record<string, string>> {
  links: T;
  defaultLinks: T;
  linksRef: MutableRefObject<T>;
  updateLinks: (updates: Partial<T>) => void;
}

const useAppLinks = <T extends Record<string, string>>(links: {
  defaultLinks: T;
  initialOverrideLinks?: Partial<T>;
}) => {
  const [overrideLinks, setOverrideLinks] = useState<Partial<T>>(() => links.initialOverrideLinks ?? {});
  const updateLinks = useCallback((updates: Partial<T>) => {
    setOverrideLinks((last) => ({ ...last, ...updates }));
  }, []);
  const lastLinksRef = useRef(links);

  const mergedLinks = useMemo(
    () => ({ ...links.defaultLinks, ...overrideLinks }) as T,
    [links.defaultLinks, overrideLinks]
  );

  const mergedLinksRef = useRef(mergedLinks);

  mergedLinksRef.current = mergedLinks;

  const value = useMemo(() => {
    return {
      links: mergedLinks,
      linksRef: mergedLinksRef,
      defaultLinks: links.defaultLinks,
      updateLinks,
    };
  }, [updateLinks, mergedLinks, links.defaultLinks]);

  useEffect(() => {
    // Skip a set state on initialization
    if (lastLinksRef.current !== links) {
      lastLinksRef.current = links;
      // If the default links change
      // we need to clear the overrides becauase they
      // may not be relevan anymore.
      setOverrideLinks(links.initialOverrideLinks ?? {});
    }
  }, [links]);

  return {
    value,
    setOverrideLinks,
  };
};

const useAppHistory = <T extends Record<string, string>>({
  updateLinks,
  defaultLinks,
  name,
  onLinksUpdated,
}: {
  updateLinks: (updates: Partial<T>) => void;
  defaultLinks: T;
  name: keyof T;
  onLinksUpdated?: (updates: Partial<T>) => void;
}) => {
  const location = useLocation();
  const fullUrl = useMemo(() => getFullUrl(location), [location]);
  const lastUrlRef = useRef<string[]>([]);
  const { skipTrackingRef, navigationTypeRef } = useContext(GlobalLinksContext);

  // when an app is mounted set the nav links to be the default root
  // this provides a convenient way for users to get back to the app root
  useEffect(() => {
    const updates = { [name]: defaultLinks[name] } as Partial<T>;

    updateLinks(updates);
    onLinksUpdated?.(updates);
  }, [updateLinks, name, defaultLinks, onLinksUpdated]);

  useEffect(() => {
    // allows components to opt out of tracking a page, e.g. 404 content
    if (!skipTrackingRef.current) {
      // On first app load store the link visited
      if (lastUrlRef.current.length) {
        switch (navigationTypeRef.current) {
          case NavigationType.Pop: {
            lastUrlRef.current.pop();

            break;
          }
          case NavigationType.Replace: {
            lastUrlRef.current[lastUrlRef.current.length - 1] = fullUrl;

            break;
          }
          case NavigationType.Push: {
            lastUrlRef.current.push(fullUrl);

            break;
          }
          // No default
        }
      } else {
        lastUrlRef.current.push(fullUrl);
      }
    }
  }, [fullUrl, name, navigationTypeRef, skipTrackingRef]);

  // When the app unmounts set the app nav link to the last location visited in the app
  // so that we can use that as the entry point back into the app

  // NOTE we purposely ignore the lint react-hooks/exhaustive-deps
  // rules around referencing refs in a useEffect cleanup for a couple reasons.
  // First, for navigationTypeRef we actually intend that
  // the navigationTypeRef value will have changed before this unmounts
  // and we want the navigation type that resulted in leaving
  // this page.
  // Second, this effect cleanup only gets called when the component
  // unmounts which means the lastUrlRef is safe since it only gets
  // updated when urls change.
  useEffect(() => {
    return () => {
      // If a user is leaving the app via the POP or REPLACE
      // use the previously visited link in the app as the last url
      if (
        navigationTypeRef.current === NavigationType.Pop ||
        // eslint-disable-next-line react-hooks/exhaustive-deps
        navigationTypeRef.current === NavigationType.Replace
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        lastUrlRef.current.pop();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const lastUrl = lastUrlRef.current.at(-1);

      // This fixes the problem during dev when React mounts and unmounts every component
      lastUrlRef.current = [];

      // If there is a previously visited url set it as the link to the app
      if (lastUrl) {
        const updates = { [name]: lastUrl } as Partial<T>;

        updateLinks(updates);
        onLinksUpdated?.(updates);
      }

      // If no urls were visited leave the default app link as the new link
    };
  }, [updateLinks, name, navigationTypeRef, onLinksUpdated]);
};

type AppHistoryProviderProps<T extends Record<string, string>> = {
  name: keyof T;
  children: ReactNode;
  onLinksUpdated?: (updates: Partial<T>) => void;
};

export const createLinksContext = <T extends Record<string, string>>(
  appName: string,
  initialDefaultLinks: T
) => {
  const LinksContext = createContext<LinkContextValue<T>>({
    links: initialDefaultLinks,
    defaultLinks: initialDefaultLinks,
    linksRef: {
      current: initialDefaultLinks,
    },
    updateLinks: noop,
  });

  LinksContext.displayName = `${appName}LinksContext`;

  const AppHistoryContext = createContext<keyof T>("");

  AppHistoryContext.displayName = `${appName}AppHistoryContext`;

  const useLinks = () => useContext(LinksContext);

  return {
    useLinks,
    LinksProvider: ({
      children,
      defaultLinks,
      initialOverrideLinks,
    }: {
      children: ReactNode;
      defaultLinks?: T;
      initialOverrideLinks?: Partial<T>;
    }) => {
      const links = useMemo(
        () => ({
          defaultLinks: defaultLinks ?? initialDefaultLinks,
          initialOverrideLinks,
        }),
        [defaultLinks, initialOverrideLinks]
      );
      const { value } = useAppLinks(links);

      return <LinksContext.Provider value={value}>{children}</LinksContext.Provider>;
    },
    AppHistoryProvider: memo(({ name, children, onLinksUpdated }: AppHistoryProviderProps<T>) => {
      const { updateLinks, defaultLinks } = useLinks();

      useAppHistory({ updateLinks, defaultLinks, name, onLinksUpdated });

      return <AppHistoryContext.Provider value={name}>{children}</AppHistoryContext.Provider>;
    }),
  };
};

const GlobalLinksContext = createContext<{
  skipTrackingRef: MutableRefObject<boolean>;
  navigationTypeRef: MutableRefObject<NavigationType>;
}>({ skipTrackingRef: { current: false }, navigationTypeRef: { current: NavigationType.Pop } });

GlobalLinksContext.displayName = "GlobalLinksContext";

export const GlobalLinksProvider: FC<PropsWithChildren> = ({ children }) => {
  const skipTrackingRef = useRef(false);
  const navigationType = useNavigationType();
  const navigationTypeRef = useRef(navigationType);

  navigationTypeRef.current = navigationType;

  const value = useMemo(
    () => ({
      navigationTypeRef,
      skipTrackingRef,
    }),
    []
  );

  return <GlobalLinksContext.Provider value={value}>{children}</GlobalLinksContext.Provider>;
};

// Used in components where the url should never be used as an app link
// e.g. 404 page.
export const useHistorySkip = () => {
  const { skipTrackingRef } = useContext(GlobalLinksContext);

  useEffect(() => {
    skipTrackingRef.current = true;

    return () => {
      skipTrackingRef.current = false;
    };
  }, [skipTrackingRef]);
};
