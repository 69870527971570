import { FC } from "react";
import { NavIcon, Props as NavIconProps } from "@libs/components/UI/NavIcon";
import { useCurrentUser } from "contexts/CurrentUserContext";

type Props = Omit<NavIconProps, "selectedClassName" | "className" | "iconClassName"> & {
  selected?: boolean;
};

export const PracticeNavIcon: FC<Props> = ({ selected, ...rest }) => {
  const currentUser = useCurrentUser();
  const isSupportUser = currentUser.type === "SUPPORT_USER";

  isSupportUser
    ? selected
      ? "bg-yellowLight"
      : "hover:bg-yellowDark active:bg-yellow"
    : selected
      ? "bg-archyBlue-50"
      : "hover:bg-slate-200 active:bg-slate-100";

  return (
    <NavIcon
      {...rest}
      className={
        isSupportUser
          ? selected
            ? "bg-yellowLight"
            : "hover:bg-yellowDark active:bg-yellow"
          : selected
            ? "bg-archyBlue-50"
            : "hover:bg-slate-200 active:bg-slate-100"
      }
      iconClassName={isSupportUser && selected ? "text-archyBlue-500" : undefined}
    />
  );
};
