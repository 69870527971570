import Skeleton from "react-loading-skeleton";
import { FormFieldLayout } from "@libs/types/form";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";

type Props = {
  displayLabel?: boolean;
  displayPlaceholder?: boolean;
  edit?: boolean;
  error?: string;
  isLoading?: boolean;
  layout?: FormFieldLayout;
  onChange: (val: string) => void;
  value: string;
};
export const SubscriberIDField: React.FC<Props> = ({
  displayLabel = true,
  displayPlaceholder = true,
  edit = true,
  error,
  layout,
  isLoading = false,
  onChange,
  value,
}) => {
  return isLoading ? (
    <Skeleton className="h-10" />
  ) : (
    <FormFieldInput
      edit={edit}
      error={error}
      label={displayLabel ? "Subscriber ID" : ""}
      layout={layout}
      placeholder={displayPlaceholder ? "Unique ID issued by carrier" : ""}
      onChange={(e) => {
        onChange(e.target.value.trim());
      }}
      required
      value={value}
    />
  );
};
