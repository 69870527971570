import { PropsWithChildren, useMemo } from "react";
import { PermissionActionVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { PermissionAction } from "components/Roles/constants";
import { PermissionResults, RoleConditionHandler, checkPermission } from "components/Roles/roleUtils";
import { AccessDeniedContent } from "components/UI/AccessDeniedContent";
import { ReactComponent as SecureLockAndKeyImg } from "assets/components/restricted-content.svg";

type RoleGuardProps = PropsWithChildren<{
  domain: PermissionActionVO["domain"];
  action: PermissionAction;
  /**
   * Condition to check for the permission. If not provided, it will check for the
   * permission directly.
   */
  onRoleCondition?: RoleConditionHandler;
}>;

type RoleGuardReasonProps = {
  /**
   * Reason to show to the user in the deny popup
   */
  reason?: string;
  conditionReason?: string;
};

export const RoleGuardHide: React.FC<RoleGuardProps> = ({ domain, action, children, onRoleCondition }) => {
  const currentUser = useCurrentUser();
  const permissionResults: PermissionResults = useMemo(
    () => checkPermission(currentUser.roleV2, domain, action, onRoleCondition),
    [action, domain, currentUser, onRoleCondition]
  );

  return permissionResults.isPermitted ? children : null;
};

export const RoleGuardLock: React.FC<RoleGuardProps & RoleGuardReasonProps> = ({
  domain,
  action,
  children,
  reason,
  conditionReason,
  onRoleCondition,
}) => {
  const currentUser = useCurrentUser();
  const permissionResults: PermissionResults = useMemo(
    () => checkPermission(currentUser.roleV2, domain, action, onRoleCondition),
    [action, domain, currentUser, onRoleCondition]
  );

  return permissionResults.isPermitted ? (
    children
  ) : (
    <div className="flex flex-col h-full place-content-center w-full">
      <AccessDeniedContent
        reason={permissionResults.failureReason === "UNMET_CONDITIONS" ? conditionReason : reason}
      />
    </div>
  );
};

export const RoleGuardClick: React.FC<RoleGuardProps & RoleGuardReasonProps> = ({
  domain,
  action,
  children,
  reason,
  conditionReason,
  onRoleCondition,
}) => {
  const currentUser = useCurrentUser();
  const permissionResults: PermissionResults = useMemo(
    () => checkPermission(currentUser.roleV2, domain, action, onRoleCondition),
    [action, domain, currentUser, onRoleCondition]
  );

  return permissionResults.isPermitted ? (
    children
  ) : (
    <CaptureClickPopup
      hasAccess={permissionResults.isPermitted}
      reason={permissionResults.failureReason === "UNMET_CONDITIONS" ? conditionReason : reason}
    >
      {children}
    </CaptureClickPopup>
  );
};

const CaptureClickPopup: React.FC<PropsWithChildren<{ hasAccess: boolean; reason?: string }>> = ({
  hasAccess,
  reason,
  children,
}) => {
  const popup = useBoolean(false);

  return (
    <>
      <div
        className="contents"
        onClickCapture={(e) => {
          if (!hasAccess) {
            popup.on();
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        {children}
      </div>
      {popup.isOn && (
        <AlertModal
          size="3xs"
          primaryText="Access restricted"
          secondaryText={reason ?? "Ask your administrator to enable necessary permissions."}
          confirmText="Close"
          childrenPlacement="BEFORE_TEXT"
          onConfirm={popup.off}
        >
          <div className="flex justify-center">
            <SecureLockAndKeyImg className="w-32 h-32" />
          </div>
        </AlertModal>
      )}
    </>
  );
};
