import { QueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";

export const handleRoleUpdated = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getEmployee"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getRoleV2"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPracticeRolesV2"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAssignableRolesForUser"), { practiceId }]);
};
