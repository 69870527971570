import { QueryClient } from "@tanstack/react-query";
import { WalletBalanceUpdatedEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handlePatientWalletBalanceUpdated = ({
  data,
  queryClient,
  practiceId,
}: {
  data: WalletBalanceUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getWallets"), { patientId: data.patientId }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getWalletActivities"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
};
