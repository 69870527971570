import { FC, useMemo, useCallback } from "react";
import { AppointmentVO, PatientProcedureVO, PatientInsuranceResponse } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFirstItem } from "@libs/utils/array";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

import { ItemModal, useItemModal } from "hooks/useItemModal";

interface Props {
  type: "print-appointment" | "print-procedures" | "send-procedures";
  onConfirm: (forceInsuranceEsimate: boolean) => void;
  onRequestClose: Func;
  title: string;
}

export const ConfirmInsuranceEstimateModal: FC<Props> = ({ type, onConfirm, onRequestClose, title }) => {
  const contentContext = type === "print-appointment" ? "appointment estimate" : "treatment plan";

  const includeInsuranceEstimate = useBoolean(false);

  const handleRequestPrint = useCallback(() => {
    onConfirm(includeInsuranceEstimate.isOn);
    onRequestClose();
  }, [includeInsuranceEstimate, onConfirm, onRequestClose]);

  return (
    <Modal title={title} onClose={onRequestClose} size="xs">
      <ModalContent className="flex flex-col gap-y-6" padding="lg">
        <p className="text-sm">
          This patient&apos;s primary insurance designates the Assignment of Benefits to the Patient. This
          means the patient will directly receive any potential insurance payments.
          <br />
          <br />
          Select the option below if you would like to include an estimated insurance payout on their{" "}
          {contentContext}. Otherwise, the estimate will show all fees assigned to the patient.
        </p>

        <Checkbox
          checked={includeInsuranceEstimate.isOn}
          onChange={includeInsuranceEstimate.toggle}
          checkboxSize="lg"
        >
          <span className="text-sm">Include an estimate of insurance&apos;s payment to the patient</span>
        </Checkbox>
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={handleRequestPrint}>
          {type === "print-appointment" || type === "print-procedures" ? "Print" : "Continue"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const useShowInsuranceEstimate = <T,>(
  itemModal: ItemModal<T>,
  canRunFeeCalc: (value: T) => boolean,
  patientInsurances?: PatientInsuranceResponse[]
) => {
  // When the patient primary insurance has assignment of benefits to the
  // patient (patient receives check payment from insurance), we offer the
  // ability to provide an estimate of the amount that will be covered by
  // insurance on the appointment or treatment plan
  const hasPrimaryInsuranceAssignmentOfBenefitsToPatient = useMemo(
    () =>
      patientInsurances &&
      patientInsurances.length > 0 &&
      !getFirstItem(patientInsurances)?.patientInsurance.assignmentOfBenefits,
    [patientInsurances]
  );

  const openModal = itemModal.open;
  const show = useCallback(
    (item: T) => {
      const shouldOpenPrintModal =
        canRunFeeCalc(item) && hasPrimaryInsuranceAssignmentOfBenefitsToPatient && itemModal.isClosed;

      if (shouldOpenPrintModal) {
        openModal(item);

        return true;
      }

      return false;
    },
    [canRunFeeCalc, openModal, itemModal.isClosed, hasPrimaryInsuranceAssignmentOfBenefitsToPatient]
  );

  return show;
};

type ConfirmProceduresEstimateItem = {
  procedures: PatientProcedureVO[];
  method: "print" | "send" | "kiosk";
};
export const useConfirmProceduresEstimate = (patientInsurances?: PatientInsuranceResponse[]) => {
  const confirmProceduresEstimateModal = useItemModal<ConfirmProceduresEstimateItem>(null);

  const canRunFeeCalc = useCallback((item: ConfirmProceduresEstimateItem) => {
    const { procedures } = item;

    // Fee-calc does not re-run for done procedures, so if all procedures are
    // done, we do not show the print modal with insurance estimate option
    return (
      procedures.length === 0 ||
      !(procedures.filter(({ status }) => status === "DONE").length === procedures.length)
    );
  }, []);

  return {
    showConfirmProceduresEstimateModal: useShowInsuranceEstimate(
      confirmProceduresEstimateModal,
      canRunFeeCalc,
      patientInsurances
    ),
    confirmProceduresEstimateModal,
    modalTitle:
      confirmProceduresEstimateModal.item?.method === "kiosk"
        ? "Send Treatment Plan to Kiosk"
        : confirmProceduresEstimateModal.item?.method === "print"
          ? "Print Treatment Plan"
          : "Send Treatment Plan",
  };
};

export const usePrintAppointmentInsuranceEstimate = (patientInsurances?: PatientInsuranceResponse[]) => {
  const printAppointmentModal = useItemModal<AppointmentVO>(null);

  const canRunFeeCalc = useCallback((value: AppointmentVO) => value.state !== "COMPLETED", []);

  return {
    showPrintAppointmentModal: useShowInsuranceEstimate(
      printAppointmentModal,
      canRunFeeCalc,
      patientInsurances
    ),
    printAppointmentModal,
  };
};
