import { QueryClient } from "@tanstack/react-query";
import { InvoiceUpdatedEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handleInvoiceUpdated = ({
  data,
  queryClient,
}: {
  data: InvoiceUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getInvoice"), { invoiceUuid: data.invoiceUuid }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "printInvoice"),
    { invoiceUuid: data.invoiceUuid },
  ]);
  queryClient.invalidateQueries([getQueryKey("practices", "getInvoices"), { patientId: data.patientId }]);

  // Invoice ledger entries `LedgerV2InvoiceSubEntryVO` may need invalidation
  // when a mix of positive and negative invoices cancel each other out
  // resulting in a $0 total amount, which wouldn't trigger a
  // `PatientLedgerUpdatedEvent` that would normally invalidate ledger entries.
  queryClient.invalidateQueries([
    getQueryKey("practices", "getLedgerEntries"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getLedgerEntryByTypeAndId"),
    { patientId: data.patientId },
  ]);
};
