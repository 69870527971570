import { getNextPageParam, makeInfiniteQuery, makeQuery, makeQueryOptionalArgs } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";
import { EmployeeStatus } from "api/employee/types";
import { OrderBy } from "api/patients/queries";

export const getEmployee = makeQuery({
  queryKey: ["practices", "getEmployee"],
  formatParams: ({
    practiceId,
    employeeId,
    ...query
  }: {
    practiceId: number;
    employeeId: number;
    includeCompensationDetails?: boolean;
    includeRole?: boolean;
  }) => [practiceId, employeeId, query],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getAllTaxonomyCodesQuery = makeQuery({
  queryKey: ["taxonomyCodes", "getAllTaxonomyCodes"],
  formatParams: () => [],
  queryOptions: cacheForSession,
});

export const getEmployeeOverview = makeQuery({
  queryKey: ["practices", "getEmployeeOverview"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getLastWorktimeAction = makeQuery({
  queryKey: ["practices", "getLastWorktimeAction"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
  queryOptions: oneMinuteCache,
});

export const getTimesheetData = makeQuery({
  queryKey: ["practices", "getTimesheetData"],
  formatParams: (args: {
    practiceId: number;
    employeeId: number;
    query: { startDate: string; endDate: string };
  }) => [args.practiceId, args.employeeId, args.query],
  queryOptions: oneMinuteCache,
});

export const getWorktimeOverview = makeQuery({
  queryKey: ["practices", "getWorktimeOverview"],
  formatParams: (args: {
    practiceId: number;
    employeeId: number;
    query: { startDate: string; endDate: string };
  }) => [args.practiceId, args.employeeId, args.query],
  queryOptions: oneMinuteCache,
});

export interface ApiEmployeeQueryParams {
  orderBy?: OrderBy;
  pageNumber: number;
  pageSize: number;
  searchString?: string;
  sortColumn?: string;
  statuses?: EmployeeStatus[];
  isOwner?: boolean;
}

export const getInfiniteEmployees = makeInfiniteQuery({
  queryKey: ["practices", "getEmployees"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & ApiEmployeeQueryParams) => [
    practiceId,
    query,
  ],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getEmployeeNames = makeQuery({
  queryKey: ["practices", "getEmployeeNames"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; statuses?: EmployeeStatus[] }) => [
    practiceId,
    query,
  ],
  queryOptions: oneMinuteCache,
});

export const getEmployeeOnboardingDocuments = makeQuery({
  queryKey: ["practices", "getEmployeeOnboardingDocuments"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getEmployeeJobTitlesV2 = makeQueryOptionalArgs({
  queryKey: ["jobTitlesV2", "getEmployeeJobTitlesV2"],
  formatParams: () => [],
  queryOptions: {
    ...fiveMinuteCache,
  },
});
