import { QueryClient } from "@tanstack/react-query";

import { getQueryKey } from "@libs/utils/queries";
import { handleUnreadMessage } from "api/websocket/handleUnreadMessage";
import { handleUpdatedErxNotificationCount } from "api/websocket/handleUpdatedErxNotificationCount";
import { handleGustoOnboardingFinished } from "api/websocket/handleGustoOnboardingFinished";
import { handlePayrollReview } from "api/websocket/handlePayrollReview";
import { handlePaymentUpdated } from "api/websocket/handlePaymentUpdated";
import { handlePatientLedgerUpdated } from "api/websocket/handlePatientLedgerUpdated";
import { handlePatientWalletBalanceUpdated } from "api/websocket/handlePatientWalletBalanceUpdated";
import { handleWalletActivityUpdated } from "api/websocket/handleWalletActivityUpdated";
import { updateUnreadMessageState } from "components/OfficeChat/officeChatEvents";
import { handleInvoiceUpdated } from "api/websocket/handleInvoiceUpdated";
import { handlePatientFormTasksUpdated } from "api/websocket/handlePatientFormTasksUpdated";
import { ServerPushMessages } from "api/websocket/types";
import { handleScheduleBlocksUpdated, handleScheduleUpdated } from "api/websocket/handleScheduleUpdated";
import { handleRoleUpdated } from "api/websocket/handleRoleUpdated";
import { handlePearlAnalysisUpdated } from "api/websocket/handlePearlAnalysisUpdated";
import { handleAppoinmentUpdated } from "api/websocket/handleAppointmentUpdated";
import {
  invalidateAlerts,
  invalidateAppointmentLists,
  invalidateNoteAppointment,
  invalidateNoteDetails,
  invalidateNoteLists,
} from "api/notes/cache";

import {
  InitiatedWebSocketEvents,
  didThisTabInitiateTheEvent,
} from "storage/initiatedWebSocketEventTimestamps";

const alreadyHandled = (
  currentUserId: number,
  initiatedById: number | undefined,
  eventType: InitiatedWebSocketEvents
) => currentUserId === initiatedById && didThisTabInitiateTheEvent(eventType);

// eslint-disable-next-line complexity, max-statements
export const messageDispatcher = (
  pushMessageData: ServerPushMessages,
  queryClient: QueryClient,
  practiceId: number,
  currentUserId: number
) => {
  switch (pushMessageData.type) {
    case "UNREAD_MESSAGE": {
      handleUnreadMessage({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "UPDATED_ERX_NOTIFICATION_COUNT": {
      handleUpdatedErxNotificationCount({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "GUSTO_ONBOARDING_FINISHED": {
      handleGustoOnboardingFinished({ queryClient, practiceId });
      break;
    }
    case "PAYROLL_REVIEW": {
      handlePayrollReview({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "PAYMENT_UPDATED": {
      handlePaymentUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "INVOICE_UPDATED": {
      handleInvoiceUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "PATIENT_LEDGER_UPDATED": {
      handlePatientLedgerUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "PATIENT_WALLET_BALANCE_UPDATED": {
      handlePatientWalletBalanceUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "PEARL_ANALYSIS_UPDATED": {
      handlePearlAnalysisUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "WALLET_ACTIVITY_UPDATED": {
      handleWalletActivityUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "INTRA_OFFICE_CHAT_UNREAD_MESSAGE": {
      updateUnreadMessageState(pushMessageData.payload.unreadMessageCount);

      break;
    }
    case "FORM_TASK": {
      handlePatientFormTasksUpdated({ data: pushMessageData.payload, queryClient, practiceId });
      break;
    }
    case "EMPLOYEE_CHAT_PREFERENCE_UPDATED": {
      queryClient.invalidateQueries([getQueryKey("practices", "getEmployeeChatPreference"), { practiceId }]);
      break;
    }
    case "SCHEDULE_UPDATED_V2": {
      if (!alreadyHandled(currentUserId, pushMessageData.initiatedById, pushMessageData.type)) {
        handleScheduleUpdated({ queryClient, practiceId });
      }

      break;
    }
    case "SCHEDULE_BLOCKS_UPDATED": {
      if (!alreadyHandled(currentUserId, pushMessageData.initiatedById, pushMessageData.type)) {
        handleScheduleBlocksUpdated({ queryClient, practiceId });
        queryClient.invalidateQueries([getQueryKey("practices", "getAvailableProviders"), { practiceId }]);
        queryClient.invalidateQueries([getQueryKey("practices", "getAvailableRooms"), { practiceId }]);
      }

      break;
    }
    case "APPOINTMENT_UPDATED": {
      if (!alreadyHandled(currentUserId, pushMessageData.initiatedById, pushMessageData.type)) {
        handleAppoinmentUpdated({
          queryClient,
          practiceId,
          appointmentUpdatedEvent: pushMessageData.payload,
        });
      }

      break;
    }
    case "ROLE_UPDATED": {
      handleRoleUpdated({ queryClient, practiceId });

      break;
    }
    case "ALERT_SETTINGS_BATCH_JOB_DONE": {
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNoteAlerts"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNote"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNoteAuditHistory"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      break;
    }
    case "APPOINTMENT_ALERT_SETTINGS_UPDATED": {
      queryClient.invalidateQueries([getQueryKey("practices", "getAlertSettings"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getNextPatientAppointment"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
      break;
    }
    case "PATIENT_NOTE_UPDATED": {
      if (!alreadyHandled(currentUserId, pushMessageData.initiatedById, pushMessageData.type)) {
        const { patientId, noteUuid, appointmentId } = pushMessageData.payload;

        // some optimizations to avoid unnecessary cache invalidations could be made by the
        // websocket event providing the old values for isArchived and isAlert and whether
        // or not the note is being created or updated
        invalidateAlerts({ queryClient, practiceId, patientId });
        invalidateNoteLists({ queryClient, practiceId, patientId });
        invalidateAppointmentLists({ queryClient, practiceId, patientId });
        invalidateNoteDetails({ queryClient, practiceId, patientNoteUuid: noteUuid });

        if (appointmentId) {
          invalidateNoteAppointment({ queryClient, practiceId, appointmentId, patientId });
        }
      }
    }
    // No default
  }
};
