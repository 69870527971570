import Skeleton from "react-loading-skeleton";
import { FormFieldUserSsnInput, FormFieldUserSsnInputProps } from "components/UI/FormFieldUserSsnInput";
import { NO_SELECTED_NUMERIC_ID } from "components/Patient/hooks";
import { FormFieldSsnInput } from "components/UI/FormFieldSsnInput";

type Props = Omit<FormFieldUserSsnInputProps, "label" | "id" | "required"> & {
  displayLabel?: boolean;
  isLoading?: boolean;
};
export const SubscriberSSNField: React.FC<Props> = ({
  displayLabel = true,
  isLoading = false,
  onSsnError,
  userId,
  ...props
}) => {
  if (isLoading) {
    return <Skeleton className="h-10" />;
  }

  const commonProps = {
    required: true,
    label: displayLabel ? "SSN" : "",
    id: "subscriberSSN",
  };

  return userId === NO_SELECTED_NUMERIC_ID ? (
    <FormFieldSsnInput {...props} {...commonProps} />
  ) : (
    <FormFieldUserSsnInput userId={userId} onSsnError={onSsnError} {...props} {...commonProps} />
  );
};
