import { FC, ChangeEventHandler, MouseEvent, useMemo, useCallback } from "react";
import { parseISO, startOfToday } from "date-fns";
import { useNavigate } from "react-router-dom";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { getFormattedSSNHidden } from "@libs/utils/ssn";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CalendarAddIcon } from "@libs/assets/icons/add-appointment.svg";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { ReactComponent as MenuVerticalIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { CheckboxCell, ButtonCell, IconsCell, Row } from "@libs/components/UI/GridTableComponents";
import { TWO_CLICKS } from "utils/handleDoubleClick";
import { paths } from "utils/routing/paths";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { useNow } from "hooks/useNow";

interface Props {
  onAddAppointment: (patientId: number) => void;
  onCopyEmail: (patient: PatientSummaryVO) => void;
  onRowClick: (patientId: number) => void;
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  patient: PatientSummaryVO;
}

export const isInThePast = (inputDate: string) => {
  return startOfToday() > parseISO(inputDate);
};

export const PatientRow: FC<Props> = ({
  onAddAppointment,
  onCopyEmail,
  onRowClick,
  onCheckboxChange,
  checked,
  patient,
}) => {
  const now = useNow();
  const navigate = useNavigate();
  const menu = useBoolean(false);
  const menuOff = menu.off;

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Send Message",
          value: "send-message",
          SvgIcon: MessageIcon,
        },
        {
          label: "Copy Email",
          value: "copy-email",
          SvgIcon: EmailIcon,
          tooltip: { content: patient.contact.email ? "" : "Email Required" },
          disabled: !patient.contact.email,
        },
        {
          label: "View Patient",
          value: "view-patient",
          SvgIcon: OpenProfileIcon,
        }
      ),
    [patient.contact.email]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "send-message": {
          navigate(paths.messaging({ patientId: patient.id }));
          break;
        }
        case "copy-email": {
          onCopyEmail(patient);
          break;
        }
        case "view-patient": {
          navigate(paths.patient({ patientId: patient.id }));
          break;
        }
        default: {
          break;
        }
      }

      menuOff();
    },
    [navigate, patient, onCopyEmail, menuOff]
  );

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (e.detail === TWO_CLICKS) {
        navigate(paths.patient({ patientId: patient.id }));
      } else {
        onRowClick(patient.id);
      }
    },
    [onRowClick, patient.id, navigate]
  );

  return (
    <Row isSelected={checked}>
      <CheckboxCell value={patient.id} checked={checked} onChange={onCheckboxChange} />
      <ButtonCell onClick={handleRowClick}>{getPatientDisplayNameFromPatient(now, patient)}</ButtonCell>
      <ButtonCell onClick={handleRowClick}>
        {patient.contact.callPhone ? formatPhoneNumber(patient.contact.callPhone) : "-"}
      </ButtonCell>
      <ButtonCell onClick={handleRowClick}>
        {patient.ssnLastFour ? getFormattedSSNHidden(patient.ssnLastFour) : "-"}
      </ButtonCell>
      <ButtonCell onClick={handleRowClick}>
        {patient.balanceAmount ? formatCurrency(patient.balanceAmount) : "-"}
      </ButtonCell>
      <ButtonCell onClick={handleRowClick}>{patient.primaryInsuranceCarrier || "-"}</ButtonCell>
      {!patient.nextAppointment || isInThePast(patient.nextAppointment) ? (
        <IconsCell className="justify-end">
          <ButtonIcon
            SvgIcon={CalendarAddIcon}
            tooltip={{ content: "Schedule Appointment", theme: "SMALL" }}
            theme="primary"
            onClick={() => {
              onAddAppointment(patient.id);
            }}
          />
        </IconsCell>
      ) : (
        <ButtonCell className="flex justify-end" onClick={handleRowClick}>
          {formatISODate(patient.nextAppointment)}
        </ButtonCell>
      )}
      <IconsCell>
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestClose={menu.off}
          onRequestOpen={() => {
            menu.on();
            onRowClick(patient.id);
          }}
          menuContent={<MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />}
          placement="bottom-end"
        >
          {(props) => <ButtonIcon {...props} SvgIcon={MenuVerticalIcon} theme="primary" />}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};
