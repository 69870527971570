import { UserAccountVO } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";
import { ReactComponent as HelpIcon } from "@libs/assets/icons/help.svg";
import { ReactComponent as BuildingIcon } from "@libs/assets/icons/building.svg";
import { FC, ReactNode, useCallback } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getIdentityToken } from "@libs/auth/getTokens";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { useItemModal } from "hooks/useItemModal";
import { AccountSwitcherPinModal } from "components/Main/AccountSwitcherPinModal";
import { issueAccountToken } from "api/user/mutations";
import { handleError } from "utils/handleError";

interface Props {
  title: ReactNode;
  accounts: UserAccountVO[];
  onAccountSelected: (account: UserAccountVO) => void;
  currentAccountId?: number;
}

export const AccountsList: FC<Props> = ({ accounts, onAccountSelected, title, currentAccountId }) => {
  const pinModal = useItemModal<UserAccountVO>(null);
  const { localStorage } = useStorageContext();

  const [issueAccountTokenMutation] = useApiMutations([issueAccountToken]);

  const switchAccount = (selectedAccount: UserAccountVO) => {
    issueAccountTokenMutation.mutate(
      { userId: selectedAccount.id, rememberMe: true },
      {
        onSuccess: async (response) => {
          const accountTokenStorage = getAccountTokenStorage(localStorage);
          const { email } = await getIdentityToken();

          accountTokenStorage.setAccountToken(email, {
            ...response.data.data,
            practiceId: selectedAccount.practice.id,
            practiceUuid: selectedAccount.practice.uuid,
          });
          onAccountSelected(selectedAccount);
        },
        onError: handleError,
      }
    );
  };

  const handleSwitchWithPinSuccess = useCallback(() => {
    const account = pinModal.item;

    if (account) {
      pinModal.close();
      onAccountSelected(account);
    }
  }, [onAccountSelected, pinModal]);

  return (
    <>
      <div className="pt-4 px-6">
        <h3 className="text-center text-lg font-sansSemiBold py-1.5 mb-6">{title}</h3>
        <div className="flex flex-col gap-y-2">
          {accounts.map((account) => {
            const disabled = account.ipBlocked;

            return (
              <button
                className={cx(
                  `p-2
                   rounded
                   border
                   border-slate-300
                   hover:bg-slate-100
                   flex
                   gap-x-4
                   w-[352px]
                   items-center`,
                  disabled ? "opacity-60" : "hover:bg-slate-100"
                )}
                type="button"
                disabled={disabled}
                key={account.id}
                onClick={() => {
                  if (account.pinRequired) {
                    pinModal.open(account);
                  } else if (account.id === currentAccountId) {
                    onAccountSelected(account);
                  } else {
                    switchAccount(account);
                  }
                }}
              >
                <div className="size-16 flex-none">
                  {account.practice.logo ? (
                    <img
                      className="rounded bg-white h-full w-full object-contain"
                      src={account.practice.logo.url}
                      alt="Logo"
                    />
                  ) : (
                    <div
                      className={`
                        h-full
                        w-full
                        rounded
                        bg-slate-100
                        flex
                        items-center
                        justify-center
                      `}
                    >
                      <Icon SvgIcon={BuildingIcon} size="lg" />
                    </div>
                  )}
                </div>
                <div className="flex-1 text-sm font-sansSemiBold text-left">{account.practice.name}</div>
                {disabled ? (
                  <Icon
                    tooltip={{ content: `This IP Address is not allowed to access ${account.practice.name}` }}
                    className="flex-none"
                    SvgIcon={HelpIcon}
                  />
                ) : null}
              </button>
            );
          })}
        </div>
      </div>
      {pinModal.isOpen ? (
        <AccountSwitcherPinModal
          account={pinModal.item}
          onClose={pinModal.close}
          onSwitchSuccess={handleSwitchWithPinSuccess}
        />
      ) : null}
    </>
  );
};
