import { QueryClient } from "@tanstack/react-query";
import { PatientBalanceAgingEntryVO } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { updatePaginatedEntriesCachedData } from "@libs/utils/queryCache";
import { produce } from "immer";
import { createBatchProcessor } from "@libs/utils/createBatchProcessor";
import { looseTypeKeyBy } from "@libs/utils/keyBy";
import { formatAsISODate, nowInTimezone } from "@libs/utils/date";

type StatementUpdatedEventArgs = {
  queryClient: QueryClient;
  practiceId: number;
  statementUuid: string;
  /** @format int64 */
  patientId: number;
};

const TWO_SECONDS = 2000;
const FIVE_SECONDS = 5000;

export const getStatementUpdatedBatchProcessor = ({ timezoneId }: { timezoneId: string }) =>
  createBatchProcessor<StatementUpdatedEventArgs>(
    (events) => {
      const { queryClient, practiceId } = events[0];
      const eventByPatientId = looseTypeKeyBy(events, "patientId");

      updatePaginatedEntriesCachedData<PatientBalanceAgingEntryVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getPatientBalanceAging"), { practiceId }],
          exact: false,
        },
        (page) => {
          return produce(page, (draft) => {
            for (const entry of draft.entries) {
              const matchingEvent = eventByPatientId[entry.patient.id];

              if (matchingEvent) {
                entry.lastStatement = {
                  uuid: matchingEvent.statementUuid,
                  date: formatAsISODate(nowInTimezone(timezoneId)),
                };
              }
            }
          });
        }
      );
    },
    {
      debounce: TWO_SECONDS,
      maxWait: FIVE_SECONDS,
    }
  );
