import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputActionMeta, MenuPlacement, MultiValue } from "react-select";
import designConfig from "@libs/design.config";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ReactComponent as FilterIcon } from "@libs/assets/icons/funnel.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { CarrierOption, handleFilterOption } from "components/Insurance/CarrierSelector/CarrierSelector";

const CheckboxRow: React.FC<{
  label: React.ReactNode;
  checked: boolean;
  payerId: string;
}> = React.memo(({ label, checked, payerId }) => {
  return (
    <Checkbox readOnly checked={checked} className="gap-0.5 pointer-events-none">
      <div className="flex items-center gap-0.5">
        <div className="flex-none w-14">{payerId}</div>
        <div className="flex-none w-48">{label}</div>
      </div>
    </Checkbox>
  );
});

const HeaderRow: React.FC<{ onToggleFilter: () => void; isFiltered: boolean; canFilter: boolean }> = ({
  onToggleFilter,
  canFilter,
  isFiltered,
}) => {
  return (
    <div
      className={`
        grid
        grid-cols-[1.5rem_3.5rem_12rem]
        gap-0.5
        h-5
        text-xs
        font-sansSemiBold
      `}
    >
      <div>
        {canFilter && (
          <ButtonIcon
            tooltip={{ content: "Filter Selected", theme: "SMALL" }}
            theme={isFiltered ? "primary" : "slate700"}
            type="button"
            SvgIcon={FilterIcon}
            onClick={onToggleFilter}
            className="h-5 -ml-1.5"
          />
        )}
      </div>
      <div>Payer ID</div>
      <div>Insurance Carrier Name</div>
    </div>
  );
};

type Props = {
  carriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  error?: string;
  label?: React.ReactNode;
  ariaLabel?: string;
  menuPlacement?: MenuPlacement;
  onSelect: (carrierIds: number[]) => void;
  required?: boolean;
  selectedCarrierIds: Set<number>;
};

export const SelectCarriers: React.FC<Props> = ({
  carriersQuery,
  error,
  label = "Insurance Carriers",
  ariaLabel,
  menuPlacement = "auto",
  onSelect,
  required,
  selectedCarrierIds,
}) => {
  const [carrierSearch, setCarrierSearch] = useState("");
  const filtered = useBoolean(false);

  const selectedCarriers = useMemo(() => {
    return new Set(selectedCarrierIds);
  }, [selectedCarrierIds]);

  const turnOffFilter = filtered.off;

  useEffect(() => {
    if (!selectedCarrierIds.size) {
      turnOffFilter();
    }
  }, [selectedCarrierIds.size, turnOffFilter]);

  const allOptions: GroupedSelectOptions<number, CarrierOption> = useMemo(() => {
    const innerOptions: CarrierOption[] =
      carriersQuery.data?.map((carrier) => {
        return {
          label: carrier.name,
          value: carrier.id,
          carrier,
        };
      }) ?? [];

    return [
      {
        label: "Custom Header",
        options: innerOptions,
      },
    ];
  }, [carriersQuery.data]);

  const filteredOptions = useMemo(() => {
    if (filtered.isOff) {
      return allOptions;
    }

    return [
      {
        label: "Custom Header",
        options: allOptions[0].options.filter((item) => {
          return selectedCarriers.has(item.value);
        }),
      },
    ];
  }, [filtered.isOff, allOptions, selectedCarriers]);

  const onInputChange = useCallback((value: string, action: InputActionMeta) => {
    if (action.action === "input-change") {
      setCarrierSearch(value);
    }
  }, []);

  const onChange = useCallback(
    (values: MultiValue<SelectOption<number>>) => {
      onSelect(values.map((value) => value.value));
    },
    [onSelect]
  );

  const customStyles = useMemo(
    () =>
      createSelectStyles<number, CarrierOption, true>({
        valueContainer: () => ({
          padding: 0,
        }),
        option: (_, { isFocused }) => ({
          backgroundColor: isFocused ? designConfig.colors.greyLightest : "white",
        }),
        menu: () => {
          return {
            minWidth: "24rem",
          };
        },
      }),
    []
  );

  const toggleFilter = useCallback(() => {
    setCarrierSearch("");
    filtered.toggle();
  }, [filtered]);

  return (
    <QueryResult queries={[carriersQuery]}>
      {carriersQuery.data && (
        <div className="flex flex-col gap-y-2">
          <FormFieldMultiSelect<number, CarrierOption>
            closeMenuOnSelect={false}
            error={error}
            filterOption={handleFilterOption}
            formatOptionLabel={(option) => {
              const { label: optionLabel, value, carrier } = option;

              return (
                <CheckboxRow
                  label={optionLabel}
                  payerId={carrier.payerId}
                  checked={selectedCarriers.has(value)}
                />
              );
            }}
            formatGroupLabel={() => (
              <HeaderRow
                onToggleFilter={toggleFilter}
                isFiltered={filtered.isOn}
                canFilter={selectedCarriers.size > 0}
              />
            )}
            hideSelectedOptions={false}
            inputValue={carrierSearch}
            label={label}
            aria-label={typeof label === "string" ? label : ariaLabel}
            menuPlacement={menuPlacement}
            noOptionsMessage={({ inputValue }) =>
              inputValue === "" ? "Loading Options..." : "No carriers found"
            }
            onChange={onChange}
            onInputChange={onInputChange}
            onMenuClose={() => {
              setCarrierSearch("");
            }}
            options={filteredOptions}
            placeholder="Search Insurance Carriers..."
            required={required}
            styles={customStyles}
            value={[...selectedCarrierIds]}
          />
        </div>
      )}
    </QueryResult>
  );
};
