import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { AppointmentVO, DailyHuddleAppointmentVO } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { ApiResponse } from "@libs/@types/api";
import { filterMap } from "@libs/utils/array";

export const invalidateNoteLists = ({
  queryClient,
  practiceId,
  patientId,
}: {
  patientId: number;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId, patientId }]);
};

const huddleAppointmentsHasPatientId = (
  data: InfiniteData<ApiResponse<DailyHuddleAppointmentVO[]>>,
  patientId: number
) => {
  for (const response of data.pages) {
    for (const appointment of response.data.data) {
      if (appointment.patient.id === patientId) {
        return true;
      }
    }
  }

  return false;
};

const appointmentCardsHasPatientId = (response: ApiResponse<AppointmentVO[]>, patientId: number) => {
  for (const appointment of response.data.data) {
    if (appointment.patient.id === patientId) {
      return true;
    }
  }

  return false;
};

// Only need to decache appointment lists if the patientId that was updated
// is in the cached query
export const invalidateAppointmentLists = ({
  queryClient,
  practiceId,
  patientId,
}: {
  patientId: number;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  const huddleKeysWithPatientId = filterMap(
    queryClient.getQueriesData<InfiniteData<ApiResponse<DailyHuddleAppointmentVO[]>>>({
      queryKey: [getQueryKey("practices", "getDailyHuddle"), { practiceId }],
    }),
    ([queryKey, data]) => (data && huddleAppointmentsHasPatientId(data, patientId) ? queryKey : null)
  );

  const cardKeysWithPatientId = filterMap(
    queryClient.getQueriesData<ApiResponse<AppointmentVO[]>>({
      queryKey: [getQueryKey("practices", "getAppointmentCards"), { practiceId }],
    }),
    ([queryKey, response]) =>
      response && appointmentCardsHasPatientId(response, patientId) ? queryKey : null
  );

  const querKeysWithPatientId = [...huddleKeysWithPatientId, ...cardKeysWithPatientId];

  for (const queryKey of querKeysWithPatientId) {
    queryClient.invalidateQueries(queryKey);
  }
};

export const invalidateAlerts = ({
  queryClient,
  practiceId,
  patientId,
}: {
  patientId: number;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientNoteAlerts"),
    { practiceId, patientId },
  ]);
};

export const invalidateNoteDetails = ({
  queryClient,
  practiceId,
  patientNoteUuid,
}: {
  patientNoteUuid: string;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientNoteAuditHistory"),
    { practiceId, patientNoteUuid },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientNote"),
    { practiceId, patientNoteUuid },
  ]);
};

// the following query resources are only related to notes in that
// if the clinical note status is updated it needs to be reflected.
// So only updates to clinical notes attached to an appointment
// cause these updates.
export const invalidateNoteAppointment = ({
  queryClient,
  practiceId,
  appointmentId,
  patientId,
}: {
  appointmentId: number;
  patientId: number;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getAppointmentClinicalNote"),
    {
      practiceId,
      appointmentId,
    },
  ]);

  queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { practiceId, appointmentId }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getNextPatientAppointment"),
    { practiceId, patientId },
  ]);
};
