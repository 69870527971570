import { useState, useCallback, useEffect, useRef } from "react";
import { captureMessage } from "@sentry/react";
import { noop } from "@libs/utils/noop";
import { SECOND_IN_MS } from "@libs/utils/date";

const useReconnect = ({
  connect,
  reconnectInterval,
}: {
  connect: Func;
  reconnectInterval: (attempt: number) => number;
}) => {
  const connectAttemptRef = useRef(0);
  const timeoutIdRef = useRef(0);

  const reconnect = useCallback(() => {
    // if we already have a request to connect
    // we can ignore. This can only happen if
    // we are trying to warm a new instance
    // and the live instance receives a close connection
    if (timeoutIdRef.current) {
      return;
    }

    connectAttemptRef.current += 1;
    timeoutIdRef.current = window.setTimeout(() => {
      timeoutIdRef.current = 0;
      connect();
    }, reconnectInterval(connectAttemptRef.current));
  }, [connect, reconnectInterval]);

  const resetReconnectAttempts = useCallback(() => {
    connectAttemptRef.current = 0;
  }, []);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutIdRef.current);
    };
  }, []);

  return { reconnect, resetReconnectAttempts };
};

const useDocumentFreezeResume = ({ onFreeze, onResume }: { onFreeze: Func; onResume: Func }) => {
  useEffect(() => {
    document.addEventListener("freeze", onFreeze);
    document.addEventListener("freeze", onResume);

    return () => {
      document.removeEventListener("freeze", onFreeze);
      document.removeEventListener("freeze", onResume);
    };
  }, [onFreeze, onResume]);
};

const useTimeout = ({
  enabled,
  onTimeout,
  timeout,
}: {
  enabled: boolean;
  onTimeout: Func;
  timeout: number;
}) => {
  useEffect(() => {
    if (enabled) {
      const timeoutId = window.setTimeout(onTimeout, timeout);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }

    return noop;
  }, [enabled, onTimeout, timeout]);
};

const useWebSocketListeners = (
  { live, warm, stale }: WebSocketState,
  {
    onWarmReady,
    onWarmClose,
    onLiveClose,
  }: {
    onWarmReady: (e: WebSocketEventMap["open"]) => void;
    onWarmClose: Func;
    onLiveClose: Func;
  }
) => {
  useEffect(() => {
    if (live) {
      live.addEventListener("close", onLiveClose);

      return () => {
        live.removeEventListener("close", onLiveClose);

        // It's important this comes last because we don't want it
        // to trigger the close handler which is meant for reconnecting.
        live.close();
      };
    }

    return noop;
  }, [live, onLiveClose]);

  useEffect(() => {
    if (warm) {
      warm.addEventListener("close", onWarmClose);
      warm.addEventListener("open", onWarmReady);

      return () => {
        warm.removeEventListener("close", onWarmClose);
        warm.removeEventListener("open", onWarmReady);
      };
    }

    return noop;
  }, [warm, onWarmClose, onWarmReady]);

  // We can't always close the warm instance when it changes
  // because it is re-used as the live instance when a conneciton
  // is opened.  This handles the other cases when the warm instance
  // is removed or changed.
  useEffect(() => {
    if (stale) {
      stale.close();
    }
  }, [stale]);
};

type WebSocketState =
  | {
      state: "Initial" | "Frozen";
      live: undefined;
      warm: undefined;
      stale: WebSocket | undefined;
    }
  | {
      state: "Connecting";
      live: undefined;
      warm: WebSocket;
      stale: WebSocket | undefined;
    }
  | {
      state: "Open";
      live: WebSocket;
      warm: undefined;
      stale: undefined;
    }
  | {
      state: "Resetting";
      live: WebSocket;
      warm: WebSocket;
      stale: WebSocket | undefined;
    };

const initialState = {
  live: undefined,
  warm: undefined,
  stale: undefined,
  state: "Initial" as const,
};

export const useWebSocketManager = ({
  createWebSocket,
  reconnectInterval,
  degradedConnectionDuration,
  resetConnectionInterval,
}: {
  createWebSocket?: () => Promise<WebSocket>;
  reconnectInterval: (attempt: number) => number;
  degradedConnectionDuration: number;
  resetConnectionInterval: number;
}) => {
  const hasCapturedDegradedState = useRef(false);
  const [webSocket, setWebSocket] = useState<WebSocketState>(initialState);

  const connect = useCallback(async () => {
    if (createWebSocket) {
      const warm = await createWebSocket();

      setWebSocket((last) =>
        last.live
          ? { state: "Resetting", live: last.live, warm, stale: last.warm }
          : { state: "Connecting", live: undefined, warm, stale: last.warm }
      );
    }
  }, [createWebSocket]);

  const open = useCallback((live: WebSocket) => {
    setWebSocket({ state: "Open", live, warm: undefined, stale: undefined });
  }, []);

  const close = useCallback(() => {
    setWebSocket((last) => ({ ...initialState, stale: last.warm }));
  }, []);

  const freeze = useCallback(() => {
    setWebSocket((last) => ({ ...initialState, stale: last.warm, state: "Frozen" }));
  }, []);

  const { reconnect, resetReconnectAttempts } = useReconnect({ connect, reconnectInterval });

  const handleLiveClose = useCallback(() => {
    close();
    reconnect();
  }, [reconnect, close]);

  const handleWarmReady = useCallback(
    (e: WebSocketEventMap["open"]) => {
      resetReconnectAttempts();
      open(e.currentTarget as WebSocket);
    },
    [resetReconnectAttempts, open]
  );

  const captureDegradedConnection = useCallback(() => {
    if (!hasCapturedDegradedState.current) {
      hasCapturedDegradedState.current = true;
      captureMessage(
        `WebSocket connection out for ${Math.round(degradedConnectionDuration / SECOND_IN_MS)} seconds.`,
        {
          level: "error",
          tags: {
            tabVisibilityState: document.visibilityState,
          },
        }
      );
    }
  }, [degradedConnectionDuration]);

  // listen to warm and live instances
  // to coordinate connections
  useWebSocketListeners(webSocket, {
    onWarmReady: handleWarmReady,
    onWarmClose: reconnect,
    onLiveClose: handleLiveClose,
  });

  // log when websocket is down for awhile
  useTimeout({
    onTimeout: captureDegradedConnection,
    timeout: degradedConnectionDuration,
    enabled: webSocket.state !== "Frozen" && !webSocket.live,
  });

  // recycle the websocket connection to comply with infrastructure
  // 2 hour connection limit
  useTimeout({
    onTimeout: connect,
    timeout: resetConnectionInterval,
    enabled: webSocket.state === "Open",
  });

  // detect if browser is freezing tabs, if so close connections and pause
  useDocumentFreezeResume({
    onFreeze: freeze,
    onResume: connect,
  });

  // Initialize
  useEffect(() => {
    connect();
  }, [connect]);

  return {
    webSocket,
    reconnect: handleLiveClose,
  };
};
