import React, { useEffect } from "react";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import OopsError from "@libs/assets/images/oops-error.svg";
import { useHistorySkip } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";
import { getSegmentAnalytics } from "utils/segment";

export const NotFound: React.FC<{ recover?: { url: string; label: string } }> = ({ recover }) => {
  useEffect(() => {
    const analytics = getSegmentAnalytics();

    if (analytics) {
      analytics.page(["[404 Page]"]);
    }
  }, []);
  // dont track a url that renders this component
  useHistorySkip();

  return (
    <div className="flex flex-col items-center justify-center h-[90vh] w-full">
      <img alt="Page Not Found" className="h-[422px] w-[500px]" src={OopsError} />
      <div className="text-secondaryTheme font-sansSemiBold text-6xl mt-8">Oops!</div>
      <div className="text-secondaryTheme font-sansSemiBold text-4xl">404 - Page not found</div>
      <div className="font-sans text-base mt-4 mb-8">The requested URL was not found.</div>
      <ButtonInternalLink to={recover?.url ?? paths.schedule()}>
        {recover?.label ?? "Return to Schedule"}
      </ButtonInternalLink>
    </div>
  );
};
