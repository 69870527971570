import { ErrorCode, FileRejection } from "react-dropzone";
import { formatBytes } from "@libs/utils/formatString";

export const isFileUploadRejection = (rejection: unknown): rejection is FileRejection => {
  return (
    Array.isArray((rejection as FileRejection).errors) && (rejection as FileRejection).file instanceof File
  );
};

const reMaxBytesAndUnitFromErrorMessage = /(?<limit>\d+)\sbytes/;

export const handleFileDropZoneRejection = (rejection: FileRejection) => {
  return rejection.errors.map((fileError) => {
    let alternateErrorMessage;

    if (fileError.code === (ErrorCode.FileTooLarge as string)) {
      const maxSizeMatch = fileError.message.match(reMaxBytesAndUnitFromErrorMessage);

      if (maxSizeMatch?.groups) {
        const limit = maxSizeMatch.groups["limit"];

        alternateErrorMessage = fileError.message.replace(
          reMaxBytesAndUnitFromErrorMessage,
          formatBytes(Number(limit))
        );
      }
    }

    return `${rejection.file.name}: ${alternateErrorMessage ?? fileError.message}`;
  });
};
