import React from "react";
import { handleError } from "utils/handleError";

type Func = () => void;
type ArgTypes<T> = T extends (...a: infer A) => unknown ? A : [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Call = (...args: any[]) => Promise<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Result<T> = T extends (...a: any[]) => Promise<infer A> ? A : unknown;

export interface UsePromise<F extends Call> {
  call: (...a: ArgTypes<F>) => Promise<Result<F> | null>;
  result: null | Result<F>;
  error: null | Error;
  isPending: boolean;
  reset: Func;
  clearResult: Func;
  clearError: Func;
}

export function usePromise<F extends Call>(
  start: F,
  options?: {
    onSuccess?: (value: Result<F>) => void;
    onError?: (err: unknown) => void;
    defaultPendingValue?: boolean;
  }
): UsePromise<F> {
  const [result, setResult] = React.useState<null | Result<F>>(null);
  const [error, setError] = React.useState<null | Error>(null);
  const [isPending, setIsPending] = React.useState<boolean>(options?.defaultPendingValue ?? false);
  const { onError, onSuccess } = options ?? {};

  const cancelRef = React.useRef(0);
  const call = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (...args: any[]): Promise<Result<F> | null> => {
      const now = Date.now();

      cancelRef.current = now;
      setIsPending(true);
      setError(null);

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
        const localResult: Result<F> = await start(...args);

        if (cancelRef.current === now) {
          setResult(localResult);

          onSuccess?.(localResult);

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return localResult;
        }
      } catch (callError) {
        if (cancelRef.current === now) {
          setError(callError as Error);
          onError?.(callError);
          handleError(callError);
        }
      } finally {
        setIsPending(false);
      }

      return null;
    },
    [start, onError, onSuccess]
  );

  const clearResult = React.useCallback(() => {
    setResult(null);
  }, []);

  const clearError = React.useCallback(() => {
    setError(null);
  }, []);

  const reset = React.useCallback(() => {
    setIsPending(options?.defaultPendingValue ?? false);
    clearError();
    clearResult();
  }, [clearError, clearResult, options?.defaultPendingValue]);

  React.useEffect(() => {
    return () => {
      cancelRef.current = Date.now();
    };
  }, []);

  return {
    call,
    result,
    error,
    isPending,
    reset,
    clearError,
    clearResult,
  };
}
