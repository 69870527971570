import React, { useCallback, useMemo, useState } from "react";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { RadioList } from "@libs/components/UI/RadioList";
import { PatientsQuery, PatientsQueryUpdates } from "utils/routing/patient";
import {
  PatientCriteriaStatus,
  PatientCriteriaGender,
  AppointmentCriteria,
  PatientCriteriaInsuranceVerified,
} from "api/patients/queries";
import { RangeSlider } from "components/UI/RangeSlider";
import { SelectCarriers } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectCarriers";

interface Props {
  carriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  onUpdateFilters: (keyValues: PatientsQueryUpdates) => void;
  queryStateApi: PatientsQuery;
}

export const STATUS_OPTIONS: { label: string; value: PatientCriteriaStatus }[] = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Archived",
    value: "ARCHIVED",
  },
  {
    label: "Deceased",
    value: "DECEASED",
  },
  {
    label: "Inactive",
    value: "INACTIVE",
  },
  {
    label: "Non Patient",
    value: "NONPATIENT",
  },
  {
    label: "Prospect",
    value: "PROSPECT",
  },
];

const GENDER_OPTIONS: { label: string; value: PatientCriteriaGender }[] = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

const MIN_AGE = 1;
const MAX_AGE = 100;

const AppointmentOptions: { label: string; value: AppointmentCriteria }[] = [
  { label: "No appointment", value: "NO_APPOINTMENT" },
  { label: "Appointment within 7 days", value: "APPOINTMENT_WITHIN_7_DAYS" },
  { label: "Appointment within 15 days", value: "APPOINTMENT_WITHIN_15_DAYS" },
  { label: "Appointment within 30 days", value: "APPOINTMENT_WITHIN_30_DAYS" },
];

const EXPIRED_STATUS = "INACTIVE";
const INSURANCE_STATUS_OPTIONS = [
  {
    label: "Verified",
    value: "VERIFIED",
  },
  {
    label: "Unverified",
    value: "NOT_VERIFIED",
  },
  {
    label: "Expired",
    value: EXPIRED_STATUS,
  },
];

export const PatientsFiltersFlyoverContent: React.FC<Props> = ({
  carriersQuery,
  onUpdateFilters,
  queryStateApi,
}) => {
  const [insuranceFilters, setInsuranceFilters] = useState(() => {
    const appliedStatus = queryStateApi["patientCriteria.primaryInsuranceVerifyStatuses"] || [];

    return {
      expired: queryStateApi["patientCriteria.primaryInsuranceExpired"],
      status: new Set(appliedStatus),
    };
  });

  const mergedStatus = useMemo(() => {
    if (insuranceFilters.expired) {
      return new Set([...insuranceFilters.status, EXPIRED_STATUS]);
    }

    return insuranceFilters.status;
  }, [insuranceFilters.expired, insuranceFilters.status]);

  const handleInsuranceStatusFilterChange = useCallback(
    (newSet: Set<string>) => {
      const values = [...newSet].filter((val) => val !== EXPIRED_STATUS);

      if (values.includes("NOT_VERIFIED")) {
        values.push("FAILED");
      }

      if (values.includes("VERIFIED")) {
        values.push("AUTO_VERIFIED");
      }

      const updates = {
        status: new Set(values) as Set<PatientCriteriaInsuranceVerified>,
        expired: newSet.has(EXPIRED_STATUS),
      };

      setInsuranceFilters((last) => {
        return { ...last, ...updates };
      });

      onUpdateFilters({
        /* eslint-disable @typescript-eslint/naming-convention */
        "patientCriteria.primaryInsuranceVerifyStatuses": [...updates.status],
        "patientCriteria.primaryInsuranceExpired": updates.expired,
        /* eslint-enable @typescript-eslint/naming-convention */
      });
    },
    [onUpdateFilters]
  );

  return (
    <div className="flex flex-col gap-y-6">
      {carriersQuery.data && (
        <SelectCarriers
          carriersQuery={carriersQuery}
          onSelect={(updatedIds) =>
            // eslint-disable-next-line @typescript-eslint/naming-convention
            onUpdateFilters({ "patientCriteria.carrierIds": updatedIds })
          }
          selectedCarrierIds={new Set(queryStateApi["patientCriteria.carrierIds"])}
        />
      )}
      <CheckboxList
        label="Patient Status"
        layout="vert"
        // eslint-disable-next-line @typescript-eslint/naming-convention
        onChange={(newSet) => onUpdateFilters({ "patientCriteria.status": [...newSet] })}
        options={STATUS_OPTIONS}
        selectedValues={new Set(queryStateApi["patientCriteria.status"])}
      />
      <RadioList
        label="Gender"
        layout="vert"
        // eslint-disable-next-line @typescript-eslint/naming-convention
        onChange={(_e, option) => onUpdateFilters({ "patientCriteria.gender": option.value })}
        options={GENDER_OPTIONS}
        selectedValue={queryStateApi["patientCriteria.gender"]}
      />
      <div className="mb-6">
        <RangeSlider
          label="Age Group"
          labelClassName="font-sansSemiBold"
          /* eslint-disable @typescript-eslint/naming-convention */
          onChange={(range) =>
            onUpdateFilters({
              "patientCriteria.ageGroup.max": range[1],
              "patientCriteria.ageGroup.min": range[0],
              /* eslint-enable @typescript-eslint/naming-convention */
            })
          }
          value={[
            queryStateApi["patientCriteria.ageGroup.min"] ?? MIN_AGE,
            queryStateApi["patientCriteria.ageGroup.max"] ?? MAX_AGE,
          ]}
        />
      </div>
      <RadioList
        label="Appointment"
        layout="vert"
        onChange={(event) =>
          onUpdateFilters({ appointmentCriteria: event.target.value as AppointmentCriteria })
        }
        options={AppointmentOptions}
        selectedValue={queryStateApi["appointmentCriteria"]}
      />
      <CheckboxList
        label="Insurance Status"
        layout="vert"
        onChange={handleInsuranceStatusFilterChange}
        options={INSURANCE_STATUS_OPTIONS}
        selectedValues={mergedStatus}
      />
    </div>
  );
};
