import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useQueryParams } from "hooks/useQueryParams";
import { getZendeskSsoJWT } from "api/user/queries";
import { LoadingOverlay } from "components/UI/LoadingOverlay";

export const SSOZendeskRoute = () => {
  const { query } = useQueryParams("ssoZendesk");
  const { practiceId } = useAccount();
  const [zendeskSsoQuery] = useApiQueries([getZendeskSsoJWT({ args: { practiceId } })]);
  const { data, isLoading } = zendeskSsoQuery;
  const jwt = data?.token;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    const params = new URLSearchParams();

    if (query.return_to) {
      params.set("return_to", query.return_to);
    }

    if (jwt) {
      params.set("jwt", jwt);
    }

    if (!jwt) {
      window.location.replace("https://support.archy.com");

      return;
    }

    // https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on
    // zendesk expects a form POST submission to /access/jwt to properly authenticate/redirect the user
    const formSubmission = document.createElement("form");

    document.body.append(formSubmission);
    formSubmission.method = "post";
    formSubmission.action = "https://archydental.zendesk.com/access/jwt";

    const input = document.createElement("input");

    input.type = "hidden";
    input.name = "jwt";
    input.value = jwt;
    formSubmission.append(input);
    formSubmission.submit();
  }, [query, isLoading, jwt]);

  return <LoadingOverlay isLoading loadingText="Loading Help Center..." />;
};
