import { useCallback, useMemo, useRef } from "react";
import { flushSync } from "react-dom";
import { ContactVO, PatientSummaryVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { createPatient } from "api/patients/mutations";
import { getContactPostData } from "components/Patient/formPostData";
import { getContactSchema } from "components/Patient/formSchemas";
import { handleError } from "utils/handleError";
import { useContactDraft } from "components/Patient/hooks";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { ContactForm } from "components/Patient/ContactForm";
import { ContactFormFields } from "components/Patient/ContactFormFields";
import { useIsDirty } from "hooks/useIsDirty";

interface Props {
  onContactSelected: (contact: ContactVO) => void;
  onCancel: Func;
  onDirty: Func;
  onMatchSelected: (patient: PatientSummaryVO) => void;
  patientFirstName: string;
}

export const AddContactPanel: React.FC<Props> = ({
  onContactSelected,
  onCancel,
  onMatchSelected,
  patientFirstName,
  onDirty,
}) => {
  const { practiceId } = useAccount();
  const contactFieldsRef = useRef<HTMLDivElement>(null);

  const { handleUpdateContactDraft, contactDraft, patientMatch } = useContactDraft();

  const [createPatientMutation] = useApiMutations([createPatient]);

  const schema = useMemo(
    () =>
      getContactSchema({
        contactModes: contactDraft.contactModes,
        preferredContactMode: contactDraft.preferredContactMode,
      }),
    [contactDraft.preferredContactMode, contactDraft.contactModes]
  );

  const { result: contactValidation, validate: validateContact } = useValidation(contactDraft, schema);

  const handleSubmit = useCallback(() => {
    const result = flushSync(() => validateContact());

    if (result.$isValid) {
      createPatientMutation.mutate(
        {
          practiceId,
          data: {
            ...getContactPostData(contactDraft),
            insuranceDetails: {
              type: "NONE",
            },
          },
        },
        {
          onError: handleError,
          onSuccess: (response) => {
            const newPatient = response.data.data[0];

            // should always be themselves as they
            // are newly created and can't add
            // a contact in this form.
            if (newPatient.contact.relation === "SELF") {
              onContactSelected({
                ...newPatient.contact,
                relation: contactDraft.relationship,
              });
            }
          },
        }
      );
    } else {
      scrollToFirstError(contactFieldsRef.current);
    }
  }, [validateContact, createPatientMutation, practiceId, contactDraft, onContactSelected]);

  useIsDirty(contactDraft, { onDirty });

  return (
    <ContactForm
      dataTestId="add-contact-form"
      isSaving={createPatientMutation.isLoading}
      onCancel={onCancel}
      disabled={Boolean(patientMatch)}
      onSave={handleSubmit}
    >
      <ContactFormFields
        containerRef={contactFieldsRef}
        onSelectPatientMatch={onMatchSelected}
        onUpdate={handleUpdateContactDraft}
        contractDraft={contactDraft}
        patientMatch={patientMatch}
        relationshipLabel={patientFirstName ? `Relationship${` to ${patientFirstName}`}` : undefined}
        validations={contactValidation}
        isCreatingContact
      />
    </ContactForm>
  );
};
