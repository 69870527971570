import { QueryClient } from "@tanstack/react-query";
import {
  AppointmentRequest,
  SchedulingConfigRequest,
  SchedulingConfigVO,
  CustomHolidayVO,
  PublicHolidayVO,
  WorkingHourItemVO,
  CreateScheduleBlockRequest,
  UpdateScheduleBlockRequest,
  AppointmentVO,
  AppointmentCategoryRequest,
} from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { makeMutation } from "@libs/utils/mutations";
import { updateCachedData, updatePaginatedCachedData } from "@libs/utils/queryCache";
import { invalidateProceduresAndTreatmentPlan } from "api/charting/mutations";
import { invalidatePendingClaims } from "api/claim/cache";
import { trackIniatedWebSocketEvents } from "storage/initiatedWebSocketEventTimestamps";
import {
  handleProviderHoursUpdated,
  handleRoomsHoursUpdated,
  handleScheduleBlocksUpdated,
} from "api/websocket/handleScheduleUpdated";
import { handleAppoinmentUpdated } from "api/websocket/handleAppointmentUpdated";

const invalidateNonScheduleQueriesRelatedToAppointments = (
  queryClient: QueryClient,
  { patientId, practiceId }: { patientId: number; practiceId: number }
) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getPatients"), { practiceId }]);
  invalidateProceduresAndTreatmentPlan(queryClient, { patientId, practiceId });

  const patientKeys = [
    getQueryKey("practices", "getPatientRecalls"),
    getQueryKey("v2", "getPatientChartV2"),
    getQueryKey("practices", "getLedgerEntries"),
    getQueryKey("practices", "getLedgerBalanceSummary"),
    getQueryKey("practices", "getPendingInvoiceEntries"),
    getQueryKey("practices", "getFamilyMembersWithPendingInvoiceEntries"),
  ];

  for (const patientKey of patientKeys) {
    queryClient.invalidateQueries([patientKey, { patientId, practiceId }]);
  }
  queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);
};

const trackBlocksUpdated = (queryClient: QueryClient, practiceId: number) => {
  trackIniatedWebSocketEvents("SCHEDULE_BLOCKS_UPDATED");
  handleScheduleBlocksUpdated({ queryClient, practiceId });
  queryClient.invalidateQueries([getQueryKey("practices", "getAvailableProviders"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getAvailableRooms"), { practiceId }]);
};

const trackHoursUpdated = (queryClient: QueryClient, practiceId: number) => {
  trackIniatedWebSocketEvents("SCHEDULE_UPDATED_V2");
  handleRoomsHoursUpdated({ queryClient, practiceId });
  handleProviderHoursUpdated({ queryClient, practiceId });
};

export const deleteAppointment = makeMutation({
  mutationKey: ["practices", "deleteAppointment"],
  formatParams: (args: { practiceId: number; appointment: AppointmentVO }) => [
    args.practiceId,
    args.appointment.id,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { appointment, practiceId }) => {
      trackIniatedWebSocketEvents("APPOINTMENT_UPDATED");

      const patientId = appointment.patient.id;
      const appointmentId = appointment.id;

      handleAppoinmentUpdated({
        queryClient,
        practiceId,
        appointmentUpdatedEvent: {
          id: appointment.id,
          state: "_DELETED",
          origState: appointment.state,
          asap: appointment.asap,
          origAsap: appointment.asap,
          date: appointment.date,
          origDate: appointment.date,
          patientId: appointment.patient.id,
        },
      });

      invalidateNonScheduleQueriesRelatedToAppointments(queryClient, {
        patientId,
        practiceId,
      });
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLedgerEntryByTypeAndId"),
        {
          practiceId,
          entryType: "APPOINTMENT",
          entryId: String(appointmentId),
        },
      ]);
    },
  }),
});

export const updateAppointment = makeMutation({
  mutationKey: ["practices", "updateAppointment"],
  formatParams: (args: {
    practiceId: number;
    appointmentId: number;
    original: Pick<AppointmentVO, "state" | "date" | "asap">;
    data: AppointmentRequest;
  }) => [args.practiceId, args.appointmentId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { appointmentId, data, original, practiceId }) => {
      trackIniatedWebSocketEvents("APPOINTMENT_UPDATED");

      const appointment = response.data.data;

      handleAppoinmentUpdated({
        queryClient,
        practiceId,
        appointmentUpdatedEvent: {
          id: appointment.id,
          state: appointment.state,
          origState: original.state,
          asap: appointment.asap,
          origAsap: original.asap,
          date: appointment.date,
          origDate: original.date,
          patientId: appointment.patient.id,
        },
      });
      invalidateNonScheduleQueriesRelatedToAppointments(queryClient, {
        patientId: data.patientId,
        practiceId,
      });

      queryClient.invalidateQueries([
        getQueryKey("practices", "getLedgerEntryByTypeAndId"),
        {
          practiceId,
          entryType: "APPOINTMENT",
          entryId: String(appointmentId),
        },
      ]);

      if (data.state === "COMPLETED") {
        invalidatePendingClaims(queryClient, { practiceId });
      }
    },
  }),
});

export const addAppointment = makeMutation({
  mutationKey: ["practices", "addAppointment"],
  formatParams: (args: { practiceId: number; data: AppointmentRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { data, practiceId }) => {
      trackIniatedWebSocketEvents("APPOINTMENT_UPDATED");

      const appointment = response.data.data;

      handleAppoinmentUpdated({
        queryClient,
        practiceId,
        appointmentUpdatedEvent: {
          id: appointment.id,
          state: appointment.state,
          asap: appointment.asap,
          date: appointment.date,
          patientId: appointment.patient.id,
        },
      });
      invalidateNonScheduleQueriesRelatedToAppointments(queryClient, {
        patientId: data.patientId,
        practiceId,
      });
    },
  }),
});

export const updateSchedulingConfig = makeMutation({
  mutationKey: ["practices", "updateSchedulingConfig"],
  formatParams: (args: { practiceId: number; data: SchedulingConfigRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<SchedulingConfigVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getSchedulingConfig"), { practiceId }], exact: true },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});

export const upsertPublicHolidays = makeMutation({
  mutationKey: ["practices", "upsertPublicHolidays"],
  formatParams: (args: { practiceId: number; data: PublicHolidayVO[] }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackHoursUpdated(queryClient, practiceId);
      queryClient.invalidateQueries([getQueryKey("practices", "getPublicHolidaysByYear"), { practiceId }]);
    },
  }),
});

export const upsertCustomHolidays = makeMutation({
  mutationKey: ["practices", "upsertCustomHolidays"],
  formatParams: (args: { practiceId: number; data: CustomHolidayVO[] }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackHoursUpdated(queryClient, practiceId);
      queryClient.invalidateQueries([getQueryKey("practices", "getCustomHolidays"), { practiceId }]);
    },
  }),
});

export const upsertProviderCustomHolidays = makeMutation({
  mutationKey: ["practices", "upsertProviderCustomHolidays"],
  formatParams: (args: { practiceId: number; employeeId: number; data: CustomHolidayVO[] }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, employeeId }) => {
      trackHoursUpdated(queryClient, practiceId);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getCustomHolidays"),
        { practiceId, providerId: employeeId },
      ]);
    },
  }),
});

export const upsertWorkingHours = makeMutation({
  mutationKey: ["practices", "upsertWorkingHours"],
  formatParams: (args: { practiceId: number; workingHours: WorkingHourItemVO[] }) => [
    args.practiceId,
    args.workingHours,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackHoursUpdated(queryClient, practiceId);
      queryClient.invalidateQueries([getQueryKey("practices", "getWorkingHours"), { practiceId }]);
    },
  }),
});

export const upsertProviderWorkingHours = makeMutation({
  mutationKey: ["practices", "upsertProviderWorkingHours"],
  formatParams: (args: { practiceId: number; employeeId: number; data: WorkingHourItemVO[] }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, employeeId }) => {
      trackHoursUpdated(queryClient, practiceId);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getWorkingHours"),
        { practiceId, providerId: employeeId },
      ]);
    },
  }),
});

export const deleteScheduleBlock = makeMutation({
  mutationKey: ["practices", "deleteScheduleBlock"],
  formatParams: (args: { practiceId: number; blockId: number }) => [args.practiceId, args.blockId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const deleteRecurringScheduleBlockInstance = makeMutation({
  mutationKey: ["practices", "deleteRecurringScheduleBlockInstance"],
  formatParams: (args: { practiceId: number; blockId: number; instanceDate: string }) => [
    args.practiceId,
    args.blockId,
    args.instanceDate,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const deleteRecurringScheduleBlockFuture = makeMutation({
  mutationKey: ["practices", "deleteRecurringScheduleBlockFuture"],
  formatParams: (args: { practiceId: number; blockId: number; instanceDate: string }) => [
    args.practiceId,
    args.blockId,
    args.instanceDate,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const updateScheduleBlock = makeMutation({
  mutationKey: ["practices", "updateScheduleBlock"],
  formatParams: (args: { practiceId: number; blockId: number; data: UpdateScheduleBlockRequest }) => [
    args.practiceId,
    args.blockId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const updateRecurringScheduleBlockInstance = makeMutation({
  mutationKey: ["practices", "updateRecurringScheduleBlockInstance"],
  formatParams: (args: {
    practiceId: number;
    blockId: number;
    instanceDate: string;
    data: UpdateScheduleBlockRequest;
  }) => [args.practiceId, args.blockId, args.instanceDate, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const updateRecurringScheduleBlockFuture = makeMutation({
  mutationKey: ["practices", "updateRecurringScheduleBlockFuture"],
  formatParams: (args: {
    practiceId: number;
    blockId: number;
    instanceDate: string;
    data: UpdateScheduleBlockRequest;
  }) => [args.practiceId, args.blockId, args.instanceDate, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const createScheduleBlock = makeMutation({
  mutationKey: ["practices", "createScheduleBlock"],
  formatParams: (args: { practiceId: number; data: CreateScheduleBlockRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      trackBlocksUpdated(queryClient, practiceId);
    },
  }),
});

export const batchUpdateAsapAppointments = makeMutation({
  mutationKey: ["practices", "batchUpdateAsapAppointments"],
  formatParams: (args: {
    practiceId: number;
    data: { asap: boolean; appointments: { id: number; patientId: number }[] };
  }) => [
    args.practiceId,
    {
      asap: args.data.asap,
      appointmentIds: args.data.appointments.map(({ id }) => id),
    },
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, data }) => {
      updatePaginatedCachedData<AppointmentVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getAsapAppointments"), { practiceId }],
          exact: false,
        },
        (cachedData) => {
          const ids = new Set(data.appointments.map(({ id }) => id));

          if (!data.asap) {
            return cachedData.filter((item) => !ids.has(item.id));
          }

          return cachedData;
        }
      );
    },
  }),
});

export const upsertAppointmentCategories = makeMutation({
  mutationKey: ["practices", "upsertAppointmentCategories"],
  formatParams: (args: { practiceId: number; data: AppointmentCategoryRequest[] }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.setQueryData([getQueryKey("practices", "getAppointmentCategories"), { practiceId }], data);
      queryClient.invalidateQueries([getQueryKey("practices", "getSchedulingConfig"), { practiceId }]);
    },
  }),
});
