import { FC, PropsWithChildren, ReactNode } from "react";
import { ROOT_APP_LAYOUT } from "@libs/utils/constants";
import { cx } from "@libs/utils/cx";
import { useCurrentUser } from "contexts/CurrentUserContext";

interface Props {
  nav: ReactNode;
}

export const AppLayout: FC<PropsWithChildren<Props>> = ({ nav, children }) => {
  const currentUser = useCurrentUser();

  return (
    <div id={ROOT_APP_LAYOUT} className="h-full z-0 relative flex items-stretch">
      <div
        className={cx(
          "flex-none w-14 p-3 print:hidden",
          currentUser.type === "SUPPORT_USER" ? "bg-yellow" : "bg-slate-50"
        )}
      >
        {nav}
      </div>
      <div className="flex-1 min-w-0 bg-slate-100">{children}</div>
    </div>
  );
};
