/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, createContext, useContext, PropsWithChildren, useMemo } from "react";

import { HOUR_IN_MINUTES, MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import { useCreateWebSocket } from "hooks/useCreateWebSocket";
import { useWebSocketManager } from "hooks/useWebSocketManager";
import { WebSocketMessageEmitter } from "api/websocket/webSocketMessage";
import { useStatementUpdatedMessage } from "hooks/useStatementUpdatedMessage";
import { useMessageDispatcher } from "hooks/useMessageDispatcher";
import { usePingPong } from "hooks/usePingPong";
import { useWebSocketMessages } from "hooks/useWebSocketMessages";

const MINUTE = MINUTE_IN_SECONDS * SECOND_IN_MS;
const FIVE_SECONDS = 5 * SECOND_IN_MS;
const TEN_SECONDS = 2 * FIVE_SECONDS;
const HOUR = HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

const Context = createContext<{ messages: WebSocketMessageEmitter | undefined }>({
  messages: undefined,
});

Context.displayName = "WebSocketContext";

export const webSocketConfig = {
  reconnectInterval: (attempt: number) =>
    attempt <= 10 ? SECOND_IN_MS : attempt <= 20 ? FIVE_SECONDS : half(MINUTE),
  degradedConnectionDuration: TEN_SECONDS,
  resetConnectionInterval: HOUR,
};

export const WebSocketProvider: FC<PropsWithChildren<{ onTokenError: (error: unknown) => void }>> = ({
  children,
  onTokenError,
}) => {
  const createWebSocket = useCreateWebSocket({ onTokenError });

  const { webSocket, reconnect } = useWebSocketManager({
    createWebSocket,
    ...webSocketConfig,
  });

  const messages = useWebSocketMessages(webSocket.live);

  useMessageDispatcher(messages);
  useStatementUpdatedMessage(messages);

  // need to send data every so often to keep connection alive
  // as well as for the client to know the connection is
  // still working.  If no response is given aftern some time
  // try to reconnect
  usePingPong({
    webSocket: webSocket.live,
    messages,
    onResetConnection: reconnect,
    pingInterval: MINUTE,
    pingTimeout: TEN_SECONDS,
  });

  const value = useMemo(
    () => ({
      messages,
    }),
    [messages]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useWebSocketContext = () => useContext(Context);
