import { ComponentProps, FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

const cxStylesIndicator = {
  theme: {
    red: {
      color: { background: "bg-red", text: "text-white" },
    },
    slate700: {
      color: { background: "bg-slate-700", text: "text-white" },
    },
  },
};

const Indicator: FC<PropsWithChildren<{ theme?: keyof typeof cxStylesIndicator.theme }>> = ({
  children,
  theme = "red",
}) => (
  <div
    className={cx(
      `flex
       items-center
       justify-center
       aspect-square
       min-h-3
       p-0.5
       rounded-full
       text-xxs
       absolute
       right-0
       bottom-0
       translate-x-1/4
       translate-y-1/4
       pointer-events-none`,
      cxStylesIndicator.theme[theme].color.background,
      cxStylesIndicator.theme[theme].color.text
    )}
  >
    {children}
  </div>
);

export const NotificationIndicator: React.FC<
  PropsWithChildren<{ hasNotifications: boolean } & ComponentProps<typeof Indicator>>
> = ({ children, hasNotifications, ...indicatorProps }) => (
  <div className="relative w-fit h-fit">
    {children}
    {hasNotifications && <Indicator {...indicatorProps} />}
  </div>
);

export const NotificationCountIndicator: React.FC<
  PropsWithChildren<{ count?: number } & ComponentProps<typeof Indicator>>
> = ({ children, count, ...indicatorProps }) => (
  <div className="relative w-fit h-fit">
    {children}
    {count ? <Indicator {...indicatorProps}>{count}</Indicator> : null}
  </div>
);
