import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";

export const getLabs = makeQuery({
  queryKey: ["practices", "getLabs"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; includeArchived?: boolean }) => [
    practiceId,
    query,
  ],
  queryOptions: fiveMinuteCache,
});

export const getLab = makeQuery({
  queryKey: ["practices", "getLab"],
  formatParams: ({ practiceId, labUuid }: { practiceId: number; labUuid: string }) => [practiceId, labUuid],
  queryOptions: fiveMinuteCache,
});

export const getLabCaseTypes = makeQuery({
  queryKey: ["practices", "getLabCaseTypes"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, query],
  queryOptions: fiveMinuteCache,
});

export const getLabCaseType = makeQuery({
  queryKey: ["practices", "getLabCaseType"],
  formatParams: ({ practiceId, labCaseTypeUuid }: { practiceId: number; labCaseTypeUuid: string }) => [
    practiceId,
    labCaseTypeUuid,
  ],
  queryOptions: fiveMinuteCache,
});

export const printLabOrderForm = makeQuery({
  queryKey: ["practices", "printLabOrderForm"],
  formatParams: ({ practiceId, labUuid }: { practiceId: number; labUuid: string }) => [practiceId, labUuid],
  queryOptions: fiveMinuteCache,
});

export const getLabCaseReturnReasons = makeQuery({
  queryKey: ["practices", "getLabCaseReturnReasons"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: fiveMinuteCache,
});

export const getLabCaseReturnReason = makeQuery({
  queryKey: ["practices", "getLabCaseReturnReason"],
  formatParams: ({
    practiceId,
    labCaseReturnReasonUuid,
  }: {
    practiceId: number;
    labCaseReturnReasonUuid: string;
  }) => [practiceId, labCaseReturnReasonUuid],
  queryOptions: fiveMinuteCache,
});

export const getLabCases = makeInfiniteQuery({
  queryKey: ["practices", "getLabCases"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    patientId?: number;
    patientSearchString?: string;
    statuses?: ("DRAFT" | "SENT" | "RECEIVED" | "RETURNED" | "READY" | "COMPLETE")[];
    sortField?:
      | "DATE"
      | "PATIENT"
      | "LAB"
      | "ESTIMATED_RECEIVE_DATE"
      | "NEXT_PATIENT_PROCEDURE_DATE"
      | "STATUS";
    sortDirection?: "ASCENDING" | "DESCENDING";
    includeArchived?: boolean;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, query],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getLabCase = makeQuery({
  queryKey: ["practices", "getLabCase"],
  formatParams: ({ practiceId, labCaseUuid }: { practiceId: number; labCaseUuid: string }) => [
    practiceId,
    labCaseUuid,
  ],
  queryOptions: fiveMinuteCache,
});
