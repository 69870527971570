import { useCallback, useState } from "react";

const FORCE_DYNAMSOFT_KEY = "forceDynamsoft";

export const getHasForceDynamsoft = () => localStorage.getItem(FORCE_DYNAMSOFT_KEY) === "1";

export const useForceDynamsoft = () => {
  const [hasForcedDynamsoft, setForceDynamsoft] = useState<boolean>(
    () => localStorage.getItem(FORCE_DYNAMSOFT_KEY) === "1"
  );

  const toggleForceDynamsoft = useCallback(() => {
    const newValue = !hasForcedDynamsoft;

    localStorage.setItem(FORCE_DYNAMSOFT_KEY, newValue ? "1" : "0");
    setForceDynamsoft(newValue);
  }, [hasForcedDynamsoft]);

  return {
    hasForcedDynamsoft,
    toggleForceDynamsoft,
  };
};
