import { FC, HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";

export type GlyphFamily = "EMOJI" | "ARCHY";

const cxGlyphFontFamilies: Record<GlyphFamily, string> = {
  EMOJI: "font-emoji",
  ARCHY: "font-archyIcons",
};

type Size = "sm" | "md" | "lg";

const cxSizes: Record<Size, string> = {
  sm: "text-sm 2dpr:text-[17px] leading-4",
  md: `
    text-base
    relative
    -top-0.5
    2dpr:top-0
    2dpr:text-[19px]
    leading-5
  `,
  lg: "text-2xl leading-6",
};

// after visually testing characters side by side
// this font size seemed to best match
// the corresponding emoji size.
// The Archy font icons have a little white space around the
// edges compared to emojis.
const archyFontSizesMap: Record<Size, string> = {
  sm: "text-[19px] leading-none",
  md: "text-[21px] leading-none",
  lg: "text-[25px] leading-none",
};

type GlyphProps = {
  family: GlyphFamily;
  size?: Size;
  inline?: boolean;
};

export const Glyph: FC<GlyphProps & Omit<HTMLAttributes<HTMLDivElement>, "className" | "style">> = ({
  family,
  inline,
  size = "md",
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={cx(
        inline && "inline align-middle",
        cxGlyphFontFamilies[family],
        family === "EMOJI" ? cxSizes[size] : archyFontSizesMap[size]
      )}
    />
  );
};
