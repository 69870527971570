import { QueryClient } from "@tanstack/react-query";
import { FormTaskEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handlePatientFormTasksUpdated = ({
  data,
  queryClient,
  practiceId,
}: {
  data: FormTaskEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  // The listFormTasks query is only used for the selected appointment within
  // PatientBlurb, and needs to be invalidated when there are appointment
  // changes that may incur new or expired form tasks
  queryClient.invalidateQueries([
    getQueryKey("practices", "listFormTasks"),
    {
      practiceId,
      patientId: data.patientId,
      // FormTaskEvent will return with a null appointmentId for tasks that are
      // not appointment-specific (e.g. Treatment Plan, Medical History, etc.),
      // but still needs to be invalidated for when the task has been completed
      ...(data.appointmentId ? { appointmentIds: [data.appointmentId] } : undefined),
    },
  ]);
};
