import { FC } from "react";
import { ContactDetailsVO, ContactModeVO } from "@libs/api/generated-api";
import { lowercase, sentenceCase } from "@libs/utils/casing";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { CONTACT_MODE_OPTIONS, ContactMode } from "components/Patient/contactModes";
import { ToggleButtonRadioListProps } from "components/UI/ToggleButtonList";
import { ToggleGroup } from "components/UI/ToggleGroup";

interface Props
  extends Pick<
    ToggleButtonRadioListProps<Uppercase<ContactMode>, ListItem<typeof CONTACT_MODE_OPTIONS>>,
    "className" | "error"
  > {
  preferredContactMode: ContactDetailsVO["preferredContactMode"] | undefined;
  contactModes: ContactModeVO;
  hasContact?: boolean;
  edit?: boolean;
  onChange: (update: ContactDetailsVO["preferredContactMode"]) => void;
}

const label = "Preferred Contact Method";

export const FormFieldPreferredContactMode: FC<Props> = ({
  edit = true,
  hasContact = false,
  preferredContactMode,
  contactModes,
  className,
  error,
  onChange,
}) => {
  const options = CONTACT_MODE_OPTIONS.map((op) => ({
    ...op,
    disabled: !contactModes[lowercase(op.value)],
  }));

  const selectedValue = preferredContactMode && !hasContact ? preferredContactMode : undefined;

  return edit ? (
    <div className={className}>
      <ToggleGroup
        onChange={(_e, { value }) => {
          onChange(value);
        }}
        disabled={hasContact}
        required={!hasContact}
        label={label}
        error={error}
        options={options}
        size="md"
        selectedValue={selectedValue}
      />
    </div>
  ) : (
    <FormFieldInput
      edit={false}
      label={label}
      className={className}
      value={selectedValue ? sentenceCase(selectedValue) : undefined}
    />
  );
};
