import { FC } from "react";

import { AppointmentVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";

import { AppointmentMenu, AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";
import {
  AppointmentComment,
  AppointmentRates,
} from "components/ScheduleAppointments/AppointmentDetailComponents";
import { AppointmentSummary } from "components/PatientBlurbInfo/AppointmentSummary";

interface Props {
  appointment: AppointmentVO;
  appointmentActions: AppointmentMenuProps["actions"];
  isNextAppointment: boolean;
  hasInsurance: boolean;
  onViewAppointments?: Func;
  onUpdateAppointmentState: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  onAppointmentDeleted?: (params: { patientId: number; appointmentId: number }) => void;
}

export const PatientAppointmentDetails: FC<Props> = ({
  appointment,
  appointmentActions,
  isNextAppointment,
  hasInsurance,
  onViewAppointments,
  onUpdateAppointmentState,
  onAppointmentDeleted,
}) => {
  return (
    <div className="flex flex-col gap-y-3 text-xs">
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center justify-between">
          <span className="font-sansSemiBold leading-6">
            {isNextAppointment ? "Next Appointment" : "Appointment Details"}
          </span>

          <div className="flex items-center gap-x-1">
            {onViewAppointments ? (
              <ButtonIcon
                tooltip={{ content: "View All Appointments", theme: "SMALL" }}
                SvgIcon={CalendarIcon}
                onClick={onViewAppointments}
              />
            ) : null}
            <AppointmentMenu
              appointment={appointment}
              placement="right-start"
              triggerSize="md"
              onAppointmentDeleted={onAppointmentDeleted}
              actions={appointmentActions}
            />
          </div>
        </div>

        <AppointmentSummary
          appointment={appointment}
          onUpdateAppointmentState={onUpdateAppointmentState}
          includePadding={false}
        />
      </div>

      <AppointmentComment appointment={appointment} />

      <AppointmentRates appointment={appointment} hasInsurance={hasInsurance} />
    </div>
  );
};
