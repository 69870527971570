import { Enum, routesConfigHelper, StrVal, BoolVal, NumberOrNew } from "@libs/router/url";
import { PatientPathParams } from "utils/routing/patient";

export type PatientInsuranceDetailsStep = "info" | "carrier" | "coverage" | "limitations";

export const PatientInsuranceQueryParams = {
  from: StrVal,
  isAdding: BoolVal,
};

const InsuranceDetailsPathParams = {
  ...PatientPathParams,
  insuranceId: NumberOrNew,
};

export const PatientInsuranceRoutesConfig = routesConfigHelper({
  patientInsuranceDetails: {
    path: "/patients/:patientId/insurance/:insuranceId",
    params: InsuranceDetailsPathParams,
    query: PatientInsuranceQueryParams,
  },
  patientInsuranceStep: {
    path: "/patients/:patientId/insurance/:insuranceId/:step",
    params: {
      ...InsuranceDetailsPathParams,
      step: Enum<PatientInsuranceDetailsStep>().Val(["info", "carrier", "coverage", "limitations"]),
    },
    query: PatientInsuranceQueryParams,
  },
});
