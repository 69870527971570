import { FC } from "react";
import { format } from "date-fns";
import { AuditVO, UserVO } from "@libs/api/generated-api";
import { getLocalDate, formatDate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as MedicalDoctorIcon } from "@libs/assets/icons/medical-doctor.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { getAppointmentQuery } from "api/scheduling/queries";

interface Props {
  appointmentId: number;
  patientId: number;
  onRequestClose: Func;
}

export const AuditHeader = ({ date, name, type }: { date: string; name: string; type?: UserVO["type"] }) => {
  const localDate = getLocalDate(date);

  return (
    <div className="flex text-xs gap-x-2">
      <div className="font-sansSemiBold">{formatDate(localDate)}</div>
      <div className="text-greyLight">|</div>
      <div>{format(localDate, "p")}</div>
      <div className="text-greyLight">|</div>

      <div className="flex text-xs gap-x-1">
        {type === "EMPLOYEE" ? (
          <Icon SvgIcon={MedicalDoctorIcon} size="sm" tooltip={{ content: "Employee", theme: "SMALL" }} />
        ) : type === "PATIENT" ? (
          <Icon SvgIcon={UserIcon} size="sm" tooltip={{ content: "Patient", theme: "SMALL" }} />
        ) : null}
        <div className="truncate max-w-full">{name}</div>
      </div>
    </div>
  );
};

const Content = ({ audit }: { audit: AuditVO }) => {
  return (
    <>
      {audit.createdAt ? (
        <div className="p-6 border-b border-b-greyLightest flex flex-col gap-y-2">
          <AuditHeader
            date={audit.createdAt}
            name={audit.createdBy?.name.fullDisplayName ?? "Archy"}
            type={audit.createdBy?.type}
          />
          <div className="font-sansSemiBold text-xs">Appointment Created</div>
        </div>
      ) : null}
      {audit.updatedAt && audit.updatedBy ? (
        <div className="p-6 border-b border-b-greyLightest flex flex-col gap-y-2">
          <AuditHeader
            date={audit.updatedAt}
            name={audit.updatedBy.name.fullDisplayName}
            type={audit.updatedBy.type}
          />
          <div className="font-sansSemiBold text-xs">Appointment Updated</div>
        </div>
      ) : null}
    </>
  );
};

export const AppointmentAuditLog: FC<Props> = ({ appointmentId, patientId, onRequestClose }) => {
  const { practiceId } = useAccount();
  const [appointmentQuery] = useApiQueries([
    getAppointmentQuery({ args: { patientId, appointmentId, practiceId } }),
  ]);

  return (
    <Flyover clickAwayToClose title="Appointment History" onClose={onRequestClose}>
      <FlyoverContent paddingClassName="">
        <QueryResult queries={[appointmentQuery]}>
          {appointmentQuery.data?.audit ? <Content audit={appointmentQuery.data.audit} /> : null}
        </QueryResult>
      </FlyoverContent>
    </Flyover>
  );
};
