import { FormEvent, useState, useCallback } from "react";

import { SupportTokenResponse } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { isNullish } from "@libs/utils/types";
import { PAGE_SIZE } from "@libs/utils/constants";

import { listPractices } from "api/user/queries";
import { issueSupportToken } from "api/user/mutations";
import { handleError } from "utils/handleError";
import { SupportPracticeSelectProps } from "components/Main/SupportPracticeSelect";

export const useSupportPracticeSelect = ({
  onPracticeSelected,
}: {
  onPracticeSelected: (response: SupportTokenResponse) => void;
}) => {
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [selectedPracticeId, setSelectedPracticeId] = useState<number>();

  const listPracticesQuery = useInfiniteApiQuery(
    listPractices({
      args: {
        searchString,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: {
        // Because this usage of the FormFieldAutocomplete relies on an infinite query
        // vs a plain query, we can't use the normal flow of:
        // - disable the query
        // - when the search value changes update the search term
        // - when the debounced search value is updated call query.refetch
        // Instead we have to only make calls once the search term and debounced values are in sync.
        // This prevents stale results from showing when typing.
        enabled: Boolean(searchString && debouncedSearchString === searchString),
      },
    })
  );

  const [issueSupportTokenMutation] = useApiMutations([issueSupportToken]);
  const issueSupportTokenMutate = issueSupportTokenMutation.mutate;

  const { validate, result } = useValidation(
    { selectedPracticeId },
    {
      selectedPracticeId: [{ $v: required, $error: "Please select a practice" }],
    }
  );

  const handleIssueSupportToken = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid || isNullish(selectedPracticeId)) {
        return;
      }

      issueSupportTokenMutate(
        { practiceId: selectedPracticeId },
        {
          onSuccess: (response) => onPracticeSelected(response.data.data),
          onError: handleError,
        }
      );
    },
    [selectedPracticeId, validate, issueSupportTokenMutate, onPracticeSelected]
  );

  const selectProps: SupportPracticeSelectProps = {
    listPracticesQuery,
    selectedPracticeId,
    onDebouncedSearchChange: setDebouncedSearchString,
    onSearchChange: setSearchString,
    onSelectChange: setSelectedPracticeId,
    error: result.selectedPracticeId.$error,
  };

  return {
    selectProps,
    handleIssueSupportToken,
    isLoadingPractice: issueSupportTokenMutation.isLoading,
  };
};
