import { useCallback, useRef } from "react";
import { getTokensForApi } from "@libs/auth/getTokens";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { formUrl } from "@libs/router/url";
import { useAccount } from "@libs/contexts/AccountContext";
import { useEnvContext } from "contexts/EnvContext";

export const useCreateWebSocket = ({ onTokenError }: { onTokenError: (error: unknown) => void }) => {
  const { REACT_APP_WEBSOCKET_HOST } = useEnvContext();
  const { practiceId, id } = useAccount();
  const { localStorage } = useStorageContext();
  const promiseRef = useRef<Promise<WebSocket> | null>(null);

  const createWebSocket = useCallback(async () => {
    let tokens = {
      identity: "",
      account: undefined as string | undefined,
    };

    try {
      tokens = await getTokensForApi(localStorage);
    } catch (e) {
      // when there is a token error the user is signed out
      onTokenError(e);

      throw e;
    }

    const url = formUrl(
      REACT_APP_WEBSOCKET_HOST,
      new URLSearchParams({
        Authorization: tokens.identity,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "x-gf-account-token": tokens.account ?? "",
        wsCreatedAt: new Date().toISOString(),
        practiceId: String(practiceId),
        userId: String(id),
      })
    );

    return new WebSocket(url);
  }, [REACT_APP_WEBSOCKET_HOST, onTokenError, practiceId, id, localStorage]);

  const cachedCreateWebSocket = useCallback(async () => {
    if (promiseRef.current) {
      return promiseRef.current;
    }

    promiseRef.current = createWebSocket();

    promiseRef.current.finally(() => {
      promiseRef.current = null;
    });

    return promiseRef.current;
  }, [createWebSocket]);

  // not websocket host is passed on tests that don't care about websockets
  return REACT_APP_WEBSOCKET_HOST ? cachedCreateWebSocket : undefined;
};
