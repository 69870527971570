import { Fragment, useMemo } from "react";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { DisplayValue } from "@libs/components/UI/Select";
import { mergeSelectStyles } from "@libs/components/UI/selectStyles";
import { findMultiSelectedOptions, MultiSelect, MultiSelectProps } from "components/UI/MultiSelect";
import { getFormFieldSelectStyles } from "components/UI/FormFieldSelect";

export type FormFieldMultiSelectProps<
  V extends SelectOptionValue,
  T extends SelectOption<V>,
> = FormFieldProps & Omit<MultiSelectProps<V, T>, "isDisabled" | "className">;

const DisplayValues = <V extends SelectOptionValue, T extends SelectOption<V>>({
  options,
  display,
}: {
  options: T[];
  display?: SelectDisplayType;
}) => {
  return (
    <>
      {options.map((option, index) => (
        <Fragment key={option.value}>
          <DisplayValue option={option} display={display} />
          {index < options.length - 1 ? ", " : ""}
        </Fragment>
      ))}
    </>
  );
};

export const FormFieldMultiSelect = <V extends SelectOptionValue, T extends SelectOption<V>>({
  disabled,
  required,
  label,
  error,
  className,
  description,
  layout,
  id,
  edit = true,
  menuPlacement = "auto",
  styles,
  ...props
}: FormFieldMultiSelectProps<V, T>) => {
  const formContext = useFormContext();
  const fieldId = useEnsureId({ customId: id });
  const mergedFormContext = useMergeFormContext(formContext, { layout });

  const selectStyles = useMemo(() => {
    const commonStyles = getFormFieldSelectStyles<V, T, true>(mergedFormContext.layout, Boolean(label));

    return mergeSelectStyles(
      {
        ...commonStyles,
        multiValue: () =>
          mergedFormContext.layout === "labelLeft" || mergedFormContext.layout === "tableValue"
            ? {
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "0.125rem",
                marginTop: "1px",
                marginBottom: "1px",
                paddingLeft: "0.25rem",
                paddingRight: "0.25rem",
              }
            : mergedFormContext.layout === "labelOut"
              ? {
                  paddingTop: "0",
                  paddingBottom: "0",
                  marginRight: "0.25rem",
                  marginTop: "0.125rem",
                  marginBottom: "0.125rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }
              : {
                  paddingTop: "0",
                  paddingBottom: "0",
                  marginRight: "0.25rem",
                  marginTop: "0",
                  marginBottom: "0.25rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                },
      },
      styles
    );
  }, [mergedFormContext.layout, label, styles]);

  const selectedOptions = useMemo(
    () => findMultiSelectedOptions(props.value, props.options, props.allowDuplicateSelections),
    [props.options, props.value, props.allowDuplicateSelections]
  );

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      description={description}
      layout={mergedFormContext.layout}
      className={className}
      edit={edit}
      id={fieldId}
    >
      {edit ? (
        <div className={cxFormFieldStyle.wrapper}>
          <MultiSelect
            isClearable={!required}
            {...props}
            inputId={fieldId}
            menuPlacement={menuPlacement}
            styles={selectStyles}
            isDisabled={disabled}
          />
        </div>
      ) : (
        <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
          <DisplayValues display={props.display} options={selectedOptions} />
        </span>
      )}
    </FormField>
  );
};
