import { FC } from "react";
import { InsuranceCarrierVO, PatientVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { PrimarySubscriber, SubscriberIdType } from "components/Patient/types";
import { SubscriberIDField } from "components/Patient/SubscriberIDField";
import { CreatePatientValidation } from "components/Patient/formSchemas";
import { SubscriberSSNField } from "components/Patient/SubscriberSSNField";
import { PatientMatch } from "components/Patient/PatientMatch";
import { CarrierSelector } from "components/Insurance/CarrierSelector/CarrierSelector";

interface Props {
  insuranceCarriers: InsuranceCarrierVO[] | undefined;
  onUpdate: (updates: Partial<PrimarySubscriber>) => void;
  primarySubscriber: PrimarySubscriber;
  subscriberMatch?: PatientVO;
  validations: CreatePatientValidation["primarySubscriber"];
}

interface SelectInsuranceIdTypeProps {
  className?: string;
  label?: string;
  onUpdate: (updates: Partial<PrimarySubscriber>) => void;
  primarySubscriber: PrimarySubscriber;
}

export const SelectInsuranceIdType: React.FC<SelectInsuranceIdTypeProps> = ({
  className,
  label,
  onUpdate,
  primarySubscriber,
}) => {
  return (
    <RadioList
      className={className}
      label={label ?? "Provide one of the following information"}
      required={true}
      selectedValue={primarySubscriber.subscriberIdType}
      options={[
        { value: "subscriberId", label: "Subscriber ID" },
        { value: "ssn", label: "SSN" },
      ]}
      onChange={(e) =>
        onUpdate({
          subscriberIdType: e.target.value as SubscriberIdType,
        })
      }
    />
  );
};

export const PrimarySubscriberFields: FC<Props> = ({
  insuranceCarriers,
  onUpdate,
  primarySubscriber,
  subscriberMatch,
  validations,
}) => {
  return (
    <>
      <div className="grid grid-cols-2 mt-2 gap-4">
        <CarrierSelector
          required
          error={validations.carrierId.$error}
          insuranceCarriers={insuranceCarriers}
          isClearable
          onUpdate={onUpdate}
          value={primarySubscriber.carrierId}
        />
        <FormFieldInput
          label="Employer"
          // error={validations.employer.$error}
          placeholder="Input employer name"
          value={primarySubscriber.employer}
          onChange={(e) => onUpdate({ employer: e.target.value })}
        />
      </div>
      <SelectInsuranceIdType className="mt-5" primarySubscriber={primarySubscriber} onUpdate={onUpdate} />
      {subscriberMatch && (
        <PatientMatch patient={subscriberMatch}>This subscriber has already been created</PatientMatch>
      )}
      <div className="grid grid-cols-2 gap-5 mt-4">
        {primarySubscriber.subscriberIdType === "ssn" ? (
          <SubscriberSSNField
            userId={primarySubscriber.patientId}
            value={primarySubscriber.ssn}
            error={validations.ssn.$error}
            onValueChange={(val) => onUpdate({ ssn: val })}
          />
        ) : (
          <SubscriberIDField
            error={validations.subscriberId.$error}
            onChange={(val) => onUpdate({ subscriberId: val })}
            value={primarySubscriber.subscriberId ?? ""}
          />
        )}
      </div>
    </>
  );
};
