import { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { getAgeByDob } from "@libs/utils/formatString";
import { formatPhoneNumber } from "@libs/utils/phone";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AddAppointmentIcon } from "@libs/assets/icons/add-appointment.svg";
import { Avatar } from "@libs/components/UI/Avatar";
import { isInThePast } from "components/Patients/Row";

import { useNow } from "hooks/useNow";

interface Props {
  patient: PatientSummaryVO;
  patientLink: string;
  onLinkToPatient: Func;
  onAddAppointment: (patientId: number) => void;
  onMouseEnterResult: MouseEventHandler<HTMLAnchorElement>;
  isHighlighted?: boolean;
  isRecent?: boolean;
  onClick?: (patientSummary: PatientSummaryVO) => void;
}

export const PatientRowContents: FC<Pick<Props, "patient" | "onAddAppointment" | "isRecent">> = ({
  patient,
  onAddAppointment,
  isRecent = false,
}) => {
  const now = useNow();
  const { id, thumbnail, dob, gender, ssnLastFour, nextAppointment, contact, name } = patient;

  const dobAgeGender = useMemo(() => {
    const strings = [formatISODate(dob), getAgeByDob(now, dob)];

    if (gender) {
      strings.push(gender.charAt(0));
    }

    return strings.join(", ");
  }, [dob, gender, now]);

  return (
    <>
      <div className="flex items-center gap-x-3">
        {isRecent ? <Avatar imageUrl={thumbnail} name={name.fullDisplayName} /> : null}
        <div className="flex flex-col">
          <span className="text-sm text-slate-900">{name.fullDisplayName}</span>
          <div
            className={`
              flex
              gap-x-2
              text-xs
              text-slate-700
              divide-x
              divide-slate-300
            `}
          >
            <span>{dobAgeGender}</span>
            {contact.callPhone ? <span className="pl-2">{formatPhoneNumber(contact.callPhone)}</span> : null}
            {ssnLastFour ? <span className="pl-2">SSN {ssnLastFour}</span> : null}
          </div>
        </div>
      </div>

      {!nextAppointment || isInThePast(nextAppointment) ? (
        <ButtonIcon
          SvgIcon={AddAppointmentIcon}
          tooltip={{ content: "Add Appointment", theme: "SMALL" }}
          onClick={(e) => {
            e.preventDefault();
            onAddAppointment(id);
          }}
        />
      ) : null}
    </>
  );
};

const cxStyles = {
  container: (isHighlighted: boolean) => {
    return cx(
      "flex items-center justify-between rounded p-3 cursor-pointer",
      isHighlighted && "bg-slate-100"
    );
  },
};

export const SearchPatientResult: FC<Props> = ({
  patient,
  patientLink,
  onLinkToPatient,
  onMouseEnterResult,
  onClick,
  isHighlighted = false,
  ...rest
}) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      onLinkToPatient();

      if (onClick) {
        e.preventDefault();
        onClick(patient);
      }
    },
    [onLinkToPatient, onClick, patient]
  );

  return (
    <Link
      className={cxStyles.container(isHighlighted)}
      to={patientLink}
      onClick={handleClick}
      onMouseEnter={onMouseEnterResult}
    >
      <PatientRowContents patient={patient} {...rest} />
    </Link>
  );
};
