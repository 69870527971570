const EVENT_NAME = "CHAT_UNREAD_MESSAGE_STATUS";

type EventPayload = { unreadCount: number };
type EventType = CustomEvent<EventPayload>;

export const updateUnreadMessageState = (unreadCount: number) => {
  document.dispatchEvent(new CustomEvent<EventPayload>(EVENT_NAME, { detail: { unreadCount } }));
};

export const addUnreadMessageStateHandler = (handler: (unreadCount: number) => void) => {
  const eventHandler = (e: Event) => handler((e as EventType).detail.unreadCount);

  document.addEventListener(EVENT_NAME, eventHandler);

  return () => {
    document.removeEventListener(EVENT_NAME, eventHandler);
  };
};
