import React from "react";
import { WebTwain } from "dwt/dist/types/WebTwain";
import { TwainContext, TwainSource, twainElementId, NO_DEVICE_INDEX } from "./TwainContext";

export const TwainProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [twain, setTwain] = React.useState<WebTwain | undefined>(undefined);
  const [sources, setSources] = React.useState<TwainSource[] | null>(null);
  const [twainLoadError, setTwainLoadError] = React.useState<Error | null>(null);
  const [isUsingTestCam, setIsUsingTestCam] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(NO_DEVICE_INDEX);
  const onImageReadyRef = React.useRef<() => void | null>(null);

  const [neededDownloadUrl, setNeededDownloadUrl] = React.useState<string | null>(null);
  const hasTriggeredInit = React.useRef(false);
  const hasLoadedSources = React.useRef(false);
  const contextValue = React.useMemo(() => {
    return {
      twain,
      setTwain,
      hasTriggeredInit,
      hasLoadedSources,
      onImageReadyRef,
      sources,
      setSources,
      isUsingTestCam,
      setIsUsingTestCam,
      neededDownloadUrl,
      setNeededDownloadUrl,
      twainLoadError,
      setTwainLoadError,
      selectedIndex,
      setSelectedIndex,
    };
  }, [isUsingTestCam, neededDownloadUrl, selectedIndex, sources, twain, twainLoadError]);

  return (
    <TwainContext.Provider value={contextValue}>
      {children}
      <div id={twainElementId} />
    </TwainContext.Provider>
  );
};
