import { FC, useMemo } from "react";
import { subDays } from "date-fns";
import { InsuranceCarrierVO, InsuranceDetailsVO, PatientVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { getLocalDate } from "@libs/utils/date";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import {
  DependentPrimarySubscriber,
  SelectedPrimarySubscriber,
  SubscriberIdType,
} from "components/Patient/types";
import { CreateInsuranceValidation, CreatePatientValidation } from "components/Patient/formSchemas";
import { NO_SELECTED_NUMERIC_ID } from "components/Patient/hooks";
import { FormFieldSsnInput } from "components/UI/FormFieldSsnInput";
import { CarrierSelector } from "components/Insurance/CarrierSelector/CarrierSelector";
import { PatientMatch } from "components/Patient/PatientMatch";

interface Props {
  insuranceCarriers: InsuranceCarrierVO[] | undefined;
  dependentPrimarySubscriber: DependentPrimarySubscriber;
  editable?: boolean;
  onUpdate: (updates: Partial<DependentPrimarySubscriber>) => void;
  onUpdateSelectedPrimarySubscriber: (updates: Partial<SelectedPrimarySubscriber>) => void;
  selectedPrimarySubscriber: SelectedPrimarySubscriber;
  subscriberMatch: PatientVO | undefined;
  validations: CreatePatientValidation | CreateInsuranceValidation;
}

// eslint-disable-next-line complexity
export const getInputValues = (
  primarySubscriberMatch: PatientVO | undefined,
  dependentPrimarySubscriber: DependentPrimarySubscriber,
  selectedPrimarySubscriber: SelectedPrimarySubscriber
) => {
  const hasSelectedMatch = selectedPrimarySubscriber.patientId === primarySubscriberMatch?.id;
  const primaryInsurance = primarySubscriberMatch?.insuranceDetails.primaryInsurance;
  const primarySubscriber = primaryInsurance?.primarySubscriber;
  let insuranceMatch: InsuranceDetailsVO | undefined = undefined;

  if (hasSelectedMatch) {
    // Update carrierId if multiple insurances:
    const { insuranceDetails } = primarySubscriberMatch;
    const insurances = [insuranceDetails.primaryInsurance, insuranceDetails.secondaryInsurance].filter(
      isDefined
    );

    insuranceMatch =
      insurances.find((item) => {
        return (
          item.type === "PRIMARY_SUBSCRIBER" &&
          item.primarySubscriber?.carrierId === dependentPrimarySubscriber.carrierId
        );
      }) ?? insuranceDetails.primaryInsurance;
  }

  return hasSelectedMatch
    ? ({
        carrierId: insuranceMatch?.primarySubscriber?.carrierId || NO_SELECTED_NUMERIC_ID,
        dob: getLocalDate(primarySubscriberMatch.personalDetails.dob),
        employer: primarySubscriber?.employer || "",
        firstName: primarySubscriberMatch.personalDetails.firstName,
        lastName: primarySubscriberMatch.personalDetails.lastName,
        patientId: primarySubscriber?.patientId ?? "",
        relationship: selectedPrimarySubscriber.relationship,
        ssnLastFour: primarySubscriber?.ssnLastFour ?? "",
        subscriberId: primarySubscriber?.subscriberId ?? "",
        subscriberIdType: primarySubscriber?.subscriberId ? "subscriberId" : ("ssn" as SubscriberIdType),
      } as DependentPrimarySubscriber)
    : dependentPrimarySubscriber;
};

export const SubscriberIdTypeView: FC<
  Omit<
    Props,
    | "onUpdateSelectedPrimarySubscriber"
    | "patientRelations"
    | "selectedPrimarySubscriber"
    | "subscriberMatch"
    | "viewType"
    | "dependentPrimarySubscriber"
    | "insuranceCarriers"
  > & {
    edit: boolean;
    ssn?: string;
    ssnLastFour?: string;
    subscriberIdType: SubscriberIdType;
    subscriberId: string;
  }
> = ({ edit, onUpdate, subscriberId, subscriberIdType, ssn, ssnLastFour, validations }) => {
  return subscriberIdType === "ssn" ? (
    <FormFieldSsnInput
      edit={edit}
      label="SSN"
      required
      ssnLastFour={ssnLastFour}
      error={validations.dependentPrimarySubscriber.ssn.$error}
      value={ssn}
      onValueChange={(value) => {
        onUpdate({
          ssn: value,
        });
      }}
    />
  ) : (
    <FormFieldInput
      label="Subscriber ID"
      edit={edit}
      type="text"
      required
      error={validations.dependentPrimarySubscriber.subscriberId.$error}
      placeholder="Unique ID issued by carrier"
      value={subscriberId}
      onChange={(e) =>
        onUpdate({
          subscriberId: e.target.value.trim(),
        })
      }
    />
  );
};

export const NewPrimarySubscriberFields: FC<Props> = ({
  insuranceCarriers,
  dependentPrimarySubscriber,
  editable = true,
  onUpdate,
  onUpdateSelectedPrimarySubscriber,
  selectedPrimarySubscriber,
  subscriberMatch,
  validations,
}) => {
  const yesterday = useMemo(() => subDays(new Date(), 1), []);
  const hasSelectedMatch = selectedPrimarySubscriber.patientId === subscriberMatch?.id;
  const canEdit = !hasSelectedMatch && editable;
  const values = getInputValues(subscriberMatch, dependentPrimarySubscriber, selectedPrimarySubscriber);

  return (
    <div className="relative">
      {hasSelectedMatch && editable ? (
        <button
          type="button"
          className={`
            font-sansSemiBold
            text-primaryTheme
            text-xs
            absolute
            -top-9
            right-0
          `}
          onClick={() =>
            onUpdateSelectedPrimarySubscriber({
              patientId: NO_SELECTED_NUMERIC_ID,
              relationship: undefined,
            })
          }
        >
          Add Someone Else
        </button>
      ) : null}

      {hasSelectedMatch ? null : (
        <>
          <RadioList
            label="Provide one of the following"
            required
            selectedValue={dependentPrimarySubscriber.subscriberIdType}
            options={[
              { value: "subscriberId", label: "Subscriber ID" },
              { value: "ssn", label: "SSN" },
            ]}
            disabled={!editable}
            onChange={(e) =>
              onUpdate({
                subscriberIdType: e.target.value as SubscriberIdType,
              })
            }
          />
          <div className="grid grid-cols-3 gap-5 mt-2">
            <SubscriberIdTypeView
              ssn={dependentPrimarySubscriber.ssn}
              ssnLastFour={dependentPrimarySubscriber.ssnLastFour}
              validations={validations}
              onUpdate={onUpdate}
              edit={editable}
              subscriberId={values.subscriberId}
              subscriberIdType={values.subscriberIdType}
            />
          </div>
        </>
      )}
      <div className="grid grid-cols-3 gap-5 mt-5">
        <FormFieldInput
          edit={canEdit}
          label="First Name"
          required
          error={validations.dependentPrimarySubscriber.firstName.$error}
          placeholder="Input first name e.g. John"
          value={values.firstName}
          onChange={(e) => onUpdate({ firstName: e.target.value })}
        />
        <FormFieldInput
          edit={canEdit}
          label="Last Name"
          required
          error={validations.dependentPrimarySubscriber.lastName.$error}
          placeholder="Input last name e.g. Doe"
          value={values.lastName}
          onChange={(e) => onUpdate({ lastName: e.target.value })}
        />
        <FormFieldSelectMenusDatepicker
          edit={canEdit}
          label="Date of Birth"
          required
          maxDate={yesterday}
          error={validations.dependentPrimarySubscriber.dob.$error}
          placeholderText="MM/DD/YYYY"
          selected={values.dob}
          onChange={(newDate) => onUpdate({ dob: newDate })}
        />
      </div>
      {subscriberMatch && !hasSelectedMatch ? (
        <div className="mt-5">
          <PatientMatch
            patient={subscriberMatch}
            onSelect={(patient) =>
              onUpdateSelectedPrimarySubscriber({
                patientId: patient.id,
              })
            }
            ctaText="Select as primary subscriber"
          />
        </div>
      ) : null}

      <div className="grid grid-cols-3 gap-5 mt-5">
        <CarrierSelector
          required
          error={validations.dependentPrimarySubscriber.carrierId.$error}
          edit={canEdit}
          placeholder="Select"
          insuranceCarriers={insuranceCarriers}
          isClearable
          onUpdate={onUpdate}
          value={values.carrierId}
        />
        <FormFieldInput
          edit={canEdit}
          label="Employer"
          placeholder="Input employer name"
          value={values.employer}
          onChange={(e) => onUpdate({ employer: e.target.value })}
        />
        {hasSelectedMatch && (
          <SubscriberIdTypeView
            validations={validations}
            onUpdate={onUpdate}
            ssn={values.ssn}
            ssnLastFour={values.ssnLastFour}
            edit={false}
            subscriberId={values.subscriberId}
            subscriberIdType={values.subscriberIdType}
          />
        )}
      </div>
    </div>
  );
};
