import { useMemo, FC } from "react";
import { SupportUserVO } from "@libs/api/generated-api";
import { SignedInAppRoutes } from "components/Main/SignedInAppRoutes";
import { CurrentUser, CurrentUserContext } from "contexts/CurrentUserContext";

export const SupportUserProvider: FC<{
  user: SupportUserVO;
  email: string;
}> = ({ email, user }) => {
  const currentUser: CurrentUser | undefined = useMemo(() => {
    return {
      ...user,
      type: "SUPPORT_USER" as const,
      phone: "",
      email,
    };
  }, [user, email]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <SignedInAppRoutes />
    </CurrentUserContext.Provider>
  );
};
