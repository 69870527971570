import { useMemo, FC, PropsWithChildren, useEffect } from "react";
import { AccountContext } from "@libs/contexts/AccountContext";
import { PracticeFeatureStateVO } from "@libs/api/generated-api";
import { setSentryUserProperties } from "utils/sentry";

export const AccountProvider: FC<
  PropsWithChildren<{
    email: string;
    userId: number;
    practiceId: number;
    practiceUuid: string;
    features: PracticeFeatureStateVO[];
  }>
> = ({ practiceId, userId, email, practiceUuid, children, features }) => {
  const account = useMemo(() => {
    return {
      practiceId,
      practiceUuid,
      email,
      id: userId,
      featuresEnabled: new Set(
        features.filter((item) => item.isEnabled).map((item) => item.practiceFeature.type)
      ),
    };
  }, [practiceId, userId, email, practiceUuid, features]);

  useEffect(() => {
    setSentryUserProperties(account);
  }, [account]);

  return <AccountContext.Provider value={account}>{children}</AccountContext.Provider>;
};
