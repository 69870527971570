import { FC } from "react";
import { ContactModeVO } from "@libs/api/generated-api";
import { sentenceCase } from "@libs/utils/casing";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { CONTACT_MODE_OPTIONS, ContactMode, DEFAULT_CONTACT_MODE } from "components/Patient/contactModes";
import { ToggleButtonCheckboxListProps, ToggleButtonList } from "components/UI/ToggleButtonList";
import { TooltipLabel } from "components/UI/TooltipLabel";

interface Props
  extends Pick<
    ToggleButtonCheckboxListProps<Uppercase<ContactMode>, ListItem<typeof CONTACT_MODE_OPTIONS>>,
    "className" | "error"
  > {
  hasContact?: boolean;
  contactModes: ContactModeVO;
  edit?: boolean;
  onChange: (updates: ContactModeVO) => void;
}

const label = "Contact Methods";

export const FormFieldContactModes: FC<Props> = ({
  hasContact = false,
  contactModes,
  onChange,
  className,
  error,
  edit = true,
}) => {
  const modes = hasContact
    ? []
    : Object.keys(DEFAULT_CONTACT_MODE).filter((contactMode) => contactModes[contactMode as ContactMode]);
  const selectedValues = new Set(modes.map((val) => val.toUpperCase()) as Uppercase<keyof ContactModeVO>[]);

  return edit ? (
    <div className={className}>
      <ToggleButtonList
        options={CONTACT_MODE_OPTIONS}
        label={
          <TooltipLabel
            tooltip={{
              content:
                "Patients must consent to these contact methods and understand that communications may contain details about their appointments or treatments. Message & data rates may apply.",
            }}
          >
            {label}
          </TooltipLabel>
        }
        shape="mediumPill"
        error={error}
        required={!hasContact}
        disabled={hasContact}
        selectedValues={selectedValues}
        type="checkbox"
        onChange={(val) => {
          onChange({
            email: val.has("EMAIL"),
            text: val.has("TEXT"),
            call: val.has("CALL"),
          });
        }}
      />
    </div>
  ) : (
    <FormFieldInput
      edit={false}
      label={label}
      className={className}
      value={modes
        .sort()
        .reverse()
        .map((contactMode) => sentenceCase(contactMode))
        .join(", ")}
    />
  );
};
