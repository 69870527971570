/**
 * This is a local copy of react-google-places-autocomplete, which has been upgraded to react-18,
 * and upgraded with the latest react-select. It also fixes some typings, etc, but is mostly stock.
 */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import AsyncSelect from "react-select/async";
import { OptionsOrGroups } from "react-select";
import { useDebouncedCallback } from "use-debounce";

import { Loader } from "@googlemaps/js-api-loader";
import { autocompletionRequestBuilder } from "@libs/components/UI/GooglePlacesAutocomplete/helpers/autocompletionRequestBuilder";
import {
  AutocompletionRequest,
  GooglePlacesAutocompleteHandle,
  GooglePlacesAutocompleteProps,
  OptionType,
} from "./GooglePlacesAutocomplete.types";

const DEFAULT_DEBOUNCE_MS = 300;

const GooglePlacesAutocompleteControl: React.ForwardRefRenderFunction<
  GooglePlacesAutocompleteHandle,
  GooglePlacesAutocompleteProps
> = (
  {
    apiKey = "",
    apiOptions,
    autocompletionRequest,
    debounce = DEFAULT_DEBOUNCE_MS,
    minLengthAutocomplete = 0,
    selectProps,
    onLoadFailed,
    withSessionToken = false,
  }: GooglePlacesAutocompleteProps,
  ref
): React.ReactElement => {
  const [placesService, setPlacesService] = useState<google.maps.places.AutocompleteService | undefined>(
    undefined
  );
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(
    undefined
  );
  const fetchSuggestions = useDebouncedCallback(
    (value: string, callback: (options: OptionsOrGroups<OptionType, never>) => void): void => {
      if (!placesService) {
        return callback([]);
      }

      if (value.length < minLengthAutocomplete) {
        return callback([]);
      }

      const autocompletionReq: AutocompletionRequest = { ...autocompletionRequest };

      placesService.getPlacePredictions(
        autocompletionRequestBuilder(autocompletionReq, value, withSessionToken && sessionToken),
        (suggestions) => {
          callback(
            (suggestions || []).map((suggestion) => ({ label: suggestion.description, value: suggestion }))
          );
        }
      );

      return undefined;
    },
    debounce
  );

  const initializeService = () => {
    if (!window.google) {
      throw new Error("[react-google-places-autocomplete]: Google script not loaded");
    }

    if (!window.google.maps) {
      throw new Error("[react-google-places-autocomplete]: Google maps script not loaded");
    }

    if (!window.google.maps.places) {
      throw new Error("[react-google-places-autocomplete]: Google maps places script not loaded");
    }

    setPlacesService(new window.google.maps.places.AutocompleteService());
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  useImperativeHandle(
    ref,
    () => ({
      getSessionToken: () => {
        return sessionToken;
      },
      refreshSessionToken: () => {
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      },
    }),
    [sessionToken]
  );

  const handleLoadFailed = useCallback(
    (error: Error) => {
      if (onLoadFailed) {
        onLoadFailed(error);
      } else {
        console.error(error);
      }
    },
    [onLoadFailed]
  );

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({ apiKey, libraries: ["places"], ...apiOptions }).load();
        }

        initializeService();
      } catch (error) {
        handleLoadFailed(error as Error);
      }
    };

    if (apiKey) {
      init();
    } else {
      initializeService();
    }
  }, [apiKey, apiOptions, handleLoadFailed]);

  return (
    <AsyncSelect
      {...selectProps}
      loadOptions={fetchSuggestions}
      getOptionValue={({ value }: OptionType) => value.place_id}
    />
  );
};

export const GooglePlacesAutocomplete = forwardRef(GooglePlacesAutocompleteControl);
