import { FC } from "react";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { Banner } from "@libs/components/UI/Banner";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useEnvContext } from "contexts/EnvContext";
import { useNotificationContext } from "contexts/NotificationsContext";
import { useWebSocketContext } from "contexts/WebSocketContext";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";

interface Props {
  title: string;
  fields: { label: string; value: string | number }[];
  cols?: "grid-cols-2" | "grid-cols-1";
}

const Section: FC<Props> = ({ title, fields, cols = "grid-cols-2" }) => {
  return (
    <div className="text-sm">
      <h3 className="mb-1 text-base">{title}</h3>
      <div className={cx("grid", cols)}>
        {fields.map(({ label, value }) => (
          <div key={label} className="flex">
            <div className="font-sansSemiBold whitespace-nowrap w-20 flex-none">{label}:</div>
            <div aria-label={label}>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const SoftwareInfoModal: FC<{ onRequestClose: Func }> = ({ onRequestClose }) => {
  const currentUser = useCurrentUser();
  const practice = useCurrentPractice();
  const { messages } = useWebSocketContext();
  const { REACT_APP_RELEASE_VERSION } = useEnvContext();
  const notification = useNotificationContext();
  const { status: aisStatus } = useImagingHub();

  const sections = [
    {
      title: "Release",
      cols: "grid-cols-1" as const,
      fields: [{ label: "Version", value: REACT_APP_RELEASE_VERSION }],
    },
    {
      title: "User",
      fields: [
        { label: "Name", value: currentUser.name.fullDisplayName },
        { label: "ID", value: currentUser.id },
        ...(currentUser.type === "EMPLOYEE"
          ? [
              { label: "Status", value: sentenceCaseConstant(currentUser.employmentDetails.status ?? "-") },
              {
                label: "Start Date",
                value: currentUser.employmentDetails.startDate
                  ? formatISODate(currentUser.employmentDetails.startDate)
                  : "-",
              },
            ]
          : []),

        { label: "Role", value: currentUser.roleV2.name },
        { label: "Role ID", value: currentUser.roleV2.roleId },
      ],
    },
    {
      title: "Practice",
      fields: [
        { label: "Name", value: practice.name },
        { label: "ID", value: practice.id },
        { label: "Owner", value: practice.ownerName ?? "" },
        { label: "UUID", value: practice.uuid },
        { label: "Timezone", value: practice.timezoneId },
      ],
    },
    {
      title: "Browser",
      cols: "grid-cols-1" as const,
      fields: [
        { label: "UserAgent", value: navigator.userAgent },
        { label: "WebSocket", value: messages ? "Connected" : "Not Connected" },
        {
          label: "AIS",
          value: aisStatus.isAISDisabled ? "Disabled" : aisStatus.isRunning ? "Connected" : "Not Running",
        },
        { label: "AIS Version", value: aisStatus.version },
      ],
    },
  ];

  const handleCopyContents = () => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    let formatted = "";

    for (const section of sections) {
      formatted += `${section.title}\n\n`;
      formatted += section.fields.map((field) => `${field.label}: ${field.value}`).join("\n");
      formatted += "\n\n";
    }
    navigator.clipboard.writeText(formatted);
    notification.handleInfo("Copied to clipboard");
  };

  return (
    <Modal title="Software Info" onClose={onRequestClose} onClickOutside={onRequestClose}>
      <Banner paddingClassName="py-2.5 px-5" theme="info">
        <p className="text-xs">This content is to help identify any problems you may experience.</p>
      </Banner>
      <ModalContent className="flex flex-col gap-y-4" padding="md">
        {sections.map((section) => (
          <Section key={section.title} {...section} />
        ))}
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleCopyContents}>Copy Contents</Button>
      </ModalFooter>
    </Modal>
  );
};
