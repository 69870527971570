import { QueryClient } from "@tanstack/react-query";
import { PatientLedgerUpdatedEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handlePatientLedgerUpdated = ({
  data,
  queryClient,
}: {
  data: PatientLedgerUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getLedgerBalanceSummary"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2"), { patientId: data.patientId }]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getLedgerEntries"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getLedgerEntryByTypeAndId"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientSummary"),
    { patientId: data.patientId },
  ]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPatient"), { patientId: data.patientId }]);
};
