import { QueryClient } from "@tanstack/react-query";

import { getQueryKey } from "@libs/utils/queries";

export const handleGustoOnboardingFinished = ({
  queryClient,
  practiceId,
}: {
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getCompanyOnboardingStatus"), { practiceId }]);
};
