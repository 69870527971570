import { FC, ReactNode, FormEventHandler } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Flyover, FlyoverProps } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";

interface Props {
  content: ReactNode;
  isSubmitting: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onClose: Func;
  paddingClassName?: string;
  title?: FlyoverProps["title"];
  headerSize?: FlyoverProps["headerSize"];
}

export const QueryFiltersFlyover: FC<Props> = ({
  content,
  isSubmitting,
  paddingClassName,
  onClose,
  onSubmit,
  title,
  headerSize,
}) => {
  return (
    <Flyover onClose={onClose} title={title ?? "Filters"} headerSize={headerSize}>
      {({ close }) => (
        <FlyoverForm onSubmit={onSubmit}>
          <FlyoverContent className="text-xs" paddingClassName={paddingClassName}>
            {content}
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary" size="medium">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={isSubmitting}
              theme="primary"
              size="medium"
              type="submit"
            >
              Apply
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
