import { ApiResponse } from "@libs/@types/api";
import {
  AddFamilyMemberRequest,
  CreatePatientRequest,
  FamilyMemberIdVO,
  PatientSummaryVO,
  UpdateFamilyMemberRequest,
  UpdatePatientRequest,
  UpdateRecallRequest,
  RecallVO,
  UpdateGuardianOrPhiAccessRequest,
  InsuranceBySubscriberIdRequest,
  PatientVO,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData, updateCachedListWithUpdatedItem } from "@libs/utils/queryCache";
import { QueryClient } from "@tanstack/react-query";
import { updateSsnCache } from "api/user/cache";
import { handlePracticeAppointmentsUpdated } from "api/websocket/handleScheduleUpdated";

// eslint-disable-next-line complexity
const invalidatePatientAppointments = (
  queryClient: QueryClient,
  practiceId: number,
  currentPatient: PatientVO,
  updatedPatient: PatientVO
) => {
  const oldNames = currentPatient.personalDetails;
  const newNames = updatedPatient.personalDetails;

  // only decache appointment data if the patient's name changed
  if (
    oldNames.firstName !== newNames.firstName ||
    oldNames.lastName !== newNames.lastName ||
    oldNames.middleName !== newNames.middleName ||
    oldNames.preferredName !== newNames.preferredName
  ) {
    // we don't need to decache patient specific queries because they never
    // reference the name of the patient
    // handlePatientAppointmentsUpdated({ queryClient, practiceId, patientId });

    handlePracticeAppointmentsUpdated({ queryClient, practiceId });
  }
};

export const updateFamilyMembers = makeMutation({
  mutationKey: ["practices", "updateFamilyMember"],
  formatParams: (args: { practiceId: number; patientId: number; updates: UpdateFamilyMemberRequest[] }) => [
    args.patientId,
    args.practiceId,
    args.updates,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2"), { patientId, practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);
    },
  }),
});

export const updateGuardiansAndPhiAccess = makeMutation({
  mutationKey: ["practices", "updateGuardiansAndPhiAccess"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    updates: UpdateGuardianOrPhiAccessRequest;
  }) => [args.practiceId, args.patientId, args.updates],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2")]);
    },
  }),
});

export const addDependentPatientInsurance = makeMutation({
  mutationKey: ["practices", "addDependentPatientInsurance"],
  formatParams: (args: { practiceId: number; patientId: number; data: InsuranceBySubscriberIdRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2")]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurances"),
        { practiceId, patientId },
      ]);
    },
  }),
});

export const addFamilyMember = makeMutation({
  mutationKey: ["practices", "addFamilyMember"],
  formatParams: (args: { practiceId: number; patientId: number; member: AddFamilyMemberRequest }) => [
    args.patientId,
    args.practiceId,
    args.member,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2"), { patientId, practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);
    },
  }),
});

export const removeFamilyMember = makeMutation({
  mutationKey: ["practices", "removeFamilyMember"],
  formatParams: (args: { practiceId: number; patientId: number; memberId: FamilyMemberIdVO }) => [
    args.patientId,
    args.practiceId,
    args.memberId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2"), { patientId, practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);
    },
  }),
});

export const updatePatient = makeMutation({
  mutationKey: ["practices", "updatePatient"],
  formatParams: (args: { practiceId: number; patientId: number; data: UpdatePatientRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, practiceId, data }) => {
      const keys = [
        // need to decache all patients when updating a patient because
        // they could be a contact for another patient
        [getQueryKey("practices", "getPatient")],
        [getQueryKey("practices", "getPatientSummary")],
        [getQueryKey("practices", "getPatients")],
        [getQueryKey("practices", "lookupPatient")],
        [getQueryKey("practices", "lookupPatientSummary")],

        // changing patient info can change what medical info they receive
        [getQueryKey("practices", "getPublishedForm"), { patientId, practiceId }],

        // Has relevant data to decache
        [getQueryKey("v2", "getFamilyMembersV2")],
      ];

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }

      const savedResponse = queryClient.getQueryData<ApiResponse<PatientVO>>([
        getQueryKey("practices", "getPatient"),
        { practiceId, patientId },
      ]);

      if (savedResponse?.data.data) {
        invalidatePatientAppointments(queryClient, practiceId, savedResponse.data.data, response.data.data);
      }

      queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);

      // if ssn was updated, invalidate all patient insurance calls that reference
      // ssns. We can't target by patient Id because the patient could be a dependent
      // of anoter patient
      if (data.personalDetails.ssn) {
        queryClient.invalidateQueries([getQueryKey("practices", "getPatientInsurance")]);
        queryClient.invalidateQueries([getQueryKey("practices", "getPatientInsurances")]);
        updateSsnCache(data.personalDetails.ssn, practiceId, patientId);
      }
    },
  }),
});

export const updatePatientRecall = makeMutation({
  mutationKey: ["practices", "updateRecall"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    recallId: string;
    data: UpdateRecallRequest;
  }) => [args.practiceId, args.patientId, args.recallId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId }) => {
      updateCachedListWithUpdatedItem<RecallVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getPatientRecalls"), { practiceId, patientId }] },
        data.data.data,
        "uuid"
      );
    },
  }),
});

export const resendPatientInvite = makeMutation({
  mutationKey: ["practices", "resendPatientInvite"],
  formatParams: (args: { practiceId: number; patientId: number }) => [args.practiceId, args.patientId],
});

export const createPatient = makeMutation({
  mutationKey: ["practices", "createPatient"],
  formatParams: (args: { practiceId: number; data: CreatePatientRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, data }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPatients"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("v2", "getFamilyMembersV2"),
        { practiceId, patientId: data.contactDetails.contactPatientId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientCallCard"), { practiceId }]);
    },
  }),
});

export const clearRecentSearches = makeMutation({
  mutationKey: ["practices", "clearRecentSearchHistory"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId }) => {
      updateCachedData<PatientSummaryVO[]>(
        queryClient,
        { queryKey: [getQueryKey("v2", "getRecentSearchHistoryV2"), { practiceId }], exact: true },
        () => []
      );
    },
  }),
});
