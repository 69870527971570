import { useMemo, FC } from "react";
import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { paths, signedInRedirect } from "utils/routing/paths";
import { SelectAccountPayload, SignOutPayload, useAuthChannelListeners } from "hooks/useAuthChannelListeners";
import { isSupportIdentityToken } from "utils/auth";
import { PracticeApiClientProvider } from "contexts/PracticeApiClientProvider";
import { CurrentUserProvider } from "components/Main/CurrentUserProvider";
import { SessionIdleTimer } from "components/Main/SessionIdleTimer";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";

export const loader =
  ({ storage }: PracticeRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);
    const returnUrl = getFullUrl(url);

    if (activityStorage.isRecentlyActive()) {
      if (!tokens.identity) {
        return redirectDocument(paths.signIn({ returnUrl }));
      }

      if (!tokens.account) {
        return redirectDocument(paths.selectAccount({ returnUrl }));
      }

      return {
        accountToken: tokens.account,
        email: tokens.identity.email,
        isSupportUser: isSupportIdentityToken(tokens.identity),
      };
    } else if (tokens.identity) {
      return redirect(paths.signOut({ returnUrl }));
    }

    return redirectDocument(paths.signIn({ returnUrl }));
  };

export const SignedInApp: FC = () => {
  const { accountToken, email, isSupportUser } = useCurriedLoaderData<typeof loader>();

  const authChannelEvents = useMemo(
    () => ({
      onSelectAccount: (event: SelectAccountPayload) => {
        if (event.userId !== accountToken.userId) {
          window.location.assign(signedInRedirect);
        }
      },
      onSignOut: (data: SignOutPayload) => {
        const params = {
          signOutReason: data.reason,
          ...(data.addReturnUrl
            ? { returnUrl: getFullUrl(window.location), lastUserId: accountToken.userId }
            : undefined),
        };

        if (isSupportUser) {
          window.location.assign(paths.supportSignIn(params));
        } else {
          window.location.assign(paths.signIn(params));
        }
      },
    }),
    [accountToken.userId, isSupportUser]
  );

  useAuthChannelListeners(authChannelEvents);

  return (
    <PracticeApiClientProvider>
      <CurrentUserProvider
        practiceId={accountToken.practiceId}
        userId={accountToken.userId}
        email={email}
        isSupportUser={isSupportUser}
      />
      <SessionIdleTimer />
    </PracticeApiClientProvider>
  );
};
