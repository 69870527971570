import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";
import { noop } from "@libs/utils/noop";
import { keys } from "@libs/utils/object";
import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

type PatientLinks = {
  billing: string;
  charting: string;
  claims: string;
  labCases: string;
  communications: string;
  documents: string;
  erx: string;
  forms: string;
  imaging: string;
  insurance: string;
  medicalHistory: string;
  overview: string;
};

type LinksByPatient = Record<number, Partial<PatientLinks> | undefined>;

type PatientLinksStateContextValue = {
  get: (patientId: number) => Partial<PatientLinks> | undefined;
  update: (patientId: number, updates: Partial<PatientLinks>) => void;
};

const Context = createContext<PatientLinksStateContextValue>({
  update: noop,
  get: noop,
} as PatientLinksStateContextValue);

Context.displayName = "PatientLinksState";
export const PatientLinksStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [linksByPatient, setLinksByPatient] = useState<LinksByPatient>({});

  const update = useCallback(
    (patientId: number, updates: Partial<PatientLinks>) =>
      setLinksByPatient((last) => {
        const updatingApps = keys(updates);
        const current = last[patientId];
        const hasUpdates =
          !current ||
          updatingApps.some((appName) => {
            return current[appName] !== updates[appName];
          });

        if (hasUpdates) {
          return {
            ...last,
            [patientId]: { ...current, ...updates },
          };
        }

        return last;
      }),
    []
  );

  const value = useMemo(() => {
    const methods: PatientLinksStateContextValue = {
      get: (patientId: number) => linksByPatient[patientId],
      update,
    };

    return methods;
  }, [linksByPatient, update]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const usePatientLinksState = () => useContext(Context);

const getDefaultPatientLinks = (patientId: number) => ({
  billing: paths.patientBilling({ patientId }),
  charting: paths.charting({ patientId }),
  claims: paths.patientClaims({ patientId }),
  labCases: paths.patientLabCases({ patientId }),
  communications: paths.patientTab({ patientId, tab: "communication" }),
  documents: paths.patientTab({ patientId, tab: "documents" }),
  erx: paths.patientTab({ patientId, tab: "erx" }),
  forms: paths.patientTab({ patientId, tab: "forms" }),
  imaging: paths.patientTab({ patientId, tab: "imaging" }),
  insurance: paths.patientTab({ patientId, tab: "insurance" }),
  medicalHistory: paths.patientTab({ patientId, tab: "medical-history" }),
  overview: paths.patient({ patientId }),
});

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext(
  "Patient",
  getDefaultPatientLinks(0)
);

export const usePatientLinks = useLinks;
export const PatientLinksProvider: FC<PropsWithChildren<{ patientId?: number }>> = ({
  patientId,
  children,
}) => {
  const patientLinksState = usePatientLinksState();

  const defaultLinks = useMemo(() => getDefaultPatientLinks(patientId ?? 0), [patientId]);
  const overrideLinks = useMemo(
    () => (patientId ? patientLinksState.get(patientId) : undefined),
    [patientId, patientLinksState]
  );

  return (
    <LinksProvider defaultLinks={defaultLinks} initialOverrideLinks={overrideLinks}>
      {children}
    </LinksProvider>
  );
};
export const PatientAppHistoryProvider: FC<
  PropsWithChildren<{ patientId: number; name: keyof PatientLinks }>
> = ({ patientId, name, children }) => {
  const { update } = usePatientLinksState();

  const handleUpdates = useCallback(
    (updates: Partial<PatientLinks>) => {
      update(patientId, updates);
    },
    [patientId, update]
  );

  return (
    <AppHistoryProvider name={name} onLinksUpdated={handleUpdates}>
      {children}
    </AppHistoryProvider>
  );
};
