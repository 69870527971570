import { LabCaseStatusVO, LabCaseVO } from "@libs/api/generated-api";

const LAB_CASE_STATUSES_RANKED: Record<LabCaseVO["status"], number> = {
  DRAFT: 0,
  SENT: 1,
  RECEIVED: 2,
  RETURNED: 3,
  READY: 4,
  COMPLETE: 5,
};

const sortLabCasesByStatus = (labCases: LabCaseStatusVO[]) => {
  const sortedLabCases = [...labCases];

  sortedLabCases.sort((a, b) => LAB_CASE_STATUSES_RANKED[a.status] - LAB_CASE_STATUSES_RANKED[b.status]);

  return sortedLabCases;
};

export const getLeastAdvancedLabCaseStatus = (labCaseStatuses: LabCaseStatusVO[]) => {
  if (labCaseStatuses.length === 0) {
    return undefined;
  }

  const sortedLabCaseStatuses = sortLabCasesByStatus(labCaseStatuses);

  return sortedLabCaseStatuses[0];
};
