import { QueryClient } from "@tanstack/react-query";
import { UnReadMessageEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handleUnreadMessage = ({
  data,
  queryClient,
  practiceId,
}: {
  data: UnReadMessageEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getPracticeCounters"), { practiceId }]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientMessagesOverview"),
    { patientId: data.patientId },
  ]);

  queryClient.invalidateQueries([getQueryKey("practices", "getPracticeMessages"), { practiceId }]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientMessages"),
    { patientId: data.patientId },
  ]);
};
