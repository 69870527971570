import { PatientInsuranceVO } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { oneMinuteCache } from "@libs/utils/queryCache";

export const getPatientInsurancesQuery = makeQuery({
  queryKey: ["practices", "getPatientInsurances"],
  formatParams: ({
    practiceId,
    patientId,
    includeBenefitCoverage = false,
    includeBenefitLimitation = false,
    includeProcedureHistory = false,
    insuranceState = ["ACTIVE"],
    includeInsurancePlan = false,
  }: {
    practiceId: number;
    patientId: number;
    includeInsurancePlan?: boolean;
    includeBenefitCoverage?: boolean;
    includeBenefitLimitation?: boolean;
    includeProcedureHistory?: boolean;
    insuranceState?: PatientInsuranceVO["insuranceState"][];
  }) => [
    practiceId,
    patientId,
    {
      includeState: insuranceState,
      includeInsurancePlan,
      includeBenefitCoverage,
      includeBenefitLimitation,
      includeProcedureHistory,
    },
  ],
  queryOptions: oneMinuteCache,
});

export const getPatientInsuranceQuery = makeQuery({
  queryKey: ["practices", "getPatientInsurance"],
  formatParams: ({
    practiceId,
    patientId,
    insuranceId,
    includeInsurancePlan = false,
    includeBenefitLimitation = false,
    includeBenefitCoverage = false,
  }: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    includeBenefitCoverage?: boolean;
    includeBenefitLimitation?: boolean;
    includeInsurancePlan?: boolean;
  }) => [
    practiceId,
    patientId,
    insuranceId,
    {
      includeBenefitCoverage,
      includeBenefitLimitation,
      includeInsurancePlan,
    },
  ],
  queryOptions: oneMinuteCache,
});

export const getClaimInsuranceCarriersQuery = makeQuery({
  queryKey: ["practices", "getClaimInsuranceCarriers"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getInsurancePlan = makeQuery({
  queryKey: ["practices", "getInsurancePlan"],
  formatParams: (args: { practiceId: number; insurancePlanUuid: string }) => [
    args.practiceId,
    args.insurancePlanUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getInsurancePlanOverview = makeQuery({
  queryKey: ["practices", "getInsurancePlanOverview"],
  formatParams: (args: { practiceId: number; insurancePlanUuid: string }) => [
    args.practiceId,
    args.insurancePlanUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getBenefitCoverages = makeQuery({
  queryKey: ["practices", "getBenefitCoverages"],
  formatParams: (args: { practiceId: number; insurancePlanUuid: string }) => [
    args.practiceId,
    args.insurancePlanUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getGroupedBenefitLimitationsQuery = makeQuery({
  queryKey: ["practices", "getGroupedBenefitLimitations"],
  formatParams: (args: { practiceId: number; insurancePlanUuid: string }) => [
    args.practiceId,
    args.insurancePlanUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getRecentProcedureHistoryItemsQuery = makeQuery({
  queryKey: ["practices", "getRecentProcedureHistoryItems"],
  formatParams: (args: { practiceId: number; insuranceId: number; patientId: number }) => [
    args.practiceId,
    args.patientId,
    args.insuranceId,
  ],
  queryOptions: oneMinuteCache,
});
