import { FC, HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";

interface Props extends HTMLAttributes<HTMLDivElement> {
  currentCount: number;
  max: number;
  warning?: number;
  unit?: string;
  className?: string;
}

export const CharacterCounter: FC<Props> = ({ currentCount, max, warning, className, unit, ...rest }) => {
  const maxLimitExceeded = currentCount > max;
  const warningLimitExceeded = currentCount > (warning ?? max);

  return (
    <div
      className={cx(
        "text-xxs",
        className,
        maxLimitExceeded ? "text-red" : warningLimitExceeded ? "text-orange" : "text-slate-500"
      )}
      {...rest}
    >
      {currentCount}/{max}
      {unit && ` ${unit}`}
    </div>
  );
};
