import { TagVO } from "@libs/api/generated-api";
import { PillProps, cxPillStyles } from "@libs/components/UI/Pill";
import { FC } from "react";
import { getLightColor } from "@libs/domains/scheduling/colors";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Glyph } from "@libs/components/UI/Glyph";
import { cx } from "@libs/utils/cx";
import { Tag } from "components/Notes/Tag";

interface Props {
  tag: TagVO;
  theme?: PillProps["theme"];
}

export const PillTag: FC<Props> = ({ tag, theme = "custom" }) => {
  return (
    <div
      style={{ backgroundColor: getLightColor(tag.color) }}
      className={cx("py-0.5 pl-1.5 pr-2.5 h-6 rounded", cxPillStyles.themes[theme])}
    >
      <Tag tag={tag} />
    </div>
  );
};

export const PillTagNoLabel: FC<Props> = ({ tag, theme = "custom" }) => {
  return (
    <div
      style={{ backgroundColor: getLightColor(tag.color) }}
      className={cx("p-0.5 h-6 w-6 rounded", cxPillStyles.themes[theme])}
    >
      <FloatingTooltip theme="SMALL" content={tag.name}>
        <div>
          <Glyph family={tag.glyph.family}>{tag.glyph.value}</Glyph>
        </div>
      </FloatingTooltip>
    </div>
  );
};
