import { useAccount } from "@libs/contexts/AccountContext";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { noop } from "@libs/utils/noop";
import { messageDispatcher } from "api/websocket/messageDispatcher";
import { WebSocketMessageEmitter, WebSocketMessageEvent } from "api/websocket/webSocketMessage";

export const useMessageDispatcher = (messages: WebSocketMessageEmitter | undefined) => {
  const queryClient = useQueryClient();
  const { practiceId, id } = useAccount();

  useEffect(() => {
    if (messages) {
      const handler = (event: WebSocketMessageEvent) => {
        messageDispatcher(event.detail, queryClient, practiceId, id);
      };

      messages.addEventListener("message", handler);

      return () => {
        messages.removeEventListener("message", handler);
      };
    }

    return noop;
  }, [messages, queryClient, practiceId, id]);
};
