import { FC, useCallback } from "react";
import { PatientVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { createPatient } from "api/patients/mutations";
import { CreatePatientForm, CreatePatientFormProps } from "components/Patient/CreatePatientForm";
import { CreatePatientFormState, getCreatePatientPostData } from "components/Patient/formPostData";
import { handleError } from "utils/handleError";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";

interface Props extends Omit<CreatePatientFormProps, "onFormSubmit" | "insuranceCarriers" | "isPosting"> {
  onCreated: (patients: PatientVO[]) => void;
}

export const CreatePatientFormPanel: FC<Props> = ({
  onCreated,
  contactSelection,
  ...createPatientFormProps
}) => {
  const { practiceId } = useAccount();
  const [{ data: insuranceCarriers }] = useApiQueries([
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
  ]);

  const [createPatientMutation] = useApiMutations([createPatient]);

  const handleFormSubmit = useCallback(
    (formState: CreatePatientFormState) => {
      if (!createPatientMutation.isLoading) {
        createPatientMutation.mutate(
          {
            practiceId,
            data: getCreatePatientPostData({ ...formState, contactSelection }),
          },
          {
            onError: handleError,
            onSuccess: (response) => onCreated(response.data.data),
          }
        );
      }
    },
    [createPatientMutation, practiceId, onCreated, contactSelection]
  );

  return (
    <CreatePatientForm
      {...createPatientFormProps}
      contactSelection={contactSelection}
      isPosting={createPatientMutation.isLoading}
      onFormSubmit={handleFormSubmit}
      insuranceCarriers={insuranceCarriers}
    />
  );
};
