import React, { useCallback, useEffect, useRef } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useAccount } from "@libs/contexts/AccountContext";
import { CurrentUser } from "contexts/CurrentUserContext";

interface PendoOptions {
  user?: CurrentUser;
  isSignedIn?: boolean;
  practice?: PracticeInfoVO;
}

const INIT_ATTEMPT_INTERVAL = 500;

type TrackEventHandler = (eventName: string, metadata?: pendo.Metadata) => void;

export const usePendo = (options?: PendoOptions) => {
  const account = useAccount();
  const { user, isSignedIn = true, practice } = options ?? {};
  const initializedRef = useRef(false);

  const pollForPendo = React.useCallback(
    (intializeOptions?: { visitor: pendo.Metadata; account: pendo.Metadata }) => {
      const intervalId = window.setInterval(() => {
        if (window.pendo) {
          window.clearInterval(intervalId);
          initializedRef.current = true;
          window.pendo.initialize(intializeOptions);
        }
      }, INIT_ATTEMPT_INTERVAL);

      return intervalId;
    },
    []
  );

  useEffect(() => {
    if (initializedRef.current === false) {
      if (isSignedIn) {
        if (user && practice) {
          // Logged-in app, when data is ready
          const visitor: pendo.Metadata = {
            id: account.id.toString(),
            practiceId: account.practiceId,
            practiceTimezoneId: practice.timezoneId,
            role: user.roleV2.name,
            ...(user.type === "EMPLOYEE"
              ? {
                  startDate: user.employmentDetails.startDate,
                  status: user.employmentDetails.status,
                }
              : undefined),
          };
          const pendoAccount: pendo.Metadata = {
            id: account.practiceId.toString(),
            name: practice.name,
            practiceTimezoneId: practice.timezoneId,
          };

          const intervalId = pollForPendo({ visitor, account: pendoAccount });

          return () => {
            window.clearInterval(intervalId);
          };
        }
      } else {
        // Logged-out app
        const intervalId = pollForPendo();

        return () => {
          window.clearInterval(intervalId);
        };
      }
    }

    return noop;
  }, [user, practice, account, isSignedIn, pollForPendo]);

  const trackEvent: TrackEventHandler = useCallback(
    (eventName: string, metadata?: pendo.Metadata) => {
      const pendo = window.pendo;

      if (pendo && user && practice) {
        pendo.track(eventName, {
          practiceId: practice.id,
          userId: user.id,
          ...metadata,
        });
      }
    },
    [practice, user]
  );

  return { trackEvent };
};
export type PendoValue = ReturnType<typeof usePendo>;
