import { PatientInsuranceResponse, PatientInsuranceVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";

export const getInsuranceLabel = (insurance?: PatientInsuranceResponse) => {
  return `${insurance?.patientInsurance.subscriber.carrier ?? ""}${
    insurance?.patientInsurance.subscriber.carrier && insurance.insurancePlan?.planType ? " - " : ""
  }${insurance?.insurancePlan?.planType ?? ""}`;
};

export const isVerifiedInsuranceStatus = (
  status: NonNullable<PatientInsuranceVO["eligibilityVerifiedStatus"]>
) => {
  return isOneOf(status, ["VERIFIED", "AUTO_VERIFIED"]);
};
