import { useEffect } from "react";
import { captureMessage } from "@sentry/react";
import { noop } from "@libs/utils/noop";
import { WebSocketMessageEmitter, WebSocketMessageEvent } from "api/websocket/webSocketMessage";

export const usePingPong = ({
  webSocket,
  messages,
  onResetConnection,
  pingInterval,
  pingTimeout,
}: {
  webSocket: WebSocket | undefined;
  messages: WebSocketMessageEmitter | undefined;
  onResetConnection: Func;
  pingInterval: number;
  pingTimeout: number;
}) => {
  useEffect(() => {
    if (webSocket && messages) {
      let pingTimeoutId = 0;

      const handlePong = (e: WebSocketMessageEvent) => {
        if (e.detail.type === "PONG") {
          window.clearTimeout(pingTimeoutId);
        }
      };

      messages.addEventListener("message", handlePong);

      const pingIntervalId = window.setInterval(() => {
        // before calling ping, set a timeout that
        // should be cancelled by pong. if it isn't
        // something is wrong and we should try to
        // re-establish a connection
        pingTimeoutId = window.setTimeout(() => {
          captureMessage("websocket ping timed out", {
            level: "error",
          });
          onResetConnection();
        }, pingTimeout);

        webSocket.send(JSON.stringify({ action: "ping" }));
      }, pingInterval);

      return () => {
        messages.removeEventListener("message", handlePong);
        window.clearInterval(pingIntervalId);
        window.clearTimeout(pingTimeoutId);
      };
    }

    return noop;
  }, [webSocket, messages, onResetConnection, pingInterval, pingTimeout]);
};
