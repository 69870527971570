import { useCallback, useMemo } from "react";
import {
  AnyQueryConfig,
  ParsedParams,
  ParsedPathParams,
  StrVal,
  compileRoutesConfig,
  formUrl,
  parseParams,
  parseQueryParams,
  queryParamsToString,
  upsertQueryParams,
} from "@libs/router/url";
import { useQueryParamsConfig } from "@libs/hooks/useQueryParamsConfig";
import { PathMatch, matchPath } from "react-router-dom";
import { union } from "@libs/utils/union";
import { PatientNoteRoutes, patientNoteRoutes } from "components/Notes/routes";

const routeOrder = union<keyof PatientNoteRoutes>().createUniqueList([
  "list",
  "filter",
  "create",
  "createFromForm",
  "edit",
  "history",
]);

type ParsedRouteValues = {
  [key in keyof PatientNoteRoutes]: PatientNoteRoutes[key] extends { query: AnyQueryConfig }
    ? {
        routeName: key;
        params: ParsedPathParams<PatientNoteRoutes[key]["params"]>;
        query: ParsedParams<PatientNoteRoutes[key]["query"]>;
        getUrl: (
          keyValues: Partial<ParsedParams<PatientNoteRoutes[key]["query"]>>,
          update?: boolean
        ) => string;
      }
    : {
        routeName: key;
        params: ParsedPathParams<PatientNoteRoutes[key]["params"]>;
      };
}[keyof PatientNoteRoutes];

type GetParsedRouteValues<T extends ParsedRouteValues["routeName"]> = Extract<
  ParsedRouteValues,
  { routeName: T }
>;

export const noteFlyoverPaths = compileRoutesConfig(patientNoteRoutes);

type NoteFlyoverPaths = typeof noteFlyoverPaths;

export const patientNotesQueryKey = "patientNotes";

const patientNotesQueryConfig = {
  [patientNotesQueryKey]: StrVal,
};

const getParsedRouteValues = <N extends keyof PatientNoteRoutes>({
  routeName,
  route,
  params,
  searchParams,
  pathname,
}: {
  routeName: N;
  route: PatientNoteRoutes[N];
  params: PathMatch["params"];
  searchParams: URLSearchParams;
  pathname: string;
}) => {
  const getUrl = (keyValues: Partial<ParsedParams<PatientNoteRoutes[N]["query"]>>, update?: boolean) => {
    const updatedKeyValues = queryParamsToString(route.query, keyValues);

    return update
      ? formUrl(pathname, upsertQueryParams(updatedKeyValues, searchParams))
      : formUrl(pathname, upsertQueryParams(updatedKeyValues));
  };

  return {
    routeName,
    params: parseParams(route.params, params),
    query: parseQueryParams(route.query, searchParams).parsed,
    getUrl,
  } as unknown as ParsedRouteValues;
};

export const resolveRoute = (url: string) => {
  const routes = routeOrder.map((routeName) => ({ route: patientNoteRoutes[routeName], routeName }));

  for (const [index, { route, routeName }] of routes.entries()) {
    const { searchParams, pathname } = new URL(url, window.location.origin);
    const match = matchPath(route.path, pathname);

    if (!match) {
      continue;
    }

    return {
      index,
      routeName,
      route,
      params: match.params,
      searchParams,
      pathname,
    };
  }

  return null;
};

const parseUrlParams = (url: string) => {
  const resolved = resolveRoute(url);

  if (resolved) {
    const routeValues: ParsedRouteValues = getParsedRouteValues(resolved);

    return routeValues;
  }

  return null;
};

export const usePatientNotesRouter = () => {
  const { query, updateQuery } = useQueryParamsConfig(patientNotesQueryConfig);
  const open = useCallback(
    <K extends keyof NoteFlyoverPaths>(key: K, ...args: Parameters<NoteFlyoverPaths[K]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      updateQuery("replaceIn", { patientNotes: noteFlyoverPaths[key](...(args as [any, any])) });
    },
    [updateQuery]
  );
  const navigate = useCallback(
    <K extends keyof NoteFlyoverPaths>(key: K, ...args: Parameters<NoteFlyoverPaths[K]>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      updateQuery("replaceIn", { patientNotes: noteFlyoverPaths[key](...(args as [any, any])) });
    },
    [updateQuery]
  );

  const update = (url: string) => {
    updateQuery("replaceIn", { patientNotes: url });
  };

  const close = useCallback(() => {
    updateQuery("replaceIn", { patientNotes: undefined });
  }, [updateQuery]);

  const parseCurrentUrlParams = useCallback(() => {
    if (!query.patientNotes) {
      return null;
    }

    return parseUrlParams(query.patientNotes);
  }, [query.patientNotes]);

  const back = useCallback(() => {
    const route = parseCurrentUrlParams();

    if (!route) {
      return;
    }

    if ("from" in route.query && route.query.from) {
      updateQuery("replaceIn", { patientNotes: route.query.from });
    } else {
      navigate("list", { patientId: route.params.patientId });
    }
  }, [updateQuery, navigate, parseCurrentUrlParams]);

  return {
    navigate,
    update,
    open,
    close,
    back,
    parseCurrentUrlParams,
    location: query.patientNotes,
    isOpen: Boolean(query.patientNotes),
  };
};

export const useParsePatientNoteParams = <T extends ParsedRouteValues["routeName"]>(
  routeName: T,
  url: string
) => {
  return useMemo(() => {
    const route = parseUrlParams(url);

    if (route?.routeName !== routeName) {
      throw new Error(`Route ${routeName} not found`);
    }

    return route as GetParsedRouteValues<T>;
  }, [url, routeName]);
};
