import { FC } from "react";
import { FamilyRelationVO } from "@libs/api/generated-api";
import { PatientRelationship } from "@libs/hooks/useRelations";
import { FormFieldLayout } from "@libs/types/form";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const SelectRelationship: FC<{
  className?: string;
  edit?: boolean;
  error?: string;
  onItemSelected: (value: FamilyRelationVO["relation"]) => void;
  layout?: FormFieldLayout;
  patientRelations: PatientRelationship[];
  value?: FamilyRelationVO["relation"];
}> = ({ className, edit = true, error, layout, onItemSelected, patientRelations, value }) => {
  return (
    <FormFieldSelect
      className={className}
      display="label"
      edit={edit}
      error={error}
      isClearable={false}
      isSearchable={false}
      label="Relationship to Primary Subscriber"
      layout={layout}
      onItemSelected={onItemSelected}
      options={patientRelations}
      placeholder="Select relationship e.g. Spouse"
      required
      value={value}
    />
  );
};
