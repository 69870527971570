import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";
import { noop } from "@libs/utils/noop";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ApiErrorResponse } from "@libs/@types/api";
import { useAccount } from "@libs/contexts/AccountContext";
import { callPatient } from "api/calls/mutations";
import { CallData, CallType, CallsByNumberMap } from "components/Mango/types";
import { MangoConfig, useMangoStorage } from "storage/mango";

type MangoContext = {
  addCall: (callType: CallType, phoneNumber: string, updates: CallData) => void;
  deleteCall: (callType: CallType, phoneNumber: string) => void;
  hideAllCalls: Func;
  inboundCallsMap: CallsByNumberMap;
  makeCall: (
    extension: string,
    phoneNumber: string,
    patientId: number,
    onErrorCallback: (e: ApiErrorResponse) => void
  ) => void;
  mode: CallMode;
  outboundCallsMap: CallsByNumberMap;
  updateCall: (callType: CallType, phoneNumber: string, updates: Partial<CallData>) => void;
  updateMode: (mode: CallMode) => void;
  allowInbound: boolean;
  ext: string;
  setMangoConfig: (config: MangoConfig) => void;
};

const defaultValue = {
  addCall: noop,
  deleteCall: noop,
  hideAllCalls: noop,
  inboundCallsMap: {},
  makeCall: noop,
  mode: undefined,
  outboundCallsMap: {},
  updateCall: noop,
  updateMode: noop,
  allowInbound: false,
  ext: "",
  setMangoConfig: noop,
};

const Context = createContext<MangoContext>(defaultValue);

Context.displayName = "MangoContext";

export const useMangoContext = () => useContext(Context);

type CallMode = "noConnection" | "phoneSettings" | "contactMango" | "calling" | undefined;

export const MangoProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { practiceId } = useAccount();
  const [inboundCallsMap, setInboundCallsMap] = useState<CallsByNumberMap>({});
  const [outboundCallsMap, setOutboundCallsMap] = useState<CallsByNumberMap>({});
  const [mode, setMode] = useState<CallMode>(undefined);

  const [{ allowInbound, ext }, setMangoConfig] = useMangoStorage();

  const updateCall = useCallback((callType: CallType, phoneNumber: string, updates: Partial<CallData>) => {
    if (callType === CallType.INBOUND) {
      setInboundCallsMap((last) => {
        const callToUpdate = last[phoneNumber];

        return {
          ...last,
          [phoneNumber]: {
            ...callToUpdate,
            ...updates,
          },
        };
      });
    } else {
      setOutboundCallsMap((last) => {
        const callToUpdate = last[phoneNumber];

        return {
          ...last,
          [phoneNumber]: {
            ...callToUpdate,
            ...updates,
          },
        };
      });
    }
  }, []);

  const addCall = useCallback(
    (callType: CallType, phoneNumber: string, call: CallData) => {
      updateCall(callType, phoneNumber, call);
    },
    [updateCall]
  );

  const deleteCall = useCallback((callType: CallType, phoneNumber: string) => {
    if (callType === CallType.INBOUND) {
      setInboundCallsMap((last) => {
        const copy = { ...last };

        delete copy[phoneNumber];

        return copy;
      });
    } else {
      setOutboundCallsMap((last) => {
        const copy = { ...last };

        delete copy[phoneNumber];

        if (Object.keys(copy).length === 0) {
          setMode(undefined);
        }

        return copy;
      });
    }
  }, []);

  const hideAllCalls = useCallback(() => {
    setOutboundCallsMap((last) => {
      const copy: CallsByNumberMap = {};

      for (const [key, value] of Object.entries(last)) {
        copy[key] = {
          ...value,
          display: false,
        };
      }

      return copy;
    });
    setInboundCallsMap((last) => {
      const copy: CallsByNumberMap = {};

      for (const [key, value] of Object.entries(last)) {
        copy[key] = {
          ...value,
          display: false,
        };
      }

      return copy;
    });
  }, []);

  const [callPatientMutation] = useApiMutations([callPatient]);

  const callPatientMutate = callPatientMutation.mutate;
  const makeCall = useCallback(
    (
      extension: string,
      phoneNumber: string,
      patientId: number,
      onErrorCallback: (e: ApiErrorResponse) => void
    ) => {
      if (!(phoneNumber in outboundCallsMap)) {
        callPatientMutate(
          {
            practiceId,
            patientId,
            data: { extension },
          },
          {
            onError: onErrorCallback,
            onSuccess: (_) => {
              addCall(CallType.OUTBOUND, phoneNumber, {
                callType: CallType.OUTBOUND,
                display: true,
                extensionNumber: extension,
                patientId,
                phoneNumber,
                state: "PHONE_CALL_INITIATED",
              });
              setMode(undefined);
            },
          }
        );
      }
    },
    [outboundCallsMap, callPatientMutate, practiceId, addCall]
  );

  const value = useMemo(() => {
    return {
      addCall,
      deleteCall,
      hideAllCalls,
      inboundCallsMap,
      makeCall,
      mode,
      outboundCallsMap,
      updateCall,
      updateMode: setMode,
      allowInbound,
      ext,
      setMangoConfig,
    };
  }, [
    addCall,
    deleteCall,
    hideAllCalls,
    inboundCallsMap,
    makeCall,
    mode,
    outboundCallsMap,
    updateCall,
    allowInbound,
    ext,
    setMangoConfig,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
