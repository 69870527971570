import { FC, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { LastWorktimeVOV2, ProfileImageVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as ClockIcon } from "@libs/assets/icons/clock.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { ReactComponent as SignOutIcon } from "@libs/assets/icons/sign-out.svg";
import { ReactComponent as CheckedCircleIcon } from "@libs/assets/icons/check-circle-2.svg";
import { ReactComponent as ReferralIcon } from "@libs/assets/icons/accounting.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { Avatar } from "@libs/components/UI/Avatar";
import { SecuritySettingsModal } from "components/Main/SecuritySettingsModal";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { ClockInOut } from "components/Main/ClockInOut";
import { useCurrentUser } from "contexts/CurrentUserContext";

interface Props {
  profileImage?: ProfileImageVO;
  clockData?: LastWorktimeVOV2;
  isOnboarding?: boolean;
  isMigrationRole?: boolean;
  name: string;
}

export const ProfileMenu: FC<Props> = ({ isOnboarding, isMigrationRole, profileImage, clockData, name }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isSupportUser = currentUser.type === "SUPPORT_USER";
  const profileMenu = useBoolean(false);
  const changePasswordModal = useBoolean(false);
  const changePasswordSuccessModal = useBoolean(false);
  const clockInOutModal = useItemModal<LastWorktimeVOV2>(null);

  const profileMenuOff = profileMenu.off;
  const changePasswordModalOn = changePasswordModal.on;

  const menuOptions = useMemo(
    () =>
      [
        clockData && !isOnboarding && !isMigrationRole && !isSupportUser
          ? createMenuOption(
              clockData.lastAction === "CLOCK_OUT"
                ? {
                    label: "Clock In",
                    value: "clock-in-out",
                    SvgIcon: ClockIcon,
                    iconTheme: "success",
                  }
                : {
                    label: "Clock Out",
                    value: "clock-in-out",
                    SvgIcon: ClockIcon,
                    iconTheme: "error",
                  }
            )
          : null,
        isOnboarding || isSupportUser
          ? null
          : createMenuOption({
              label: "My Profile",
              value: "my-profile",
              SvgIcon: UserIcon,
            }),
        ...(isSupportUser
          ? []
          : createMenuOptions(
              {
                label: "Security Settings",
                value: "security-settings",
                SvgIcon: LockIcon,
              },
              {
                label: (
                  <div className="flex items-center gap-x-2">
                    <span>Refer Archy</span>
                    <span
                      className={`
                        bg-yellowLight
                        rounded
                        px-1.5
                        text-yellowDark
                        font-sansSemiBold
                      `}
                    >
                      $350
                    </span>
                  </div>
                ),
                value: "archy-referral",
                SvgIcon: ReferralIcon,
              }
            )),
        createMenuOption({
          label: "Sign Out",
          value: "sign-out",
          SvgIcon: SignOutIcon,
        }),
      ].filter(isDefined),
    [clockData, isOnboarding, isMigrationRole, isSupportUser]
  );

  const handleViewProfile = useCallback(
    () => navigate(paths.employee({ id: currentUser.id })),
    [navigate, currentUser.id]
  );
  const handleReferArchy = useCallback(
    () =>
      navigate(
        paths.archyReferral(
          { id: currentUser.id },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { archy_user_referral: currentUser.id, archyfname: currentUser.name.fullDisplayName }
        )
      ),
    [navigate, currentUser.id, currentUser.name.fullDisplayName]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "clock-in-out": {
          if (clockData) {
            clockInOutModal.open(clockData);
          }

          break;
        }
        case "my-profile": {
          handleViewProfile();
          break;
        }
        case "security-settings": {
          changePasswordModalOn();
          break;
        }
        case "archy-referral": {
          handleReferArchy();
          break;
        }
        case "sign-out": {
          navigate(paths.signOut());
          break;
        }
        default: {
          break;
        }
      }

      profileMenuOff();
    },
    [
      changePasswordModalOn,
      clockData,
      clockInOutModal,
      handleReferArchy,
      handleViewProfile,
      profileMenuOff,
      navigate,
    ]
  );

  return (
    <>
      <ButtonMenu
        className="text-greyDark flex items-center"
        isOpen={profileMenu.isOn}
        onRequestClose={profileMenu.off}
        onRequestOpen={profileMenu.on}
        menuContent={<MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />}
        floatingOffset={20}
        placement="right-end"
        shadow="heavy"
      >
        {(props) => (
          <button
            {...props}
            // Devops looks for this id and data attribute as a canary for
            // successful loading of the app
            id="logged-in-employee"
            data-employee-name={name}
            type="button"
          >
            <Avatar
              borderColor={
                clockData && !isSupportUser
                  ? clockData.lastAction === "CLOCK_IN"
                    ? "greenDark"
                    : "redDark"
                  : "slate300"
              }
              borderThickness="thick"
              imageUrl={profileImage?.thumbnail}
              name={name}
            />
          </button>
        )}
      </ButtonMenu>

      {changePasswordModal.isOn && (
        <SecuritySettingsModal
          onRequestClose={changePasswordModal.off}
          onSuccess={() => {
            changePasswordModal.off();
            changePasswordSuccessModal.on();
          }}
        />
      )}
      {changePasswordSuccessModal.isOn && (
        <Modal size="xs" onClose={changePasswordSuccessModal.off}>
          <ModalContent className="p-10 text-center">
            <button type="button" className="absolute top-5 right-5" onClick={changePasswordSuccessModal.off}>
              <CancelIcon className="fill-primaryTheme w-5 h-5" />
            </button>
            <div className="flex justify-center py-5">
              <CheckedCircleIcon className="fill-primaryTheme w-14 h-14" />
            </div>
            <div className="font-sansSemiBold text-2xl mb-3">Password Updated!</div>
            <div className="font-sans text-sm mb-3">Your password has been changed successfully.</div>
          </ModalContent>
        </Modal>
      )}
      {clockInOutModal.isOpen ? (
        <ClockInOut lastStatus={clockInOutModal.item} onCloseClick={clockInOutModal.close} />
      ) : null}
    </>
  );
};
