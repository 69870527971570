import { FC } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import {
  AppointmentDate,
  AppointmentPills,
  AppointmentProvidersRoom,
  AppointmentTimeSpan,
} from "components/ScheduleAppointments/AppointmentDetailComponents";

const cxThemes = {
  white: "hover:bg-slate-100",
  slate100: "hover:bg-slate-200",
};

interface Props {
  appointment: AppointmentVO | undefined;
  onUpdateAppointmentState?: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  theme?: keyof typeof cxThemes;
  includePadding?: boolean;
}

export const AppointmentSummary: FC<Props> = ({
  appointment,
  onUpdateAppointmentState,
  theme,
  includePadding = true,
}) => {
  return (
    <div
      className={cx(
        "text-xs flex flex-col gap-y-1 rounded transition-colors",
        theme && cxThemes[theme],
        includePadding && "px-3 py-2"
      )}
    >
      {appointment ? (
        <>
          <div className="flex flex-wrap gap-1 font-sansSemiBold">
            <AppointmentDate date={appointment.date} state={appointment.state} />
            <span>•</span>
            <AppointmentTimeSpan
              duration={appointment.duration}
              date={appointment.date}
              state={appointment.state}
              endTime={appointment.endTime}
              startTime={appointment.startTime}
            />
          </div>

          <AppointmentProvidersRoom
            dentist={appointment.dentist}
            provider={appointment.provider}
            date={appointment.date}
            state={appointment.state}
            room={appointment.room}
          />

          <AppointmentPills appointment={appointment} onUpdateAppointmentState={onUpdateAppointmentState} />
        </>
      ) : (
        <>
          <span className="font-sansSemiBold">Next Appointment</span>
          <span className="">None Scheduled</span>
        </>
      )}
    </div>
  );
};
