import { FC } from "react";
import { AppointmentCardConfigVO, AppointmentVO, TagVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { cx } from "@libs/utils/cx";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { PatientInsuranceBadges } from "components/Insurance/PatientInsuranceBadges";
import { AppointmentTimeSpan } from "components/ScheduleAppointments/AppointmentDetailComponents";
import { LabCaseMultiStatusIcon } from "components/LabCases/LabComponents";
import { ClinicalNoteStatus } from "components/ScheduleAppointments/ClinicalNoteStatus";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";
import { PillTag } from "components/Notes/PillTag";
import { getPatientCardName } from "components/ScheduleAppointments/utils";

interface Props {
  appointment: AppointmentVO;
  tags: TagVO[];
  groupingContextDetail?: string;
  hipaaView?: boolean;
  config: AppointmentCardConfigVO;
}

// eslint-disable-next-line complexity
export const AppointmentCardTooltip: FC<Props> = ({
  appointment,
  tags,
  groupingContextDetail,
  hipaaView = false,
  config,
}) => {
  const patientNotes = usePatientNotesRouter();
  const { id: patientId, namePronunciation } = appointment.patient;
  const { practiceId } = useAccount();

  const [{ data: activeInsurances }] = useApiQueries([
    getPatientInsurancesQuery({
      args: {
        patientId,
        practiceId,
        includeInsurancePlan: true,
        includeBenefitCoverage: true,
        includeBenefitLimitation: true,
        includeProcedureHistory: true,
        insuranceState: ["ACTIVE"],
      },
    }),
  ]);

  const name = getPatientCardName({ hipaaView, appointment, config });

  const [primaryInsurance] = activeInsurances ?? [undefined];

  const labCases = appointment.patientProcedures
    .map((procedure) => procedure.labCaseStatus)
    .filter(isDefined);

  return (
    <div className="flex flex-col gap-y-1 items-stretch">
      <div
        className={cx(
          "font-sansSemiBold",
          config.fontSize === "STANDARD" ? "text-xs" : "lg:text-xxxs xl:text-xxs"
        )}
      >
        {name}
      </div>
      {config.showPatientPronunciation && namePronunciation && !hipaaView ? (
        <div className="italic">{namePronunciation}</div>
      ) : null}
      <div>{appointment.patientProcedures.map((proc) => proc.displayName).join(", ")}</div>
      <div>{groupingContextDetail}</div>
      <AppointmentTimeSpan
        duration={appointment.duration}
        date={appointment.date}
        state={appointment.state}
        startTime={appointment.startTime}
        endTime={appointment.endTime}
      />

      <div className="flex flex-wrap gap-1 items-center">
        {appointment.clinicalNoteStatus ? (
          <ClinicalNoteStatus
            patientId={patientId}
            appointmentId={appointment.id}
            status={appointment.clinicalNoteStatus}
            onNoteLoaded={(note) => patientNotes.open("edit", { patientId, uuid: note.uuid })}
          />
        ) : null}
        {labCases.length > 0 && <LabCaseMultiStatusIcon shape="square" labCases={labCases} size="md" />}
        {tags.map((tag) => (
          <PillTag key={tag.id} tag={tag} />
        ))}
      </div>

      <p className="whitespace-pre-wrap">{appointment.comments}</p>

      {primaryInsurance && (
        <div className="flex flex-row min-w-0 items-center mt-0.5 squeeze-children">
          <PatientInsuranceBadges
            badgeSize="lg"
            borderColor="white"
            isLoading={!activeInsurances}
            primaryInsurance={primaryInsurance}
          />
        </div>
      )}
    </div>
  );
};
