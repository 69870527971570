import { CallRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";

export const callPatient = makeMutation({
  mutationKey: ["practices", "callPatient"],
  formatParams: (args: { practiceId: number; patientId: number; data: CallRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
});
