import { ContactVO, NameVO, PatientSummaryVO } from "@libs/api/generated-api";
import { getAgeByDob } from "@libs/utils/formatString";
import { abbreviateGender } from "components/PatientProfile/PatientOverview/utils";

export const getInitials = (name: NameVO): string => {
  const firstNameInitial = name.firstName.charAt(0).toUpperCase();
  const lastNameInitial = name.lastName.charAt(0).toUpperCase();

  return `${firstNameInitial}${lastNameInitial}`;
};

export const getPatientDisplayNameFromPatient = (now: Date, patient: PatientSummaryVO): string => {
  // TODO once GF-5826 is closed getPatientDisplayNameFromPatient can be removed
  // and replaced with getPatientDisplayName

  return getPatientDisplayName({
    name: patient.name,
    age: getAgeByDob(now, patient.dob),
    gender: patient.gender,
  });
};

const endsWithAge = /,\s\d+$/;

export const fixFamilyMemberFullDisplayName = (fullDisplayName: string) => {
  return endsWithAge.test(fullDisplayName) ? fullDisplayName.split(",")[0] : fullDisplayName;
};

export const getPatientDisplayName = ({
  name,
  age,
  gender,
}: {
  name: NameVO;
  age: number;
  gender?: ContactVO["gender"];
}): string => {
  // Once GF-5817 has been closed we can remove fixFamilyMemberFullDisplayName
  const fullName = fixFamilyMemberFullDisplayName(name.fullDisplayName);

  return `${fullName}, ${age}${gender ? ` ${abbreviateGender(gender)}` : ""}`;
};

export const getFirstAndLastName = (name: NameVO): string => {
  return `${name.firstName} ${name.lastName}`;
};
