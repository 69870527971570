import { SupportedExtentions, allSupportedUploadExtensions } from "@libs/utils/mimeTypes";

export const printFile = (blob: Blob) => {
  const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));

  window.open(url);

  // Clean up memory.
  window.URL.revokeObjectURL(url);
};

export const downloadFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);
  document.body.append(link);
  link.click();
};

const supportedExtensions = new Set(allSupportedUploadExtensions());

export const removeFilenameExtension = (filename: string) => {
  return filename.slice(0, Math.max(0, filename.lastIndexOf("."))) || filename;
};

export const addFilenameExtension = (initialFilename: string, updatedFilename: string) => {
  const extension = initialFilename
    .slice(initialFilename.lastIndexOf(".") + 1)
    .toLowerCase() as SupportedExtentions;

  if (supportedExtensions.has(extension)) {
    return `${updatedFilename}.${extension}`;
  }

  return updatedFilename;
};

export const mimeTypeMapping = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  pdf: "application/pdf",
  jpeg: "image/jpeg",
  png: "image/png",
  bmp: "image/bmp",
};
