import { QueryClient } from "@tanstack/react-query";
import { WalletActivityUpdatedEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";

export const handleWalletActivityUpdated = ({
  data,
  queryClient,
}: {
  data: WalletActivityUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getWalletActivityById"),
    { patientId: data.patientId, walletActivityId: data.walletActivityId },
  ]);

  queryClient.invalidateQueries([
    getQueryKey("practices", "getWalletActivities"),
    { patientId: data.patientId, walletUuid: data.walletUuid },
  ]);
};
