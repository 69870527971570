import { useState, useRef, useCallback, useEffect } from "react";

export const useIsDirty = <T>(
  value: T,
  options?: { initialValue?: boolean; onDirty?: (newValue: T) => void }
) => {
  const [isDirty, setIsDirty] = useState(options?.initialValue ?? false);

  const ref = useRef(value);
  const markDirty = useCallback(() => setIsDirty(true), []);
  const markClean = useCallback(() => setIsDirty(false), []);

  const onDirty = options?.onDirty;

  useEffect(() => {
    if (ref.current !== value) {
      ref.current = value;
      markDirty();
      onDirty?.(value);
    }
  }, [value, markDirty, onDirty]);

  return { isDirty, markDirty, markClean };
};
