import type { BaseChannel } from "@sendbird/chat";
import type { GroupChannel } from "@sendbird/chat/groupChannel";
import {
  MessageType,
  AdminMessage,
  BaseMessage,
  FileMessage,
  UserMessage,
  MultipleFilesMessage,
} from "@sendbird/chat/message";
import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { isAudio, isImage, isVideo } from "@libs/utils/mimeTypes";

export const GroupChannelCustomType = {
  GROUP: "GROUP" as const,
  DIRECT: "DIRECT" as const,
};

export const isGroupChannel = (channel: Partial<BaseChannel>): channel is GroupChannel =>
  Boolean(channel.isGroupChannel?.());

export const isMultipleFilesMessage = (message: Partial<BaseMessage>): message is MultipleFilesMessage =>
  message["isMultipleFilesMessage"] && typeof message.isMultipleFilesMessage === "function"
    ? message.isMultipleFilesMessage()
    : message.messageType === MessageType.FILE &&
      Object.prototype.hasOwnProperty.call(message, "fileInfoList");

export const isFileMessage = (message?: Partial<BaseMessage>): message is FileMessage =>
  message?.messageType === MessageType.FILE && !isMultipleFilesMessage(message);

export const isUserMessage = (message?: Partial<BaseMessage>): message is UserMessage =>
  message?.messageType === MessageType.USER;

export const isAdminMessage = (message?: Partial<BaseMessage>): message is AdminMessage =>
  message?.messageType === MessageType.ADMIN;

export const isVideoMessage = (message: Partial<BaseMessage>): boolean =>
  isFileMessage(message) && isVideo(message.type);

export const isAudioMessage = (message: Partial<BaseMessage>): boolean =>
  isFileMessage(message) && isAudio(message.type);

export const isImageMessage = (message: Partial<BaseMessage>): boolean =>
  isFileMessage(message) && isImage(message.type);

export const getLastMessageCreatedAt = (createdAt: number) => {
  if (isToday(createdAt)) {
    return format(createdAt, "p");
  }

  if (isYesterday(createdAt)) {
    return "Yesterday";
  }

  if (isThisYear(createdAt)) {
    return format(createdAt, "MMM d");
  }

  return format(createdAt, "MM/dd/yyyy");
};

export const getMessageCreatedAtTime = (message: Partial<BaseMessage>) =>
  message.createdAt ? format(message.createdAt, "p") : null;

export const getLastMessage = (channel: Partial<GroupChannel>) => {
  if (isFileMessage(channel.lastMessage)) {
    return channel.lastMessage.name;
  }

  if (isUserMessage(channel.lastMessage)) {
    return channel.lastMessage.message;
  }

  return "";
};

export const getChannelTitle = (channel: Partial<GroupChannel>, currentUserId?: string) => {
  if (channel.name && channel.name !== "Group Channel") {
    return channel.name;
  }

  if (!channel.members) {
    return "Empty Chat";
  }

  let members = channel.members;

  // If there is only one member, then show the name. It's a chat with yourself.
  if ((channel.memberCount || 0) > 1) {
    members = members.filter(({ userId }) => userId !== currentUserId);
  }

  return members
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
    .map(({ nickname }) => nickname)
    .join(", ");
};

export const DEFAULT_URL_PREFIX = "https://static.sendbird.com/sample/cover/cover_";

export const getChannelAvatarSource = (channel: Partial<GroupChannel>, currentUserId: string): string => {
  if (channel.coverUrl && !channel.coverUrl.startsWith(DEFAULT_URL_PREFIX)) {
    return channel.coverUrl;
  }

  return (channel.members || [])
    .filter((member) => member.userId !== currentUserId)
    .map(({ profileUrl }) => profileUrl)[0];
};

// Not currently used, but will be when we remove ChannelList usage from @sendbird.
export const isSameGroup = (message: Partial<BaseMessage>, comparingMessage?: Partial<BaseMessage>) => {
  if (!isUserMessage(message) || !isUserMessage(comparingMessage)) {
    return false;
  }

  return (
    message.sender.userId === comparingMessage.sender.userId &&
    getMessageCreatedAtTime(message) === getMessageCreatedAtTime(comparingMessage)
  );
};

export const sortChannels = (channels: GroupChannel[], currentUserId?: string): GroupChannel[] => {
  const channelsWithTitles = channels.map((entry) => {
    // We cannot use spread to copy title here because it will drop the GroupChannel functions.
    // We must assign the data to the existing object.
    const newEntryType = entry as GroupChannel & { title: string };

    newEntryType.title = getChannelTitle(entry, currentUserId);

    return newEntryType;
  });

  // Sort the channels alphabetically.
  channelsWithTitles.sort((a, b) => a.title.localeCompare(b.title));

  return channelsWithTitles;
};
