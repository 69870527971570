import { FC, useCallback, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ReactComponent as InboxIcon } from "@libs/assets/icons/inbox.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as AcceptIcon } from "@libs/assets/icons/check.svg";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { ReactComponent as LogIcon } from "@libs/assets/icons/readme.svg";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptionValue, MenuOptions } from "@libs/components/UI/MenuOptions";
import { useItemModal } from "hooks/useItemModal";
import { AppointmentAuditLog } from "components/ScheduleAppointments/AppointmentAuditLog";
import {
  ConfirmInsuranceEstimateModal,
  usePrintAppointmentInsuranceEstimate,
} from "components/Insurance/ConfirmInsuranceEstimateModal";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { printFile } from "utils/files";
import { handleError } from "utils/handleError";
import { updateAppointment } from "api/scheduling/mutations";
import { getAppointmentRequest } from "components/ScheduleAppointments/utils";
import { paths } from "utils/routing/paths";
import { useDeleteAppointment } from "contexts/DeleteAppointmentContext";
import { usePromise } from "utils/usePromise";

type Actions = "edit" | "history" | "accept" | "print" | "hold" | "delete" | "view";

const getMenuOptions = ({
  appointment,
  isOnSchedule,
  actionInProgress,
  isLoadingInsurances,
}: {
  appointment: AppointmentVO;
  isOnSchedule?: boolean;
  actionInProgress?: Extract<Actions, "accept" | "print" | "hold" | "delete">;
  isLoadingInsurances: boolean;
}) => {
  const options: InternalMenuOption[] = [];

  if (!isOnSchedule) {
    options.push({
      label: "View on Schedule",
      value: "view",
      SvgIcon: CalendarIcon,
      appointment,
    });
  }

  options.push(
    {
      label: "Edit",
      SvgIcon: EditIcon,
      value: "edit",
      appointment,
    },
    {
      label: "History",
      SvgIcon: LogIcon,
      value: "history",
      appointment,
    }
  );

  if (appointment.state === "REQUESTED") {
    options.push({
      label: "Accept",
      SvgIcon: AcceptIcon,
      value: "accept",
      isLoading: actionInProgress === "accept",
      appointment,
    });
  } else {
    options.push({
      label: "Print Estimate",
      SvgIcon: PrintIcon,
      value: "print",
      isLoading: actionInProgress === "print" || isLoadingInsurances,
      appointment,
    });
  }

  if (appointment.state !== "UNSCHEDULED") {
    options.push({
      label: "Save As Hold",
      SvgIcon: InboxIcon,
      value: "hold",
      isLoading: actionInProgress === "hold",
      appointment,
    });
  }

  if (appointment.state !== "COMPLETED") {
    options.push({
      label: "Delete",
      SvgIcon: DeleteIcon,
      iconTheme: "error",
      value: "delete",
      appointment,
    });
  }

  return options;
};

type InternalMenuOption = MenuOptionValue<Actions> & { appointment: AppointmentVO };

type AppointmentMenuOption = MenuOptionValue<Actions> & {
  appointment: AppointmentVO;
};

export type AppointmentActions = {
  edit?: (option: AppointmentMenuOption) => void;
  view?: (option: AppointmentMenuOption) => void;
};

export interface AppointmentMenuActionProps {
  appointment: AppointmentVO;
  onRequestClose: Func;
  onMenuBusy: Func;
  onAppointmentDeleted?: (params: { patientId: number; appointmentId: number }) => void;
  isOnSchedule?: boolean;
  actions?: AppointmentActions;
}

export const AppointmentMenuActions: FC<AppointmentMenuActionProps> = ({
  appointment,
  actions,
  isOnSchedule,
  onMenuBusy,
  onAppointmentDeleted,
  onRequestClose,
}) => {
  const { fetchBlob } = useContext(ApiClientContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { practiceId } = useAccount();
  const apptAuditLog = useItemModal<AppointmentVO>(null);

  const [activeInsurancesQuery] = useApiQueries([
    getPatientInsurancesQuery({
      args: {
        patientId: appointment.patient.id,
        practiceId,
        insuranceState: ["ACTIVE"],
      },
    }),
  ]);

  const [updateAppointmentMutation] = useApiMutations([updateAppointment]);

  const { requestDeleteAppointment, isDeleting } = useDeleteAppointment();

  const { printAppointmentModal, showPrintAppointmentModal } = usePrintAppointmentInsuranceEstimate(
    activeInsurancesQuery.data
  );

  const handlePrintAppointment = useCallback(
    async (forceInsuranceEstimate?: boolean) => {
      onMenuBusy();

      const query =
        typeof forceInsuranceEstimate === "boolean"
          ? `?forceInsuranceEstimate=${forceInsuranceEstimate}`
          : "";

      const blob = await fetchBlob(
        `/practices/${practiceId}/patients/${appointment.patient.id}/appointments/${appointment.id}/print${query}`
      );

      return printFile(blob);
    },
    [fetchBlob, practiceId, appointment.id, appointment.patient.id, onMenuBusy]
  );

  const printPromise = usePromise(handlePrintAppointment, {
    onSuccess: onRequestClose,
    onError: onRequestClose,
  });

  const actionInProgress =
    updateAppointmentMutation.isLoading && updateAppointmentMutation.variables?.data.state === "UNCONFIRMED"
      ? "accept"
      : updateAppointmentMutation.isLoading &&
          updateAppointmentMutation.variables?.data.state === "UNSCHEDULED"
        ? "hold"
        : isDeleting
          ? "delete"
          : printPromise.isPending
            ? "print"
            : undefined;

  const options = useMemo(() => {
    return getMenuOptions({
      appointment,
      isOnSchedule,
      isLoadingInsurances: activeInsurancesQuery.isLoading,
      actionInProgress,
    });
  }, [appointment, isOnSchedule, actionInProgress, activeInsurancesQuery.isLoading]);

  const mutateAppointment = updateAppointmentMutation.mutate;
  const handleUpdateAppointmentState = useCallback(
    (newState: AppointmentVO["state"]) => {
      onMenuBusy();
      mutateAppointment(
        {
          appointmentId: appointment.id,
          practiceId,
          original: {
            state: appointment.state,
            date: appointment.date,
            asap: appointment.asap,
          },
          data: {
            ...getAppointmentRequest(appointment),
            state: newState,
          },
        },
        {
          onError: (err) => {
            handleError(err);
            onRequestClose();
          },
          onSuccess: onRequestClose,
        }
      );
    },
    [mutateAppointment, onRequestClose, practiceId, appointment, onMenuBusy]
  );

  const viewOverride = actions?.view;
  const handleViewAppointment = useCallback(
    (option: AppointmentMenuOption) => {
      if (viewOverride) {
        viewOverride(option);
      } else if (appointment.state === "UNSCHEDULED") {
        navigate(
          paths.schedule({
            patientId: appointment.patient.id,
            appointmentId: appointment.id,
            showHolds: true,
          })
        );
      } else {
        navigate(
          paths.schedule({
            patientId: appointment.patient.id,
            appointmentId: appointment.id,
            date: appointment.date,
          })
        );
      }

      onRequestClose();
    },
    [navigate, appointment, viewOverride, onRequestClose]
  );

  const handleDeleteSelectedAppointment = useCallback(() => {
    onMenuBusy();
    requestDeleteAppointment(appointment, {
      onSuccess: () => {
        onAppointmentDeleted?.({ patientId: appointment.patient.id, appointmentId: appointment.id });
        onRequestClose();
      },
      onError: () => {
        onRequestClose();
      },
    });
  }, [requestDeleteAppointment, onAppointmentDeleted, onRequestClose, appointment, onMenuBusy]);

  const editOverride = actions?.edit;
  const handleEditAppointment = useCallback(
    (option: AppointmentMenuOption) => {
      if (editOverride) {
        editOverride(option);
      } else {
        const url = paths.editAppointment(
          { appointmentId: appointment.id, patientId: appointment.patient.id },
          { from: getFullUrl(location) }
        );

        navigate(url);
      }

      onRequestClose();
    },
    [navigate, location, appointment, editOverride, onRequestClose]
  );

  const openAuditLog = apptAuditLog.open;
  const print = printPromise.call;
  const appointmentMenuActions = useMemo(
    () => ({
      history: (option: AppointmentMenuOption) => openAuditLog(option.appointment),
      print: (option: AppointmentMenuOption) => {
        if (showPrintAppointmentModal(option.appointment)) {
          return;
        }

        print();
      },
      hold: () => handleUpdateAppointmentState("UNSCHEDULED"),
      view: handleViewAppointment,
      accept: () => handleUpdateAppointmentState("UNCONFIRMED"),
      edit: handleEditAppointment,
      delete: handleDeleteSelectedAppointment,
    }),
    [
      handleEditAppointment,
      handleViewAppointment,
      handleDeleteSelectedAppointment,
      print,
      showPrintAppointmentModal,
      openAuditLog,
      handleUpdateAppointmentState,
    ]
  );

  const handleAppointmentMenuOptionSelected = useCallback(
    (option: AppointmentMenuOption) => {
      if (!actionInProgress) {
        appointmentMenuActions[option.value](option);
      }
    },
    [appointmentMenuActions, actionInProgress]
  );

  return (
    <>
      <MenuOptions options={options} onOptionClick={handleAppointmentMenuOptionSelected} />

      {apptAuditLog.isOpen && (
        <AppointmentAuditLog
          patientId={appointment.patient.id}
          appointmentId={apptAuditLog.item.id}
          onRequestClose={() => {
            apptAuditLog.close();
            onRequestClose();
          }}
        />
      )}
      {printAppointmentModal.isOpen ? (
        <ConfirmInsuranceEstimateModal
          type="print-appointment"
          title="Print Appointment Estimate"
          onConfirm={(forceInsuranceEstimate) => printPromise.call(forceInsuranceEstimate)}
          onRequestClose={() => {
            printAppointmentModal.close();
            onRequestClose();
          }}
        />
      ) : null}
    </>
  );
};
