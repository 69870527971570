import { FC } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon, ButtonIconProps } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu, ButtonMenuProps } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import {
  AppointmentMenuActionProps,
  AppointmentMenuActions,
} from "components/ScheduleAppointments/AppointmentMenuActions";

export interface AppointmentMenuProps
  extends Omit<ButtonMenuProps, "isOpen" | "onRequestClose" | "onRequestOpen" | "menuContent" | "children"> {
  appointment: AppointmentVO;
  triggerSize?: ButtonIconProps["size"];
  isOnSchedule?: AppointmentMenuActionProps["isOnSchedule"];
  onTriggerClick?: Func;
  onAppointmentDeleted?: AppointmentMenuActionProps["onAppointmentDeleted"];
  actions?: AppointmentMenuActionProps["actions"];
}

export const AppointmentMenu: FC<AppointmentMenuProps> = ({
  appointment,
  actions,
  onTriggerClick,
  onAppointmentDeleted,
  isOnSchedule,
  triggerSize,
  ...buttonMenuProps
}) => {
  const isMenuBusy = useBoolean(false);
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      {...buttonMenuProps}
      isOpen={menu.isOn}
      onRequestClose={() => {
        if (isMenuBusy.isOff) {
          menu.off();
        }
      }}
      onRequestOpen={menu.on}
      menuContent={
        <AppointmentMenuActions
          actions={actions}
          onRequestClose={() => {
            isMenuBusy.off();
            menu.off();
          }}
          onMenuBusy={isMenuBusy.on}
          onAppointmentDeleted={onAppointmentDeleted}
          isOnSchedule={isOnSchedule}
          appointment={appointment}
        />
      }
    >
      {(props) => <ButtonIcon {...props} onClick={onTriggerClick} SvgIcon={MenuIcon} size={triggerSize} />}
    </ButtonMenu>
  );
};
