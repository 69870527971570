import { FC } from "react";

import { UserAccountVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";

import { signedInRedirect } from "utils/routing/paths";
import { AccountsList } from "components/Main/AccountsList";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { getUserAccounts } from "api/user/queries";

export const AccountPickerModal: FC<{
  onRequestClose: Func;
}> = ({ onRequestClose }) => {
  const { id } = useAccount();

  const [userAccountsQuery] = useApiQueries([getUserAccounts({ args: { userTypes: ["EMPLOYEE"] } })]);

  const handleAccountSelected = (account: UserAccountVO) => {
    if (id === account.id) {
      onRequestClose();
    } else {
      postAuthChannelMessage({
        type: "selectAccount",
        userId: account.id,
      });
      window.location.assign(signedInRedirect);
    }
  };

  return (
    <Modal centerVertically={false} onClickOutside={onRequestClose} onClose={onRequestClose} size="fit">
      <ModalContent className="px-3">
        <QueryResult queries={[userAccountsQuery]}>
          {userAccountsQuery.data ? (
            <>
              <AccountsList
                title="Switch Account"
                accounts={userAccountsQuery.data}
                onAccountSelected={handleAccountSelected}
                currentAccountId={id}
              />
              <div className="flex items-center justify-center py-6">
                <Button className="text-sm" theme="link" onClick={onRequestClose}>
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
        </QueryResult>
      </ModalContent>
    </Modal>
  );
};
