import {
  ContactDetailsVO,
  ContactModeVO,
  InsuranceDetailsVO,
  PatientSummaryVO,
} from "@libs/api/generated-api";
import { isObject, isString } from "@libs/utils/types";
import { required, phoneNumber, email, min, ssn, notEq, notInNumberSet } from "@libs/utils/validators";
import { ValidationResult } from "@libs/hooks/useValidation";
import { lowercase } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { ContactMode, hasAllowedContactMode } from "components/Patient/contactModes";
import { CreatePatientFormState, UpdateInsuranceFormState } from "components/Patient/formPostData";
import { ContactDraft, PatientDraft, SubscriberIdType } from "components/Patient/types";

const patientValidators = {
  dob: {
    $v: required,
    $error: "Date of birth is required",
  },

  firstName: {
    $v: required,
    $error: "First name is required",
  },

  lastName: {
    $v: required,
    $error: "Last name is required",
  },

  relationship: (options?: { ignore?: boolean }) => ({
    $v: required,
    $error: "Relationship is required",
    $ignore: Boolean(options?.ignore),
  }),

  status: {
    $v: required,
    $error: "Status is required",
  },
  contactModes: (options?: { ignore?: boolean }) => ({
    $v: (val: unknown) => {
      if (!val || !isObject(val)) {
        return true;
      }

      const values = Object.values(val);

      if (!values.length) {
        return true;
      }

      return values.some(Boolean);
    },
    $error: "At least one contact method must be allowed",
    $ignore: Boolean(options?.ignore),
  }),
  preferredContactMode: (contactModes: ContactModeVO, options?: { ignore?: boolean }) => ({
    $v: (val: unknown) => {
      const value = required(val) && isString(val) && contactModes[lowercase(val) as ContactMode];

      return value;
    },
    $error: "A preferred contact method is required",
    $ignore: !hasAllowedContactMode(contactModes) || Boolean(options?.ignore),
  }),

  email: (
    contactModes: ContactModeVO,
    preferredContactMode: ContactDetailsVO["preferredContactMode"] | undefined,
    options?: { ignore?: boolean }
  ) => ({
    $v: required,
    $error: "Preferred contact method requires email.",
    $ignore: Boolean(options?.ignore) || preferredContactMode !== "EMAIL" || !contactModes.email,
  }),
  emailFormat: {
    $v: email,
    $error: "Please enter a valid email",
  },

  phoneNumber: (
    contactModes: ContactModeVO,
    preferredContactMode: ContactDetailsVO["preferredContactMode"] | undefined,
    options?: { ignore?: boolean }
  ) => ({
    $v: required,
    $error: `Preferred contact method requires phone number.`,
    $ignore:
      Boolean(options?.ignore) ||
      !preferredContactMode ||
      !isOneOf(preferredContactMode, ["CALL", "TEXT"]) ||
      !contactModes[lowercase(preferredContactMode)],
  }),
  phoneNumberFormat: {
    $v: phoneNumber,
    $error: "Please enter a valid phone number",
  },
};

const insuranceValidators = {
  carrierId: {
    $v: min(1),
    $error: "Please select an insurance carrier",
  },
  ssn: {
    $v: required,
    $error: "Please enter a social security number",
  },
  ssnFormat: {
    $v: ssn,
    $error: "Please enter a valid social security number",
  },
  subscriberId: {
    $v: required,
    $error: "Please enter a subscriber ID",
  },
};

interface PatientSchemaArgs {
  contactModes: ContactModeVO;
  preferredContactMode: ContactDetailsVO["preferredContactMode"] | undefined;
  usingContact: boolean;
  ignoreContactMode?: boolean;
  relationshipRequired?: boolean;
}

const getPatientSchema = ({
  contactModes,
  preferredContactMode,
  usingContact,
  ignoreContactMode = false,
  relationshipRequired,
}: PatientSchemaArgs) => {
  return {
    dob: [patientValidators.dob],
    firstName: [patientValidators.firstName],
    lastName: [patientValidators.lastName],
    status: [patientValidators.status],
    contactModes: [patientValidators.contactModes({ ignore: usingContact })],
    preferredContactMode: [patientValidators.preferredContactMode(contactModes, { ignore: usingContact })],
    email: [
      patientValidators.email(contactModes, preferredContactMode, {
        ignore: usingContact || ignoreContactMode,
      }),
      patientValidators.emailFormat,
    ],
    phoneNumber: [
      patientValidators.phoneNumber(contactModes, preferredContactMode, {
        ignore: usingContact || ignoreContactMode,
      }),
      patientValidators.phoneNumberFormat,
    ],
    relationship: [patientValidators.relationship({ ignore: !relationshipRequired })],
  };
};

export const getEditPatientSchema = (args: PatientSchemaArgs) => {
  return {
    ...getPatientSchema(args),
    ssn: [insuranceValidators.ssnFormat],
  };
};

export const getContactSchema = (args: Pick<PatientSchemaArgs, "contactModes" | "preferredContactMode">) => {
  return getPatientSchema({ ...args, usingContact: false, relationshipRequired: true });
};

const getFamilyMemberPatientSchema = (
  args: Pick<PatientSchemaArgs, "usingContact" | "contactModes" | "preferredContactMode">
) => {
  return getPatientSchema({ ...args, relationshipRequired: true });
};

const getPrimarySubscriberSchema = ({
  insuranceSubscriberType,
  primarySubscriberSubscriberIdType,
  usingPatientSsn,
}: {
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  primarySubscriberSubscriberIdType: SubscriberIdType;
  usingPatientSsn: boolean;
}) => ({
  $ignore: insuranceSubscriberType !== "PRIMARY_SUBSCRIBER",
  carrierId: [insuranceValidators.carrierId],
  ssn: {
    $ignore: primarySubscriberSubscriberIdType !== "ssn" || usingPatientSsn,
    $validations: [insuranceValidators.ssn, insuranceValidators.ssnFormat],
  },
  subscriberId: {
    $ignore: primarySubscriberSubscriberIdType !== "subscriberId",
    $validations: [insuranceValidators.subscriberId],
  },
});

const getDependentPrimarySubscriberSchema = ({
  insuranceSubscriberType,
  dependentPrimarySubscriberIdType,
  isCreatingInsurance,
}: {
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  dependentPrimarySubscriberIdType: SubscriberIdType;
  // Once insurance is created, you can't change subscriberId or ssn for a dependent
  isCreatingInsurance: boolean;
}) => ({
  $ignore: insuranceSubscriberType !== "DEPENDENT",
  firstName: [patientValidators.firstName],
  lastName: [patientValidators.lastName],
  dob: [patientValidators.dob],
  relationship: [patientValidators.relationship()],
  carrierId: [insuranceValidators.carrierId],
  ssn: {
    $ignore: dependentPrimarySubscriberIdType !== "ssn" || !isCreatingInsurance,
    $validations: [insuranceValidators.ssn, insuranceValidators.ssnFormat],
  },
  subscriberId: {
    $ignore: dependentPrimarySubscriberIdType !== "subscriberId" || !isCreatingInsurance,
    $validations: [insuranceValidators.subscriberId],
  },
});

export const getCreatePatientSchema = ({
  dependentPrimarySubscriberIdType,
  insuranceSubscriberType,
  usingContact,
  contactModes,
  preferredContactMode,
  primarySubscriberSubscriberIdType,
}: {
  dependentPrimarySubscriberIdType: SubscriberIdType;
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  primarySubscriberSubscriberIdType: SubscriberIdType;
} & PatientSchemaArgs) => {
  const primarySubscriberSchema = getPrimarySubscriberSchema({
    insuranceSubscriberType,
    primarySubscriberSubscriberIdType,
    usingPatientSsn: false,
  });

  const dependentPrimarySubscriberSchema = getDependentPrimarySubscriberSchema({
    dependentPrimarySubscriberIdType,
    insuranceSubscriberType,
    isCreatingInsurance: true,
  });

  return {
    dependentPrimarySubscriber: dependentPrimarySubscriberSchema,
    patientDraft: getPatientSchema({
      contactModes,
      preferredContactMode,
      usingContact,
      relationshipRequired: false,
    }),
    patientMatch: [
      {
        $v: (value: unknown) => !value,
        $error: "This patient is already in the system.",
      },
    ],
    primarySubscriber: primarySubscriberSchema,
  };
};

export const getPatientInsuranceSchema = ({
  dependentPrimarySubscriberIdType,
  insuranceSubscriberType,
  isCreatingInsurance,
  primarySubscriberSubscriberIdType,
  usingPatientSsn,
}: {
  dependentPrimarySubscriberIdType: SubscriberIdType;
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  isCreatingInsurance: boolean;
  primarySubscriberSubscriberIdType: SubscriberIdType;
  usingPatientSsn: boolean;
}) => {
  const primarySubscriberSchema = getPrimarySubscriberSchema({
    insuranceSubscriberType,
    primarySubscriberSubscriberIdType,
    usingPatientSsn,
  });

  const dependentPrimarySubscriberSchema = getDependentPrimarySubscriberSchema({
    dependentPrimarySubscriberIdType,
    insuranceSubscriberType,
    isCreatingInsurance,
  });

  return {
    dependentPrimarySubscriber: dependentPrimarySubscriberSchema,
    primarySubscriber: primarySubscriberSchema,
  };
};

export const getAddFamilyMemberFormSchema = ({
  currentPatientId,
  familyMemberIdsSet,
  insuranceSubscriberType,
  isExistingPatient,
  patientMatch,
  contactModes,
  preferredContactMode,
  usingContact,
  primarySubscriberSubscriberIdType,
  selectedPrimarySubscriberId,
}: {
  currentPatientId: number;
  familyMemberIdsSet: Set<number>;
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  isExistingPatient: boolean;
  patientMatch: PatientSummaryVO | undefined;
  primarySubscriberSubscriberIdType: SubscriberIdType;
  selectedPrimarySubscriberId: number;
} & PatientSchemaArgs) => {
  const primarySubscriberSchema = getPrimarySubscriberSchema({
    insuranceSubscriberType,
    primarySubscriberSubscriberIdType,
    usingPatientSsn: false,
  });

  return {
    isExistingPatient: [
      {
        $v: (value: unknown) => Boolean(value) || !patientMatch,
        $error: "This patient is already in the system.",
      },
    ],
    existingPatient: {
      $ignore: !isExistingPatient,
      patientId: [
        { $v: min(1), $error: "Please select a patient" },
        { $v: notEq(currentPatientId), $error: "A patient cannot be a family member to themself." },
        { $v: notInNumberSet(familyMemberIdsSet), $error: "This patient is already a family member." },
      ],
      relationship: [patientValidators.relationship()],
    },
    patientDraft: {
      ...getFamilyMemberPatientSchema({
        contactModes,
        preferredContactMode,
        usingContact,
      }),
      $ignore: isExistingPatient,
    },
    primarySubscriber: {
      ...primarySubscriberSchema,
      $ignore: primarySubscriberSchema.$ignore || isExistingPatient,
    },
    selectedPrimarySubscriber: {
      $ignore: insuranceSubscriberType !== "DEPENDENT" || isExistingPatient,
      patientId: [
        {
          $v: min(1),
          $error: "Please select a primary subscriber",
        },
      ],
      carrierId: [insuranceValidators.carrierId],
      relationship: [
        {
          $v: required,
          $error: "Please select a relationship",
          $ignore: currentPatientId === selectedPrimarySubscriberId,
        },
      ],
    },
  };
};

export type EditPatientValidation = ValidationResult<
  {
    dob: string;
    firstName: string;
    lastName: string;
    status: "PROSPECT" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "NONPATIENT" | "DECEASED" | undefined;
    ssn: string | null | undefined;
    contactModes: ContactModeVO;
    preferredContactMode: "CALL" | "TEXT" | "EMAIL";
    email: string | undefined;
    phoneNumber: string | undefined;
  },
  ReturnType<typeof getEditPatientSchema>
>;

export type ContactValidation = ValidationResult<ContactDraft, ReturnType<typeof getContactSchema>>;

export type PatientValidation = ValidationResult<PatientDraft, ReturnType<typeof getPatientSchema>>;

export type CreatePatientValidation = ValidationResult<
  CreatePatientFormState,
  ReturnType<typeof getCreatePatientSchema>
>;

export type CreateInsuranceValidation = ValidationResult<
  UpdateInsuranceFormState,
  ReturnType<typeof getPatientInsuranceSchema>
>;
