import { ReactNode } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { useItemModal } from "hooks/useItemModal";
import { Flyover, FlyoverProps } from "components/UI/Flyover";

type DirtyFlyoverProps = Pick<FlyoverProps, "onClose" | "overlay" | "size" | "dataTestId">;

export const DirtyFlyover = ({
  children,
  isDirty,
  ...flyoverProps
}: {
  isDirty: boolean;
  children: (props: { confirm: (props: { onConfirm: Func }) => void; onRequestClose: Func }) => ReactNode;
} & DirtyFlyoverProps) => {
  const startClose = useBoolean(false);

  const confirmLeave = useItemModal<{ onConfirm: Func }>(null);

  const handleClose = () => {
    if (isDirty) {
      confirmLeave.open({ onConfirm: startClose.on });
    } else {
      startClose.on();
    }
  };

  return (
    <Flyover
      clickAwayToClose={false}
      onClickAway={handleClose}
      triggerClose={startClose.isOn}
      {...flyoverProps}
    >
      <>
        {children({ onRequestClose: handleClose, confirm: confirmLeave.open })}
        {confirmLeave.isOpen ? (
          <ConfirmationModal
            onCancel={confirmLeave.close}
            onConfirm={() => {
              confirmLeave.item.onConfirm();
              confirmLeave.close();
            }}
            size="2xs"
            confirmText="Yes"
            cancelText="No"
            primaryText="Changes Made"
            secondaryText="Changes will be lost, do you still want to leave?"
          />
        ) : null}
      </>
    </Flyover>
  );
};
