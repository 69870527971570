import { useAccount } from "@libs/contexts/AccountContext";
import { useContext, useEffect } from "react";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";

// Learnupon SSO: https://grindfoundry.atlassian.net/browse/GF-7831
// To login to manage learnupon use https://archy.learnupon.com/users/sign_in?no_sso=true to avoid SSO
export const SSOLearnuponRoute = () => {
  const { practiceId } = useAccount();
  const { httpClient } = useContext(ApiClientContext);
  const navigate = useNavigate();
  const currentPortal = usePortalElement();

  useEffect(() => {
    httpClient.practices
      .learnUponSso(practiceId)
      .then((response) => {
        window.location.href = response.data.data.url;
      })
      .catch((e) => {
        handleError(e);
        navigate(paths.home());
      });
  }, [httpClient.practices, navigate, practiceId]);

  return (
    currentPortal &&
    createPortal(
      <div className="absolute z-10 inset-0">
        <LoadingOverlay isLoading loadingText="Loading Training Center..." />
      </div>,
      currentPortal
    )
  );
};
