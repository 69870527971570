import { isInstanceOf } from "@sentry/utils";
import { captureException } from "@sentry/react";

export class DocumentUploadError extends Error {
  responseText;
  folderId;
  filename;
  constructor({
    errorString,
    responseText,
    folderId,
    filename,
  }: {
    errorString: string;
    responseText: string;
    folderId: number;
    filename: string;
  }) {
    super(errorString);
    this.name = this.constructor.name;
    this.responseText = responseText;
    this.folderId = folderId;
    this.filename = filename;
  }
}

export const isDocumentUploadError = (error: unknown): error is DocumentUploadError => {
  return isInstanceOf(error, DocumentUploadError);
};

export const handleDocumentUploadError = (error: DocumentUploadError) => {
  let extraErrorInfo: Record<string, string | undefined | null> = {};
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(error.responseText, "application/xml");
  const errorNode = xmlDoc.querySelector("Error");

  if (errorNode) {
    extraErrorInfo = {
      ...extraErrorInfo,
      errorCode: errorNode.querySelector("Code")?.textContent,
      errorMessage: errorNode.querySelector("Message")?.textContent,
      requestId: errorNode.querySelector("RequestId")?.textContent,
    };
  }

  captureException(error, {
    contexts: {
      ["Document Upload Error"]: {
        folderId: error.folderId,
        filename: error.filename,
        ...extraErrorInfo,
      },
    },
  });
};
