import React, { ReactNode } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ReactComponent as NoShowIcon } from "@libs/assets/icons/warning-circle-filled.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle-filled.svg";
import { Icon } from "@libs/components/UI/Icon";
import { isOneOf } from "@libs/utils/isOneOf";
import { getStateStyles } from "components/ScheduleAppointments/statusOptions";

interface Props {
  appointmentState: AppointmentVO["state"];
  isSelected: boolean;
  size?: "sm";
  children?: ReactNode;
}

export const AppointmentStatus: React.FC<Props> = ({ appointmentState, isSelected, children, size }) => {
  const stateStyle = getStateStyles(appointmentState);

  return (
    <>
      {isOneOf(appointmentState, ["NO_SHOW", "CANCELED"]) ? (
        <div className={cx("relative", size === "sm" ? "w-3 h-3" : "w-4 h-4")}>
          <Icon
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            size={size ?? "md"}
            SvgIcon={appointmentState === "NO_SHOW" ? NoShowIcon : CancelIcon}
          />
        </div>
      ) : (
        <div
          className={cx(
            "rounded-full relative overflow-hidden",
            stateStyle,
            size === "sm" ? "w-3 h-3" : "w-4 h-4"
          )}
        />
      )}
      {children && <span className={cx("block ml-2", isSelected && "font-sansSemiBold")}>{children}</span>}
    </>
  );
};
