import { ContactVO, PatientSummaryVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientQuery } from "api/patients/queries";
import { EditContactPanelForm } from "components/Patient/EditContactPanelForm";

interface Props {
  onCancel: Func;
  onDirty: Func;
  onMatchSelected: (patient: PatientSummaryVO) => void;
  onContactSelected: (contact: ContactVO) => void;
  contactPatientId: number;
  patientFirstName: string;
}

export const EditContactPanel: React.FC<Props> = ({
  onCancel,
  onDirty,
  onMatchSelected,
  contactPatientId,
  onContactSelected,
  patientFirstName,
}) => {
  const { practiceId } = useAccount();
  const [contactPatientQuery] = useApiQueries([
    getPatientQuery({
      args: { patientId: contactPatientId, practiceId },
    }),
  ]);

  return (
    <QueryResult queries={[contactPatientQuery]}>
      {contactPatientQuery.data ? (
        <EditContactPanelForm
          onDirty={onDirty}
          patient={contactPatientQuery.data}
          onContactUpdated={onContactSelected}
          onMatchSelected={onMatchSelected}
          onCancel={onCancel}
          patientFirstName={patientFirstName}
        />
      ) : null}
    </QueryResult>
  );
};
