import { useEffect } from "react";
import { useWebSocketContext } from "contexts/WebSocketContext";
import { ServerPushMessages } from "api/websocket/types";
import { WebSocketMessageEvent } from "api/websocket/webSocketMessage";

export const useWebSocketMessage = (onMessageHandler?: (data: ServerPushMessages) => void) => {
  const { messages } = useWebSocketContext();

  useEffect(() => {
    if (messages && onMessageHandler) {
      const handler = (event: WebSocketMessageEvent) => {
        onMessageHandler(event.detail);
      };

      messages.addEventListener("message", handler);

      return () => {
        messages.removeEventListener("message", handler);
      };
    }

    return undefined;
  }, [messages, onMessageHandler]);
};
