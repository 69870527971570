import { useMemo } from "react";
import { MenuPlacement } from "react-select";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { FormFieldLayout } from "@libs/types/form";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { Select, SelectProps, DisplayValue, findSelectedOption } from "@libs/components/UI/Select";
import { createSelectStyles, mergeSelectStyles } from "@libs/components/UI/selectStyles";

export type FormFieldSelectProps<V extends SelectOptionValue, T extends SelectOption<V>> = FormFieldProps &
  Omit<SelectProps<V, T>, "isDisabled" | "className">;

export const getSelectLayoutStyles = ({
  layout,
  hasLabel,
}: {
  layout: FormFieldLayout;
  hasLabel: boolean;
}) => {
  const dimension = layout === "labelLeft" ? "0.75rem" : "1rem";
  const isLabelOut = layout === "labelOut";
  const isLabelLeft = layout === "labelLeft";
  const isTableValue = layout === "tableValue";
  const horizPadding = isLabelLeft ? "0.25rem" : isTableValue ? "0.5rem" : "1rem";
  const verticalPadding = (!hasLabel && !isLabelLeft) || isLabelOut || isTableValue ? "0.1875rem" : "0rem";

  return {
    dropdownIndicator: () => ({
      height: dimension,
      width: dimension,
    }),
    clearIndicator: () => ({
      height: dimension,
      width: dimension,
    }),
    control: () => ({
      minHeight: isLabelLeft ? "1.25rem" : isLabelOut || isTableValue || !hasLabel ? "2rem" : "1.5rem",
      paddingTop: verticalPadding,
      paddingBottom: verticalPadding,
      paddingRight: horizPadding,
      paddingLeft: horizPadding,
    }),
  };
};

export const getFormFieldSelectStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
>(
  layout: FormFieldLayout,
  hasLabel: boolean,
  menuPlacement?: MenuPlacement
) => {
  return createSelectStyles<V, T, IsMulti>({
    ...getSelectLayoutStyles({ layout, hasLabel }),
    menu: () => ({
      fontSize: ".8rem",
      marginBottom: menuPlacement === "top" ? "1.5rem" : "0.5rem",
    }),
  });
};

export const FormFieldSelect = <V extends SelectOptionValue, T extends SelectOption<V>>({
  disabled,
  required,
  label,
  error,
  className,
  styles,
  displayErrorMessage = true,
  description,
  id,
  layout,
  edit = true,
  menuPlacement = "auto",
  containerClassName,
  inputClassName,
  ...props
}: FormFieldSelectProps<V, T>) => {
  const isCreatable = Boolean(props.onCreateOption);
  const fieldId = useEnsureId({ customId: id });
  const formContext = useFormContext();
  const mergedFormContext = useMergeFormContext(formContext, { layout });

  const selectStyles = useMemo(() => {
    const commonStyles = getFormFieldSelectStyles<V, T, false>(
      mergedFormContext.layout,
      Boolean(label),
      menuPlacement
    );

    return mergeSelectStyles(commonStyles, styles);
  }, [mergedFormContext.layout, label, menuPlacement, styles]);

  const selectedOption = useMemo(
    () => findSelectedOption(props.options, props.value),
    [props.options, props.value]
  );

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      layout={mergedFormContext.layout}
      className={className}
      containerClassName={containerClassName}
      displayErrorMessage={displayErrorMessage}
      edit={edit}
      description={description}
      id={fieldId}
    >
      {edit ? (
        <div className={cx(inputClassName, cxFormFieldStyle.wrapper)}>
          <Select
            inputId={fieldId}
            isClearable={!required}
            {...props}
            styles={selectStyles}
            isDisabled={disabled}
            menuPlacement={menuPlacement}
          />
        </div>
      ) : (
        <span
          aria-labelledby={fieldId}
          className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}
        >
          {selectedOption || (isCreatable && props.value) ? (
            <DisplayValue
              display={props.display}
              option={selectedOption ?? ({ value: props.value, label: props.value } as T)}
            />
          ) : (
            "-"
          )}
        </span>
      )}
    </FormField>
  );
};
