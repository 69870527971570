import { FC, PropsWithChildren, Ref, useMemo } from "react";
import { subDays } from "date-fns";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { getRelations } from "@libs/hooks/useRelations";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { ContactValidation } from "components/Patient/formSchemas";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { PatientMatch } from "components/Patient/PatientMatch";
import { STATUS_OPTIONS } from "components/Patients/FiltersFlyoverContent";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldContactModes } from "components/Patient/FormFieldContactModes";
import { FormFieldPreferredContactMode } from "components/Patient/FormFieldPreferredContactMode";
import { ContactDraft } from "components/Patient/types";
import { AddressFields } from "components/Patient/AddressFields";
import { getPreferredContactMode } from "components/Patient/contactModes";

interface Props {
  onSelectPatientMatch?: (matchedPatient: PatientSummaryVO) => void;
  onUpdate: (updates: Partial<ContactDraft>) => void;
  contractDraft: ContactDraft;
  patientMatch?: PatientSummaryVO;
  relationshipLabel?: string;
  validations: ContactValidation;
  containerRef: Ref<HTMLDivElement>;
  isCreatingContact?: boolean;
}

export const ContactFormFields: FC<PropsWithChildren<Props>> = ({
  onSelectPatientMatch,
  onUpdate,
  contractDraft,
  patientMatch,
  relationshipLabel,
  validations,
  containerRef,
  children,
  isCreatingContact,
}) => {
  const yesterday = useMemo(() => subDays(new Date(), 1), []);

  return (
    <div ref={containerRef}>
      {children}
      <div className="grid gap-4 grid-cols-4">
        {patientMatch && (
          <div className="col-span-4">
            {patientMatch.contact.relation === "SELF" ? (
              <PatientMatch onSelect={onSelectPatientMatch} ctaText="Use as contact" patient={patientMatch} />
            ) : (
              <PatientMatch patient={patientMatch}>
                <p>
                  A patient with the same name and birthday is already in the system. They can&apos;t be used
                  as a contact because they already have someone assigned as their contact.
                </p>
              </PatientMatch>
            )}
          </div>
        )}

        <FormFieldSelect
          className="col-span-4"
          error={validations.relationship.$error}
          isClearable={false}
          isSearchable={false}
          label={relationshipLabel ?? "Relationship"}
          onChange={(option) => onUpdate({ relationship: option?.value })}
          options={getRelations().filter((option) => option.value !== "SELF")}
          required
          value={contractDraft.relationship}
        />

        <FormFieldInput
          className="col-span-2"
          error={validations.firstName.$error}
          label="First Name"
          onChange={(e) => onUpdate({ firstName: e.target.value })}
          required
          value={contractDraft.firstName}
        />
        <FormFieldInput
          className="col-span-2"
          error={validations.lastName.$error}
          label="Last Name"
          onChange={(e) => onUpdate({ lastName: e.target.value })}
          required
          value={contractDraft.lastName}
        />
        <FormFieldSelectMenusDatepicker
          className="col-span-2"
          error={validations.dob.$error}
          label="Date of Birth"
          maxDate={yesterday}
          onChange={(newDate) => onUpdate({ dob: newDate })}
          required
          selected={contractDraft.dob}
        />
        <FormFieldSelect
          className="col-span-2"
          error={validations.status.$error}
          isClearable={false}
          isSearchable={false}
          label="Status"
          onChange={(option) => onUpdate({ status: option?.value })}
          options={
            isCreatingContact ? STATUS_OPTIONS.filter((op) => op.value !== "DECEASED") : STATUS_OPTIONS
          }
          required
          display="label"
          value={contractDraft.status ?? "NONPATIENT"}
        />

        <FormFieldContactModes
          error={validations.contactModes.$error}
          contactModes={contractDraft.contactModes}
          className="col-span-2"
          onChange={(val) => {
            onUpdate({
              contactModes: val,
              preferredContactMode: getPreferredContactMode(val, contractDraft.preferredContactMode),
            });
          }}
        />

        <FormFieldPreferredContactMode
          error={validations.preferredContactMode.$error}
          contactModes={contractDraft.contactModes}
          preferredContactMode={contractDraft.preferredContactMode}
          className="col-span-2"
          onChange={(value) => {
            onUpdate({
              preferredContactMode: value,
            });
          }}
        />
        <FormFieldPhoneInput
          className="col-span-2"
          Icon={InfoIcon}
          error={validations.phoneNumber.$error}
          label="Phone"
          onValueChange={(value) => onUpdate({ phoneNumber: value })}
          required={
            contractDraft.preferredContactMode &&
            isOneOf(contractDraft.preferredContactMode, ["TEXT", "CALL"])
          }
          value={contractDraft.phoneNumber}
        />
        <FormFieldInput
          className="col-span-2"
          error={validations.email.$error}
          label="Email"
          onChange={(e) => onUpdate({ email: e.target.value })}
          required={contractDraft.preferredContactMode === "EMAIL"}
          type="email"
          value={contractDraft.email}
        />

        <AddressFields
          address={contractDraft.addressDetails}
          onUpdate={(addressDetails) => onUpdate({ addressDetails })}
        />
      </div>
    </div>
  );
};
