import { getQueryKey } from "@libs/utils/queries";
import { QueryClient } from "@tanstack/react-query";
import { PearlAnalysisUpdatedEvent } from "api/websocket/types";

export const handlePearlAnalysisUpdated = ({
  data,
  queryClient,
  practiceId,
}: {
  data: PearlAnalysisUpdatedEvent;
  queryClient: QueryClient;
  practiceId: number;
}) => {
  const { patientId, imageId, mountId } = data;

  queryClient.invalidateQueries({
    exact: false,
    queryKey: [getQueryKey("practices", "getMount"), { practiceId, patientId, mountId }],
  });
  queryClient.invalidateQueries({
    exact: false,
    queryKey: [
      getQueryKey("practices", "getMedicalImageDetails"),
      {
        practiceId,
        patientId,
        imageId,
      },
    ],
  });
};
