import { PropsWithChildren } from "react";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FlyoverContent, FlyoverFooter, FlyoverForm, FlyoverHeader } from "components/UI/FlyoverComponents";

interface Props {
  isSaving: boolean;
  disabled?: boolean;
  dataTestId?: string;
  onSave: Func;
  onCancel: Func;
}

export const ContactForm: React.FC<PropsWithChildren<Props>> = ({
  isSaving,
  onSave,
  onCancel,
  disabled,
  children,
  dataTestId,
}) => {
  return (
    <FlyoverForm
      data-testid={dataTestId}
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <FlyoverHeader size="sm" onClose={onCancel}>
        Contact Person
      </FlyoverHeader>
      <FlyoverContent>{children}</FlyoverContent>
      <FlyoverFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary">
          Cancel
        </Button>
        <AsyncButton className="min-w-button" disabled={disabled} type="submit" isLoading={isSaving}>
          Add
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
