export enum CallType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export type CallDataState =
  | "PHONE_CALL_RINGING"
  | "PHONE_CALL_ANSWERED"
  | "PHONE_CALL_HANGUP"
  | "PHONE_CALL_INITIATED";

export type CallData = {
  callerId?: string;
  callMainId?: string;
  callType: CallType;
  display: boolean;
  extensionNumber: string;
  patientId?: number;
  phoneNumber: string;
  state: CallDataState;
};

export type CallsByNumberMap = Record<string, CallData>;
export type CallsToDisplayMap = {
  inboundCalls: CallsByNumberMap;
  ongoingCalls: CallsByNumberMap;
  outboundCalls: CallsByNumberMap;
};
export type CallsToDisplayMapKeys = keyof CallsToDisplayMap;
