/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, useState } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { UserAccountVO } from "@libs/api/generated-api";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { getIdentityToken } from "@libs/auth/getTokens";
import { handleError } from "utils/handleError";
import { INITIAL_PIN, Pin, PinModal } from "components/UI/PinModal";
import { issueAccountToken } from "api/user/mutations";

export const AccountSwitcherPinModal: FC<{
  onSwitchSuccess: Func;
  onClose: Func;
  account: UserAccountVO;
}> = ({ onClose, onSwitchSuccess, account }) => {
  const { localStorage } = useStorageContext();
  const [issueAccountTokenMutation] = useApiMutations([issueAccountToken]);
  const [pin, setPin] = useState<Pin>(() => INITIAL_PIN);

  const submitPin = (pinToSubmit: string) => {
    issueAccountTokenMutation.mutate(
      { userId: account.id, rememberMe: true, pin: pinToSubmit },
      {
        onSuccess: async (response) => {
          const accountTokenStorage = getAccountTokenStorage(localStorage);
          const { email } = await getIdentityToken();

          accountTokenStorage.setAccountToken(email, {
            ...response.data.data,
            practiceId: account.practice.id,
            practiceUuid: account.practice.uuid,
          });
          onSwitchSuccess();
        },
        onError: (err) => {
          if (!hasFailedPin(err)) {
            handleError(err);
          }

          setPin(INITIAL_PIN);
        },
      }
    );
  };

  return (
    <PinModal
      onClose={onClose}
      onSubmit={submitPin}
      error={issueAccountTokenMutation.error}
      isVerifyingPin={issueAccountTokenMutation.isLoading}
      pin={pin}
      onChangePin={setPin}
    />
  );
};
