import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as SuccessIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";

const Icons = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: WarningIcon,
  info: InfoIcon,
  announcement: WarningIcon,
} as const;

type Theme = keyof typeof Icons;

const cxThemesClassName: Record<Theme, { container: string; icon: string }> = {
  success: {
    container: "bg-greenLight",
    icon: "text-greenDark",
  },
  warning: {
    container: "bg-orangeLight dark:bg-slate-800",
    icon: "text-orangeDark dark:text-white",
  },
  error: {
    container: "bg-redLight",
    icon: "text-redDark",
  },
  info: {
    container: "bg-whiteLight",
    icon: "text-greyMedium",
  },
  announcement: {
    container: "bg-blueLight",
    icon: "text-blueDark",
  },
};

const cxStyles = {
  container: (theme: Theme) => cxThemesClassName[theme].container,
  icon: (theme: Theme) => cxThemesClassName[theme].icon,
};

const cxIconAlignment = {
  center: "items-center",
  start: "items-start",
};

interface Props {
  theme: Theme;
  className?: string;
  paddingClassName?: string;
  includeIcon?: boolean;
  Icon?: IconComponent;
  children?: ReactNode;
  onRequestClose?: Func;
  contentAlignment?: keyof typeof cxIconAlignment;
}

export const Banner: FC<Props> = ({
  theme,
  children,
  className,
  contentAlignment = "center",
  paddingClassName = "px-3 py-2.5",
  includeIcon = true,
  Icon,
  onRequestClose,
  ...rest
}) => {
  const BannerIcon = Icon ?? Icons[theme];

  return (
    <div
      role="banner"
      className={cx(
        "flex justify-between gap-x-3",
        cxIconAlignment[contentAlignment],
        cxStyles.container(theme),
        paddingClassName,
        className
      )}
      {...rest}
    >
      <div className={cx("flex gap-x-3 w-full", cxIconAlignment[contentAlignment])}>
        {includeIcon ? <BannerIcon className={cx("h-5 w-5 flex-none", cxStyles.icon(theme))} /> : null}
        {children}
      </div>

      {onRequestClose ? (
        <ButtonIcon SvgIcon={CloseIcon} className={cxStyles.icon(theme)} onClick={onRequestClose} />
      ) : null}
    </div>
  );
};
